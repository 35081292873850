import { request } from "./request.js";
export function invoiceWaitList(data = {}) {
  return request({
    url: "invoice/invoiceWaitList",
    method: "post",
    data,
  });
}
export function getCompShippingAdd(data = {}) {
  return request({
    url: "invoice/getCompShippingAdd",
    method: "post",
    data,
  });
}
export function getCurrencyList(data = {}) {
  return request({
    url: "invoice/getCurrencyList",
    method: "post",
    data,
  });
}
export function createInvoice(data = {}) {
  return request({
    url: "invoice/createInvoice",
    method: "post",
    data,
  });
}
export function addInvoice(data = {}) {
  return request({
    url: "invoice/addInvoice",
    method: "post",
    data,
  });
}
export function getInvoiceDetailList(data = {}) {
  return request({
    url: "invoice/getInvoiceDetailList",
    method: "post",
    data,
  });
}
export function addInvoiceDetails(data = {}) {
  return request({
    url: "invoice/addInvoiceDetails",
    method: "post",
    data,
  });
}
export function delInvoiceDetails(data = {}) {
  return request({
    url: "invoice/delInvoiceDetails",
    method: "post",
    data,
  });
}
export function invoiceList(data = {}) {
  return request({
    url: "invoice/invoiceList",
    method: "post",
    data,
  });
}
export function delInvoice(data = {}) {
  return request({
    url: "invoice/delInvoice",
    method: "post",
    data,
  });
}
export function confirmInvoiceList(data = {}) {
  return request({
    url: "invoice/confirmInvoiceList",
    method: "post",
    data,
  });
}
