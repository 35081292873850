export default {
  selection: true,
  searchFields: [
    // 搜索组件的配置项
    {
      name: "输入日期，格式：2022/07/01",
      value: "month",
      type: "input",
      isFixed: true,
      span: 4,
    },
    {
      name: "设置备注",
      value: "remark",
      type: "input",
      isFixed: true,
      span: 4,
    },
  ],
  columns: [
    // 表格列的配置
    {
      label: "归属月份",
      prop: "month",
      fixed: true,
    },
    {
      label: "计薪天数",
      prop: "paid_days",
    },
    {
      label: "法定假期",
      prop: "paid_leave",
    },
    {
      label: "测试费",
      prop: "pdt_test_fee",
    },
    {
      label: "公司保险费",
      prop: "comp_insurance",
    },
    {
      label: "阿里交易费率",
      prop: "bank_fee",
    },
    {
      label: "阿里提现费率",
      prop: "hand_fee",
    },
    {
      label: "创建时间",
      prop: "create_time",
    },
    {
      label: "状态",
      prop: "status",
      type: "select",
      options: { 1: "有效", 0: "失效" },
    },
    {
      label: "备注",
      prop: "remark",
    },
    {
      label: "操作",
      type: "operate_btn",
      prop: "",
      width: "280px",
    },
  ],
  forms: [
    {
      label: "归属月份",
      prop: "month",
      type: "time",
      timeType: "month",
      placeholder: "- 请选择月份 -",
      span: 11,
      rule: [
        {
          required: true,
          message: "- 请选择月份 -",
          trigger: "blur",
        },
      ],
    },
    {
      label: "计薪天数",
      prop: "paid_days",
      type: "input",
      span: 11,
      placeholder: "请输入当月计薪总天数（含带法定假期）",
      rule: [
        {
          required: true,
          message: "请输入当月计薪总天数（含带法定假期）",
          trigger: "blur",
        },
        {
          pattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
          message: "请输入非负数",
        },
      ],
    },
    {
      label: "公司保险费",
      prop: "comp_insurance",
      type: "input",
      span: 11,
      placeholder: "请输入当月公司承担的所有保险费总额",
      rule: [
        {
          pattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
          message: "请输入非负数",
        },
      ],
    },
    {
      label: "法定假期",
      prop: "paid_leave",
      type: "input",
      span: 11,
      placeholder: "请输入当月法定假期总天数",
      rule: [
        {
          pattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
          message: "请输入非负数",
        },
      ],
    },
    {
      label: "阿里交易费率",
      prop: "bank_fee",
      type: "input",
      span: 11,
      placeholder: "请输入阿里交易费率：0.02",
      rule: [
        {
          required: true,
          message: "请输入阿里交易费率：0.02",
          trigger: "blur",
        },
        {
          pattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
          message: "请输入非负数",
        },
      ],
    },
    {
      label: "阿里提现费率",
      prop: "hand_fee",
      type: "input",
      span: 11,
      placeholder: "请输入阿里提现费率：0.004",
      rule: [
        {
          required: true,
          message: "请输入阿里提现费率：0.004",
          trigger: "blur",
        },
        {
          pattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
          message: "请输入非负数",
        },
      ],
    },
    {
      label: "测试费",
      prop: "pdt_test_fee",
      type: "input",
      span: 11,
      placeholder: "请输入手机测试费用",
      rule: [
        {
          required: true,
          message: "请输入手机测试费用",
          trigger: "blur",
        },
        {
          pattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
          message: "请输入非负数",
        },
      ],
    },
    {
      label: "状态:",
      prop: "status",
      type: "switch",
      span: 11,
      modelValue: 1,
      activeText: "有效",
      inactiveText: "无效",
    },
    {
      label: "备注",
      prop: "remark",
      type: "input",
      span: 22,
      inputType: "textarea",
    },
  ],
};
