export default {
  selection: true,
  searchFields: [
    // 搜索组件的配置项
    {
      name: "设置备注",
      value: "remark",
      type: "input",
      isFixed: true,
      span: 4,
    },
  ],
  columns: [
    // 表格列的配置
    {
      label: "请假人",
      prop: "user_name_ch",
      fixed: true,
    },
    {
      label: "开始时间",
      prop: "start",
    },
    {
      label: "结束时间",
      prop: "end",
    },
    {
      label: "请假天数",
      prop: "leave_days",
    },
    {
      label: "创建时间",
      prop: "create_time",
    },
    {
      label: "状态",
      prop: "status",
      type: "select",
      options: { 1: "有效", 0: "失效" },
    },
    {
      label: "备注",
      prop: "remark",
    },
  ],
};
