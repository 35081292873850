<template>
  <el-config-provider :locale="locale">
    <router-view></router-view>
  </el-config-provider>
</template>
<script>
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";
import { ElConfigProvider } from "element-plus";
// import zhCn from "element-plus/lib/locale/lang/zh-cn";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";
export default {
  components: {
    [ElConfigProvider.name]: ElConfigProvider,
  },
  setup() {
    // 解决ERROR ResizeObserver loop completed with undelivered notifications.
    const debounce = (fn, delay) => {
      let timer = null;

      return function () {
        let context = this;

        let args = arguments;

        clearTimeout(timer);

        timer = setTimeout(function () {
          fn.apply(context, args);
        }, delay);
      };
    };

    // 解决ERROR ResizeObserver loop completed with undelivered notifications.
    const _ResizeObserver = window.ResizeObserver;
    window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
      constructor(callback) {
        callback = debounce(callback, 16);
        super(callback);
      }
    };
    // 切换为中文
    let locale = zhCn;
    const token = window.localStorage.getItem("token");
    const router = useRouter();
    if (!token) {
      ElMessage.success("请先登录");
      router.push({
        path: "/login",
      });
    }
    return {
      locale,
    };
  },
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
}
</style>
