import { request } from "./request.js";

export function customerList(data = {}) {
  return request({
    url: "customer/customerList",
    method: "post",
    data,
  });
}
export function customerAdd(data = {}) {
  return request({
    url: "customer/customerAdd",
    method: "post",
    data,
  });
}
export function customerDel(data = {}) {
  return request({
    url: "customer/customerDel",
    method: "post",
    data,
  });
}
export function customerRecycle(data = {}) {
  return request({
    url: "customer/customerRecycle",
    method: "post",
    data,
  });
}
export function getCustomerInfo(data = {}) {
  return request({
    url: "customer/getCustomerInfo",
    method: "post",
    data,
  });
}
export function getCustomerList(data = {}) {
  // 根据用户权限，返回不同的用户id及其对应的英文名
  return request({
    url: "customer/getCustomerList",
    method: "post",
    data,
  });
}
export function getCstNationList(data = {}) {
  return request({
    url: "customer/getCstNationList",
    method: "post",
    data,
  });
}
export function getCstSourceList(data = {}) {
  return request({
    url: "customer/getCstSourceList",
    method: "post",
    data,
  });
}
export function getCstLevelList(data = {}) {
  return request({
    url: "customer/getCstLevelList",
    method: "post",
    data,
  });
}
export function customerTranslate(data = {}) {
  return request({
    url: "customer/customerTranslate",
    method: "post",
    data,
  });
}
export function customerPayerList(data = {}) {
  return request({
    url: "customer/customerPayerList",
    method: "post",
    data,
  });
}
export function addCustomerPayer(data = {}) {
  return request({
    url: "customer/addCustomerPayer",
    method: "post",
    data,
  });
}
export function delCustomerPayer(data = {}) {
  return request({
    url: "customer/delCustomerPayer",
    method: "post",
    data,
  });
}
export function customerShippingList(data = {}) {
  return request({
    url: "customer/customerShippingList",
    method: "post",
    data,
  });
}
export function addCustomerShipping(data = {}) {
  return request({
    url: "customer/addCustomerShipping",
    method: "post",
    data,
  });
}
export function delCustomerShipping(data = {}) {
  return request({
    url: "customer/delCustomerShipping",
    method: "post",
    data,
  });
}
export function contactPersonList(data = {}) {
  return request({
    url: "customer/contactPersonList",
    method: "post",
    data,
  });
}
export function addcontactPerson(data = {}) {
  return request({
    url: "customer/addcontactPerson",
    method: "post",
    data,
  });
}
export function delcontactPerson(data = {}) {
  return request({
    url: "customer/delcontactPerson",
    method: "post",
    data,
  });
}
export function getUserRights(data = {}) {
  return request({
    url: "customer/getUserRights",
    method: "post",
    data,
  });
}
export function getCustomerBaseInfo(data = {}) {
  return request({
    url: "customer/getCustomerBaseInfo",
    method: "post",
    data,
  });
}
export function setCustomerFocus(data = {}) {
  return request({
    url: "customer/setCustomerFocus",
    method: "post",
    data,
  });
}
export function getTagsList(data = {}) {
  return request({
    url: "customer/getTagsList",
    method: "post",
    data,
  });
}
export function getStageList(data = {}) {
  return request({
    url: "customer/getStageList",
    method: "post",
    data,
  });
}
export function setCustomerStage(data = {}) {
  return request({
    url: "customer/setCustomerStage",
    method: "post",
    data,
  });
}
export function getGroupList(data = {}) {
  return request({
    url: "customer/getGroupList",
    method: "post",
    data,
  });
}
export function setCustomerGroup(data = {}) {
  return request({
    url: "customer/setCustomerGroup",
    method: "post",
    data,
  });
}
export function setCustomerInfo(data = {}) {
  return request({
    url: "customer/setCustomerInfo",
    method: "post",
    data,
  });
}
export function addFollowList(data = {}) {
  return request({
    url: "customer/addFollowList",
    method: "post",
    data,
  });
}
export function customerContactPersonList(data = {}) {
  return request({
    url: "customer/customerContactPersonList",
    method: "post",
    data,
  });
}
export function getCustomerOrderListByCstID(data = {}) {
  return request({
    url: "customer/getCustomerOrderListByCstID",
    method: "post",
    data,
  });
}
export function customerFollowList(data = {}) {
  return request({
    url: "customer/customerFollowList",
    method: "post",
    data,
  });
}
export function delCustomerTag(data = {}) {
  return request({
    url: "customer/delCustomerTag",
    method: "post",
    data,
  });
}
export function setCustomerLevel(data = {}) {
  return request({
    url: "customer/setCustomerLevel",
    method: "post",
    data,
  });
}
export function getCompInfo(data = {}) {
  return request({
    url: "customer/getCompInfo",
    method: "post",
    data,
  });
}
export function getTrackInfo(data = {}) {
  return request({
    url: "customer/getTrackInfo",
    method: "post",
    data,
  });
}
export function setCustomerToOpenLeads(data = {}) {
  return request({
    url: "customer/setCustomerToOpenLeads",
    method: "post",
    data,
  });
}
export function addOpenCustomerToMyCustomer(data = {}) {
  return request({
    url: "customer/addOpenCustomerToMyCustomer",
    method: "post",
    data,
  });
}
