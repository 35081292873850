<template>
  <el-popover
    v-model:visible="tagsPopoverVisible"
    :placement="placement"
    :width="240"
    :trigger="trigger"
    popper-class="table-box-tag-popover"
  >
    <template v-slot:reference>
      <slot name="reference"></slot>
    </template>
    <div v-show="tagsViewState === 'list'" v-loading="tagLoading">
      <div>
        <div class="tag-filter">
          <el-input
            v-model="tagListFilterWords"
            placeholder="搜索标签"
          ></el-input>
        </div>
        <div class="tag-list">
          <ul>
            <li
              v-for="item in tagListFilter"
              @click="checkTag(item)"
              :key="item.businessId"
              :style="`background:${item.color};color:${item.iconColor};`"
            >
              <i
                v-show="checkListTag(item)"
                class="el-icon-check tag-icon-edit"
                style="left: 5px"
              ></i>
              <span>{{ item.labelName }}</span>
              <i
                class="el-icon-edit tag-icon-edit"
                v-if="isCanEditOrAdd && item.isSystemLabel !== 'Y'"
                @click.stop="showTagsForm(item)"
              ></i>
            </li>
          </ul>
        </div>
        <div class="tag-add-btn" v-if="isCanEditOrAdd">
          <span @click="showTagsForm">创建标签</span>
        </div>
      </div>
    </div>
    <div v-show="tagsViewState === 'form'" v-loading="tagLoading">
      <div class="tag-add-title">
        <i
          class="el-icon-back head-icon back"
          @click="tagsViewState = 'list'"
        ></i>
        创建标签
        <!--<i class="el-icon-close head-icon close" ></i>-->
      </div>
      <div style="padding-left: 20px; padding-right: 20px">
        <div class="tag-add-input">
          <el-form
            ref="tagForm"
            class="row-box"
            :model="tagsFormData"
            :rules="rule"
          >
            <el-form-item label="" prop="labelName">
              <el-input
                v-model="tagsFormData.labelName"
                placeholder="输入标签名"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="tag-add-color">
          <ul>
            <li
              v-for="item in initTagsColor"
              :class="{ 'el-icon-check': item.color === tagsFormData.color }"
              :style="`background:${item.color};color:${item.iconColor};`"
              :key="item.color"
              @click="checkColor(item)"
            ></li>
          </ul>
          <el-popconfirm
            title="相同标签的记录会一并清除标签，删除后不能恢复，确认是否继续?"
            @confirm="deleteTags"
          >
            <template #reference>
              <el-button
                type="danger"
                class="tags-operate-btn"
                v-show="tagsFormData.id && tagsFormData.isSystemLabel !== 'Y'"
                >删除</el-button
              >
            </template>
          </el-popconfirm>
          <el-button
            type="primary"
            :loading="tagLoading"
            :disabled="!(tagsFormData.labelName && tagsFormData.color)"
            @click="saveTags"
            class="tags-operate-btn"
          >
            {{ tagsFormData.id ? "保存" : "创建" }}</el-button
          >
        </div>
      </div>
    </div>
  </el-popover>
</template>
<script>
import http from "@/utils/service";
const validateTagName = (rule, value, callback) => {
  // 只能输入汉字和英文字母
  const re = /^[\u4e00-\u9fa5a-zA-Z0-9_\-]+$/;
  if (!re.test(value)) {
    callback(new Error("只能输入汉字和字母和数字和下划线"));
  } else {
    callback();
  }
};
export default {
  emits: ["check-tag", "delete-tag", "edit-tag"],
  name: "lotsTagPopover",
  created() {
    // this.loadTags();
  },
  data() {
    return {
      tagList: [],
      tagsPopoverVisible: false,
      tagsViewState: "list",
      tagListFilterWords: "",
      tagsFormData: {
        module: this.tagsConfig.module || "",
        labelName: "",
        isSystemLabel: "N",
        isCancelLabel: "Y",
        isShowLabel: "Y",
        iconColor: "",
        color: "",
      },
      rule: {
        labelName: [
          { required: true, message: "标签名称不能为空", trigger: "blur" },
          { required: true, message: "标签名称不能为空", trigger: "change" },
          { max: 32, message: "不能超过32个字符", trigger: "blur" },
          { validator: validateTagName, trigger: "blur" },
        ],
      },
      tagLoading: false,
    };
  },
  props: {
    placement: {
      type: String,
      default: "bottom",
    },
    trigger: {
      type: String,
      default: "click",
    },
    hasSelectedUniqueIdentification: {
      // 判断是否已选的唯一标识，tableBox使用labelId，searchBox使用id
      type: String,
      default: "labelId",
    },
    isCanEditOrAdd: {
      type: Boolean,
      default: true,
    },
    // 已选中的列表
    checkTagList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    tagsConfig: {
      type: Object,
      default() {
        return {};
      },
    },
    initTagsColor: {
      type: Array,
      default() {
        return [
          { iconColor: "#333", color: "#FECACA" },
          { iconColor: "#333", color: "#FED8AA" },
          { iconColor: "#333", color: "#FDE68A" },
          { iconColor: "#333", color: "#B4F3A8" },
          { iconColor: "#333", color: "#D6E0C0" },
          { iconColor: "#333", color: "#C8D2FF" },
          { iconColor: "#333", color: "#DCBBED" },
          { iconColor: "#333", color: "#FFBFF2" },
          { iconColor: "#333", color: "#BDF4E9" },
          { iconColor: "#333", color: "#BBE6FD" },
        ];
      },
    },
  },
  methods: {
    checkTag(tag) {
      const list = JSON.parse(JSON.stringify(this.checkTagList));
      const checkIndex = list.findIndex((item) => {
        return item[this.hasSelectedUniqueIdentification]
          ? item[this.hasSelectedUniqueIdentification] === tag.id
          : item === tag.id;
      });
      if (checkIndex === -1) {
        list.push(tag);
      } else {
        list.splice(checkIndex, 1);
      }
      this.$emit("check-tag", tag, list);
    },
    checkListTag(tag) {
      const findItem = this.checkTagList.findIndex((item) => {
        return item[this.hasSelectedUniqueIdentification]
          ? item[this.hasSelectedUniqueIdentification] === tag.id
          : item === tag.id;
      });
      return findItem >= 0;
    },
    checkColor(item) {
      this.tagsFormData.color = item.color;
      this.tagsFormData.iconColor = item.iconColor;
    },
    deleteTags() {
      if (this.tagsFormData.id) {
        this.tagLoading = true;
        http
          .request({
            url: this.tagsConfig.deleteURL,
            method: "get",
            params: {
              labelId: this.tagsFormData.id,
              businessType: "businessType",
              module: this.tagsConfig.module,
            },
          })
          .then((res) => {
            if (+res.code === 0) {
              this.tagListFilterWords = "";
              this.loadTags();
              this.tagsViewState = "list";
            }
          })
          .finally(() => {
            this.tagLoading = false;
            this.$emit("delete-tag");
          });
      }
    },
    showTagsForm(tagInfo) {
      this.tagsViewState = "form";
      if (tagInfo.id) {
        Object.assign(this.tagsFormData, tagInfo);
      } else {
        this.tagsFormData = {
          module: this.tagsConfig.module || "",
          labelName: "",
          isSystemLabel: "N",
          isCancelLabel: "Y",
          isShowLabel: "Y",
          iconColor: "",
          color: "",
        };
      }
    },
    saveTags() {
      this.$refs.tagForm.validate((valid) => {
        if (valid) {
          const data = JSON.parse(JSON.stringify(this.tagsFormData));
          delete data.colorIndex;
          this.tagLoading = true;
          http
            .request({
              url: data.id ? this.tagsConfig.editURL : this.tagsConfig.addURL,
              method: "post",
              data: data,
            })
            .then((res) => {
              if (+res.code === 0) {
                this.tagListFilterWords = "";
                this.loadTags();
                this.tagsViewState = "list";
                if (data.id) {
                  this.$emit("edit-tag");
                }
              }
            })
            .finally(() => {
              this.tagLoading = false;
            });
        }
      });
    },
    loadTags() {
      if (this.tagsConfig.listURL) {
        this.tagLoading = true;
        http
          .request({
            url: this.tagsConfig.listURL,
            method: "get",
            params: {
              module: this.tagsConfig.module,
              labelName: this.tagListFilterWords,
              isShowLabel: "Y",
            },
          })
          .then((res) => {
            if (+res.code === 0) {
              this.tagList = res.data || [];
            }
          })
          .finally(() => {
            this.tagLoading = false;
          });
      }
    },
    toggleToAddTags() {
      this.tagsViewState = "form";
    },
  },
  computed: {
    tagListFilter() {
      if (!this.tagListFilterWords.trim()) {
        return this.tagList;
      } else {
        const list = this.tagList.filter((item) => {
          return item.labelName.indexOf(this.tagListFilterWords) >= 0;
        });
        return list;
      }
    },
  },
  watch: {
    tagsPopoverVisible(newVal) {
      if (newVal) {
        this.tagsViewState = "list";
        this.tagListFilterWords = "";
        this.loadTags();
      }
    },
  },
};
</script>
<style lang="less"></style>
