export default {
  selection: false,
  columns: [
    // 表格列的配置
    {
      label: "供应商名字",
      prop: "spr_comp_name",
    },
    {
      label: "相关账单",
      prop: "payment_no",
    },
    {
      label: "应付总额",
      prop: "payable_amount",
    },
    {
      label: "已付总额",
      prop: "paid_amount",
    },
    {
      label: "待付余额",
      prop: "wait_amount",
    },
    {
      label: "实付金额",
      prop: "amount",
      type: "amount",
    },
    {
      label: "支付备注",
      prop: "remark",
      type: "remark",
    },
    {
      label: "操作",
      type: "operate_btn",
      width: "150",
      prop: "",
    },
  ],
};
