export default {
  selection: false,
  searchFields: [
    // 搜索组件的配置项
    {
      name: "收款单号",
      value: "list_no",
      type: "input",
      isFixed: true,
      span: 4,
    },
    {
      name: "收款单备注",
      value: "remark",
      type: "input",
      isFixed: true,
      span: 4,
    },
  ],
  columns: [
    //表格列的配置
    {
      label: "创建日期",
      prop: "create_time",
    },
    {
      label: "收款单号",
      prop: "list_no",
    },
    {
      label: "客户名称",
      prop: "cst_name_ch",
    },
    {
      label: "收款方式",
      prop: "method_id",
    },
    {
      label: "收款货币",
      prop: "currency_id",
    },
    {
      label: "总收款额",
      prop: "total_amount",
    },
    {
      label: "产品总额",
      prop: "product_amount",
    },
    {
      label: "外币费用",
      prop: "total_bank_fee",
    },
    {
      label: "提现费用",
      prop: "total_hand_fee",
    },
    {
      label: "收款状态",
      prop: "status",
      mustFill: true,
    },
    {
      label: "收款备注",
      prop: "remark",
    },
    {
      label: "操作",
      type: "operate_btn",
      prop: "",
    },
  ],
};
