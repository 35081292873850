<template>
  <div class="demo-date-picker">
    <el-row>
      <el-col :span="24">
        <div style="height: 100%; line-height: 33px">
          {{ statementString }}
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="4">
        <el-select v-model="userID" @change="changeUser">
          <el-option
            v-for="item in getUserList"
            :key="item.user_id"
            :label="item.user_name_ch"
            :value="item.user_id"
          /> </el-select
      ></el-col>
      <el-col :span="4">
        <el-select v-model="reportType" @change="changeReportType">
          <el-option
            v-for="(item, index) in reportTypeList"
            :key="index"
            :label="item.lable"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-select v-model="calType" @change="handleGetStatements">
          <el-option
            v-for="(item, index) in calTypeList"
            :key="index"
            :label="item.lable"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="8">
        <el-date-picker
          v-if="reportType == 'dayReport'"
          v-model="dateValue"
          type="daterange"
          :disabled-date="disabledDate"
          range-separator="至"
          start-placeholder="起始日期"
          end-placeholder="结束日期"
          size="larger"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          @change="dateChange"
        />
        <el-date-picker
          v-else-if="reportType == 'monthReport'"
          v-model="dateValue"
          type="monthrange"
          :disabled-date="disabledDate"
          range-separator="至"
          start-placeholder="起始月份"
          end-placeholder="结束月份"
          size="larger"
          format="YYYY-MM"
          value-format="YYYY-MM"
          @change="dateChange"
        />
      </el-col>
      <el-col :span="4" style="text-align: left">
        <el-button @click="handleGetStatements" type="primary">搜索</el-button>
      </el-col>
    </el-row>
    <div class="box">
      <columnChart
        v-if="showChart"
        id="dayLine"
        type="line"
        :configData="dualAxesData"
      ></columnChart>
    </div>
  </div>
</template>

<script>
import { ref, reactive, toRefs, onMounted } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { getUserList } from "@/network/user.js";
import { getStatements } from "@/network/finance.js";
import columnChart from "@/components/columnChart.vue";
import dayjs from "dayjs";
import { dualAxes } from "./config.js";
export default {
  components: {
    columnChart,
  },
  setup() {
    const reportType = ref("dayReport");
    const reportTypeList = [
      {
        lable: "日报表",
        value: "dayReport",
      },
      {
        lable: "月报表",
        value: "monthReport",
      },
      {
        lable: "年报表",
        value: "yearReport",
      },
    ];
    const showChart = ref(false);
    const dualAxesData = ref(dualAxes);
    const loading = ref(false);
    const state = reactive({
      userID: "",
      getUserList: [],
      statementString: "",
    });
    const calType = ref("ordReport");
    const calTypeList = [
      {
        lable: "订单",
        value: "ordReport",
      },
      {
        lable: "销售",
        value: "saleReport",
      },
      {
        lable: "毛利",
        value: "profitReport",
      },
      {
        lable: "客户",
        value: "customerReport",
      },
      {
        lable: "日程",
        value: "scheduleReport",
      },
    ];
    const changeReportType = (val) => {
      reportType.value = val;
      handleDateDefault();
      handleGetStatements();
    };
    const changeUser = (val) => {
      state.userID = val;
      handleDateDefault();
      handleGetStatements();
    };
    const dateValue = ref([
      dayjs().subtract(1, "month").format("YYYY-MM-DD"),
      dayjs().format("YYYY-MM-DD"),
    ]);
    const handleGetStatements = () => {
      loading.value = true;
      showChart.value = false;
      getUserList().then((res) => {
        if (res.code == 0) {
          state.getUserList = res.data;
        } else {
          ElMessage.error(res.msg);
        }
      });
      getStatements({
        userID: state.userID,
        dateVal: dateValue.value,
        calType: calType.value,
        reportType: reportType.value,
      }).then((res) => {
        loading.value = false;
        showChart.value = true;
        if (res.code == 0) {
          dualAxesData.value.data = res.data.list;
          state.statementString = res.data.statementString;
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    const dateChange = (val) => {
      dateValue.value = val;
      handleGetStatements();
    };
    const handleDateDefault = () => {
      if (reportType.value == "dayReport") {
        dateValue.value = [
          dayjs().subtract(1, "month").format("YYYY-MM-DD"),
          dayjs().format("YYYY-MM-DD"),
        ];
      } else if (reportType.value == "monthReport") {
        dateValue.value = [
          dayjs().subtract(6, "month").format("YYYY-MM"),
          dayjs().format("YYYY-MM"),
        ];
      }
    };
    onMounted(() => {
      handleDateDefault();
      handleGetStatements();
    });
    const disabledDate = (time) => {
      return time.getTime() >= Date.now();
    };

    return {
      ...toRefs(state),
      dateValue,
      dateChange,
      calTypeList,
      calType,
      disabledDate,
      handleGetStatements,
      dualAxesData,
      loading,
      showChart,
      reportType,
      reportTypeList,
      changeReportType,
      changeUser,
    };
  },
};
</script>

<style scoped lang="scss">
.demo-date-picker {
  width: 100%;
  height: 100%;
  padding: 0;
}
.demo-date-picker .title {
  text-align: left;
  font-size: 20px;
}
.demo-date-picker .block {
  text-align: right;
  border-right: solid 1px var(--el-border-color);
}
.demo-date-picker .block:last-child {
  border-right: none;
}
.box {
  height: calc(100% - 66px);
  padding: 15px 0;
  box-sizing: border-box;
}
.content {
  height: calc(100% - 180px);
  position: relative;
}
.el-table {
  position: absolute;
}
.el-table__header {
  //width: 100%;
}
.el-table__body {
  height: calc(100% - 40px);
}
</style>
