export default {
  selection: true,
  columns: [
    // 表格列的配置
    {
      label: "所属客户",
      prop: "cst_id_v",
    },
    {
      label: "收件人",
      prop: "consignee_name",
    },
    {
      label: "公司名",
      prop: "consignee_comp",
    },
    {
      label: "收件电话",
      prop: "consignee_phone",
      mustFill: true,
    },
    {
      label: "收件地址",
      prop: "consignee_add",
      mustFill: true,
    },
    {
      label: "邮政编码",
      prop: "consignee_post_code",
      mustFill: true,
    },
    {
      label: "VAT",
      prop: "consignee_vat",
      mustFill: true,
    },
    {
      label: "发货唛头",
      prop: "shipping_remark",
      mustFill: true,
    },
    {
      label: "状态",
      prop: "status",
      type: "select",
      options: { 1: "有效", 0: "关闭" },
    },
    {
      label: "备注",
      prop: "remark",
      mustFill: true,
    },
  ],
  actions: {
    // 表格操作列的配置
    fixedWidth: 110, // fixedWidth（可选） 设置操作列的固定宽度，不加该参数默认是按钮list长度*50px
    list: [
      {
        label: "编辑",
        event: "edit",
        displayRule: () => {
          return true;
        },
      },
      {
        label: "删除",
        event: "del",
        displayRule: () => {
          return true;
        },
      },
    ],
  },
  forms: [
    // 表单页面的配置
    {
      label: "收件人",
      prop: "consignee_name",
      span: 11,
      type: "input",
      rule: [{ required: true, message: "收件人不能为空", trigger: "blur" }],
    },
    {
      label: "公司名字",
      prop: "consignee_comp",
      span: 11,
      type: "input",
    },
    {
      label: "收件电话",
      prop: "consignee_phone",
      span: 11,
      type: "input",
      rule: [{ required: true, message: "收件电话不能为空", trigger: "blur" }],
    },
    {
      label: "邮政编码",
      prop: "consignee_post_code",
      type: "input",
      span: 11,
    },
    {
      label: "VAT账户",
      prop: "consignee_vat",
      type: "input",
      span: 11,
      placeholder: "如果需要显示EORI或GOV，VAT为必填",
    },
    {
      label: "EORI编码",
      prop: "consignee_eori",
      type: "input",
      span: 11,
    },
    {
      label: "GOV号码",
      prop: "consignee_gov",
      type: "input",
      span: 11,
    },
    {
      label: "收件地址",
      prop: "consignee_add",
      span: 22,
      type: "input",
      inputType: "textarea",
      rule: [{ required: true, message: "收货不能为空", trigger: "blur" }],
    },
    {
      label: "发货唛头",
      prop: "shipping_remark",
      type: "input",
      span: 22,
      inputType: "textarea",
    },
    {
      label: "备注",
      prop: "remark",
      type: "input",
      span: 22,
      inputType: "textarea",
    },
    {
      label: "状态",
      prop: "status",
      type: "switch",
      span: 11,
      activeValue: 1,
      inactiveValue: 0,
      options: [
        { label: "开启", value: 1 },
        { label: "关闭", value: 0 },
      ],
      rule: [{ required: true }],
    },
  ],
};
