<template>
  <div class="flex-layout order-management">
    <isdListPaper v-loading="tableLoading">
      <template v-slot:list="props">
        <isdTable
          show-summary
          :loading="tableLoading"
          :rows="totalData"
          :height="props.tableHeight"
          :columns="config.columns"
          :selection="config.selection"
          :summary-method="getSummaries"
          :actions="config.actions"
          @selection-change="handleSelectedRow"
          style="font-size: 12px"
        >
          <template v-slot:col-append="{ col, row }">
            <span v-if="col.type === 'operate_btn'">
              <el-button
                type="success"
                round
                disabled
                size="small"
                v-if="row.status == 3"
                ><el-icon style="vertical-align: middle"><Check /></el-icon
                ><span style="vertical-align: middle">
                  完成结算
                </span></el-button
              >
              <el-button
                round
                size="small"
                @click="salaryGo(row.id, 2)"
                v-else-if="row.status == 1"
                ><el-icon style="vertical-align: middle"><VideoPlay /></el-icon
                ><span style="vertical-align: middle">
                  发起结算
                </span></el-button
              >
              <el-button
                round
                size="small"
                @click="salaryGo(row.id, 3)"
                v-else-if="operate_right"
                ><el-icon style="vertical-align: middle"><Notebook /></el-icon
                ><span style="vertical-align: middle">
                  确认支付
                </span></el-button
              >
              <el-button round size="small" disabled v-else
                ><el-icon style="vertical-align: middle"><AlarmClock /></el-icon
                ><span style="vertical-align: middle">
                  等待支付
                </span></el-button
              >
              <el-button round size="small" @click="showDetails(row.id)"
                ><el-icon style="vertical-align: middle"><View /></el-icon
                ><span style="vertical-align: middle"> 详情 </span></el-button
              >
            </span>
          </template>
        </isdTable>
      </template>
      <template v-slot:footer>
        <isdPagination
          @size-change="sizeChange"
          :current-page="currentPage"
          @current-change="pageChange"
          layout="total,sizes,prev,pager,next,jumper"
          :page-size="pageSize"
          :page-sizes="[20, 50, 100]"
          :total="pageTotal"
        ></isdPagination>
      </template>
    </isdListPaper>
  </div>
</template>
<script>
import { salaryList, updateSalaryList } from "@/network/finance.js";
import {
  VideoPlay,
  AlarmClock,
  Delete,
  Notebook,
  View,
  Check,
} from "@element-plus/icons-vue";
import { defineComponent, ref, reactive, onMounted, markRaw } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import { ListClass } from "@/composition/ListClass.js";
import configData from "./config";
import isdTable from "@/views/components/isdTable/index.vue";
import isdPagination from "@/views/components/isdPage/index.vue";
import isdListPaper from "@/views/components/isdListPaper/index.vue";
import isdSearchBox from "@/views/components/isdSearchBox/index.vue";
export default defineComponent({
  components: {
    Delete: markRaw(Delete),
    VideoPlay: markRaw(VideoPlay),
    AlarmClock: markRaw(AlarmClock),
    Notebook: markRaw(Notebook),
    View: markRaw(View),
    Check: markRaw(Check),
    isdTable,
    isdPagination,
    isdSearchBox,
    isdListPaper,
  },
  setup() {
    const confim_info = ref(null);
    const operate_right = ref(true); // 搜索实例
    const config = reactive(configData); // 列表配置
    const searchBoxIns = ref(null); // 搜索实例
    const columnConfigIns = ref(null); // 列配置实例
    const statusDialogIns = ref(null); // 状态弹框实例
    const detailPageIns = ref(null); // 详情编辑页ref
    const tableBox = ref(null);
    // 格式化查询条件
    const dataFormat = (condition) => {
      condition.fieldName = "status";
      condition.fieldSymbol = "=";
      condition.fieldVal = 3;
      return condition;
    };
    class TableList extends ListClass {
      beforeGetList(condition) {
        condition = dataFormat(condition);
        return condition;
      }
      afterGetList(res) {
        operate_right.value = res.data.operate_right;
        super.afterGetList(res);
      }
    }
    const {
      currentPage,
      pageNo,
      pageSize,
      pageTotal,
      tableLoading,
      totalData,
      getList,
      searchList,
      pageChange,
      sizeChange,
      selectedRows,
      handleSelectedRow,
    } = new TableList({
      Api: {
        listApi: salaryList,
      },
    });
    const router = useRouter();
    const showDetails = (val) => {
      router.push({
        name: "showSalaryDetails",
        query: {
          id: val,
        },
      });
    };
    // 确认事件
    const salaryGo = (val, operate_type) => {
      if (operate_type == 2) {
        confim_info.value =
          "一旦提交无法撤回：确定工资、社保、提成等准确无误，并发起工资结算吗？";
      } else {
        confim_info.value = "确定该员工工资已经支付？";
      }
      ElMessageBox.confirm(confim_info.value, "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          updateSalaryList({ id: val, type: operate_type }).then((res) => {
            if (res.code == 0) {
              ElMessage.success(res.msg);
              getList();
            } else {
              ElMessage.error(res.msg);
            }
          });
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "确认取消",
          });
        });
    };
    const getSummaries = (param) => {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index == 0) {
          sums[0] = "合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (
          !values.every((value) => isNaN(value)) &&
          [2, 5, 6, 7, 8, 9, 10].includes(index)
        ) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        } else {
          sums[index] = "";
        }
      });
      return sums;
    };
    onMounted(() => {
      getList();
    });
    return {
      tableBox,
      config,
      pageNo,
      pageSize,
      pageTotal,
      tableLoading,
      totalData,
      searchList,
      pageChange,
      sizeChange,
      handleSelectedRow,
      searchBoxIns,
      columnConfigIns,
      detailPageIns,
      statusDialogIns,
      showDetails,
      currentPage,
      selectedRows,
      operate_right,
      salaryGo,
      getSummaries,
    };
  },
});
</script>

<style lang="scss">
.order-management {
  .el-table th > .cell {
    word-break: keep-all;
  }
}
</style>
