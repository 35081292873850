class LotsUIEvent {
    // 私有变量，存储所有订阅事件
    // #listenList = {};
    constructor() {
        this.listenList = {};
    }

    // 订阅事件
    listen(key, cb) {
        this.listenList[key] = this.listenList[key] || [];
        this.listenList[key].push(cb);
    };

    // 触发器
    trigger() {
        const key = Array.prototype.shift.call(arguments);
        const args = arguments;
        const fns = this.listenList[key];
        const _this = this;
        if (fns && fns.length) {
            fns.forEach(fn => {
                fn.apply(_this, args);
            });
        } else {
            // eslint-disable-next-line
            console.error(`lotsUI plugin is missing a function ${key}`);
        }
    }
}

const lotsUIEventInstance = new LotsUIEvent();

export { LotsUIEvent, lotsUIEventInstance };
