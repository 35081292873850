<template>
  <el-pagination
    :page-size="10"
    :page-sizes="[10, 20, 50, 100]"
    :layout="filterLayout"
    v-bind="{ ...$attrs }"
  >
  </el-pagination>
</template>
<script>
export default {
  props: {
    layout: {
      default: "sizes, prev, pager, next",
    },
  },
  data() {
    return {
      filterLayout: "sizes, prev, pager, next",
    };
  },
  created() {
    const layoutArr = {
      sizes: "sizes",
      prev: "prev",
      next: "next",
      pager: "pager",
      jumper: "jumper",
      total: "total",
    };
    const layArr = this.layout.split(",");
    const filterLayout = [];
    for (let i = 0; i < layArr.length; i++) {
      const layOne = layArr[i].replace(/^\s+|\s+$/g, "");
      if (layoutArr[layOne]) {
        filterLayout.push(layOne);
      }
    }
    this.filterLayout = filterLayout.join(",");
  },
};
</script>
