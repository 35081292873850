export default {
  selection: false,
  searchFields: [
    // 搜索组件的配置项
    {
      name: "渠道中文名",
      value: "shipping_name_ch",
      type: "input",
      isFixed: true,
      span: 4,
    },
  ],
  columns: [
    // 表格列的配置
    {
      label: "渠道中文名",
      prop: "shipping_name_ch",
    },
    {
      label: "渠道英文名",
      prop: "shipping_name_en",
    },
    {
      label: "物流查询链接",
      prop: "office_url",
    },
    {
      label: "官方电话",
      prop: "office_tel",
    },
    {
      label: "状态",
      prop: "shipping_status",
      mustFill: true,
      type: "select",
      options: { 1: "有效", 0: "关闭" },
    },
    {
      label: "备注",
      prop: "shipping_remark",
      mustFill: true,
    },
    {
      label: "操作",
      type: "operate_btn",
      prop: "",
      width: "120px",
    },
  ],
  forms: [
    {
      label: "渠道中文名：",
      prop: "shipping_name_ch",
      type: "input",
      placeholder: "请输入渠道中文名",
      rule: [
        {
          required: true,
          message: "请输入渠道中文名",
          trigger: "blur",
        },
      ],
    },
    {
      label: "渠道英文名：",
      prop: "shipping_name_en",
      type: "input",
    },
    {
      label: "物流查询链接：",
      prop: "office_url",
      type: "input",
    },
    {
      label: "官方查询电话：",
      prop: "office_tel",
      type: "input",
    },
    {
      label: "状态:",
      prop: "shipping_status",
      type: "switch",
      modelValue: "1",
      activeText: "是",
      inactiveText: "否",
      rule: [{ required: true }],
    },
    {
      label: "备注",
      prop: "shipping_remark",
      type: "input",
      inputType: "textarea",
    },
  ],
};
