export default {
  selection: false,
  columns: [
    // 表格列的配置
    {
      label: "产品编码",
      prop: "pdt_no",
    },
    {
      label: "销售数量",
      prop: "pdt_num",
    },
    {
      label: "销售价格",
      prop: "pdt_sale_price",
    },
    {
      label: "产品描述",
      prop: "pdt_dep",
      mustFill: true,
    },
    {
      label: "发票显示",
      prop: "is_show_inv",
      mustFill: true,
      type: "select",
      options: { 1: "显示", 0: "隐藏" },
    },
    {
      label: "出运显示",
      prop: "is_show_shipping",
      mustFill: true,
      type: "select",
      options: { 1: "显示", 0: "隐藏" },
    },
    {
      label: "备注",
      prop: "pdt_remark",
      mustFill: true,
    },
  ],
  actions: {
    // 表格操作列的配置
    fixedWidth: 110, // fixedWidth（可选） 设置操作列的固定宽度，不加该参数默认是按钮list长度*50px
    list: [
      {
        label: "编辑",
        event: "edit",
        displayRule: () => {
          return true;
        },
      },
      {
        label: "删除",
        event: "del",
        displayRule: () => {
          return true;
        },
      },
    ],
  },
  forms: [
    // 表单页面的配置
    {
      label: "产品编码:",
      prop: "pdt_id",
      options: [],
      filterable: true,
      type: "custom",
      rule: [
        { required: true, message: "产品编码不能为空", trigger: "change" },
      ],
    },
    {
      label: "销售数量:",
      prop: "pdt_num",
      type: "input",
      rule: [
        { required: true, message: "销售数量不能为空", trigger: "blur" },
        {
          pattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
          message: "请输入非负数",
        },
      ],
    },
    {
      label: "销售价格:",
      prop: "pdt_sale_price",
      type: "input",
      rule: [{ required: true, message: "销售价格不能为空", trigger: "blur" }],
    },
    {
      label: "发票显示:",
      prop: "is_show_inv",
      type: "switch",
      activeText: "是",
      inactiveText: "否",
      rule: [{ required: true }],
    },
    {
      label: "出运显示:",
      prop: "is_show_shipping",
      type: "switch",
      activeText: "是",
      inactiveText: "否",
      rule: [{ required: true }],
    },
    {
      label: "产品描述:",
      prop: "pdt_dep",
      type: "input",
      inputType: "textarea",
      rule: [{ required: true, message: "产品描述不能为空", trigger: "blur" }],
    },
    {
      label: "备注:",
      prop: "pdt_remark",
      type: "input",
      inputType: "textarea",
    },
  ],
};
