<template>
  <span class="advanced-search-box">
    <el-popover
      popper-class="advanced-search-box"
      placement="bottom"
      :width="`${advancedWidth}px`"
      @show="initData"
      trigger="manual"
      :visible="showPopover"
    >
      <template v-slot:reference>
        <el-badge
          :hidden="innerSearchCount < 1"
          :value="innerSearchCount > 9 ? '9+' : innerSearchCount"
          class="item"
          type="primary"
        >
          <el-button
            class="search-btn"
            :icon="Filter"
            @click="showPopover = !showPopover"
          >
          </el-button>
        </el-badge>
      </template>
      <div class="search-set">
        <ul class="search-list-box">
          <template v-for="item in fields">
            <li
              :key="item.name"
              v-if="
                isShowAllFields || item.isFixed || fieldsShowState[item.value]
              "
              :class="{ 'is-fussy': item.isFussy }"
            >
              <span class="label" :title="item.name">{{ item.name }}</span>
              <div class="form-item">
                <el-input
                  v-if="item.type === 'input'"
                  class="text-box"
                  :disabled="showState || lodash.get(item, 'disabled', false)"
                  :clearable="lodash.get(item, 'clearable', true)"
                  v-model="searchData[item.value]"
                  @input="fieldChange(item.value, $event)"
                >
                </el-input>
                <el-select
                  v-else-if="item.type === 'select'"
                  @change="fieldChange(item.value, $event)"
                  class="text-box lots-select"
                  v-model="searchData[item.value]"
                  :clearable="lodash.get(item, 'clearable', true)"
                  :multiple="item.multiple ? item.multiple : false"
                  :filterable="lodash.get(item, 'filterable', true)"
                  :disabled="showState || lodash.get(item, 'disabled', false)"
                  collapse-tags
                >
                  <el-option
                    v-for="(val, index) in item.options"
                    :key="index"
                    :label="val.label"
                    :value="val.value"
                  >
                  </el-option>
                </el-select>
                <el-date-picker
                  @change="fieldChange(item.value, $event)"
                  v-else-if="
                    item.type === 'datetimerange' || item.type === 'daterange'
                  "
                  class="text-box"
                  v-model="searchData[item.value]"
                  :clearable="lodash.get(item, 'clearable', true)"
                  :format="
                    item.type === 'datetimerange'
                      ? 'YYYY-MM-DD HH:mm:ss'
                      : 'YYYY-MM-DD'
                  "
                  :shortcuts="
                    lodash.get(item, 'shortcuts') || pickerOptions.shortcuts
                  "
                  :disabled="showState || lodash.get(item, 'disabled', false)"
                  :type="item.type"
                  unlink-panels
                  :range-separator="'至'"
                  :start-placeholder="
                    item.startPlaceholder ? item.startPlaceholder : '开始日期'
                  "
                  :end-placeholder="
                    item.endPlaceholder ? item.endPlaceholder : '结束日期'
                  "
                  :default-time="[
                    new Date(2000, 1, 1, 0, 0, 0),
                    new Date(2000, 1, 1, 23, 59, 59),
                  ]"
                >
                </el-date-picker>
                <el-date-picker
                  v-else-if="
                    ['date', 'week', 'month', 'dates', 'year'].includes(
                      item.type
                    )
                  "
                  @change="fieldChange(item.value, $event)"
                  class="text-box"
                  v-model="searchData[item.value]"
                  :disabled="showState || lodash.get(item, 'disabled', false)"
                  :clearable="lodash.get(item, 'clearable', true)"
                  :format="item.format"
                  :value-format="item.valueFormat"
                  :type="item.type"
                  unlink-panels
                  :placeholder="
                    item.placeholder ? item.placeholder : '选择日期'
                  "
                >
                </el-date-picker>
                <el-input
                  v-else-if="item.type === 'advanceUser'"
                  class="text-box advance-input"
                  v-model="searchData[item.value]"
                  readonly
                  :disabled="showState || lodash.get(item, 'disabled', false)"
                >
                  <template v-slot:append>
                    <el-button
                      :disabled="
                        showState || lodash.get(item, 'disabled', false)
                      "
                      @click="userDetailSearch(item)"
                      icon="el-icon-search"
                    ></el-button>
                  </template>
                  <template v-slot:suffix>
                    <em
                      :disabled="
                        showState || lodash.get(item, 'disabled', false)
                      "
                      @click="resetAdvanceParams(item.value)"
                      class="el-input__icon el-icon-circle-close"
                      v-if="searchData[item.value]"
                    >
                    </em>
                  </template>
                </el-input>
                <basic-data-input
                  v-else-if="item.type === 'advance'"
                  :label="searchData[item.value]"
                  :config="item"
                  :disabled="showState || lodash.get(item, 'disabled', false)"
                  :cascadeData="searchData"
                  @change="handleBasicDataInputChange(item.value, $event)"
                ></basic-data-input>
                <basic-data-multi
                  v-else-if="item.type === 'advanceMultiple'"
                  :params="item"
                  :disabled="showState || lodash.get(item, 'disabled', false)"
                  v-model="searchData[item.value]"
                  @change="handleBasicDataInputMultiChange(item.value, $event)"
                ></basic-data-multi>
                <basic-data-selector
                  v-else-if="item.type === 'lotsBasicData'"
                  :config="item.config"
                  :disabled="showState || lodash.get(item, 'disabled', false)"
                  v-model="searchData[item.value]"
                  @change="fieldChange(item.value, $event)"
                ></basic-data-selector>
                <addressSelect
                  v-else-if="item.type === 'addressSelect'"
                  @input="fieldChange(item.value, $event)"
                  class="text-box"
                  :disabled="showState || lodash.get(item, 'disabled', false)"
                  v-model="searchData[item.value]"
                  :value="searchData[item.value]"
                  :minLayers="item.minLayers"
                  :placeholder="item.name"
                  :basicProps="item.config"
                  :tabsAddress="item.tabsAddress"
                  :showNoChoice="item.showNoChoice"
                  :hotData="item.hotData"
                >
                </addressSelect>
                <lots-tag-input
                  v-else-if="item.type === 'tag'"
                  :config="item"
                  :checkTagList="searchData[item.value]"
                  @handleTagInputClose="handleTagInputClose"
                  @handleCloseTag="handleCloseTag"
                  @handleCheckTag="handleCheckTag"
                >
                </lots-tag-input>
              </div>
              <span class="input-append-fussy" v-if="item.isFussy">
                <el-checkbox v-model="searchData[item.value + 'Fussy']">{{
                  "模糊"
                }}</el-checkbox>
              </span>
              <advance-user
                ref="advanceUser"
                v-if="item.type === 'advanceUser'"
                :draggable="item.draggable"
                @confirm="detailSearchSure"
              ></advance-user>
            </li>
          </template>
        </ul>
        <div>
          <div v-if="!showState" class="btn-box box-border">
            <div class="text-btn" v-if="!isShowAllFields">
              <el-button class="gray-btn" @click="showStateOpen">{{
                "自定义搜索条件"
              }}</el-button>
            </div>
            <div class="btn-btn">
              <el-button class="gray-btn" @click="clear">{{
                "重 置"
              }}</el-button>
              <lots-button
                type="primary"
                :loading="loading"
                @click="search()"
                >{{ "搜 索" }}</lots-button
              >
            </div>
          </div>
          <div v-if="showState">
            <p class="line"></p>
            <p>
              {{ 选择添加搜索条件 }}
            </p>
            <ul class="search-total-box">
              <li
                v-for="(item, index) in fields"
                :key="index"
                :title="item.name"
                v-show="!item.isFixed"
              >
                <div
                  class="search-total-box_label"
                  :class="{
                    'search-total-box_label-active':
                      fieldsShowState[item.value],
                    'search-total-box_label-disabled': item.isFixed,
                  }"
                  @click="btnChange(item.value, item.isFixed)"
                >
                  {{ item.name }}
                </div>
              </li>
            </ul>
            <div class="btn-box">
              <el-button class="gray-btn" @click="cancelSearch">{{
                取消
              }}</el-button>
              <lots-button type="primary" @click="saveSearch">{{
                保存
              }}</lots-button>
            </div>
          </div>
        </div>
      </div>
      <basic-data-dialog
        ref="advanceDialog"
        @confirm="detailSearchSure"
      ></basic-data-dialog>
    </el-popover>
    <!-- 透明背景，防止误点到弹框外其他内容 -->
    <div
      class="search-set_back"
      v-show="showPopover"
      @click.self="showPopover = !showPopover"
    ></div>
  </span>
</template>
<script>
import { Delete, Edit, Filter, Share, Upload } from "@element-plus/icons-vue";
import lodash from "lodash";
import { fieldConfig } from "@/utils/api";
import advanceUser from "../lotsUserSelectorDialog";
import addressSelect from "../lotsAddressCascader";
import basicDataDialog from "../lotsBasicDataDialog";
import basicDataInput from "../lotsBasicDataInput";
import basicDataSelector from "../lotsBasicDataSelector/index";
import lotsTagInput from "../lotsTag/input";
import basicDataMulti from "../lotsBasicDataMulti";
import lotsButton from "../lotsButton";
import advanceMixin from "./AdvanceMixin";
export default {
  name: "SearchAdvance",
  mixins: [advanceMixin],
  components: {
    addressSelect,
    advanceUser,
    basicDataDialog,
    basicDataInput,
    basicDataSelector,
    basicDataMulti,
    lotsButton,
    lotsTagInput,
  },
  props: {
    isShowAllFields: {
      type: Boolean,
      default: false,
    },
    searchKey: String,
    fields: {
      type: Array,
      default() {
        return [];
      },
    },
    initialVal: {
      type: Object,
      default() {
        return {};
      },
    },
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    keyMaps: Object,
    cacheIdentifer: {
      type: String,
      default() {
        return "";
      },
    },
    advancedWidth: {
      type: [String, Number],
      default() {
        return "850";
      },
    },
    tenantCode: {
      type: String,
      default: "",
    },
  },
  // 定义抛emits:
  emits: ["search", "field-change"],
  data() {
    return {
      Filter,
      id: "searchBox",
      fieldsShowState: {},
      searchData: {},
      showState: false,
      showPopover: false,
      keyMap: {},
    };
  },
  methods: {
    handleTagInputClose(field) {
      this.searchData[field] = [];
      this.fieldChange(field, []);
    },
    handleCloseTag(field, tag) {
      const checkIndex = this.searchData[field].findIndex((item) => {
        return item.id ? item.id === tag.id : item === tag.id;
      });
      if (checkIndex === -1) {
        return false;
      } else {
        this.searchData[field].splice(checkIndex, 1);
      }
    },
    handleCheckTag(field, value, list) {
      this.searchData[field] = list;
      this.fieldChange(field, list);
    },
    fieldChange(field, value) {
      this.$emit("field-change", field, value);
    },
    // 切换自定义搜索条件按钮，isFixed为默认查询项，不可操作
    btnChange(field, isFixed) {
      if (isFixed) return;
      this.fieldsShowState[field] = !this.fieldsShowState[field];
    },
    cancelSearch() {
      this.getSetList();
      this.showState = false;
    },
    showStateOpen() {
      this.showState = true;
    },
    saveSearch() {
      this.showState = false;
      const configName = [];
      const configNameCn = [];
      this.fields.forEach((o) => {
        if (this.fieldsShowState[o.value]) {
          configName.push(o.value);
          configNameCn.push(o.name);
        }
      });
      const params = {
        id: this.id,
        configName: configName.join(","),
        configNameCn: configNameCn.join(","),
        subjectType: this.searchKey,
        moduleCode: this.cacheIdentifer || window.location.hash,
        tenantCode: this.tenantCode,
      };
      fieldConfig.set(params);
    },
    clear() {
      for (const key in this.searchData) {
        if (key.includes("Fussy")) {
          this.searchData[key] = false;
        } else {
          this.searchData[key] = Array.isArray(this.searchData[key])
            ? []
            : null;
        }
      }
      this.$emit("reset", this.searchData);
    },
    search() {
      const searchData = lodash.cloneDeep(this.searchData);
      this.$emit("search", searchData);
      this.showPopover = false;
    },
    userDetailSearch(current) {
      this.$refs.advanceUser.show(current);
    },
    // 高级弹框
    detailSearch(current) {
      // 高级搜索级联操作，current.advanceCascade为需要级联的参数的key的集合，例如advanceCascade: key1,key2,key3
      const advanceCascade = {};
      if (lodash.isString(current.advanceCascade)) {
        let advanceArray = [];
        advanceArray = current.advanceCascade.split(",");
        advanceArray.forEach((item) => {
          let { 0: sourceProp, 1: destProp } = item.split("#");
          destProp = destProp || sourceProp;
          advanceCascade[destProp] = this.searchData[sourceProp];
        });
      }
      this.$refs.advanceDialog.show(true, current, advanceCascade);
    },
    detailSearchSure(data) {
      // 将返回数据添加到map里，用于高级搜索清空时，顺带清空其他关联属性
      const keyArr = Object.keys(data);
      if (keyArr.length > 0) {
        keyArr.forEach((key) => {
          this.keyMap[key] = keyArr;
        });
      }
      this.searchData = { ...this.searchData, ...data };
    },
    // 获取搜索项配置
    async getSetList() {
      let checkedList = null;
      const fieldsShowState = {};
      const params = {
        id: this.id,
        subjectType: this.searchKey,
        moduleCode: this.cacheIdentifer || window.location.hash,
        tenantCode: this.tenantCode,
      };
      try {
        const res = await fieldConfig.get(params);
        if (res.data && res.data.configName) {
          checkedList = res.data.configName;
        }
      } catch (error) {
        return;
      }
      this.fields.forEach((o) => {
        fieldsShowState[o.value] = !checkedList
          ? true
          : checkedList.includes(o.value);
      });
      this.fieldsShowState = fieldsShowState;
    },
    // 页面初始化
    initData() {
      // 获取搜索项配置
      this.getSetList();
      this.showState = false;
    },
    // 高级输入框的关闭按钮点击
    resetAdvanceParams(prop) {
      const keyArr = this.keyMap[prop];
      // 把属性相关联的字段置空
      keyArr.forEach((key) => {
        this.searchData[key] = "";
      });
    },
    handleBasicDataInputChange(prop, data) {
      this.searchData = { ...this.searchData, ...data };
      Object.keys(data).forEach((key) => {
        this.fieldChange(key, data[key]);
      });
    },
    handleBasicDataInputMultiChange(prop, data) {
      this.searchData[prop] = data;
      this.fieldChange(prop, data);
    },
  },
  watch: {
    initialVal: {
      handler(val) {
        this.searchData = { ...this.searchData, ...val };
      },
      deep: true,
      immediate: true,
    },
    keyMaps: {
      handler(val) {
        this.keyMap = { ...this.keyMap, ...val };
      },
      deep: true,
    },
  },
  computed: {
    lodash() {
      return lodash;
    },
    // 计算除了fixed字段外，高级搜索弹窗内选择的字段数
    innerSearchCount() {
      const num = this.fields.reduce((preValue, item) => {
        // fieldsShowState关联查询条件显示  isShowAllFields存在 则去掉fieldsShowState关联判断，除item.isFixed外的全部都要计算
        if (
          !item.isFixed &&
          (this.isShowAllFields || this.fieldsShowState[item.value])
        ) {
          const isNotempty =
            (item.value &&
              !Array.isArray(this.searchData[item.value]) &&
              this.searchData[item.value]) ||
            (Array.isArray(this.searchData[item.value]) &&
              this.searchData[item.value].length !== 0);
          if (isNotempty) {
            preValue = preValue + 1;
          }
        }
        return preValue;
      }, 0);
      return num;
    },
  },
};
</script>

<style lang="scss">
.advanced-search-box {
  .el-badge {
    vertical-align: top;
  }
  .el-badge__content {
    padding: 2px 2px;
    border-radius: 10px;
    color: #fff;
    display: inline-block;
    font-size: 10px;
    height: 10px;
    line-height: 10px;
    padding: 2px 4px;
    text-align: center;
    white-space: nowrap;
    border: 1px solid #fff;
    right: 10px;
  }
  .search-total-box_label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .search-btn {
    background: rgba(66, 133, 245, 0.1);
    border-color: transparent;
  }
  .el-range-separator {
    width: 8%;
  }
  .lots-select {
    width: 100%;
    .el-select__tags {
      flex-wrap: initial;
      & > span {
        display: flex;
      }
    }
  }
  .iconsearch_senior {
    color: #4285f5;
  }
  .search-set_back {
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    margin: 0;
    // opacity: 0.5;
    // background: #000000;
  }
  .advance-input {
    .el-input__inner {
      padding-right: 25px;
    }
    .el-input-group__append {
      text-align: center;
      padding: 0 14px;
      .el-button {
        padding: 8px;
      }
    }
  }
  .search-set {
    .el-input__inner {
      height: 28px !important;
      padding: 3px 10px;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
    }
    .search-list-box {
      margin-top: 10px;
      max-height: 42vh;
      overflow-y: scroll;
      padding: 0;
      > li {
        width: 400px;
        margin-bottom: 6px;
        display: flex;
        padding-top: 8px;
        > .label {
          font-size: 12px;
          display: inline-block;
          height: 28px;
          line-height: 28px;
          width: 100px;
          padding: 0 10px 0 0;
          text-align: right;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        > .form-item {
          width: 320px;
          > div {
            width: 100%;
            vertical-align: middle;
          }
        }
        .select-btn {
          color: #eee !important;
        }
        &.is-fussy {
          .form-item {
            width: 260px;
          }
          .input-append-fussy {
            width: 48px;
            margin-top: 4px;
            margin-left: 6px;
            .el-checkbox__label {
              padding-left: 4px;
            }
          }
        }
      }
    }
    .line {
      height: 1px;
      width: 100%;
      background-color: #f2f2f2;
    }
    .search-total-box {
      max-height: 120px;
      overflow: auto;
      list-style: none;
      padding: 0;
      li {
        width: 144px;
        padding: 0 10px 10px 0;
      }
      .search-total-box_label {
        padding: 5px 10px;
        font-size: 12px;
        border-radius: 4px;
        border: 1px solid #cecece;
        text-align: center;
        cursor: pointer;
      }
      .search-total-box_label-active {
        color: #409eff;
        border-color: #409eff;
      }
      .search-total-box_label-disabled {
        cursor: not-allowed;
      }
    }
    .btn-box {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      .text-btn {
        width: 40%;
      }
      .btn-btn {
        width: 60%;
        display: flex;
        justify-content: flex-end;
      }
      .gray-btn {
        background: #f5f5f5;
        color: #666;
        border: none;
        &:hover {
          color: #4285f5;
        }
      }
    }
    .box-border {
      border-top: 1px solid #f0f0f0;
      padding-top: 10px;
    }
    .text-box {
      position: relative;
      font-size: 12px;
      width: 100%;
    }
    .v-modal {
      z-index: 2000 !important;
    }
    .el-date-editor--year {
      .el-input__inner {
        padding-left: 30px;
      }
    }
  }
}
</style>
