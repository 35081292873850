export default {
  selection: false,
  columns: [
    // 表格列的配置
    {
      label: "公司名字",
      prop: "company_name",
      type: "compName",
      fixed: true,
    },
    {
      label: "主联系人",
      prop: "cst_main_person",
    },
    {
      label: "客户标签",
      prop: "cst_stags",
      type: "cstStags",
    },
    {
      label: "客户归属",
      prop: "user_id_v",
    },
    {
      label: "客户分组",
      prop: "group_id_v",
    },
    {
      label: "客户阶段",
      prop: "stage_id_v",
    },
    {
      label: "客户来源",
      prop: "source_id_v",
    },
    {
      label: "客户星级",
      prop: "level_id",
      type: "cstStar",
    },
    {
      label: "国家/地区",
      prop: "nation_id_v",
    },
    {
      label: "创建时间",
      prop: "create_time",
    },
    {
      label: "备注",
      prop: "cst_remark",
    },
  ],
};
