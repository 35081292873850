export default {
  selection: true,
  columns: [
    // 表格列的配置
    {
      label: "公司名字",
      prop: "company_name",
    },
    {
      label: "英文名",
      prop: "name_en",
    },
    {
      label: "性别",
      prop: "gender",
      type: "select",
      options: { 1: "男", 0: "女" },
      mustFill: true,
    },
    {
      label: "邮箱",
      prop: "email",
      mustFill: true,
    },
    {
      label: "电话",
      prop: "phone",
    },
    {
      label: "WhatsApp",
      prop: "wa_account",
    },
    {
      label: "状态",
      prop: "status",
      type: "select",
      options: { 1: "有效", 0: "关闭" },
    },
    {
      label: "备注",
      prop: "remark",
      mustFill: true,
    },
  ],
  actions: {
    // 表格操作列的配置
    fixedWidth: 110, // fixedWidth（可选） 设置操作列的固定宽度，不加该参数默认是按钮list长度*50px
    list: [
      {
        label: "编辑",
        event: "edit",
        displayRule: () => {
          return true;
        },
      },
      {
        label: "删除",
        event: "del",
        displayRule: () => {
          return true;
        },
      },
    ],
  },
  forms: [
    // 表单页面的配置
    {
      label: "公司名字：",
      prop: "company_name",
      type: "input",
      span: 22,
      disabled: true,
    },
    {
      label: "英文名字：",
      prop: "name_en",
      type: "input",
      span: 11,
      rule: [{ required: true, message: "联系人英文名", trigger: "blur" }],
    },
    {
      label: "中文名字：",
      prop: "name_ch",
      type: "input",
      span: 11,
    },
    {
      label: "主联系人：",
      prop: "type",
      type: "switch",
      span: 11,
      activeValue: 1,
      inactiveValue: 0,
      activeText: "是",
      inactiveText: "否",
      rule: [{ required: true }],
    },
    {
      label: "客户性别：",
      prop: "gender",
      type: "switch",
      span: 11,
      activeValue: 1,
      inactiveValue: 0,
      activeText: "男",
      inactiveText: "女",
      rule: [{ required: true }],
    },
    {
      label: "国家/地区：",
      prop: "nation_id",
      type: "select",
      span: 11,
      options: "",
      filterable: true,
      placeholder: "选择客户来源国家/地区",
      rule: [
        {
          required: true,
          message: "选择客户来源国家/地区",
          trigger: "blur",
        },
      ],
    },
    {
      label: "注册时间",
      prop: "login_time",
      type: "time",
      span: 11,
      rule: [{ required: true }],
    },
    {
      label: "TM 账号：",
      prop: "tm_account",
      type: "input",
      span: 11,
      placeholder: "当填入TM后，会自动生成一个阿里收款账户",
    },
    {
      label: "邮箱号码：",
      prop: "email",
      span: 11,
      type: "input",
      placeholder: "联系人电子邮箱",
    },
    {
      label: "电话号码：",
      prop: "phone",
      span: 11,
      type: "input",
      placeholder: "联系人手机电话",
    },
    {
      label: "WhatsApp：",
      prop: "wa_account",
      span: 11,
      type: "input",
      placeholder: "联系人WhatsApp",
    },
    {
      label: "微信账号：",
      prop: "wc_account",
      span: 11,
      type: "input",
      placeholder: "联系人微信",
    },
    {
      label: "状态：",
      prop: "status",
      type: "switch",
      span: 11,
      activeValue: 1,
      inactiveValue: 0,
      activeText: "是",
      inactiveText: "否",
      rule: [{ required: true }],
    },
    {
      label: "备注：",
      prop: "remark",
      type: "input",
      span: 22,
      inputType: "textarea",
      autoSize: { minRows: 5 },
      placeholder: "客户特殊要求、客户在线时间等，一些有价值的特性信息",
    },
  ],
};
