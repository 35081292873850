<template>
  <div style="height: 100%">
    <div class="actionBtn">
      <el-button type="primary" @click="productGo(0)"
        ><el-icon> <plus /> </el-icon><span>新增产品</span></el-button
      >
      <!-- <el-button type="primary" @click="productRandNo()"
        ><el-icon> <plus /> </el-icon><span>生成随机码</span></el-button
      > -->
    </div>
    <el-dialog v-model="dialogFormVisible" :title="title">
      <el-form :model="formState" :rules="rules">
        <el-row :gutter="20">
          <el-col :span="10">
            <el-form-item
              label="产品型号："
              :label-width="formLabelWidth"
              prop="pdt_model"
            >
              <el-input
                v-model="formState.pdt_model"
                placeholder="格式：6G64G"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item
              label="测试费率："
              :label-width="formLabelWidth"
              prop="test_type"
            >
              <el-input
                v-model="formState.test_type"
                placeholder="测试基础费为5元，没有测试费填0"
                clearable
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" v-if="formState.pdt_id == '0'">
          <el-col :span="1"></el-col>
          <el-col :span="19">
            <el-form-item label="产品属性：" prop="pdt_property_id">
              <el-select
                placeholder="请选择属性（可以多选）"
                multiple
                style="width: 100%"
                v-model="formState.pdt_property_id"
                :popper-append-to-body="false"
              >
                <el-option-group
                  v-for="group in getProductPropertyListForAddProduct"
                  :key="group.pdt_property_id"
                  :label="group.pdt_property_name_ch"
                >
                  <el-option
                    v-for="item in group.children"
                    :key="item.pdt_property_id"
                    :label="item.pdt_property_name_ch"
                    :value="item.pdt_property_id"
                  />
                </el-option-group>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" v-if="formState.pdt_id == '0'">
          <el-col :span="1"></el-col>
          <el-col :span="19">
            <el-form-item label="所属分类：" prop="pdt_group_id">
              <el-select
                placeholder="请选择分类（可以多选）"
                multiple
                style="width: 100%"
                v-model="formState.pdt_group_id"
                :popper-append-to-body="false"
              >
                <el-option-group
                  v-for="group in getProductGroupListForAddProduct"
                  :key="group.pdt_group_id"
                  :label="group.pdt_group_name_ch"
                >
                  <el-option
                    v-for="item in group.children"
                    :key="item.pdt_group_id"
                    :label="item.pdt_group_name_ch"
                    :value="item.pdt_group_id"
                  />
                </el-option-group>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" v-if="formState.pdt_id == '0'">
          <el-col :span="1"></el-col>
          <el-col :span="19">
            <el-form-item label="所属等级：" prop="pdt_level_id">
              <el-select
                placeholder="请选择等级（可以多选）"
                multiple
                style="width: 100%"
                v-model="formState.pdt_level_id"
                :popper-append-to-body="false"
              >
                <el-option-group
                  v-for="group in getProductLevelListForAddProduct"
                  :key="group.pdt_level_id"
                  :label="group.pdt_level_name_ch"
                >
                  <el-option
                    v-for="item in group.children"
                    :key="item.pdt_level_id"
                    :label="item.pdt_level_name_ch"
                    :value="item.pdt_level_id"
                  />
                </el-option-group>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" v-if="formState.pdt_id == '0'">
          <el-col :span="1"></el-col>
          <el-col :span="19">
            <el-form-item label="产品颜色：" prop="color_id">
              <el-select
                placeholder="请选择颜色（可以多选）"
                multiple
                style="width: 100%"
                v-model="formState.color_id"
                :popper-append-to-body="false"
              >
                <el-option
                  v-for="item in getColorList"
                  :key="item.color_id"
                  :label="item.color_name_ch"
                  :value="item.color_id"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="20">
            <el-form-item label="海关编码：" :label-width="formLabelWidth">
              <el-input
                v-model="formState.hs_code"
                placeholder="格式：85171410"
                clearable
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="10">
            <el-form-item label="价格：" :label-width="formLabelWidth">
              <el-input
                v-model="formState.pdt_price"
                placeholder="产品采购价格"
                clearable
              >
                <template #prepend>￥</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="重量：" :label-width="formLabelWidth">
              <el-input
                v-model="formState.pdt_weight"
                placeholder="单位：kg"
                clearable
              >
                <template #prepend>KG</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="2"> </el-col>
          <el-col :span="5">
            <el-form-item label="长：">
              <el-input
                v-model="formState.pdt_length"
                placeholder="长度"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="宽：">
              <el-input v-model="formState.pdt_width" placeholder="宽度" />
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="高：">
              <el-input v-model="formState.pdt_height" placeholder="高度" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="20">
            <el-form-item label="产品描述：" :label-width="formLabelWidth">
              <el-input type="textarea" v-model="formState.pdt_dep" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="20">
            <el-form-item label="产品图片：" :label-width="formLabelWidth">
              <el-upload class="upload-demo" drag action="#" multiple
                ><el-icon :size="50" style="margin-top: 40px">
                  <plus />
                </el-icon>
                <div class="el-upload__text">
                  将文件拖到此处，或<em>点击上传</em>
                </div>
                <template #tip>
                  <div class="el-upload__tip">
                    只能上传 jpg/png 文件，且不超过 500kb
                  </div>
                </template>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="状态：" :label-width="formLabelWidth">
          <el-switch
            v-model="formState.pdt_status"
            class="ml-2"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-text="开启"
            inactive-text="关闭"
          />
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="20">
            <el-form-item label="备注：" :label-width="formLabelWidth">
              <el-input
                type="textarea"
                v-model="formState.pdt_remark"
                clearable
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取消</el-button>
          <el-button type="primary" @click="add">{{ goTitle }}</el-button>
        </span>
      </template>
    </el-dialog>

    <div class="content">
      <el-table
        :data="dataSource"
        style="width: 100%; font-size: 12px"
        height="100%"
        stripe:true
      >
        <el-table-column fixed prop="pdt_id" label="ID" width="100" sortable />
        <el-table-column fixed prop="pdt_no" label="产品编码" width="250" />
        <el-table-column prop="pdt_group_id_v" label="分类" width="120" />
        <el-table-column prop="pdt_model" label="产品型号" width="120" />
        <el-table-column prop="pdt_level_id_v" label="产品等级" width="120" />
        <el-table-column prop="pdt_price" label="产品价格" width="120">
          <template #default="scope"> ￥{{ scope.row.pdt_price }} </template>
        </el-table-column>
        <el-table-column prop="pdt_weight" label="实重" width="120">
          <template #default="scope"> {{ scope.row.pdt_weight }}KG </template>
        </el-table-column>
        <el-table-column prop="pdt_volume" label="体积" width="120">
          <template #default="scope"> {{ scope.row.pdt_volume }}cm² </template>
        </el-table-column>
        <el-table-column prop="pdt_property_id_v" label="属性" width="120" />
        <el-table-column prop="color_id_v" label="颜色" width="120" />
        <el-table-column prop="pdt_status" label="状态" width="120">
          <template #default="scope">
            <span
              :style="{ color: scope.row.pdt_status == 1 ? 'green' : 'red' }"
            >
              {{ scope.row.pdt_status == 1 ? "上架" : "下架" }}</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="pdt_dep" label="产品简介" width="200" />
        <el-table-column prop="pdt_remark" label="备注" width="300" />
        <el-table-column fixed="right" label="操作" width="200">
          <template #default="scope">
            <el-button type="primary" @click="productGo(scope.row)">
              <el-icon> <edit /> </el-icon><span>编辑</span></el-button
            >
            <el-button type="danger" @click="del(scope.row.pdt_id)"
              ><el-icon> <delete /> </el-icon><span>删除</span></el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="demo-pagination-block">
        <el-pagination
          :currentPage="currentPage"
          :page-size="pageSize"
          :page-sizes="[20, 50, 100]"
          :background="true"
          layout="sizes, prev, pager, next, jumper,total"
          :total="pageTotal"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, toRefs, onMounted } from "vue";
import {
  productList,
  productAdd,
  productDel,
  productInfo,
  getProductGroupListForAddProduct,
  getProductLevelListForAddProduct,
  //getBrandList,
  getProductPropertyListForAddProduct,
  creatProductRandNo,
} from "@/network/product.js";
import { getColorList } from "@/network/basic.js";
import { Delete, Edit, Plus } from "@element-plus/icons-vue";
import { ElMessage, ElMessageBox } from "element-plus";

export default {
  components: {
    Delete,
    Edit,
    Plus,
  },
  setup() {
    const currentPage = ref(1);
    const pageSize = ref(10);
    const pageTotal = ref(0);
    const state = reactive({
      dataSource: [],
      title: "添加产品",
      goTitle: "添加",
      getProductGroupListForAddProduct: [],
      getProductLevelListForAddProduct: [],
      getProductPropertyListForAddProduct: [],
      getColorList: [],
      dialogFormVisible: false,
      formLabelWidth: "120px",
    });
    const rules = reactive({
      pdt_model: [
        {
          required: true,
          message: "请输入产品型号：6G64G",
          trigger: "blur",
        },
      ],
      test_type: [
        {
          required: true,
          message: "请输入测试费率",
          trigger: "blur",
        },
      ],
      pdt_group_id: [
        {
          required: true,
          message: "请选择产品所属分类",
          trigger: "blur",
        },
      ],
      pdt_property_id: [
        {
          required: true,
          message: "请选择产品属性",
          trigger: "blur",
        },
      ],
      pdt_level_id: [
        {
          required: true,
          message: "请选择产品等级",
          trigger: "blur",
        },
      ],
      color_id: [
        {
          required: true,
          message: "请选择产品颜色",
          trigger: "blur",
        },
      ],
    });
    const formState = reactive({
      pdt_id: 0,
      pdt_no: "",
      hs_code: "",
      pdt_group_id: "",
      pdt_model: "",
      pdt_level_id: "",
      pdt_pic: "",
      pdt_price: "",
      pdt_weight: "",
      pdt_length: "",
      pdt_width: "",
      pdt_height: "",
      pdt_property_id: "",
      color_id: "",
      pdt_dep: "",
      test_type: "",
      pdt_status: true,
      pdt_remark: "",
    });
    const getList = () => {
      productList({
        currentPage: currentPage.value,
        pageSize: pageSize.value,
        listType: 1,
      }).then((res) => {
        if (res.code == 0) {
          state.dataSource = res.data.lists;
          pageTotal.value = res.data.pageTotal;
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    const handleSizeChange = (val) => {
      pageSize.value = val;
      getList();
    };
    const handleCurrentChange = (val) => {
      currentPage.value = val;
      getList();
    };
    onMounted(() => {
      getList();
    });
    //------------以上为列表数据

    const productGo = (row) => {
      state.dialogFormVisible = true;
      //返回产品分类列表
      getProductGroupListForAddProduct().then((res) => {
        if (res.code == 0) {
          state.getProductGroupListForAddProduct = res.data;
        } else {
          ElMessage.error(res.msg);
        }
      });
      //返回产品等级列表
      getProductLevelListForAddProduct().then((res) => {
        if (res.code == 0) {
          state.getProductLevelListForAddProduct = res.data;
        } else {
          ElMessage.error(res.msg);
        }
      });
      //返回产品属性列表
      getProductPropertyListForAddProduct().then((res) => {
        if (res.code == 0) {
          state.getProductPropertyListForAddProduct = res.data;
        } else {
          ElMessage.error(res.msg);
        }
      });
      //返回产品颜色列表
      getColorList().then((res) => {
        if (res.code == 0) {
          state.getColorList = res.data;
        } else {
          ElMessage.error(res.msg);
        }
      });
      if (row) {
        Object.assign(formState, row);
        state.goTitle = "更新";
        state.title = "修改产品信息";
        formState.pdt_status = Boolean(row.pdt_status);
      } else {
        state.title = "添加产品";
        formState.pdt_id = 0;
        formState.hs_code = "85171410";
        formState.pdt_group_id = "";
        formState.pdt_model = "";
        formState.pdt_level_id = "";
        formState.pdt_pic = "";
        formState.pdt_price = "";
        formState.pdt_weight = "";
        formState.pdt_length = "";
        formState.pdt_width = "";
        formState.pdt_height = "";
        formState.pdt_property_id = "";
        formState.color_id = "";
        formState.pdt_dep = "";
        formState.test_type = "";
        formState.pdt_status = true;
        formState.pdt_remark = "";
      }
    };

    const productRandNo = () => {
      //生成产品随机item码
      creatProductRandNo().then((res) => {
        if (res.code == 0) {
          ElMessage.success(res.msg);
        } else {
          ElMessage.error(res.msg);
        }
      });
    };

    const add = () => {
      //无论新增还是修改，产品型号都是必填;
      if (!formState.pdt_model) {
        ElMessage.info("请输入产品型号");
        return false;
      }
      productAdd(formState).then((res) => {
        if (res.code == 0) {
          ElMessage.success(res.msg);
          state.dialogFormVisible = false;
          getList();
        } else if (res.code == 66) {
          ElMessage.success(res.msg);
          state.dialogFormVisible = false;
          getList();
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    //------------以上为编辑和修改数据
    const del = (pdt_id) => {
      if (!pdt_id) {
        ElMessage("所选数据为空，请重新选择！");
      } else {
        ElMessageBox.confirm("确定要删除该条数据吗?", "警告", {
          confirmButtonText: "删除",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            productDel({ pdt_id: pdt_id, del_type: 0 }).then((res) => {
              if (res.code == 0) {
                ElMessage.success(res.msg);
                getList();
              } else {
                ElMessage.error(res.msg);
              }
            });
          })
          .catch(() => {
            ElMessage({
              type: "info",
              message: "删除取消",
            });
          });
      }
    };

    return {
      ...toRefs(state),
      rules,
      formState,
      productGo,
      productRandNo,
      add,
      del,
      currentPage,
      pageSize,
      pageTotal,
      handleSizeChange,
      handleCurrentChange,
    };
  },
};
</script>
<style scoped>
.el-select :deep .select-popper {
  z-index: 8888 !important;
  top: auto !important;
  left: auto !important;
}
.actionBtn {
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;
}
.content {
  height: calc(100% - 180px);
  position: relative;
}
.el-table {
  position: absolute;
}
.el-table__header {
  width: 100%;
}
.el-table__body {
  height: calc(100% - 40px);
}
.el-pagination {
  position: absolute;
  bottom: -56px;
  right: 20px;
}
</style>
