<template>
  <div style="height: 100%">
    <el-row :gutter="20">
      <el-col :span="4" :offset="22" style="margin-bottom: 20px">
        <el-button type="primary" @click="addProductConfig"
          ><span>新增产品</span></el-button
        >
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <isdTable
          :loading="tableLoading"
          :rows="totalData"
          :height="600"
          :columns="config.columns"
          :selection="config.selection"
          :actions="config.actions"
          @action-click="actionClick"
          style="font-size: 12px"
        >
        </isdTable>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <isdPagination
          @size-change="sizeChange"
          :current-page="currentPage"
          @current-change="pageChange"
          layout="total,sizes,prev,pager,next,jumper"
          :page-size="pageSize"
          :page-sizes="[20, 50, 100]"
          :total="pageTotal"
        ></isdPagination>
      </el-col>
    </el-row>
    <!-- 新增/编辑弹窗 -->
    <isdFormDialog
      ref="formDialogIns"
      :config="config.forms"
      :loading="formDialog.loading"
      :label-width="formDialog.labelWidth"
      :row="formDialog.row"
      v-model="formDialog.show"
      :col="formDialog.col"
      :title="formDialog.title"
      :width="formDialog.width"
      @close-dialog="closeFormDialog"
    >
      <template v-slot:form-append="{ form, itemSetting }">
        <el-form-item
          v-if="itemSetting.prop === 'pdt_id'"
          :label="itemSetting.label"
          :prop="itemSetting.prop"
          :label-width="formDialog.labelWidth"
        >
          <el-select
            v-model="form[itemSetting.prop]"
            :popper-append-to-body="false"
            @change="dataChange"
            v-selectLoadMore="
              () => {
                selectLoadMore1(searchVal);
              }
            "
            clearable
            filterable
            remote
            :remote-method="
              (query) => {
                remoteMethod(query);
              }
            "
            @clear="remoteMethod('')"
            :loading="selectLoading"
            :placeholder="请选择产品编号"
          >
            <el-option
              v-for="(it, idx) in productList.val"
              :key="idx"
              :label="it.label"
              :value="it.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </template>
    </isdFormDialog>
  </div>
</template>
<script>
import { ref, reactive, onMounted } from "vue";
import {
  getProductList,
  addOrderDetails,
  getProductDep,
  getOrderDetailList,
  delProductDetails,
} from "@/network/order.js";
import { useRoute } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import isdFormDialog from "@/views/components/isdFormDialog/Index.vue";
import isdTable from "@/views/components/isdTable/index.vue";
import configData from "./config";
import { ListClass } from "@/composition/ListClass.js";
import isdPagination from "@/views/components/isdPage/index.vue";
export default {
  components: {
    isdFormDialog,
    isdTable,
    isdPagination,
  },
  props: {
    orderID: {
      type: Number,
      default: null,
    },
  },
  setup(props) {
    const searchVal = ref("");
    const selTotalPage = ref(0);
    const selFiledParam = reactive({
      currentPage: 1,
      pageSize: 10,
      query: "",
    });
    const selectLoading = ref(false);
    const formDialogIns = ref(null);
    const config = reactive(configData);
    const productList = reactive({
      val: [],
    });
    // 新建/修改弹窗对象
    const formDialog = reactive({
      labelWidth: "90px", // 弹窗label宽度
      loading: false, // 表单加载中显示
      row: {}, // 数据项对象
      show: false, // 显示弹窗
      col: 1, // 弹窗内列数
      title: "新建", // 弹窗标题
      width: "650px", // 弹窗宽度
    });
    class TableList extends ListClass {
      beforeGetList(condition) {
        condition.ord_list_id = Number(ordId.value) || props.orderID;
        return condition;
      }
    }
    const {
      currentPage,
      pageSize,
      pageTotal,
      tableLoading,
      totalData,
      getList,
      searchList,
      pageChange,
      sizeChange,
      handleSelectedRow,
    } = new TableList({
      Api: {
        listApi: getOrderDetailList,
      },
    });
    const route = ref(useRoute());
    const ordId = ref(route.value.query.ordId);
    const getProductOptionList = (params) => {
      getProductList(params).then((res) => {
        if (res.code == 0) {
          selTotalPage.value = res.data.pageTotal;
          if (res.data && res.data.list.length) {
            let ary = res.data.list.map((item) => {
              return {
                label: item.pdt_no,
                value: item.pdt_id,
              };
            });
            productList.val = productList.val.concat(ary);
          }
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    onMounted(() => {
      getList();
      getProductOptionList(selFiledParam);
    });
    const editProduct = (row) => {
      formDialog.show = true;
      formDialog.title = "编辑";
      formDialog.row = row;
    };
    // 订单详情表格编辑删除事件
    const actionClick = (event, row) => {
      switch (event) {
        case "edit":
          editProduct(row);
          break;
        case "del":
          deleteProduct(row);
          break;
      }
    };
    // 产品编号懒加载
    const selectLoadMore1 = (val) => {
      let timeout = null;
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        if (
          selFiledParam.currentPage * selFiledParam.pageSize >
          selTotalPage.value
        )
          return;
        selFiledParam.currentPage += 1; // 请求接口
        selFiledParam.query = val;
        getProductOptionList(selFiledParam);
      }, 200);
    };
    // 产品编号远程搜索
    const remoteMethod = (val) => {
      searchVal.value = val;
      selFiledParam.query = val;
      selFiledParam.currentPage = 1;
      productList.val = [];
      setTimeout(() => {
        getProductOptionList(selFiledParam); // 请求接口
      }, 200);
    };
    const closeAction = () => {
      formDialog.show = false;
      formDialog.row = {};
      formDialog.loading = false;
    };
    // 删除订单详情
    const deleteProduct = (row) => {
      ElMessageBox.confirm("确定要删除该条数据吗?", "警告", {
        confirmButtonText: "删除",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delProductDetails({ ord_details_id: row.ord_details_id }).then(
            (res) => {
              if (res.code == 0) {
                ElMessage.success(res.msg);
                getList();
              } else {
                ElMessage.error(res.msg);
              }
            }
          );
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "删除取消",
          });
        });
    };
    // 新增弹窗关闭
    const closeFormDialog = (params) => {
      productList.val = [];
      getProductOptionList({ currentPage: 1, pageSize: 10, query: "" });
      if (!params) {
        closeAction();
        return;
      }
      formDialog.loading = true;
      if (ordId.value) {
        // 编辑
        addOrderDetails({
          ord_list_id: ordId.value,
          detailInfo: formDialogIns.value.form,
        }).then((res) => {
          if (res.code == 0) {
            formDialog.loading = false;
            getList();
            ElMessage.success(res.msg);
            closeAction();
          } else {
            ElMessage.error(res.msg);
          }
        });
      } else {
        //新增
        addOrderDetails({
          ord_list_id: props.orderID,
          detailInfo: params,
        }).then((res) => {
          if (res.code == 0) {
            formDialog.loading = false;
            getList();
            ElMessage.success(res.msg);
            closeAction();
          }
        });
      }
    };
    const dataChange = (val) => {
      getProductDep({ pdt_id: val }).then((res) => {
        if (res.code == 0) {
          formDialogIns.value.form.pdt_dep = res.data.pdt_dep;
          formDialogIns.value.form.pdt_remark = res.data.pdt_remark;
        }
      });
    };
    const addProductConfig = () => {
      // 新增行
      formDialog.show = true;
      formDialog.title = "新建";
      formDialog.row = {
        ord_details_id: "",
        is_show_shipping: 1,
        is_show_inv: 1,
      };
    };
    const removeProductConfig = (item) => {
      // 删除行
    };

    return {
      addProductConfig,
      removeProductConfig,
      closeFormDialog,
      formDialog,
      config,
      actionClick,
      currentPage,
      pageSize,
      pageTotal,
      tableLoading,
      totalData,
      getList,
      searchList,
      pageChange,
      sizeChange,
      handleSelectedRow,
      dataChange,
      formDialogIns,
      selectLoading,
      selectLoadMore1,
      remoteMethod,
      searchVal,
      productList,
    };
  },
};
</script>
