<template>
  <div class="flex-layout order-management">
    <isdListPaper v-loading="tableLoading">
      <template v-slot:header-button>
        <el-button type="primary" @click="addSet()">
          <el-icon style="vertical-align: middle"> <Plus /> </el-icon
          ><span style="vertical-align: middle"> 新增请假 </span>
        </el-button>
      </template>
      <template v-slot:header-search>
        <isdSearchBox
          ref="searchBoxIns"
          :search-key="config.name"
          :fields="config.searchFields"
          @search-change="searchList"
          :loading="tableLoading"
          class="track-search"
        ></isdSearchBox>
      </template>
      <template v-slot:list="props">
        <isdTable
          :loading="tableLoading"
          :rows="totalData"
          :height="props.tableHeight"
          :columns="config.columns"
          :selection="config.selection"
          :actions="config.actions"
          @selection-change="handleSelectedRow"
        >
          <template v-slot:col-append="{ col, row }">
            <span v-if="col.type === 'operate_btn'">
              <el-button
                round
                size="small"
                @click="addSet(row)"
                v-if="row.status < 2"
                ><el-icon style="vertical-align: middle"> <EditPen /> </el-icon
                ><span style="vertical-align: middle"> 编辑 </span></el-button
              >
              <el-button
                type="success"
                round
                size="small"
                disabled
                v-if="row.status == 2"
                ><el-icon style="vertical-align: middle"
                  ><SuccessFilled /></el-icon
                ><span style="vertical-align: middle">
                  审核通过
                </span></el-button
              >
              <el-button
                round
                size="small"
                v-else-if="operate_right"
                @click="confirmSet(row.id)"
                ><el-icon style="vertical-align: middle"><Notebook /></el-icon
                ><span style="vertical-align: middle"> 审核 </span></el-button
              >
              <el-button round size="small" disabled v-else
                ><el-icon style="vertical-align: middle"><AlarmClock /></el-icon
                ><span style="vertical-align: middle">
                  等待审核
                </span></el-button
              >
            </span>
          </template>
        </isdTable>
      </template>
      <template v-slot:footer-buttons>
        <el-button
          type="danger"
          plain
          @click="deleteItem"
          :disabled="!selectedRows.length"
          ><el-icon><Delete /></el-icon>
          <span style="vertical-align: middle"> 批量删除 </span>
        </el-button>
      </template>
      <template v-slot:footer>
        <isdPagination
          @size-change="sizeChange"
          :current-page="currentPage"
          @current-change="pageChange"
          layout="total,sizes,prev,pager,next,jumper"
          :page-size="pageSize"
          :page-sizes="[20, 50, 100]"
          :total="pageTotal"
        ></isdPagination>
      </template>
    </isdListPaper>
    <!-- 新增/编辑弹窗 -->
    <isdFormDialog
      ref="formDialogIns"
      :config="config.forms"
      :loading="formDialog.loading"
      :label-width="formDialog.labelWidth"
      :row="formDialog.row"
      v-model="formDialog.show"
      :col="formDialog.col"
      :title="formDialog.title"
      :width="formDialog.width"
      @close-dialog="closeFormDialog"
    ></isdFormDialog>
  </div>
</template>
<script>
import {
  leaveList,
  addLeaveList,
  delLeaveList,
  getUserList,
  confirmLeaveList,
} from "@/network/monthSet.js";
import {
  EditPen,
  Printer,
  Delete,
  Check,
  Plus,
  View,
  Notebook,
  AlarmClock,
  SuccessFilled,
} from "@element-plus/icons-vue";
import { defineComponent, ref, reactive, onMounted } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { ListClass } from "@/composition/ListClass.js";
import configData from "./config";
import isdTable from "@/views/components/isdTable/index.vue";
import isdPagination from "@/views/components/isdPage/index.vue";
import isdListPaper from "@/views/components/isdListPaper/index.vue";
import isdFormDialog from "@/views/components/isdFormDialog/Index.vue";
import isdSearchBox from "@/views/components/isdSearchBox/index.vue";
export default defineComponent({
  name: "monthOASet",
  components: {
    EditPen,
    Printer,
    Delete,
    Check,
    Plus,
    View,
    Notebook,
    AlarmClock,
    SuccessFilled,
    isdTable,
    isdPagination,
    isdSearchBox,
    isdListPaper,
    isdFormDialog,
  },
  setup() {
    const userList = reactive({
      val: [],
    });
    const operate_right = ref(true);
    const formDialogIns = ref(null);
    const config = reactive(configData); // 列表配置
    const searchBoxIns = ref(null); // 搜索实例
    const columnConfigIns = ref(null); // 列配置实例
    const statusDialogIns = ref(null); // 状态弹框实例
    const detailPageIns = ref(null); // 详情编辑页ref
    const tableBox = ref(null);
    // 新建/修改弹窗对象
    const formDialog = reactive({
      labelWidth: "110px", // 弹窗label宽度
      loading: false, // 表单加载中显示
      row: {}, // 数据项对象
      show: false, // 显示弹窗
      col: 1, // 弹窗内列数
      title: "新建", // 弹窗标题
      width: "60%", // 弹窗宽度
    });
    // 格式化查询条件
    const dataFormat = (condition) => {
      condition.fieldName = "status";
      condition.fieldSymbol = ">";
      condition.fieldVal = 0;
      return condition;
    };
    class TableList extends ListClass {
      beforeGetList(condition) {
        condition = dataFormat(condition);
        return condition;
      }
      afterGetList(res) {
        operate_right.value = res.data.operate_right;
        super.afterGetList(res);
      }
    }
    const {
      currentPage,
      pageNo,
      pageSize,
      pageTotal,
      tableLoading,
      totalData,
      getList,
      searchList,
      pageChange,
      sizeChange,
      selectedRows,
      handleSelectedRow,
    } = new TableList({
      Api: {
        listApi: leaveList,
      },
    });
    //新增、修改日程
    const addSet = (row) => {
      getUserList().then((res) => {
        if (res.code == 0) {
          config.forms[0].options = res.data;
        } else {
          ElMessage.error(res.msg);
        }
      });
      formDialog.show = true;
      if (row) {
        formDialog.title = "修改";
        formDialog.row = row;
      } else {
        formDialog.title = "新增";
        formDialog.row = {};
      }
    };
    const closeFormDialog = (params) => {
      if (!params) {
        closeAction();
        return;
      }
      saveItem(params);
    };
    const closeAction = () => {
      formDialog.show = false;
      formDialog.row = {};
      formDialog.loading = false;
    };
    const saveItem = (params) => {
      // 新增/更新成功后对数据操作
      if (!params) {
        closeAction();
        return;
      }
      formDialog.loading = true;
      // 保存、编辑
      addLeaveList(params).then((res) => {
        if (res.code == 0) {
          formDialog.loading = false;
          getList();
          ElMessage.success(res.msg);
          closeAction();
        } else {
          ElMessage.error(res.msg);
          formDialog.loading = false;
          getList();
          closeAction();
        }
      });
    };
    const showSetInfo = () => {
      ElMessage("等待开发！");
    };
    const deleteItem = () => {
      const map = new Map();
      const arr = selectedRows.value.map((item) => {
        return item.id; // 只返回id号
      });
      //方法
      if (!arr) {
        ElMessage("所选数据为空，请重新选择！");
      } else {
        ElMessageBox.confirm("确定要删除选中的请假条吗?", "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            delLeaveList({ id: arr, type: 0 }).then((res) => {
              if (res.code == 0) {
                ElMessage.success(res.msg);
                getList();
              } else {
                ElMessage.error(res.msg);
                getList();
              }
            });
          })
          .catch(() => {
            ElMessage({
              type: "info",
              message: "删除取消",
            });
          });
      }
    };
    // 确认信息
    const confirmSet = (val) => {
      ElMessageBox.confirm("确定同意该请假条？", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          confirmLeaveList({ id: val, type: 2 }).then((res) => {
            if (res.code == 0) {
              ElMessage.success(res.msg);
              getList();
            } else {
              ElMessage.error(res.msg);
            }
          });
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "确认取消",
          });
        });
    };
    onMounted(() => {
      getList();
    });
    return {
      tableBox,
      config,
      pageNo,
      pageSize,
      pageTotal,
      tableLoading,
      totalData,
      searchList,
      pageChange,
      sizeChange,
      handleSelectedRow,
      searchBoxIns,
      columnConfigIns,
      detailPageIns,
      statusDialogIns,
      formDialog,
      closeFormDialog,
      addSet,
      currentPage,
      formDialogIns,
      selectedRows,
      showSetInfo,
      operate_right,
      deleteItem,
      confirmSet,
    };
  },
});
</script>

<style lang="scss">
.order-management {
  .el-table th > .cell {
    word-break: keep-all;
  }
}
</style>
