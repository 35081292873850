export default {
  selection: false,
  searchFields: [
    // 搜索组件的配置项
    {
      name: "设置项中文名",
      value: "set_name_ch",
      type: "input",
      isFixed: true,
      span: 4,
    },
    {
      name: "设置项URL",
      value: "set_url",
      type: "input",
      isFixed: true,
      span: 4,
    },
    {
      name: "备注",
      value: "set_remark",
      type: "input",
      isFixed: true,
      span: 4,
    },
  ],
  columns: [
    // 表格列的配置
    {
      label: "ID",
      prop: "set_id",
    },
    {
      label: "所属分组",
      prop: "set_group_id_v",
    },
    {
      label: "设置项中文名",
      prop: "set_name_ch",
    },
    {
      label: "设置项英文名",
      prop: "set_name_en",
    },
    {
      label: "设置项URL",
      prop: "set_url",
    },
    {
      label: "状态",
      prop: "set_status",
      mustFill: true,
      type: "select",
      options: { 1: "有效", 0: "关闭" },
    },
    {
      label: "备注",
      prop: "set_remark",
      mustFill: true,
    },
    {
      label: "操作",
      type: "operate_btn",
      prop: "",
      width: "200px",
    },
  ],
  forms: [
    {
      label: "设置分组：",
      prop: "set_group_id",
      type: "select",
      placeholder: "-请选择分组-",
      rule: [
        {
          required: true,
          message: "请选择设置分组",
          trigger: "blur",
        },
      ],
    },
    {
      label: "设置项中文名：",
      prop: "set_name_ch",
      type: "input",
      rule: [
        {
          required: true,
          message: "请输入设置项中文名",
          trigger: "blur",
        },
      ],
    },
    {
      label: "设置项英文名：",
      prop: "set_name_en",
      type: "input",
      rule: [
        {
          required: true,
          message: "请输入设置项英文名",
          trigger: "blur",
        },
      ],
    },
    {
      label: "设置项URL：",
      prop: "set_url",
      type: "input",
      rule: [
        {
          required: true,
          message: "请输入设置项URL",
          trigger: "blur",
        },
      ],
    },
    {
      label: "状态:",
      prop: "set_status",
      type: "switch",
      modelValue: 1,
      activeText: "是",
      inactiveText: "否",
      rule: [{ required: true }],
    },
    {
      label: "备注",
      prop: "set_remark",
      type: "input",
      inputType: "textarea",
    },
  ],
};
