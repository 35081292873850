<template>
  <div style="height: 100%">
    <div class="actionBtn">
      <el-button type="primary" @click="userGo(0)"
        ><el-icon> <plus /> </el-icon><span>新增</span></el-button
      >
    </div>
    <el-dialog v-model="dialogFormVisible" :title="title">
      <el-form :model="formState" :rules="rules">
        <el-row :gutter="20">
          <el-col :span="10"
            ><div class="grid-content bg-purple" />
            <el-form-item
              label="账户："
              :label-width="formLabelWidth"
              prop="user_email"
            >
              <el-input
                v-model="formState.user_email"
                placeholder="输入账号：请用邮箱"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="10"
            ><div class="grid-content bg-purple" />
            <el-form-item label="密码：" :label-width="formLabelWidth">
              <el-input
                v-model="formState.user_psw"
                type="password"
                :placeholder="pswMsg"
                clearable
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="10">
            <el-form-item
              label="中文名："
              :label-width="formLabelWidth"
              prop="user_name_ch"
            >
              <el-input
                v-model="formState.user_name_ch"
                placeholder="输入中文名"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="英文名：" :label-width="formLabelWidth">
              <el-input
                v-model="formState.user_name_en"
                placeholder="输入英文名"
                clearable
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="10">
            <el-form-item
              label="所属部门："
              :label-width="formLabelWidth"
              prop="user_group_id"
            >
              <el-select
                v-model="formState.user_group_id"
                :popper-append-to-body="false"
              >
                <el-option
                  v-for="item in getGroupList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item
              label="业务等级："
              :label-width="formLabelWidth"
              prop="user_level_id"
            >
              <el-select
                v-model="formState.user_level_id"
                :popper-append-to-body="false"
              >
                <el-option
                  v-for="item in getLevelList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="10">
            <el-form-item label="入职时间：" :label-width="formLabelWidth">
              <el-date-picker
                type="date"
                v-model="formState.entry_time"
                format="YYYY/MM/DD"
                value-format="YYYY-MM-DD"
              />
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item
              label="手机："
              :label-width="formLabelWidth"
              prop="user_phone"
            >
              <el-input v-model="formState.user_phone" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item
          label="身份证："
          :label-width="formLabelWidth"
          prop="user_id_card"
        >
          <el-input v-model="formState.user_id_card" clearable />
        </el-form-item>
        <el-form-item label="性别：" :label-width="formLabelWidth">
          <el-select
            v-model="formState.user_gender"
            :popper-append-to-body="false"
          >
            <el-option
              v-for="item in sexList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="工资卡号：" :label-width="formLabelWidth">
          <el-input v-model="formState.user_bank_card" clearable />
        </el-form-item>

        <el-form-item label="居住地址：" :label-width="formLabelWidth">
          <el-input type="textarea" v-model="formState.user_add" />
        </el-form-item>

        <el-form-item label="用户生日：" :label-width="formLabelWidth">
          <el-date-picker
            type="date"
            v-model="formState.user_birthday"
            format="YYYY/MM/DD"
            value-format="YYYY-MM-DD"
          />
        </el-form-item>

        <el-form-item label="生日类型：" :label-width="formLabelWidth">
          <el-switch
            v-model="formState.user_birthday_type"
            class="ml-2"
            active-color="#660066"
            inactive-color="#13ce66"
            active-text="公历"
            inactive-text="农历"
          />
        </el-form-item>

        <el-form-item
          label="个人手机："
          :label-width="formLabelWidth"
          prop="user_self_phone"
        >
          <el-input v-model="formState.user_self_phone" clearable />
        </el-form-item>

        <el-form-item label="紧急联系人：" :label-width="formLabelWidth">
          <el-input v-model="formState.user_emergency_person" clearable />
        </el-form-item>

        <el-form-item
          label="紧急联系号："
          :label-width="formLabelWidth"
          prop="user_emergency_phone"
        >
          <el-input v-model="formState.user_emergency_phone" clearable />
        </el-form-item>

        <el-form-item label="状态：" :label-width="formLabelWidth">
          <el-switch
            v-model="formState.user_status"
            class="ml-2"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-text="开启"
            inactive-text="关闭"
          />
        </el-form-item>
        <el-form-item label="备注：" :label-width="formLabelWidth">
          <el-input type="textarea" v-model="formState.user_remark" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取消</el-button>
          <el-button type="primary" @click="add">{{ goTitle }}</el-button>
        </span>
      </template>
    </el-dialog>

    <div class="content">
      <el-table
        :data="dataSource"
        style="width: 100%"
        height="100%"
        stripe:true
      >
        <!-- <el-table-column type="index" width="80" label="序号" /> -->
        <el-table-column fixed prop="user_id" label="ID" width="100" sortable />
        <el-table-column prop="user_name_ch" label="中文名" width="120" fixed />
        <el-table-column prop="user_name_en" label="英文名" width="120" />
        <el-table-column prop="user_email" label="账号" width="200" />
        <el-table-column prop="user_group_id_v" label="所属部门" width="120" />
        <el-table-column prop="id_v" label="级别" width="120" />
        <el-table-column prop="user_phone" label="手机" width="120" />
        <el-table-column prop="user_gender" label="性别" width="120">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <span>{{ scope.row.user_gender == 1 ? "男" : "女" }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="entry_time"
          label="入职时间"
          width="200"
          format="YYYY/MM/DD"
          value-format="YYYY-MM-DD"
        />
        <el-table-column prop="user_status" label="状态" width="120">
          <template #default="scope">
            <span
              :style="{ color: scope.row.user_status == 1 ? 'green' : 'red' }"
            >
              {{ scope.row.user_status == 1 ? "开启" : "关闭" }}</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="user_add" label="地址" width="300" />
        <el-table-column fixed="right" label="操作" width="200">
          <template #default="scope">
            <el-button type="primary" @click="userGo(scope.row)">
              <el-icon> <edit /> </el-icon><span>编辑</span></el-button
            >
            <el-button type="danger" @click="del(scope.row.user_id)"
              ><el-icon> <delete /> </el-icon><span>删除</span></el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="demo-pagination-block">
        <el-pagination
          :currentPage="currentPage"
          :page-size="pageSize"
          :page-sizes="[20, 50, 100]"
          :background="true"
          layout="sizes, prev, pager, next, jumper,total"
          :total="pageTotal"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, toRefs, onMounted } from "vue";
import {
  userList,
  userAdd,
  userDel,
  getGroupList,
  getLevelList,
} from "@/network/user.js";
import { Delete, Edit, Plus } from "@element-plus/icons-vue";
import { ElMessage, ElMessageBox } from "element-plus";

export default {
  components: {
    Delete,
    Edit,
    Plus,
  },
  setup() {
    const currentPage = ref(1);
    const pageSize = ref(10);
    const pageTotal = ref(0);
    const sexList = reactive([
      { label: "男", value: 1 },
      { label: "女", value: 0 },
    ]);
    const state = reactive({
      dataSource: [],
      title: "添加用户",
      pswMsg: "请输入密码",
      goTitle: "添加",
      getLevelList: [],
      getGroupList: [],
      dialogFormVisible: false,
      formLabelWidth: "140px",
    });
    const rules = reactive({
      user_email: [
        {
          required: true,
          message: "请输入邮箱号码",
          trigger: "blur",
        },
      ],
      user_name_ch: [
        {
          required: true,
          message: "请输入中文名",
          trigger: "blur",
        },
      ],
      user_group_id: [
        {
          required: true,
          message: "请选择用户所属部门",
          trigger: "change",
        },
      ],
      user_level_id: [
        {
          required: true,
          message: "请选择用户所属业务层级",
          trigger: "change",
        },
      ],
      user_phone: [
        {
          required: true,
          message: "请输入分配员工的手机号码",
          trigger: "blur",
        },
        { min: 11, max: 15, message: "长度为11-15位手机号", trigger: "blur" },
      ],
      user_id_card: [
        {
          required: false,
          message: "请输入员工的身份证",
          trigger: "blur",
        },
        { min: 18, max: 18, message: "身份证长度为18位", trigger: "blur" },
      ],
      user_self_phone: [
        {
          required: false,
          message: "请输入分配员工的手机号码",
          trigger: "blur",
        },
        { min: 11, max: 15, message: "长度为11-15位手机号", trigger: "blur" },
      ],
      user_emergency_phone: [
        {
          required: false,
          message: "请输入分配员工的手机号码",
          trigger: "blur",
        },
        { min: 11, max: 15, message: "长度为11-15位手机号", trigger: "blur" },
      ],
    });
    const formState = reactive({
      user_id: 0,
      user_group_id: 1,
      user_name_ch: "",
      user_name_en: "",
      id: 1,
      user_email: "",
      user_psw: "",
      user_phone: "",
      user_id_card: "",
      user_gender: 1,
      user_bank_card: "",
      user_add: "",
      user_birthday: "",
      user_birthday_type: true,
      user_self_phone: "",
      user_emergency_person: "",
      user_emergency_phone: "",
      entry_time: "",
      user_status: true,
      user_remark: "",
    });
    const getList = () => {
      userList({
        currentPage: currentPage.value,
        pageSize: pageSize.value,
      }).then((res) => {
        if (res.code == 0) {
          state.dataSource = res.data.lists;
          pageTotal.value = res.data.pageTotal;
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    const handleSizeChange = (val) => {
      pageSize.value = val;
      getList();
    };
    const handleCurrentChange = (val) => {
      currentPage.value = val;
      getList();
    };
    onMounted(() => {
      getList();
    });
    //------------以上为列表数据

    const userGo = (row) => {
      state.dialogFormVisible = true;
      //返回业务等级列表
      getLevelList().then((res) => {
        if (res.code == 0) {
          state.getLevelList = res.data;
        } else {
          ElMessage.error(res.msg);
        }
      });
      //返回部门列表
      getGroupList().then((res) => {
        if (res.code == 0) {
          state.getGroupList = res.data;
        } else {
          ElMessage.error(res.msg);
        }
      });

      if (row) {
        Object.assign(formState, row);
        state.goTitle = "更新";
        state.title = "修改用户信息";
        state.pswMsg = "不修改密码，则留空";
        formState.user_psw = ""; //默认将密码置空
        formState.user_birthday_type = Boolean(row.user_birthday_type);
        formState.user_status = Boolean(row.user_status);
      } else {
        state.title = "添加用户";
        state.pswMsg = "请输入密码";
        formState.user_id = 0;
        formState.user_group_id = 1;
        formState.user_name_en = "";
        formState.user_name_ch = "";
        formState.id = 1;
        formState.user_email = "";
        formState.user_psw = "";
        formState.user_phone = "";
        formState.user_id_card = "";
        formState.user_gender = 1;
        formState.user_bank_card = "";
        formState.user_add = "";
        formState.user_birthday = "";
        formState.user_birthday_type = true;
        formState.user_self_phone = "";
        formState.user_emergency_person = "";
        formState.user_emergency_phone = "";
        formState.entry_time = "";
        formState.user_status = true;
        formState.user_remark = "";
      }
    };

    const add = () => {
      //无论新增还是修改，用户名都是必填;
      if (!formState.user_email) {
        ElMessage.info("请输入账号");
        return false;
      }
      if (formState.user_id == 0) {
        //添加用户时验证密码必填
        if (!formState.user_psw) {
          ElMessage.info("请输入密码");
          return false;
        }
        if (!formState.entry_time) {
          ElMessage.warning("请选择入职日期");
          return false;
        }
      }
      userAdd(formState).then((res) => {
        if (res.code == 0) {
          ElMessage.success(res.msg);
          state.dialogFormVisible = false;
          getList();
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    //------------以上为编辑和修改数据
    const del = (user_id) => {
      if (!user_id) {
        ElMessage("所选数据为空，请重新选择！");
      } else {
        ElMessageBox.confirm("确定要删除该条数据吗?", "警告", {
          confirmButtonText: "删除",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            userDel({ user_id: user_id }).then((res) => {
              if (res.code == 0) {
                ElMessage.success(res.msg);
                getList();
              } else {
                ElMessage.error(res.msg);
              }
            });
          })
          .catch(() => {
            ElMessage({
              type: "info",
              message: "删除取消",
            });
          });
      }
    };

    return {
      ...toRefs(state),
      rules,
      formState,
      userGo,
      add,
      del,
      sexList,
      currentPage,
      pageSize,
      pageTotal,
      handleSizeChange,
      handleCurrentChange,
    };
  },
};
</script>
<style scoped>
.el-select :deep .select-popper {
  z-index: 8888 !important;
  top: auto !important;
  left: auto !important;
}
.actionBtn {
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;
}
.content {
  height: calc(100% - 180px);
  position: relative;
}
.el-table {
  position: absolute;
}
.el-table__header {
  width: 100%;
}
.el-table__body {
  height: calc(100% - 40px);
}
.el-pagination {
  position: absolute;
  bottom: -56px;
  right: 20px;
}
</style>
