import { request } from "./request.js";
export function financeCollectionList(data = {}) {
  return request({
    url: "finance/financeCollectionList",
    method: "post",
    data,
  });
}
export function getCollectionListInfo(data = {}) {
  return request({
    url: "finance/getCollectionListInfo",
    method: "post",
    data,
  });
}
export function getCurrencyList(data = {}) {
  return request({
    url: "finance/getCurrencyList",
    method: "post",
    data,
  });
}
export function getAccountList(data = {}) {
  return request({
    url: "finance/getAccountList",
    method: "post",
    data,
  });
}
export function getPaymentTypeList(data = {}) {
  return request({
    url: "finance/getPaymentTypeList",
    method: "post",
    data,
  });
}
export function createListAdd(data = {}) {
  return request({
    url: "finance/createListAdd",
    method: "post",
    data,
  });
}
export function createListReview(data = {}) {
  return request({
    url: "finance/createListReview",
    method: "post",
    data,
  });
}
export function getCollectionDetailList(data = {}) {
  return request({
    url: "finance/getCollectionDetailList",
    method: "post",
    data,
  });
}
export function addAmountRelative(data = {}) {
  return request({
    url: "finance/addAmountRelative",
    method: "post",
    data,
  });
}
export function financeCollectionWait(data = {}) {
  return request({
    url: "finance/financeCollectionWait",
    method: "post",
    data,
  });
}
export function financePaymentList(data = {}) {
  return request({
    url: "finance/financePaymentList",
    method: "post",
    data,
  });
}
export function confirmPaymentList(data = {}) {
  return request({
    url: "finance/confirmPaymentList",
    method: "post",
    data,
  });
}
export function createPayment(data = {}) {
  return request({
    url: "finance/createPayment",
    method: "post",
    data,
  });
}
export function addPayment(data = {}) {
  return request({
    url: "finance/addPayment",
    method: "post",
    data,
  });
}
export function getPaymentDetailList(data = {}) {
  return request({
    url: "finance/getPaymentDetailList",
    method: "post",
    data,
  });
}
export function addPaymentDetails(data = {}) {
  return request({
    url: "finance/addPaymentDetails",
    method: "post",
    data,
  });
}
export function delPaymentDetails(data = {}) {
  return request({
    url: "finance/delPaymentDetails",
    method: "post",
    data,
  });
}
export function getUserList(data = {}) {
  return request({
    url: "finance/getUserList",
    method: "post",
    data,
  });
}
export function financePaymentWait(data = {}) {
  return request({
    url: "finance/financePaymentWait",
    method: "post",
    data,
  });
}
export function getFinanceList(data = {}) {
  return request({
    url: "finance/getFinanceList",
    method: "post",
    data,
  });
}
export function updateChargePerson(data = {}) {
  return request({
    url: "finance/updateChargePerson",
    method: "post",
    data,
  });
}
export function delPaymentList(data = {}) {
  return request({
    url: "finance/delPaymentList",
    method: "post",
    data,
  });
}
export function paymentReview(data = {}) {
  return request({
    url: "finance/paymentReview",
    method: "post",
    data,
  });
}
export function confirmPayment(data = {}) {
  return request({
    url: "finance/confirmPayment",
    method: "post",
    data,
  });
}
export function confirmCollectionList(data = {}) {
  return request({
    url: "finance/confirmCollectionList",
    method: "post",
    data,
  });
}
export function orderProfitList(data = {}) {
  return request({
    url: "finance/orderProfitList",
    method: "post",
    data,
  });
}
export function updateApproval(data = {}) {
  return request({
    url: "finance/updateApproval",
    method: "post",
    data,
  });
}
export function salaryList(data = {}) {
  return request({
    url: "finance/salaryList",
    method: "post",
    data,
  });
}
export function updateSalaryList(data = {}) {
  return request({
    url: "finance/updateSalaryList",
    method: "post",
    data,
  });
}
/**报表类 开始 */

export function getStatements(data = {}) {
  return request({
    url: "finance/getStatements",
    method: "post",
    data,
  });
}
export function getHomeReport(data = {}) {
  return request({
    url: "finance/getHomeReport",
    method: "post",
    data,
  });
}
export function addTaxes(data = {}) {
  return request({
    url: "finance/addTaxes",
    method: "post",
    data,
  });
}

/**报表类 结束 */
export function calTest(data = {}) {
  return request({
    url: "finance/calTest",
    method: "post",
    data,
  });
}
