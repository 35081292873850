import { defineStore } from "pinia";
import { homeMenu } from "@/network/admin.js";
const useTabViews = defineStore("tabViews", {
  state: () => ({
    userName: "",
    activeTab: "",
    menuList: [],
    tabList: [],
  }),
  getters: {
    getAllSonMenu(state) {
      const tempAry = [{ url: "/home", label: "首页" }];
      state.menuList.forEach((item) => {
        if (item.son && item.son.length) {
          tempAry.push(...item.son);
        }
      });
      return tempAry;
    },
  },
  actions: {
    async getMenuList() {
      const res = await homeMenu();
      //可以将登陆用户的token传给接口
      this.menuList = res.data.lists;
      this.userName = res.data.user_name_ch;
    },

    handleRouteTabs(currentPath) {
      const menuItem = this.getAllSonMenu.filter((item) => {
        return item.url == currentPath;
      });
      if (menuItem.length) {
        const { label: title, url: name } = menuItem[0];
        // 如果点击重复的菜单应该过滤掉
        const exsitItem = this.tabList.filter((item) => {
          return item.name == name;
        });
        if (!exsitItem.length) {
          this.tabList.push({
            title,
            name,
          });
        }
        this.activeTab = name;
      }
    },
  },
});
export default useTabViews;
