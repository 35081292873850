import { request } from "./request.js";

export function productList(data = {}) {
  return request({
    url: "product/productList",
    method: "post",
    data,
  });
}
export function productAdd(data = {}) {
  return request({
    url: "product/productAdd",
    method: "post",
    data,
  });
}
export function productDel(data = {}) {
  return request({
    url: "product/productDel",
    method: "post",
    data,
  });
}
export function productRecycle(data = {}) {
  return request({
    url: "product/productRecycle",
    method: "post",
    data,
  });
}
export function productInfo(data = {}) {
  return request({
    url: "product/productInfo",
    method: "post",
    data,
  });
}
export function productGroupList(data = {}) {
  return request({
    url: "product/productGroupList",
    method: "post",
    data,
  });
}
export function productGroupAdd(data = {}) {
  return request({
    url: "product/productGroupAdd",
    method: "post",
    data,
  });
}
export function productGroupDel(data = {}) {
  return request({
    url: "product/productGroupDel",
    method: "post",
    data,
  });
}
// export function getProductGroupList(data = {}) {
//   return request({
//     url: "product/getProductGroupList",
//     method: "post",
//     data,
//   });
// }
export function getFatherProductGroupList(data = {}) {
  return request({
    url: "product/getFatherProductGroupList",
    method: "post",
    data,
  });
}
export function getProductGroupListForAddProduct(data = {}) {
  return request({
    url: "product/getProductGroupListForAddProduct",
    method: "post",
    data,
  });
}
export function brandList(data = {}) {
  return request({
    url: "product/brandList",
    method: "post",
    data,
  });
}
export function brandAdd(data = {}) {
  return request({
    url: "product/brandAdd",
    method: "post",
    data,
  });
}
export function brandDel(data = {}) {
  return request({
    url: "product/brandDel",
    method: "post",
    data,
  });
}
export function getBrandList(data = {}) {
  return request({
    url: "product/getBrandList",
    method: "post",
    data,
  });
}
export function propertyList(data = {}) {
  return request({
    url: "product/propertyList",
    method: "post",
    data,
  });
}
export function propertyAdd(data = {}) {
  return request({
    url: "product/propertyAdd",
    method: "post",
    data,
  });
}
export function propertyDel(data = {}) {
  return request({
    url: "product/propertyDel",
    method: "post",
    data,
  });
}
export function getFatherPropertyList(data = {}) {
  return request({
    url: "product/getFatherPropertyList",
    method: "post",
    data,
  });
}
export function getProductPropertyListForAddProduct(data = {}) {
  return request({
    url: "product/getProductPropertyListForAddProduct",
    method: "post",
    data,
  });
}
export function levelList(data = {}) {
  return request({
    url: "product/levelList",
    method: "post",
    data,
  });
}
export function levelAdd(data = {}) {
  return request({
    url: "product/levelAdd",
    method: "post",
    data,
  });
}
export function levelDel(data = {}) {
  return request({
    url: "product/levelDel",
    method: "post",
    data,
  });
}
export function getFatherLevelList(data = {}) {
  return request({
    url: "product/getFatherLevelList",
    method: "post",
    data,
  });
}
export function getProductLevelListForAddProduct(data = {}) {
  return request({
    url: "product/getProductLevelListForAddProduct",
    method: "post",
    data,
  });
}
/**辅助函数：一次性生成产品item码 -- 只能用一次 */
export function creatProductRandNo(data = {}) {
  return request({
    url: "product/creatProductRandNo",
    method: "post",
    data,
  });
}
