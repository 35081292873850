import { request } from "./request.js";

export function supplierList(data = {}) {
  return request({
    url: "supplier/supplierList",
    method: "post",
    data,
  });
}
export function supplierAdd(data = {}) {
  return request({
    url: "supplier/supplierAdd",
    method: "post",
    data,
  });
}
export function supplierDel(data = {}) {
  return request({
    url: "supplier/supplierDel",
    method: "post",
    data,
  });
}
export function supplierRecycle(data = {}) {
  return request({
    url: "supplier/supplierRecycle",
    method: "post",
    data,
  });
}
export function supplierInfo(data = {}) {
  return request({
    url: "supplier/supplierInfo",
    method: "post",
    data,
  });
}
export function supplierGroupList(data = {}) {
  return request({
    url: "supplier/supplierGroupList",
    method: "post",
    data,
  });
}
export function getSupplierGroupList(data = {}) {
  return request({
    url: "supplier/getSupplierGroupList",
    method: "post",
    data,
  });
}
export function supplierGroupAdd(data = {}) {
  return request({
    url: "supplier/supplierGroupAdd",
    method: "post",
    data,
  });
}
export function supplierGroupDel(data = {}) {
  return request({
    url: "supplier/supplierGroupDel",
    method: "post",
    data,
  });
}
