<template>
  <div class="layout-content">
    <div class="list-layout">
      <slot name="before-header"></slot>
      <header class="list-header">
        <slot name="header-back"></slot>
        <div class="header-search">
          <slot name="header-search"></slot>
        </div>
        <div class="header-button">
          <slot name="header-button"></slot>
        </div>
      </header>
      <main class="list-main" :ref="`listMain`">
        <slot name="list" :tableHeight="tableHeight"></slot>
      </main>
      <footer class="list-footer">
        <div><slot name="footer-buttons"></slot></div>
        <slot name="footer"></slot>
      </footer>
    </div>
  </div>
</template>
<script>
export default {
  name: "isdListPaper",
  data() {
    return {
      tableHeight: 0,
    };
  },
  activated() {
    this.$nextTick(() => {
      //   this.resetTableHeight();
    });
  },
  mounted() {
    this.$nextTick(() => {
      //   this.resetTableHeight();
    });
    // window.addEventListener('resize', this.resize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.resize);
  },
  methods: {
    resize() {
      this.resetListHeight();
      this.$nextTick(() => {
        this.resetTableHeight();
      });
    },
    resetListHeight() {
      /* 将table 设置为一个较小的值，让 listMain 高度重新自适应 */
      this.tableHeight = 30;
    },
    resetTableHeight() {
      this.tableHeight = this.$refs.listMain
        ? window.getComputedStyle(this.$refs.listMain).height
        : 0;
    },
  },
};
</script>
<style lang="scss">
@import "./index.scss";
.list-layout-wrapper {
  display: flex;
}
.layout-content {
  height: 100%;
  .list-main {
    display: flex;
    flex-direction: column;
    > .table-box {
      flex: auto;
    }
    > .edit-table-box {
      flex: auto;
      display: flex;
      flex-direction: column;
      > .edit-table-box-table {
        flex: auto;
      }
    }
  }
}
</style>
