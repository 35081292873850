<template>
  <div class="order-detail-contain">
    <el-tabs v-model="activeName">
      <el-tab-pane label="待计划" name="first">
        <shippingWait></shippingWait>
      </el-tab-pane>
      <el-tab-pane label="待发货" name="second">
        <shippingPlan></shippingPlan>
      </el-tab-pane>
      <el-tab-pane label="待签收" name="three">
        <shippingList></shippingList>
      </el-tab-pane>
      <el-tab-pane label="已完成" name="four">
        <shippingSuccess></shippingSuccess>
      </el-tab-pane>
      <el-tab-pane label="回收站" name="five">
        <shippingRecycle></shippingRecycle>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { ref } from "vue";
import shippingWait from "./Wait/index.vue";
import shippingPlan from "./Plan/index.vue";
import shippingList from "./List/index.vue";
import shippingSuccess from "./Success/index.vue";
import shippingRecycle from "./Recycle/index.vue";
export default {
  name: "shipping",
  components: {
    shippingWait,
    shippingPlan,
    shippingList,
    shippingSuccess,
    shippingRecycle,
  },
  setup() {
    const activeName = ref("first");
    return {
      activeName,
    };
  },
};
</script>
<style scoped lang="scss">
.order-detail-contain {
  height: 100%;
  padding: 20px 0;
  .el-tabs {
    height: 100%;
    :deep .el-tabs__content {
      height: calc(100% - 100px);
    }
    .el-tab-pane {
      height: 100%;
    }
  }
}
</style>
