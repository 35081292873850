import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from "vue-router";
import { storeToRefs } from "pinia";
import useTabViews from "@/store/modules/tabViews.js";
import Index from "../views/Index.vue";
// import home from "../views/Home.vue";
import home from "../views/Home/index.vue";
import Login from "../views/Login.vue";
import Test from "../views/Test.vue";
import userList from "../views/User/userList.vue";
import userInfo from "../views/User/userInfo.vue";
import groupList from "../views/User/groupList.vue";
import menuList from "../views/Index/menuList.vue";
import compInfo from "../views/Basic/compInfo/index.vue";
import compAddList from "../views/Basic/compInfo/add/index.vue";
import compAccountList from "../views/Basic/compInfo/account/index.vue";
import basicList from "../views/Basic/basicList/index.vue";
import nationList from "../views/Basic/nationList.vue";
import colorList from "../views/Basic/colorList.vue";
import continentList from "../views/Basic/continentList.vue";
import currencyList from "../views/Basic/currencyList.vue";
import methodList from "../views/Basic/methodList.vue";
import socialList from "../views/Basic/socialList.vue";
import customerStageList from "../views/Basic/customerStageList.vue";
import customerTagsList from "../views/Basic/customerTagsList.vue";
import customerLevelList from "../views/Basic/customerLevelList.vue";
import customerGroupList from "../views/Basic/customerGroupList.vue";
import customerSourceList from "../views/Basic/customerSourceList.vue";
import paymentTypeList from "../views/Basic/paymentTypeList.vue";
import financeStatusList from "../views/Basic/financeStatusList.vue";
import shippingStatusList from "../views/Basic/shippingStatusList.vue";
import orderStatusList from "../views/Basic/orderStatus/index.vue";
import shippingComp from "../views/Basic/shippingComp/index.vue";
import dicList from "../views/Basic/dicList/index.vue";
import customerList from "../views/Customer/index.vue";
import openCustomerList from "../views/Customer/openCustomer/index.vue";
import customerCheck from "../views/Customer/customerCheck/index.vue";
import getCustomerInfo from "../views/Customer/customerInfo/index.vue";
import getOpenCustomerInfo from "../views/Customer/openCustomer/openCustomerInfo/index.vue";
import customerSet from "../views/Customer/customerSet/index.vue";
import supplierList from "../views/Purchase/supplier/index.vue";
import productList from "../views/Product/productList.vue";
import productGroup from "../views/Product/productGroup.vue";
import brandList from "../views/Product/brandList.vue";
import propertyList from "../views/Product/propertyList.vue";
import productRecycle from "../views/Product/productRecycle.vue";
import productLevel from "../views/Product/productLevel.vue";
import orderList from "../views/Order/orderList/index.vue";
import imeiSearch from "../views/Order/imeiSearch/index.vue";
import invoice from "../views/Invoice/index.vue";
import createInvoice from "../views/Invoice/create/index.vue";
import addOrder from "../views/Order/addOrder/index.vue";
import financeCollection from "../views/Finance/financeCollection/index.vue";
import createFinanceCollection from "../views/Finance/financeCollection/create/index.vue";
import financePayment from "../views/Finance/financePayment/index.vue";
import createFinancePayment from "../views/Finance/financePayment/Create/index.vue";
import orderProfit from "../views/Finance/orderProfit/index.vue";
import commissionManage from "../views/Finance/commissionManage/index.vue";
import salaryList from "../views/Finance/Salary/index.vue";
import financialStatements from "../views/Finance/financialStatements/index.vue";
import shipping from "../views/Shipping/index.vue";
import createShippingPlan from "../views/Shipping/create/index.vue";
import delivery from "../views/Delivery/index.vue";
import createDelivery from "../views/Delivery/Create/index.vue";
import purchase from "../views/Purchase/purchase/index.vue";
import createPurchase from "../views/Purchase/purchase/Create/index.vue";
import inspectPurchaseOrder from "../views/Purchase/purchase/Inspect/index.vue";
import printPurchaseOrder from "../views/ShowDetails/printPurchaseOrder/index.vue";
import printShippingOrder from "../views/ShowDetails/printShippingOrder/index.vue";
import printOrderInvoice from "../views/ShowDetails/printOrderInvoice/index.vue";
import printOrder from "../views/ShowDetails/printOrder/index.vue";
import showPurchaseDetails from "../views/ShowDetails/showPurchaseDetails/index.vue";
import showShippingDetails from "../views/ShowDetails/showShippingDetails/index.vue";
import showOrderIMEI from "../views/ShowDetails/showOrderIMEI/index.vue";
import showCollectionInfo from "../views/ShowDetails/showCollectionInfo/index.vue";
import showDeliveredInfo from "../views/ShowDetails/showDeliveredInfo/index.vue";
import showPaymentInfo from "../views/ShowDetails/showPaymentInfo/index.vue";
import showOrderProfitDetails from "../views/ShowDetails/showOrderProfitDetails/index.vue";
import showSalaryDetails from "../views/ShowDetails/showSalaryDetails/index.vue";
import deliveryOrder from "../views/Delivery/Create/index.vue";
import scheduleList from "../views/Schedule/index.vue";
import scheduleLevel from "../views/Schedule/Level/index.vue";
import monthSet from "../views/MonthSet/Set/index.vue";
import monthOASet from "../views/MonthSet/OA/index.vue";
import monthGoalsSet from "../views/MonthSet/Goals/index.vue";
import dayScheduleList from "../views/daySchedule/index.vue";

const routes = [
  {
    path: "/",
    name: "Index",
    component: Index,
    meta: {
      title: "首页",
    },
    children: [
      //子路由中没有/
      {
        path: "home",
        name: "home",
        component: home,
        // component: () => import("../views/Home/index.vue"),
        meta: {
          title: "首页",
        },
      },
      {
        path: "userList",
        name: "userList",
        component: userList,
      },
      {
        path: "menuList",
        name: "menuList",
        component: menuList,
      },
      {
        path: "userInfo",
        name: "userInfo",
        component: userInfo,
      },
      {
        path: "groupList",
        name: "groupList",
        component: groupList,
      },
      {
        path: "customerList",
        name: "customerList",
        component: customerList,
      },
      {
        path: "openCustomerList",
        name: "openCustomerList",
        component: openCustomerList,
      },
      {
        path: "customerCheck",
        name: "customerCheck",
        component: customerCheck,
      },
      {
        path: "getCustomerInfo",
        name: "getCustomerInfo",
        component: getCustomerInfo,
      },
      {
        path: "getOpenCustomerInfo",
        name: "getOpenCustomerInfo",
        component: getOpenCustomerInfo,
      },
      {
        path: "customerSet",
        name: "customerSet",
        component: customerSet,
      },
      {
        path: "basicList",
        name: "basicList",
        component: basicList,
      },
      {
        path: "compInfo",
        name: "compInfo",
        component: compInfo,
      },
      {
        path: "compAddList",
        name: "compAddList",
        component: compAddList,
      },
      {
        path: "compAccountList",
        name: "compAccountList",
        component: compAccountList,
      },
      {
        path: "nationList",
        name: "nationList",
        component: nationList,
      },
      {
        path: "colorList",
        name: "colorList",
        component: colorList,
      },
      {
        path: "continentList",
        name: "continentList",
        component: continentList,
      },
      {
        path: "currencyList",
        name: "currencyList",
        component: currencyList,
      },
      {
        path: "methodList",
        name: "methodList",
        component: methodList,
      },
      {
        path: "socialList",
        name: "socialList",
        component: socialList,
      },
      {
        path: "customerGroupList",
        name: "customerGroupList",
        component: customerGroupList,
      },
      {
        path: "customerLevelList",
        name: "customerLevelList",
        component: customerLevelList,
      },
      {
        path: "customerStageList",
        name: "customerStageList",
        component: customerStageList,
      },
      {
        path: "customerTagsList",
        name: "customerTagsList",
        component: customerTagsList,
      },
      {
        path: "customerSourceList",
        name: "customerSourceList",
        component: customerSourceList,
      },
      {
        path: "paymentTypeList",
        name: "paymentTypeList",
        component: paymentTypeList,
      },
      {
        path: "financeStatusList",
        name: "financeStatusList",
        component: financeStatusList,
      },
      // {
      //   path: "customerRecycle",
      //   name: "customerRecycle",
      //   component: customerRecycle,
      // },
      {
        path: "supplierList",
        name: "supplierList",
        component: supplierList,
      },
      {
        path: "productList",
        name: "productList",
        component: productList,
      },
      {
        path: "productGroup",
        name: "productGroup",
        component: productGroup,
      },
      {
        path: "brandList",
        name: "brandList",
        component: brandList,
      },
      {
        path: "propertyList",
        name: "propertyList",
        component: propertyList,
      },
      {
        path: "productRecycle",
        name: "productRecycle",
        component: productRecycle,
      },
      {
        path: "productLevel",
        name: "productLevel",
        component: productLevel,
      },
      {
        path: "orderList",
        name: "orderList",
        component: orderList,
      },
      {
        path: "imeiSearch",
        name: "imeiSearch",
        component: imeiSearch,
      },
      {
        path: "addOrder",
        name: "addOrder",
        component: addOrder,
      },
      {
        path: "invoice",
        name: "invoice",
        component: invoice,
      },
      {
        path: "createInvoice",
        name: "createInvoice",
        component: createInvoice,
      },
      {
        path: "financeCollection",
        name: "financeCollection",
        component: financeCollection,
      },
      {
        path: "createFinanceCollection",
        name: "createFinanceCollection",
        component: createFinanceCollection,
      },
      {
        path: "financePayment",
        name: "financePayment",
        component: financePayment,
      },
      {
        path: "createFinancePayment",
        name: "createFinancePayment",
        component: createFinancePayment,
      },
      {
        path: "orderProfit",
        name: "orderProfit",
        component: orderProfit,
      },
      {
        path: "commissionManage",
        name: "commissionManage",
        component: commissionManage,
      },
      {
        path: "salaryList",
        name: "salaryList",
        component: salaryList,
      },
      {
        path: "financialStatements",
        name: "financialStatements",
        component: financialStatements,
      },
      {
        path: "shipping",
        name: "shipping",
        component: shipping,
      },
      {
        path: "shippingStatusList",
        name: "shippingStatusList",
        component: shippingStatusList,
      },
      {
        path: "orderStatusList",
        name: "orderStatusList",
        component: orderStatusList,
      },
      {
        path: "shippingComp",
        name: "shippingComp",
        component: shippingComp,
      },
      {
        path: "dicList",
        name: "dicList",
        component: dicList,
      },
      {
        path: "createShippingPlan",
        name: "createShippingPlan",
        component: createShippingPlan,
      },
      {
        path: "deliveryOrder",
        name: "deliveryOrder",
        component: deliveryOrder,
      },
      {
        path: "delivery",
        name: "delivery",
        component: delivery,
      },
      {
        path: "createDelivery",
        name: "createDelivery",
        component: createDelivery,
      },
      {
        path: "purchase",
        name: "purchase",
        component: purchase,
      },
      {
        path: "createPurchase",
        name: "createPurchase",
        component: createPurchase,
      },
      {
        path: "inspectPurchaseOrder",
        name: "inspectPurchaseOrder",
        component: inspectPurchaseOrder,
      },
      {
        path: "scheduleList",
        name: "scheduleList",
        component: scheduleList,
      },
      {
        path: "scheduleLevel",
        name: "scheduleLevel",
        component: scheduleLevel,
      },
      {
        path: "monthSet",
        name: "monthSet",
        component: monthSet,
      },
      {
        path: "monthOASet",
        name: "monthOASet",
        component: monthOASet,
      },
      {
        path: "monthGoalsSet",
        name: "monthGoalsSet",
        component: monthGoalsSet,
      },
      {
        path: "dayScheduleList",
        name: "dayScheduleList",
        component: dayScheduleList,
      },
    ],
  },
  {
    path: "/Login",
    name: "Login",
    component: Login,
  },
  {
    path: "/Test",
    name: "Test",
    component: Test,
  },
  {
    path: "/printPurchaseOrder",
    name: "printPurchaseOrder",
    component: printPurchaseOrder,
  },
  {
    path: "/printShippingOrder",
    name: "printShippingOrder",
    component: printShippingOrder,
  },
  {
    path: "/printOrderInvoice",
    name: "printOrderInvoice",
    component: printOrderInvoice,
  },
  {
    path: "/printOrder",
    name: "printOrder",
    component: printOrder,
  },
  {
    path: "/showPurchaseDetails",
    name: "showPurchaseDetails",
    component: showPurchaseDetails,
  },
  {
    path: "/showShippingDetails",
    name: "showShippingDetails",
    component: showShippingDetails,
  },
  {
    path: "/showOrderIMEI",
    name: "showOrderIMEI",
    component: showOrderIMEI,
  },
  {
    path: "/showDeliveredInfo",
    name: "showDeliveredInfo",
    component: showDeliveredInfo,
  },
  {
    path: "/showPaymentInfo",
    name: "showPaymentInfo",
    component: showPaymentInfo,
  },
  {
    path: "/showOrderProfitDetails",
    name: "showOrderProfitDetails",
    component: showOrderProfitDetails,
  },
  {
    path: "/showSalaryDetails",
    name: "showSalaryDetails",
    component: showSalaryDetails,
  },
  {
    path: "/showCollectionInfo",
    name: "showCollectionInfo",
    component: showCollectionInfo,
  },
];
const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach(async (to, from, next) => {
  const { fullPath } = to;
  const { menuList } = storeToRefs(useTabViews());
  const token = window.localStorage.getItem("token");
  try {
    if (!menuList.value.length && token && fullPath != "/Login") {
      await useTabViews().getMenuList();
    }
  } catch {
    next("/Login");
    return;
  }

  useTabViews().handleRouteTabs(fullPath);
  next();
});
export default router;
