export default {
  selection: true,
  columns: [
    // 表格列的配置
    {
      label: "生成日期",
      prop: "create_time",
    },
    {
      label: "相关账单",
      prop: "payment_no",
    },
    {
      label: "账单来源",
      prop: "fee_type",
      type: "select",
      options: { 7: "采购订单", 8: "物流订单" },
    },
    {
      label: "供应商名字",
      prop: "spr_comp_name",
    },
    {
      label: "应付总额",
      prop: "payable_amount",
    },
    {
      label: "已付总额",
      prop: "paid_amount",
    },
    {
      label: "待付余额",
      prop: "wait_amount",
    },
    {
      label: "创建人",
      prop: "user_name_ch",
    },
    {
      label: "状态",
      prop: "status",
      type: "select",
      options: {
        0: "待做单",
        1: "待审核",
      },
    },
    {
      label: "操作",
      type: "operate_btn",
      prop: "",
      width: "120px",
    },
  ],
};
