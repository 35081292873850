export default {
  selection: true,
  searchFields: [
    // 搜索组件的配置项
    {
      name: "设置备注",
      value: "remark",
      type: "input",
      isFixed: true,
      span: 4,
    },
  ],
  columns: [
    // 表格列的配置
    {
      label: "归属月份",
      prop: "month",
      fixed: true,
    },
    {
      label: "货币",
      prop: "currency_name_ch",
    },
    {
      label: "业务汇率",
      prop: "rate_saler",
    },
    {
      label: "公司汇率",
      prop: "rate_comp",
    },
    {
      label: "创建时间",
      prop: "create_time",
    },
    {
      label: "状态",
      prop: "status",
      type: "select",
      options: { 1: "有效", 0: "失效" },
    },
    {
      label: "备注",
      prop: "remark",
    },
    {
      label: "操作",
      type: "operate_btn",
      prop: "",
      width: "280px",
    },
  ],
  forms: [
    {
      label: "归属月份",
      prop: "month_id",
      type: "select",
      placeholder: "- 请选择月份 -",
      span: 11,
      rule: [
        {
          required: true,
          message: "- 请选择月份 -",
          trigger: "blur",
        },
      ],
    },
    {
      label: "货币",
      prop: "currency_id",
      type: "select",
      span: 11,
      placeholder: "请选择需要设置的货币",
      rule: [
        {
          required: true,
          message: "请选择需要设置的货币",
          trigger: "blur",
        },
      ],
    },
    {
      label: "业务汇率",
      prop: "rate_saler",
      type: "input",
      span: 11,
      placeholder: "请输入当前货币汇率（业务员）",
      rule: [
        {
          required: true,
          message: "请输入当前货币汇率（业务员）",
          trigger: "blur",
        },
        {
          pattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
          message: "请输入非负数",
        },
      ],
    },
    {
      label: "公司汇率",
      prop: "rate_comp",
      type: "input",
      span: 11,
      placeholder: "请输入当前货币汇率（公司）",
      rule: [
        {
          required: true,
          message: "请输入当前货币汇率（公司）",
          trigger: "blur",
        },
        {
          pattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
          message: "请输入非负数",
        },
      ],
    },
    {
      label: "状态:",
      prop: "status",
      type: "switch",
      span: 11,
      modelValue: 1,
      activeText: "有效",
      inactiveText: "无效",
    },
    {
      label: "备注",
      prop: "remark",
      type: "input",
      span: 22,
      inputType: "textarea",
    },
  ],
};
