<template>
  <div style="height: 100%">
    <div style="text-align: center; margin: 20px; font-size: 30px">
      供应商分类
    </div>
    <el-row :gutter="20">
      <el-col :span="4" :offset="22" style="margin-bottom: 20px">
        <el-button type="primary" @click="addGroupConfig()"
          ><span>新增</span></el-button
        >
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <isdTable
          :loading="tableLoading"
          :rows="totalData"
          :height="600"
          :columns="config.columns"
          :selection="config.selection"
          :actions="config.actions"
          @action-click="actionClick"
          style="font-size: 12px"
        >
        </isdTable>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <isdPagination
          @size-change="sizeChange"
          :current-page="currentPage"
          @current-change="pageChange"
          layout="total,sizes,prev,pager,next,jumper"
          :page-size="pageSize"
          :page-sizes="[20, 50, 100]"
          :total="pageTotal"
        ></isdPagination>
      </el-col>
    </el-row>
    <!-- 新增/编辑弹窗 -->
    <isdFormDialog
      ref="formDialogIns"
      :config="config.forms"
      :loading="formDialog.loading"
      :label-width="formDialog.labelWidth"
      :row="formDialog.row"
      v-model="formDialog.show"
      :col="formDialog.col"
      :title="formDialog.title"
      :width="formDialog.width"
      @close-dialog="closeFormDialog"
    ></isdFormDialog>
  </div>
</template>
<script>
import { ref, reactive, onMounted } from "vue";
import {
  supplierGroupList,
  supplierGroupAdd,
  supplierGroupDel,
} from "@/network/supplier.js";
import { getUserList } from "@/network/user.js";
//import { useRoute } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import isdFormDialog from "@/views/components/isdFormDialog/Index.vue";
import isdTable from "@/views/components/isdTable/index.vue";
import configData from "./config";
import { ListClass } from "@/composition/ListClass.js";
import isdPagination from "@/views/components/isdPage/index.vue";
export default {
  components: {
    isdFormDialog,
    isdTable,
    isdPagination,
  },
  setup() {
    const formDialogIns = ref(null);
    const config = reactive(configData);
    const userList = reactive({
      val: [],
    });
    // 新建/修改弹窗对象
    const formDialog = reactive({
      labelWidth: "9em", // 弹窗label宽度
      loading: false, // 表单加载中显示
      row: {}, // 数据项对象
      show: false, // 显示弹窗
      col: 1, // 弹窗内列数
      title: "新建", // 弹窗标题
      width: "750px", // 弹窗宽度
    });
    class TableList extends ListClass {
      beforeGetList(condition) {
        condition.symbolType = "=";
        condition.listType = 1;
        return condition;
      }
    }
    const {
      currentPage,
      pageSize,
      pageTotal,
      tableLoading,
      totalData,
      getList,
      searchList,
      pageChange,
      sizeChange,
      handleSelectedRow,
    } = new TableList({
      Api: {
        listApi: supplierGroupList,
      },
    });
    onMounted(() => {
      getUserList().then((res) => {
        if (res.code == 0) {
          userList.val = res.data;
          const ary = userList.val.map((item) => {
            return {
              label: item.user_name_ch,
              value: item.user_id,
            };
          });
          config.forms[4].options = ary;
        } else {
          ElMessage.error(res.msg);
        }
      });
      getList();
    });
    const editGroup = (row) => {
      formDialog.show = true;
      formDialog.title = "编辑";
      row.spr_group_status = "1";
      formDialog.row = row;
    };
    // 编辑删除事件
    const actionClick = (event, row) => {
      switch (event) {
        case "edit":
          editGroup(row);
          break;
        case "del":
          deleteGroup(row);
          break;
      }
    };
    const closeAction = () => {
      formDialog.show = false;
      formDialog.row = {};
      formDialog.loading = false;
    };
    // 删除
    const deleteGroup = (row) => {
      ElMessageBox.confirm("确定要删除该条数据吗?", "警告", {
        confirmButtonText: "删除",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          supplierGroupDel({
            spr_group_id: row.spr_group_id,
            del_type: 0,
          }).then((res) => {
            if (res.code == 0) {
              ElMessage.success(res.msg);
              getList();
            } else {
              ElMessage.error(res.msg);
            }
          });
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "删除取消",
          });
        });
    };
    // 新增弹窗关闭
    const closeFormDialog = (params) => {
      if (!params) {
        closeAction();
        return;
      }
      formDialog.loading = true;
      // 编辑或更新
      supplierGroupAdd(params).then((res) => {
        if (res.code == 0) {
          formDialog.loading = false;
          getList();
          ElMessage.success(res.msg);
          closeAction();
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    const addGroupConfig = () => {
      // 新增行
      formDialog.show = true;
      formDialog.title = "新增供应商分组";
      formDialog.row = {
        spr_group_id: "",
        spr_group_status: "1",
      };
    };
    // const removeProductConfig = (item) => {
    //   // 删除行
    // };

    return {
      addGroupConfig,
      //removeProductConfig,
      closeFormDialog,
      formDialog,
      config,
      actionClick,
      currentPage,
      pageSize,
      pageTotal,
      tableLoading,
      totalData,
      getList,
      searchList,
      pageChange,
      sizeChange,
      handleSelectedRow,
      formDialogIns,
    };
  },
};
</script>
<style scoped>
.div_title {
  margin: 20px 0;
  padding-left: 10px;
  border-left: 10px solid grey;
  font-size: 20px;
}
</style>
