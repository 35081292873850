<template>
  <div>
    <el-card
      v-for="(item, index) in contactPersonList"
      class="box-card"
      :key="index"
    >
      <template #header>
        <div class="card-header">
          <span>{{ `联系人${index + 1}` }}</span>
          <el-button v-if="index == 0" class="btn-add" @click="addContactPerson"
            ><el-icon> <plus /> </el-icon
          ></el-button>
        </div>
      </template>
      <el-row>
        <el-form :model="item">
          <el-col :span="24">
            <el-form-item label="昵称：" prop="name_en"
              >{{ item.name_en }}
            </el-form-item>
            <el-form-item label="性别：" prop="gender">
              {{ item.gender == 1 ? "男" : "女" }}</el-form-item
            >
            <el-form-item label="国家/地区：" prop="notion_id_v">
              {{ item.nation_id_v }}</el-form-item
            >
            <el-form-item label="注册时间：" prop="login_time">
              {{ item.login_time }}</el-form-item
            >
            <el-form-item label="邮箱：" prop="email" v-if="item.email"
              >{{ item.email }}
            </el-form-item>
            <el-form-item v-for="(item, index) in item.phone" :key="index">
              <el-col :span="24" :model="item">
                <el-form-item label="电话：" prop="phone"
                  >{{ item.phone }}
                </el-form-item>
              </el-col>
            </el-form-item>
            <el-form-item v-for="(item, index) in item.social" :key="index">
              <el-col :span="24" :model="item">
                <el-form-item prop="social">
                  <span slot="label">
                    <i class="el-icon-edit"></i>
                    {{ item.social_name_ch }}：
                  </span>
                  {{ item.account }}
                </el-form-item>
              </el-col>
            </el-form-item>
            <el-form-item label="备注：" prop="remark" v-if="item.remark"
              >{{ item.remark }}
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
    </el-card>
  </div>
</template>
<script>
import { customerContactPersonList } from "@/network/customer.js";
import { useRoute, useRouter } from "vue-router";
import { Plus } from "@element-plus/icons-vue";
import { ref, reactive, toRefs, onMounted } from "vue";
export default {
  components: { Plus },
  setup() {
    const route = ref(useRoute());
    const cst_id = route.value.query.cstId;
    const state = reactive({
      contactPersonList: [],
    });
    const getList = () => {
      customerContactPersonList({ cst_id: cst_id }).then((res) => {
        if (res.code == 0) {
          state.contactPersonList = res.data;
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    const router = useRouter();
    const addContactPerson = () => {
      let routeUrl = router.resolve({
        name: "customerSet",
        query: {
          cstId: cst_id,
        },
      });
      window.open(routeUrl.href, "_blank");
    };
    onMounted(() => {
      getList();
    });
    return {
      ...toRefs(state),
      addContactPerson,
    };
  },
};
</script>
<style scoped lang="scss">
.box-card {
  margin-bottom: 10px;
}
:deep .el-card__header {
  padding: 10px;
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
}
.btn-add {
  padding: 4px;
  height: 24px;
  border-radius: 4px;
  cursor: pointer;
}
</style>
