import axios from "axios";
import _ from "lodash";
import NProgress from "nprogress";
import { ElMessage as Message } from "element-plus";
import { lotsUIEventInstance } from "./event.js";

const RESPONSE_STATUS = {
  OK: 200,
  NOT_LOGIN: 401,
  NO_PERMISSON: 403,
  NOT_FOUND: 404,
  SERVER_ERROR: 500,
};
const MILLISECOND_PER_MINUTE = 60000;
const MINUTES = 30;
const PAUSE_SECONDS = 3;

const service = axios.create({
  timeout: MILLISECOND_PER_MINUTE * MINUTES,
  withCredentials: true,
});

// request拦截器
service.interceptors.request.use(
  (config) => {
    NProgress.start();
    // 过滤get请求的空参数 null, undefined, ''
    config.params = _.chain(config)
      .get("params")
      .toPairs()
      .map((arr) => _.map(arr, _.trim))
      .filter((arr) => !_.isNil(arr[1]) && arr[1] !== "")
      .fromPairs()
      .value();
    return config;
  },
  (error) => {
    NProgress.done();
    Promise.reject(error);
  }
);

// respone拦截器
service.interceptors.response.use(
  (response) => {
    NProgress.done();
    if (response.data && response.data.code === RESPONSE_STATUS.NO_PERMISSON) {
      Message.error({
        message: "参数错误或需要权限！",
        showClose: true,
      });
    } else if (
      response.data &&
      response.data.code &&
      response.data.code !== "0" &&
      +response.data.code !== RESPONSE_STATUS.OK
    ) {
      // 200 为了 oss 接口添加
      Message.error({
        message: response.data.msg,
        showClose: true,
      });
    }
    return response.data;
  },
  (error) => {
    NProgress.done();
    const response = Object.assign({}, error.response);
    if (response) {
      switch (response.status) {
        case RESPONSE_STATUS.NOT_LOGIN:
        case RESPONSE_STATUS.NO_PERMISSON:
          Message.closeAll();
          Message.error({
            message: "当前没有登录用户或鉴权参数缺失，请重新登录！",
            showClose: true,
          });
          // 登出节流
          if (window.t) {
            clearTimeout(window.t);
          }
          window.t = setTimeout(function () {
            lotsUIEventInstance.trigger("logout");
          }, PAUSE_SECONDS * 1000);
          break;
        case RESPONSE_STATUS.NOT_FOUND:
          Message.error({
            message: "没找到接口信息",
            showClose: true,
          });
          break;
        case RESPONSE_STATUS.SERVER_ERROR:
          Message.error({
            message: "系统异常",
            showClose: true,
          });
          break;
        default:
          break;
      }
    }
    return Promise.reject(error);
  }
);

export default service;
