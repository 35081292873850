export default {
  selection: false,
  searchFields: [
    // 搜索组件的配置项
    {
      name: "采购单号",
      value: "pur_no",
      type: "input",
      isFixed: true,
      span: 4,
    },
    {
      name: "采购单备注",
      value: "remark",
      type: "input",
      isFixed: true,
      span: 4,
    },
  ],
  columns: [
    // 表格列的配置
    {
      label: "采购单号",
      prop: "pur_no",
    },
    {
      label: "相关订单",
      prop: "ord_no",
    },
    {
      label: "供应商",
      prop: "spr_id",
    },
    {
      label: "采购类型",
      prop: "pur_type",
    },
    {
      label: "总采购数",
      prop: "num_total",
    },
    {
      label: "已入库数",
      prop: "store_num_total",
    },
    {
      label: "总采金额",
      prop: "amount",
    },
    {
      label: "下单时间",
      prop: "order_time",
    },
    {
      label: "预计到货",
      prop: "expected_time",
    },
    {
      label: "入库时间",
      prop: "warehouse_time",
    },
    {
      label: "创建人",
      prop: "user_id",
    },
    {
      label: "备注",
      prop: "remark",
    },
    {
      label: "操作",
      type: "operate_btn",
      prop: "",
    },
  ],
  // actions: {
  //   // 表格操作列的配置
  //   fixedWidth: 110, // fixedWidth（可选） 设置操作列的固定宽度，不加该参数默认是按钮list长度*50px
  //   list: [
  //     {
  //       label: "详情",
  //       event: "print",
  //       displayRule: () => {
  //         return true;
  //       },
  //     },
  //   ],
  // },
};
