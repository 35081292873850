export default {
  selection: true,
  searchFields: [
    // 搜索组件的配置项
    {
      name: "IMEI码",
      value: "imei",
      type: "input",
      isFixed: true,
      span: 4,
    },
  ],
  columns: [
    // 表格列的配置
    {
      label: "来源订单",
      prop: "ord_no",
      fixed: true,
    },
    {
      label: "签约日期",
      prop: "create_time",
    },
    {
      label: "归属人",
      prop: "user_name_ch",
    },
    {
      label: "产品编码",
      prop: "pdt_no",
    },
    {
      label: "IMEI",
      prop: "imei",
    },
    {
      label: "供应商",
      prop: "spr_comp_name",
    },
    {
      label: "入库时间",
      prop: "warehouse_time",
    },
    {
      label: "操作",
      type: "operate_btn",
      prop: "",
    },
  ],
};
