export default {
  selection: false,
  searchFields: [
    // 搜索组件的配置项
    {
      name: "付款单编码",
      value: "pay_no",
      type: "input",
      isFixed: true,
      span: 4,
    },
    {
      name: "付款单备注",
      value: "remark",
      type: "input",
      isFixed: true,
      span: 4,
    },
  ],
  columns: [
    // 表格列的配置
    {
      label: "生成日期",
      prop: "create_time",
    },
    {
      label: "应付款编码",
      prop: "pay_no",
    },
    {
      label: "相关账单",
      prop: "payment_no",
    },
    {
      label: "供应商名字",
      prop: "spr_comp_name",
    },
    {
      label: "相关账单总额",
      prop: "payable_amount",
    },
    {
      label: "当前账单总额",
      prop: "total_amount",
    },
    {
      label: "预付日期",
      prop: "pay_time",
    },
    {
      label: "付款人",
      prop: "pay_person",
    },
    {
      label: "账单负责人",
      prop: "charge_person",
    },
    {
      label: "状态",
      prop: "status",
      type: "select",
      options: {
        0: "作废",
        1: "待审核",
        2: "待付款",
        3: "已付款",
      },
    },
    {
      label: "备注",
      prop: "remark",
    },
    {
      label: "操作",
      type: "operate_btn",
      prop: "",
      width: "260px",
    },
  ],
  forms: [
    // 表单页面的配置
    {
      label: "付款单号：",
      prop: "pay_no",
      type: "input",
      disabled: true,
    },
    {
      label: "付款时间：",
      prop: "pay_time",
      type: "time",
      rule: [
        {
          required: true,
          message: "选择应付款实际付款时间",
          trigger: "change",
        },
      ],
    },
  ],
};
