<template>
  <div class="order-detail-contain">
    <el-tabs v-model="activeName">
      <el-tab-pane label="我的订单" name="first">
        <myOrderList></myOrderList>
      </el-tab-pane>
      <el-tab-pane label="所有订单" name="second" v-if="operate_right">
        <orderListIndex></orderListIndex>
      </el-tab-pane>
      <el-tab-pane label="回收站" name="three">
        <orderRecycle></orderRecycle>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { ref, onMounted } from "vue";
import { getUserRights } from "@/network/customer.js";
import myOrderList from "./my/index.vue";
import orderListIndex from "./List/index.vue";
import orderRecycle from "./Recycle/index.vue";
export default {
  name: "orderList",
  components: {
    myOrderList,
    orderListIndex,
    orderRecycle,
  },
  setup() {
    const activeName = ref("first");
    const operate_right = ref(false);
    const getList = () => {
      getUserRights().then((res) => {
        if (res.code == 0) {
          operate_right.value = true;
        }
      });
    };
    onMounted(() => {
      getList();
    });
    return {
      activeName,
      operate_right,
    };
  },
};
</script>
<style scoped lang="scss">
.order-detail-contain {
  height: 100%;
  padding: 20px 0;
  .el-tabs {
    height: 100%;
    :deep .el-tabs__content {
      height: calc(100% - 100px);
    }
    .el-tab-pane {
      height: 100%;
    }
  }
}
</style>
