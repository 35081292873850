<template>
  <el-button v-if="isShow" v-bind="{ ...$attrs, ...attrsExt }">
      <slot></slot>
  </el-button>
</template>
<script>
import { computed, defineProps, onMounted, reactive, toRef, useAttrs } from 'vue';
import lodash from 'lodash';
import { getSession } from '@/utils/storage';
export default {
    name: 'lotsButton',
    inheritAttrs: false
};
</script>
<script setup>
const props = defineProps({
    code: {
        type: String,
        default: ''
    },
    delay: {
        type: Number,
        default: 500
    }
});
const attrs = useAttrs();
const attrsExt = reactive({
    onClick: () => {}
});

const isShow = computed(() => {
    if (!props.code) {
        return true;
    }
    const btnPermissions = getSession('btnPermissions') || [];
    return btnPermissions.includes(props.code);
});

onMounted(() => {
    const clickEvent = lodash.get(attrs, 'onClick');
    if (clickEvent) {
        const options = { leading: true, trailing: false };
        attrsExt.onClick = lodash.debounce(clickEvent, props.delay, options);
    }
});
</script>
