export default {
  selection: true,
  searchFields: [
    // 搜索组件的配置项
    {
      name: "订单号",
      value: "ord_no",
      type: "input",
      isFixed: true,
      span: 4,
    },
  ],
  columns: [
    // 表格列的配置
    {
      label: "订单号",
      prop: "ord_no",
      fixed: true,
    },
    {
      label: "签约日期",
      prop: "create_time",
    },
    {
      label: "客户",
      prop: "cst_id_v",
    },
    {
      label: "归属人",
      prop: "user_id_v",
    },
    {
      label: "订单状态",
      prop: "ord_status_v",
    },
    {
      label: "订单总收入",
      prop: "orderTotalIncome",
    },
    {
      label: "产品总支出",
      prop: "orderProductCost",
    },
    {
      label: "财务总支出",
      prop: "orderFinanceCost",
    },
    {
      label: "物流总支出",
      prop: "orderShippingCost",
    },
    {
      label: "订单总支出",
      prop: "orderTotalCost",
    },
    {
      label: "订单毛利",
      prop: "grossProfit",
    },
    {
      label: "订单毛利率",
      prop: "grossMargin",
    },
    {
      label: "操作",
      type: "operate_btn",
      prop: "",
      width: "220px",
    },
  ],
  forms: [
    {
      label: "归属月份：",
      prop: "month_id",
      type: "select",
      placeholder: "- 请选择月份 -",
      rule: [
        {
          required: true,
          message: "请选择该笔订单归属月份",
          trigger: "blur",
        },
      ],
    },
  ],
};
