<template>
  <div style="height: 100%; padding: 10px">
    <div style="text-align: center; font-size: 30px; margin-bottom: 30px">
      出运计划： {{ baseInfo.ship_no }}
    </div>
    <div>
      <el-row :gutter="24" style="margin: 20px 0px">
        <el-col :span="12">发票编码：{{ baseInfo.inv_list_id }} </el-col>
        <el-col :span="12">发货渠道：{{ baseInfo.shipping_id }} </el-col>
      </el-row>
      <el-row :gutter="24" style="margin-bottom: 20px">
        <el-col :span="12"
          >&nbsp;&nbsp;&nbsp;计划日期：{{ baseInfo.create_time }}
        </el-col>
        <el-col :span="12">预期发货：{{ baseInfo.expected_time }} </el-col>
      </el-row>
      <el-row :gutter="24" style="margin-bottom: 20px">
        <el-col :span="24">&nbsp;&nbsp;&nbsp;相关订单：{{ orderNoS }} </el-col>
      </el-row>
    </div>
    <div class="content">
      <el-table
        :data="dataSource"
        show-summary
        style="width: 100%; font-size: 12px"
        height="500px"
      >
        <el-table-column prop="pdt_no" label="产品编码" width="230" />
        <el-table-column prop="pdt_dep" label="产品描述" width="300" />
        <el-table-column prop="pdt_color" label="产品颜色" width="100" />
        <el-table-column prop="num" label="下单数量" />
      </el-table>
    </div>
    <div>
      <el-row :gutter="20" style="margin-top: 20px">
        <el-col> 出运备注：{{ baseInfo.remark }} </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { ref, reactive, toRefs, onMounted } from "vue";
import { ElMessage } from "element-plus";
import { printShippingOrder } from "@/network/showDetails.js";
import { useRoute } from "vue-router";
export default {
  components: {},
  setup() {
    const route = ref(useRoute());
    const state = reactive({
      dataSource: [],
      baseInfo: [],
      orderNoS: "",
    });
    const printInfo = route.value.query.arrData;
    const getList = () => {
      printShippingOrder({ data: printInfo }).then((res) => {
        if (res.code == 0) {
          state.orderNoS = res.data.orderNoS;
          state.baseInfo = res.data.baseInfo;
          state.dataSource = res.data.lists;
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    onMounted(() => {
      getList();
    });
    return {
      ...toRefs(state),
    };
  },
};
</script>
