<template>
  <div class="search">
    <!-- 1.输入搜索关键字的表单 -->
    <el-form
      :model="searchForm"
      ref="formRef"
      :label-width="isAdvanced ? '' : searchConfig.labelWidth || '6em'"
    >
      <el-row :gutter="20">
        <!-- 便捷搜索模式 -->
        <div class="advanced-search" v-if="isAdvanced">
          <el-row style="width: 100%">
            <el-col :span="9">
              <el-form-item label="" prop="name">
                <el-select
                  class="advanced_field"
                  v-model="searchForm.name"
                  placeholder="请选择搜索字段"
                  filterable
                  @change="
                    (val) => {
                      changeFiled(val, false);
                    }
                  "
                >
                  <el-option
                    v-for="item in filedOptions"
                    :key="item.fieldName"
                    :label="item.displayName"
                    :value="item.fieldName"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="" prop="queryMethod">
                <el-select
                  class="advanced_method"
                  v-model="searchForm.queryMethod"
                  placeholder="请选择匹配方式"
                >
                  <el-option
                    v-for="item in queryMethodOptions"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="9">
              <el-form-item label="" prop="value">
                <!-- 输入框类型 -->
                <el-input
                  v-if="seachType == 'input'"
                  v-model="searchForm.value"
                  placeholder="请输入搜索关键字"
                />
                <!-- 下拉框类型 -->
                <el-select
                  class="seachVal"
                  v-else-if="seachType == 'select'"
                  v-model="searchForm.value"
                  filterable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in queryValOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
                <!-- 时间范围类型 -->
                <el-date-picker
                  v-else-if="seachType == 'daterange'"
                  v-model="searchForm.value"
                  type="daterange"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                  range-separator="-"
                  unlink-panels
                  :start-placeholder="'开始时间'"
                  :end-placeholder="'结束时间'"
                  :shortcuts="shortcuts"
                />
                <!-- 日期选择框 -->
                <el-date-picker
                  v-else-if="seachType == 'date'"
                  v-model="searchForm.value"
                  type="date"
                  label=""
                  placeholder="请选择日期"
                  value-format="YYYY-MM-DD"
                  style="width: 100%"
                />
                <!-- 日期时间 -->
                <el-date-picker
                  v-else-if="seachType == 'datetime'"
                  v-model="searchForm.value"
                  type="datetime"
                  label=""
                  format="YYYY-MM-DD HH:mm:ss"
                  value-format="YYYY-MM-DD HH:mm:ss"
                  placeholder="请选择日期时间"
                  style="width: 100%"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </div>

        <!-- 普通搜索模式 -->
        <div class="pt-seach" v-else>
          <template v-for="item in searchConfig.formItems" :key="item.prop">
            <el-col :span="item.span || 8">
              <el-form-item :label="item.label" :prop="item.prop">
                <template v-if="item.type === 'input'">
                  <el-input
                    v-model="searchForm[item.prop]"
                    :placeholder="item.placeholder"
                  />
                </template>
                <template v-if="item.type === 'daterange'">
                  <el-date-picker
                    v-model="searchForm[item.prop]"
                    type="daterange"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                    range-separator="-"
                    unlink-panels
                    :start-placeholder="item.startPlaceholder || '开始时间'"
                    :end-placeholder="item.startPlaceholder || '结束时间'"
                    :shortcuts="shortcuts"
                  />
                </template>
                <template v-if="item.type === 'select'">
                  <el-select
                    v-model="searchForm[item.prop]"
                    :placeholder="item.placeholder"
                    style="width: 100%"
                  >
                    <template
                      v-for="option in item.options"
                      :key="option.value"
                    >
                      <el-option :label="option.label" :value="option.value" />
                    </template>
                  </el-select>
                </template>
              </el-form-item>
            </el-col>
          </template>
        </div>
        <!-- 2.重置和搜索的按钮 -->
        <div class="seach_btns">
          <div class="btns">
            <el-button size="small" icon="Refresh" v-debounce="handleResetClick"
              >重置</el-button
            >
            <el-button
              size="small"
              icon="Search"
              type="primary"
              v-debounce="handleQueryClick"
              >查询</el-button
            >
          </div>
          <el-badge
            class="seach_badge"
            :value="AdvanceConditionNum"
            :hidden="!(AdvanceConditionNum > 1)"
            type="warning"
          >
            <div
              :class="{
                advanced: true,
                active_advanced: AdvanceConditionNum > 1,
              }"
              @click="advanceClick"
              v-if="filedOptions.length > 1"
            >
              <el-icon><Filter /></el-icon>
              高级搜索
            </div>
          </el-badge>
        </div>
      </el-row>
    </el-form>
  </div>
</template>

<script setup>
import { reactive, ref, onMounted, computed, onUnmounted, watch } from "vue";
import { ElForm, ElMessage } from "element-plus";
import { getNomalApi } from "@/network/basic.js";
const shortcuts = [
  {
    text: "今天",
    value: () => {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime());
      return [start, end];
    },
  },
  {
    text: "昨天",
    value: () => {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
      end.setTime(end.getTime() - 3600 * 1000 * 24 * 1);
      return [start, end];
    },
  },
  {
    text: "前天",
    value: () => {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 2);
      end.setTime(end.getTime() - 3600 * 1000 * 24 * 2);
      return [start, end];
    },
  },
  {
    text: "本周至今",
    value: () => {
      const today = new Date();
      const dayOfWeek = today.getDay(); // 获取今天是星期几（0-6，0表示星期日）
      const startOfWeek = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - dayOfWeek + 1
      );
      const end = new Date();
      const start = startOfWeek.getTime();
      return [start, end];
    },
  },
  {
    text: "上周整周",
    value: () => {
      const today = new Date();
      const dayOfWeek = today.getDay(); // 获取今天是星期几（0-6，0表示星期日）
      const startOfWeek = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - dayOfWeek - 6
      ); // 获取上周的起始日期
      const endOfWeek = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - dayOfWeek
      ); // 获取上周的结束日期
      const start = startOfWeek.getTime();
      const end = endOfWeek.getTime();
      return [start, end];
    },
  },

  {
    text: "本月至今",
    value: () => {
      const today = new Date();
      const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
      const end = new Date();
      const start = startOfMonth.getTime();
      return [start, end];
    },
  },
  {
    text: "上月整月",
    value: () => {
      const today = new Date();
      const startOfMonth = new Date(
        today.getFullYear(),
        today.getMonth() - 1,
        1
      ); // 获取上月的起始日期
      const endOfMonth = new Date(today.getFullYear(), today.getMonth(), 0); // 获取上月的结束日期
      const start = startOfMonth.getTime();
      const end = endOfMonth.getTime();
      return [start, end];
    },
  },
  {
    text: "本年至今",
    value: () => {
      const today = new Date();
      const startOfYear = new Date(today.getFullYear(), 0, 1); // 获取本年的起始日期
      const end = new Date();
      const start = startOfYear.getTime();
      return [start, end];
    },
  },
];

// 定义自定义事件/接收的属性
// interface IFiledType {
//   fieldName: string; //字段名
//   displayName: string; //字段中文名
//   condition: string[]; //匹配下拉数组
//   textType: string; // 值的类型：输入框、下拉框、日期
//   apiUrl?: string; //下拉接口
//   dictCode?: string; //数据字典编码
//   defaultValue?: any; //搜索默认值
//   optionValueFieldName?: string; //决定数据字典是否值为label还是value
// }
// interface IProps {
//   searchConfig: {
//     isAdvanced: boolean;
//     labelWidth?: string;
//     immediatelyQuery?: boolean;
//     formItems?: any[];
//     filedOptions: IFiledType[];
//   };
// }
const emit = defineEmits(["queryClick", "resetClick"]);
const advanSeach = ref();
const advanceParams = ref([]);
const props = defineProps({
  searchConfig: {
    type: Object,
    default: () => {
      return {};
    },
  },
});
const isAdvanced = computed(() => {
  return props.searchConfig.isAdvanced;
});
// 定义form的数据
const initialForm = {};
if (props.searchConfig.formItems) {
  for (const item of props.searchConfig.formItems) {
    initialForm[item.prop] = item.initialValue ?? "";
  }
}
// interface IfieldType {
//   name: string;
//   queryMethod: string;
//   value: any;
// }
// interface IseachType {
//   fields: IfieldType[];
// }
const serchListParams = computed(() => {
  const seachParams = { fields: [] };
  if (advanceParams.value.length) {
    seachParams.fields = advanceParams.value;
  } else {
    seachParams.fields = [{ ...searchForm }];
  }
  seachParams.fields = seachParams.fields.map((item) => {
    return {
      name: item.name,
      queryMethod: item.queryMethod,
      value: item.value,
    };
  });
  return seachParams;
});

const queryValOptions = ref([]); //搜索值下拉数据
const queryMethodOptions = ref([]); //匹配值下拉数据
const filedOptions = ref([...props.searchConfig.filedOptions]); //搜索字段下拉数据
const seachType = ref("input"); //1：表示输入框、2：表示下拉选择框、3:表示日期范围
const searchForm = reactive({ ...initialForm });
const initAdvanced = ref(searchForm);
const initAdvancedSearchForm = computed(() => {
  return initAdvanced.value;
});
watch(
  () => initAdvancedSearchForm,
  () => {
    initAdvanced.value.name = searchForm.name;
    initAdvanced.value.queryMethod = searchForm.queryMethod;
    initAdvanced.value.value = searchForm.value;
    initAdvanced.value.textType = searchForm.textType;
    initAdvanced.value.queryValOptions = queryValOptions.value;
    initAdvanced.value.queryMethodOptions = queryMethodOptions.value;
  }
);
const handleQueryClick = () => {
  if (filedOptions.value.length || true) {
    emit("queryClick", serchListParams.value);
  } else {
  }
};
function handleResetClick() {
  // 1.form中的数据全部重置
  // formRef.value?.resetFields()
  // 默认取搜索字段第一个作为搜索
  if (filedOptions.value.length) {
    changeFiled(filedOptions.value?.[0].fieldName, true);
  }
  // 2.将事件出去, content内部重新发送网络请求
  if (filedOptions.value.length) {
    emit("resetClick", serchListParams.value);
  } else {
    // 搜索条件不能为空
    ElMessage.warning("搜索条件不能为空");
  }
}
// 搜索字段和条件都默认取下拉的第一个
const handleSeachConfig = (filed, flag) => {
  if (
    isAdvanced.value &&
    filedOptions.value.length > 0 &&
    queryMethodOptions.value.length > 0
  ) {
    initialForm.name = filed; //不是第一次进来，可以自己选择字段查询
    // 初次加载页面获取下拉第一个字段
    if (flag) {
      initialForm.name = filedOptions.value[0].fieldName;
    }
    initialForm.queryMethod = queryMethodOptions.value[0].code;
    Object.assign(searchForm, initialForm);
  }
};
// 改变搜索字段了
const changeFiled = (filed, flag) => {
  const checkFiledObj = filedOptions.value.filter((item) => {
    return item.fieldName == filed;
  });
  if (checkFiledObj.length) {
    queryMethodOptions.value = checkFiledObj[0].condition;
    seachType.value = checkFiledObj[0].textType;
    initialForm.textType = seachType.value;
    initialForm.value = checkFiledObj[0].defaultValue;
    if (seachType.value == "select") {
      if (checkFiledObj[0].apiUrl) {
        // 当搜索框类型为下拉类型时，需要调用接口获取下拉数据
        //   getCommonSelectOption(checkFiledObj[0].apiUrl).then((res: any) => {
        //     if (res.code == 200) {
        //       if (checkFiledObj[0].optionValueFieldName) {
        //         res.result = res.result.map((item: any) => {
        //           item.value = item.label;
        //           return item;
        //         });
        //       }
        //       queryValOptions.value = res.result;
        //     }
        //   });
      } else if (checkFiledObj[0].dictCode) {
        // 数据字典下拉
        getNomalApi([checkFiledObj[0].dictCode]).then((res) => {
          if (res.code == 0) {
            if (checkFiledObj[0].optionValueFieldName) {
              res.data = res.data.map((item) => {
                item.value = item.dictName;
                return item;
              });
            }
            queryValOptions.value = res.data[checkFiledObj[0].dictCode];
          }
        });
      }
      // queryValOptions.value = checkFiledObj[0].queryValOptions;
    }
  }
  // 搜索字段和条件都默认取下拉的第一个
  handleSeachConfig(filed, flag);
};

// const setQueryMethodOptions = (fieldName: string) => {
//   changeFiled(fieldName, false);
// };
// const callBackAdvanceSeachParams = (advanceParam: any[]) => {
//   advanceParams.value = advanceParam;
//   emit('queryClick', serchListParams.value);
// };
const handleKeyDown = (key) => {
  if (key.keyCode == 13) {
    // 回车
    emit("queryClick", serchListParams.value);
  }
};
onMounted(() => {
  // 搜索条件配置
  // getSysDynCondConfig(props.searchConfig.pageName).then((res: any) => {
  //   if (res.code == 200) {
  //     filedOptions.value = handleConditonformat(res.result);
  //     // 默认取搜索字段第一个作为搜索
  //     if (filedOptions.value.length) {
  //       changeFiled(filedOptions.value?.[0].fieldName, true);
  //     }
  //     // 特殊情况，如果是需要立刻带条件搜索的情况
  //     if (props.searchConfig.immediatelyQuery) {
  //       handleQueryClick();
  //     }
  //   }
  // });
  // 默认取搜索字段第一个作为搜索
  if (filedOptions.value.length) {
    changeFiled(filedOptions.value?.[0].fieldName, true);
  }
  window.addEventListener("keydown", handleKeyDown);
});
onUnmounted(() => {
  window.removeEventListener("keydown", handleKeyDown);
});
defineExpose({ handleQueryClick });
</script>

<style lang="scss" scoped>
.search {
  margin-bottom: 20px;
  .advanced-search {
    width: 50%;
    display: flex;
    align-items: center;
    margin-right: 20px;
    .el-form-item {
      padding: 0;
      padding-right: 6px;
      :deep(.el-form-item__content) {
        .el-input {
          width: 100%;
        }
        .advanced_field {
          width: 100%;
        }
      }
    }
    .seachVal {
      width: 100%;
    }
  }

  .el-form-item {
    padding: 10px 30px;
    margin-bottom: 0;
  }
  .seach_btns {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    .advanced {
      font-size: 12px;
      display: flex;
      // align-items: center;
      color: #aaa;
      margin-left: 20px;
      .el-icon {
        margin-right: 4px;
      }
    }

    .advanced:hover {
      color: var(--el-color-primary);
      cursor: pointer;
    }
    .active_advanced {
      color: var(--el-color-primary);
    }
  }
  .btns {
    display: flex;
    .el-button {
      height: 30px;
    }
  }
  .el-divider {
    margin: 20px 0px;
  }
  .seach_badge {
    :deep(.el-badge__content) {
      top: -8px;
      transform: scale(0.7) translateY(-50%) translateX(100%);
      right: 0;
    }
  }
}
</style>
