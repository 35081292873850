<template>
  <div class="order-detail-contain">
    <el-tabs v-model="activeName">
      <el-tab-pane label="应付款" name="first">
        <paymentList></paymentList>
      </el-tab-pane>
      <el-tab-pane label="待审核" name="second">
        <waitReviewList></waitReviewList>
      </el-tab-pane>
      <el-tab-pane label="待付款" name="three">
        <waitPayList></waitPayList>
      </el-tab-pane>
      <el-tab-pane label="已完成" name="four">
        <successList></successList>
      </el-tab-pane>
      <el-tab-pane label="回收站" name="five">
        <recycleList></recycleList>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { ref } from "vue";
import paymentList from "./List/index.vue";
import waitReviewList from "./waitReview/index.vue";
import waitPayList from "./waitPay/index.vue";
import successList from "./Success/index.vue";
import recycleList from "./Recycle/index.vue";
export default {
  name: "financePayment",
  components: {
    paymentList,
    waitReviewList,
    waitPayList,
    successList,
    recycleList,
  },
  setup() {
    const activeName = ref("first");
    return {
      activeName,
    };
  },
};
</script>
<style scoped lang="scss">
.order-detail-contain {
  height: 100%;
  padding: 20px 0;
  .el-tabs {
    height: 100%;
    :deep .el-tabs__content {
      height: calc(100% - 100px);
    }
    .el-tab-pane {
      height: 100%;
    }
  }
}
</style>
