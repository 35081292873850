import axios from "axios";
export function request(config) {
  const instance = axios.create({
    // baseURL: "http://api.isidun.io/index.php/index/",
    baseURL: "https://api.isidun.com/index.php/index/",
    timeout: 50000,
  });
  // 请求拦截
  instance.interceptors.request.use(
    (config) => {
      const token = window.localStorage.getItem("token");
      if (token) {
        config.headers.Authorization = token;
      }
      return config;
    },
    (err) => {
      console.log(err);
    }
  );
  // 响应拦截
  instance.interceptors.response.use(
    (res) => {
      return res.data;
    },
    (err) => {
      console.log(err);
    }
  );
  return instance(config);
}
