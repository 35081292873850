<template>
  <div style="height: 100%">
    <div style="text-align: center; margin: 20px; font-size: 30px">
      出运编码 {{ formState.ship_no }}
    </div>
    <el-form :model="formState" :rules="rules">
      <div class="div_title">发票信息：</div>
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="出运发票：" prop="inv_list_id">
            <el-select
              v-model="formState.inv_list_id"
              :popper-append-to-body="false"
              placeholder="-请选择发票-"
            >
              <el-option
                v-for="item in invoiceList"
                :key="item.inv_id"
                :label="item.inv_no"
                :value="item.inv_id"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="div_title">发货信息：</div>
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="发货渠道：" prop="shipping_id">
            <el-select
              v-model="formState.shipping_id"
              :popper-append-to-body="false"
              placeholder="-请选择渠道-"
            >
              <el-option
                v-for="item in getShippingComp"
                :key="item.shipping_id"
                :label="item.shipping_name_ch"
                :value="item.shipping_id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="分批出运：">
            <el-switch
              v-model="formState.is_multiple_ship"
              :popper-append-to-body="false"
              inline-prompt
              :disabled="update_type"
              active-text="是"
              inactive-text="否"
            >
            </el-switch>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="预期发货：">
            <el-date-picker
              v-model="formState.expected_time"
              type="date"
              placeholder="设置期待发货日期"
              format="YYYY/MM/DD"
              value-format="YYYY-MM-DD"
              :disabled-date="disabledDate"
              :shortcuts="shortcuts"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="24">
          <el-form-item label="出运备注：">
            <el-input
              type="textarea"
              v-model="formState.remark"
              placeholder="请输入该笔出运的特殊备注：唛头、打包特殊要求等"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="24" style="text-align: right">
          <el-button size="middle" type="primary" @click="add(2)"
            ><el-icon style="vertical-align: middle"> <Refresh /></el-icon>
            <span style="vertical-align: middle">
              {{ btn_title }}
            </span></el-button
          >
          <el-button
            v-if="operate_type"
            size="middle"
            type="info"
            @click="add(1)"
            ><el-icon style="vertical-align: middle"> <RefreshLeft /></el-icon
            ><span style="vertical-align: middle">驳回</span></el-button
          >
          <el-button
            v-if="operate_type"
            size="middle"
            type="success"
            @click="add(3)"
            ><el-icon style="vertical-align: middle"> <RefreshRight /></el-icon
            ><span style="vertical-align: middle">通过</span></el-button
          >
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import { ref, reactive, toRefs, onMounted, defineComponent } from "vue";
import { Refresh, RefreshLeft, RefreshRight } from "@element-plus/icons-vue";
import {
  getShippingDetails,
  getShippingComp,
  createListAdd,
  createListReview,
} from "@/network/shipping.js";
import { getUserList } from "@/network/user.js";
import { useRoute, useRouter, route } from "vue-router";
import { ElMessage } from "element-plus";
export default defineComponent({
  components: {
    Refresh,
    RefreshLeft,
    RefreshRight,
  },
  emits: ["getCreateDetails"],
  setup(props, context) {
    const route = ref(useRoute());
    const state = reactive({
      dataSource: [],
      getShippingComp: [],
      getUserList: [],
      invoiceList: [],
      btn_title: "保存",
      operate_type: false,
      update_type: false, //如果是更新出运单，不允许修改是否“分批出运”
    });
    const shippingInfo = route.value.query.arrData;
    if (shippingInfo[0] == "udt") {
      state.btn_title = "更新";
      state.operate_type = true;
      state.update_type = true;
    }
    const rules = reactive({
      inv_list_id: [
        {
          required: true,
          message: "请选择发票",
          trigger: "blur",
        },
      ],
      shipping_id: [
        {
          required: true,
          message: "请选择发货渠道",
          trigger: "blur",
        },
      ],
    });
    const formState = reactive({
      id: "",
      ship_no: "",
      shipping_id: "",
      inv_list_id: "",
      expected_time: "",
      is_multiple_ship: false,
      remark: "",
    });
    const router = useRouter();
    const getList = () => {
      getShippingDetails({ data: shippingInfo }).then((res) => {
        if (res.code == 0) {
          state.invoiceList = res.data.invoiceList;
          Object.assign(formState, res.data.list);
        } else {
          ElMessage.error(res.msg);
          router.push({
            name: "shipping",
          });
        }
      });
      getUserList().then((res) => {
        if (res.code == 0) {
          state.getUserList = res.data;
        } else {
          ElMessage.error(res.msg);
        }
      });
      getShippingComp().then((res) => {
        if (res.code == 0) {
          state.getShippingComp = res.data;
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    onMounted(() => {
      getList();
    });
    const add = (val) => {
      if (val == 2) {
        //添加或更新
        createListAdd({
          formState: formState,
          shippingInfo: shippingInfo,
        }).then((res) => {
          if (res.code == 0) {
            ElMessage.success(res.msg);
            formState.id = res.data; //将生成或者更新成功后返回的出运单ID传给关系表页面
            context.emit("getCreateDetails", {
              ship_id: formState.id,
              ship_no: formState.ship_no,
            });
          } else {
            ElMessage.error(res.msg);
          }
        });
      } else {
        //审核通过或驳回
        createListReview({
          operate_type: val,
          ship_id: shippingInfo[1],
        }).then((res) => {
          if (res.code == 0) {
            ElMessage.success(res.msg);
            router.push({
              name: "shipping",
              params: {},
            });
          } else {
            ElMessage.error(res.msg);
          }
        });
      }
    };
    return {
      ...toRefs(state),
      formState,
      add,
      rules,
    };
  },
});
</script>
<style scoped>
.div_title {
  margin: 20px 0;
  padding-left: 10px;
  border-left: 10px solid grey;
  font-size: 20px;
}
</style>
