export default {
  selection: false,
  searchFields: [
    // 搜索组件的配置项
    {
      name: "订单号",
      value: "ord_no",
      type: "input",
      isFixed: true,
      span: 4,
    },
    {
      name: "订单备注",
      value: "ord_remark",
      type: "input",
      isFixed: true,
      span: 4,
    },
  ],
  columns: [
    // 表格列的配置
    {
      label: "订单号",
      prop: "ord_no",
      fixed: true,
    },
    {
      label: "签约日期",
      prop: "create_time",
    },
    {
      label: "订单金额",
      prop: "receivables",
    },
    {
      label: "收款状态",
      prop: "collection_status",
    },
    {
      label: "产品总数",
      prop: "pdt_sum",
    },
    {
      label: "出运状态",
      prop: "ship_status",
    },
    {
      label: "结算状态",
      prop: "ord_approval_id_v",
    },
    {
      label: "发票状态",
      prop: "inv_status",
    },
    {
      label: "操作",
      type: "operate_btn",
      prop: "",
      width: "180px",
    },
  ],
  forms: [
    {
      label: "选择客户：",
      prop: "cst_id",
      type: "select",
      filterable: true,
      options: "",
      placeholder: "选择需要做单的客户",
    },
  ],
};
