<template>
  <div class="order-detail-contain">
    <el-tabs v-model="activeName">
      <el-tab-pane label="联系人管理" name="first">
        <contactPersonList :cstId="cstId"></contactPersonList>
      </el-tab-pane>
      <el-tab-pane label="付款信息" name="second">
        <customerPayerList :cstId="cstId"></customerPayerList>
      </el-tab-pane>
      <el-tab-pane label="物流信息" name="third">
        <customerShippingList :cstId="cstId"></customerShippingList>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { computed, ref } from "vue";
import contactPersonList from "./person/index.vue";
import customerPayerList from "./payer/index.vue";
import customerShippingList from "./shipping/index.vue";
import { useRoute } from "vue-router";
export default {
  name: "customerSet",
  components: {
    contactPersonList,
    customerPayerList,
    customerShippingList,
  },
  setup() {
    const activeName = ref("first");
    const route = ref(useRoute());
    const cstId = Number(route.value.params.cstId || route.value.query.cstId);
    return {
      activeName,
      cstId,
    };
  },
};
</script>
<style scoped>
.order-detail-contain {
  padding: 20px 0;
}
</style>
