<template>
  <!--    {{selectorConfig.value}}-->
  <el-select
    ref="lotsBasicDataSelct"
    :class="{
      'basic-select-reverse': dropDownVisible,
      'lots-basic-select-for-lazy': true,
    }"
    v-model="selectorConfig.value"
    :value-key="config.valueKey"
    :loading="selectorConfig.loading"
    :disabled="disabled"
    filterable
    remote
    collapse-tags
    reserve-keyword
    :clearable="config.clearable"
    :placeholder="config.title"
    :remote-method="remoteMethod"
    :multiple="config.multiple"
    :no-data-text="
      $translate('lotsUI.no_matching_options', { defaultText: '无匹配选项' })
    "
    @focus="onFocus"
    @change="change"
    @dblclick="copy"
    v-loadmore:[lotsBasicDataClass]="loadMore"
    :popper-class="lotsBasicDataClass"
    @visible-change="visibleChange"
  >
    <el-option
      class="lots-basic-select-for-search-option"
      v-for="(item, index) in selectorConfig.options"
      :key="index"
      :label="item.label"
      :value="item.value"
    >
      <span style="float: left">{{ item.label }}</span>
      <span v-show="config.showValue" class="option-right">{{
        config.defineShowKey ? item.defineShowValue : item.value
      }}</span>
    </el-option>
    <div class="lots-basic-select-loading-info">
      <div v-show="lazyLoaing && !isNoMore" class="load-status">
        <i class="el-icon-loading" style="font-size: 16px"></i>
        <span style="font-size: 12px"
          >&nbsp;{{
            $translate("lotsUI.loading", { defaultText: "加载中" })
          }}...</span
        >
      </div>
      <div v-show="isNoMore" class="load-status">
        <span style="font-size: 12px"
          >&nbsp;{{
            $translate("lotsUI.no_more", { defaultText: "没有更多" })
          }}</span
        >
      </div>
      <i class="load-status el-icon-more" v-show="!lazyLoaing && !isNoMore"></i>
    </div>
  </el-select>
</template>

<script>
import { defineComponent, reactive, ref, watch } from "vue";
import { ElMessage } from "element-plus";
import http from "@/utils/service";
import utils from "@/utils/common";
import axios from "axios";
const CancelToken = axios.CancelToken;
const scrollButtomCallback = function (binding, SELECTDOM) {
  return () => {
    const isAtBottom =
      Math.floor(SELECTDOM.scrollHeight - SELECTDOM.scrollTop) <=
      SELECTDOM.clientHeight;
    if (isAtBottom) {
      // 执行回调方法
      binding.value();
    }
  };
};
const DEFAULT_TIMEOUT = 300;
let scrollCallback = null;
export default defineComponent({
  name: "lotsBasicDataSelector",
  directives: {
    loadmore: {
      // 指令的定义
      mounted(el, binding) {
        const SELECTDOM = document.querySelector(
          `.${binding.arg} .el-select-dropdown__wrap`
        );
        // 为对应的ul绑定滚动条滚动事件
        scrollCallback = scrollButtomCallback(binding, SELECTDOM);
        SELECTDOM.addEventListener(
          "scroll",
          scrollButtomCallback(binding, SELECTDOM)
        );
      },
      beforeUnmount(el, binding) {
        const SELECTDOM = document.querySelector(
          `.${binding.arg} .el-select-dropdown__wrap`
        );
        SELECTDOM.removeEventListener("scroll", scrollCallback);
      },
    },
  },
  emits: ["update:modelValue", "change"],
  props: {
    config: {
      type: Object,
      default: () => {
        return {
          urlConfig: {},
          title: "",
          valueKey: "",
          labelKey: "",
          defineShowKey: "",
          searchKey: "",
          delay: 1000,
          showValue: false,
          clearable: false,
          getAllData: false,
          multiple: false,
        };
      },
    },
    disabled: {
      // 是否启动组件
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  // eslint-disable-next-line max-lines-per-function
  setup(props, context) {
    const lotsBasicDataSelct = ref(null);
    const originList = ref([]);
    const selectorConfig = reactive({
      // value: props.config.getAllData ? {} : '',
      value: [
        // 'LN202106222740', 'LN00068201'
        // { lineCode: 'LN202106222740', lineName: '乌兰察布市卓资县-包头市石拐区' },
        // { lineCode: 'LN00068201', lineName: '徐州市鼓楼区-苏州市相城区' }
      ],
      options: [],
      pageNo: 1,
      pageSize: 30,
      loading: false,
      urlConfig: props.config.urlConfig,
      title: props.config.title,
      valueKey: "",
      valueDesKey: "",
      labelKey: "",
      labelDesKey: "",
      defineShowKey: "",
      defineShowDesKey: "",
      searchKey: props.config.searchKey,
      delay: props.config.delay,
      showValue: props.config.showValue,
      clearable: props.config.clearable,
      getAllData: props.config.getAllData,
      multiple: props.config.multiple,
    });
    const initKeyMap = () => {
      const valueKeyArr = props.config.valueKey.split("#");
      selectorConfig.valueKey = valueKeyArr[0];
      selectorConfig.valueDesKey = valueKeyArr[1]
        ? valueKeyArr[1]
        : valueKeyArr[0];

      const labelKeyArr = props.config.labelKey.split("#");
      selectorConfig.labelKey = labelKeyArr[0];
      selectorConfig.labelDesKey = labelKeyArr[1]
        ? labelKeyArr[1]
        : labelKeyArr[0];

      if (props.config.defineShowKey) {
        const defineShowKeyArr = props.config.defineShowKey.split("#");
        selectorConfig.defineShowKey = defineShowKeyArr[0];
        selectorConfig.defineShowDesKey = defineShowKeyArr[1]
          ? defineShowKeyArr[1]
          : defineShowKeyArr[0];
      }
    };
    initKeyMap();
    const checkedItem = ref([]);
    watch(
      () => props.modelValue,
      () => {
        checkedItem.value = [];
        if (props.config.multiple) {
          selectorConfig.value = [];
          props.modelValue.forEach((item) => {
            selectorConfig.value.push(item[selectorConfig.valueDesKey]);
            const find = selectorConfig.options.find((optionsItem) => {
              return optionsItem.value === item[selectorConfig.valueDesKey];
            });
            const option = {
              value: item[selectorConfig.valueDesKey],
              label: item[selectorConfig.labelDesKey],
              defineShowValue: item[selectorConfig.defineShowDesKey] || "",
            };
            if (!find) {
              selectorConfig.options.push(option);
            }
            checkedItem.value.push(option);
          });
        } else {
          if (props.modelValue.length > 0) {
            // 单选取第一个值，其他忽略
            const valueItem = props.modelValue[0];
            // 解决单选时selectorConfig.value接收数字类型，回显显示valueKey内容的bug
            selectorConfig.value = String(
              valueItem[selectorConfig.valueDesKey]
            );
            const find = selectorConfig.options.find((optionsItem) => {
              return (
                optionsItem.value ===
                String(valueItem[selectorConfig.valueDesKey])
              );
            });
            const option = {
              value: String(valueItem[selectorConfig.valueDesKey]),
              label: valueItem[selectorConfig.labelDesKey],
              defineShowValue: valueItem[selectorConfig.defineShowDesKey] || "",
            };
            if (!find) {
              selectorConfig.options.push(option);
            }
            checkedItem.value.push(option);
          } else {
            selectorConfig.value = "";
          }
        }
      },
      { immediate: true }
    );

    const dropDownVisible = ref(false);
    const visibleChange = (visible) => {
      dropDownVisible.value = !!visible;
      if (visible) {
        remoteMethod("", false);
      } else {
        document.querySelector(
          `.${lotsBasicDataClass.value} .el-select-dropdown__wrap`
        ).scrollTop = 0;
        selectorConfig.pageNo = 1;
        isNoMore.value = false;
      }
    };
    const copy = () => {
      if (props.config.multiple) {
        return;
      }
      const copylabel = selectorConfig.options.find(
        (item) => item.value === selectorConfig.value
      );
      if (copylabel) {
        const input = document.createElement("textarea");
        input.value = copylabel.label;
        input.style.display = "none;";
        document.body.appendChild(input);
        input.select();
        if (document.execCommand("Copy")) {
          // 复制成功
          ElMessage.success(
            $translate("lotsUI.E1202212300001", { defaultText: "复制成功" })
          );
        }
        document.body.removeChild(input);
      }
    };

    // 选择结果后，抛出结果到v-model
    const change = (data) => {
      const values = Array.isArray(data) ? data : [data];
      const cbData = [];
      selectorConfig.options.forEach((item) => {
        if (values.includes(item.value)) {
          const one = {};
          one[selectorConfig.valueDesKey] = String(item.value);
          one[selectorConfig.labelDesKey] = item.label;
          one[selectorConfig.defineShowDesKey] = item.defineShowValue;
          cbData.push(one);
        }
      });
      const originDataList = originList.value.filter((item) => {
        if (values.includes(item[selectorConfig.valueKey])) {
          return true;
        }
        return false;
      });
      // 解决有默认值清空后 再选择，第一个会回显显示valueKey内容的bug
      if (!data.length) {
        selectorConfig.options = [];
      }
      context.emit("update:modelValue", cbData);
      context.emit("change", cbData, originDataList);
    };
    const lotsBasicDataClass = ref(`lots-basic-data-class-${utils.uuid()}`);
    const keyword = ref("");
    const isNoMore = ref(false);
    const loadMore = () => {
      remoteMethod(keyword.value, true);
    };
    const cancel = [];
    const getData = (value, requestMethod) => {
      if (cancel.length) {
        cancel.forEach((item) => {
          item("取消请求");
        });
      }
      const cancelToken = new CancelToken(function executor(c) {
        cancel.push(c);
      }); // 取消请求函数
      selectorConfig.urlConfig.cancelToken = cancelToken; // 存进urlConfig中 放到axios请求内
      const data = {
        ...(requestMethod === "post"
          ? selectorConfig.urlConfig.data
          : selectorConfig.urlConfig.params),
        ...{
          [selectorConfig.searchKey || selectorConfig.labelKey]: value,
          pageNo: selectorConfig.pageNo,
          pageSize: selectorConfig.pageSize,
        },
      };
      if (requestMethod === "post") {
        selectorConfig.urlConfig.data = data;
      } else {
        selectorConfig.urlConfig.params = data;
      }
      return http.request(selectorConfig.urlConfig);
    };

    const formatOptions = function (list, lazy) {
      const values = [];
      checkedItem.value.forEach((checkedItem) => {
        values.push(String(checkedItem.value));
      });
      const data = [];
      list.forEach((listItem) => {
        if (!values.includes(String(listItem[selectorConfig.valueKey]))) {
          data.push({
            value: String(listItem[selectorConfig.valueKey]),
            label: listItem[selectorConfig.labelKey],
            defineShowValue: listItem[selectorConfig.defineShowKey] || "",
          });
        }
      });
      if (lazy) {
        selectorConfig.options = [...selectorConfig.options, ...data];
        // selectorConfig.options = Object.freeze([...selectorConfig.options, ...data]);
        originList.value = [...originList.value, ...list];
      } else {
        checkedItem.value.forEach((checkedItem) => {
          data.unshift(checkedItem);
        });
        selectorConfig.options = data;
        // selectorConfig.options = Object.freeze(data);
        originList.value = JSON.parse(JSON.stringify(list));
      }
    };

    let timer = "";
    const lazyLoaing = ref(false);
    const remoteMethod = (value, lazy = false) => {
      if (value !== keyword.value || !lazy) {
        selectorConfig.pageNo = 1;
        isNoMore.value = false;
      }
      keyword.value = value;
      if (isNoMore.value) {
        return;
      }
      if (lazy && !lazyLoaing.value) {
        selectorConfig.pageNo = selectorConfig.pageNo + 1;
      }
      if (!lazy) {
        selectorConfig.loading = true;
      } else {
        lazyLoaing.value = true;
      }

      // 如果是初始加载，则不使用延迟调接口
      let timeout = DEFAULT_TIMEOUT;
      if (!value && !lazy) {
        timeout = 0;
      } else {
        timeout = selectorConfig.delay || DEFAULT_TIMEOUT;
      }
      clearTimeout(timer);
      timer = setTimeout(() => {
        const requestMethod =
          selectorConfig.urlConfig.method.toLowerCase() !== "post"
            ? "get"
            : "post";
        getData(value, requestMethod)
          .then((res) => {
            if (res && res.code === "0" && res.data) {
              // 如果返回list长度为0 ，则讲懒加载设置为没有更多，下次滚动不执行接口调用
              if (res.data.list.length <= 0) {
                isNoMore.value = true;
              }
              formatOptions(res.data.list, lazy);
            } else {
              selectorConfig.options = [];
            }
          })
          .catch(() => {})
          .finally(() => {
            lazyLoaing.value = false;
            selectorConfig.loading = false;
          });
      }, timeout);
    };

    const onFocus = (val) => {
      if (!selectorConfig.options.length) {
        remoteMethod(val.target.value, false, 0);
      }
    };

    return {
      lotsBasicDataSelct,
      remoteMethod,
      selectorConfig,
      onFocus,
      loadMore,
      lotsBasicDataClass,
      change,
      visibleChange,
      checkedItem,
      dropDownVisible,
      isNoMore,
      lazyLoaing,
      copy,
    };
  },
});
</script>
<style lang="scss">
.lots-basic-select-loading-info {
  text-align: center;
  padding-bottom: 40px;
  font-size: 14px;
  color: #bbb;
  .load-status {
    height: 30px;
    line-height: 40px;
  }
}
.lots-basic-select-for-search-option {
  .option-right {
    float: right;
    color: #8492a6;
    font-size: 12px;
    margin-left: 4px;
  }
}
</style>
