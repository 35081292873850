<template>
  <div class="day_schedule">
    <el-button class="actionBtn" type="primary" @click="addDateGo"
      ><el-icon> <plus /> </el-icon><span>新增计划</span></el-button
    >
    <!-- 新增计划日期 -->
    <el-dialog
      v-model="dialogFormDate"
      :title="title"
      :append-to-body="true"
      z-index="100"
    >
      <el-form ref="outerFormRef" :model="formDate" :rules="outerFormRule">
        <el-form-item
          prop="schedule_date"
          style="padding-left: 20px"
          label="计划日期："
        >
          <el-date-picker
            class="time_sel"
            v-model="formDate.schedule_date"
            type="date"
            placeholder="选择日期"
            :disabled="formDate.day_id ? true : false"
          ></el-date-picker>
        </el-form-item>
        <ul class="formTipUl">
          <li v-for="(item, index) in formDate.tips" :key="index">
            <el-form
              :ref="(el) => (innerFormRef[index] = el)"
              :model="item"
              :rules="innerFormRule"
            >
              <el-row>
                <el-col :span="20">
                  <el-form-item prop="time" label="时间节点：">
                    <el-time-select
                      class="time_sel"
                      v-model="item.time"
                      start="09:00"
                      step="01:00"
                      end="24:00"
                      placeholder="选择时间"
                      :disabled="item.complete"
                    />
                  </el-form-item>
                  <el-form-item
                    prop="content"
                    style="margin-bottom: 0px"
                    label="计划内容："
                  >
                    <el-input
                      type="textarea"
                      :autosize="true"
                      :resize="none"
                      :disabled="item.complete"
                      v-model="item.content"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4" class="right-plusMinus">
                  <el-row>
                    <el-col :span="12">
                      <el-icon
                        v-if="formDate.tips.length - 1 == index"
                        class="add"
                        @click="addTips(index)"
                        ><Plus
                      /></el-icon>
                    </el-col>
                    <el-col :span="12">
                      <el-icon
                        @click="deleteTips(item, index)"
                        v-if="formDate.tips.length != 1 && !item.complete"
                        ><Minus
                      /></el-icon>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </el-form>
            <div class="status">
              <el-tag v-if="item.complete" type="success">已完成</el-tag>
              <el-tag v-else type="danger">未完成</el-tag>
            </div>
          </li>
        </ul>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormDate = false">取消</el-button>
          <el-button type="primary" @click="addDate">{{ goTitle }}</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 新增计划项目 -->
    <el-dialog
      v-model="dialogFormTips"
      :title="title"
      :append-to-body="true"
      z-index="100"
    >
      <el-form :model="formTips">
        <el-form-item label="时间节点：">
          <el-time-select
            class="time_sel"
            v-model="formTips.time"
            start="09:00"
            step="01:00"
            end="24:00"
            placeholder="选择时间"
          />
        </el-form-item>
        <el-form-item style="margin-bottom: 0px" label="计划内容：">
          <el-input
            type="textarea"
            :autosize="true"
            :resize="none"
            v-model="formTips.content"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormTips = false">取消</el-button>
          <el-button type="primary" @click="addTips">{{ goTitle }}</el-button>
        </span>
      </template>
    </el-dialog>

    <el-row gutter="24" class="content">
      <el-col
        class="cellItem"
        :span="12"
        v-for="(item, index) in dayList"
        :key="index"
      >
        <el-card class="box-card">
          <template #header>
            <div class="card-header">
              <span class="card-date">{{ item.schedule_date }}</span>
              <el-button
                @click="editTipsGo(item)"
                v-if="item.editRight"
                class="button"
                text
                round
                ><el-icon> <Edit /> </el-icon>编辑</el-button
              >
            </div>
          </template>
          <el-row v-for="(item, index) in item.tips" :key="index">
            <template v-if="item.complete">
              <el-col
                :span="16"
                style="text-decoration: line-through; color: #bebebe"
              >
                {{ item.time }} | {{ item.content }}
              </el-col>
              <el-col class="btns" :span="8"
                ><el-button size="small" type="success" round disabled
                  ><el-icon style="vertical-align: middle"> <Check /> </el-icon
                  >已完成</el-button
                ></el-col
              >
            </template>
            <template v-else>
              <el-col :span="16"> {{ item.time }} | {{ item.content }} </el-col>
              <el-col class="btns" :span="8">
                <el-button
                  @click="completeTips(item)"
                  size="small"
                  type="success"
                  round
                  ><el-icon style="vertical-align: middle"> <Check /> </el-icon
                  >完成</el-button
                >
                <el-button
                  @click="delTips(item)"
                  size="small"
                  type="danger"
                  v-if="item.editRight"
                  round
                  ><el-icon style="vertical-align: middle"> <Delete /> </el-icon
                  >删除</el-button
                >
              </el-col>
            </template>
          </el-row>
          <div class="footer-button">
            <span
              >创建人：{{ item.build_user }} | 创建时间：{{
                item.create_time
              }}</span
            >
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-pagination
      v-model:current-page="currentPage"
      v-model:page-size="pageSize"
      :page-sizes="[4, 10, 20, 30]"
      :small="false"
      :background="false"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pageTotal"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
import { ref, reactive, toRefs, onMounted } from "vue";
import {
  dayScheduleList,
  addDaySchedule,
  updateScheduleTips,
} from "@/network/daySchedule.js";
import { Delete, Edit, Plus, View, Check } from "@element-plus/icons-vue";
import { ElMessage, ElMessageBox } from "element-plus";
export default {
  components: {
    Delete,
    Edit,
    Plus,
    View,
    Check,
  },
  setup() {
    const pageSize = ref(4);
    const outerFormRef = ref();
    const innerFormRef = ref([]);
    const currentPage = ref(1);
    const pageTotal = ref(0);
    const state = reactive({
      title: "新增",
      goTitle: "添加",
      dialogFormDate: false,
      dialogFormTips: false,
      showDetails: false,
      dayList: [],
      dayTipsList: [],
    });
    const outerFormRule = {
      schedule_date: {
        required: true,
        message: "请选择计划日期",
        trigger: "blur",
      },
    };

    const innerFormRule = {
      time: {
        required: true,
        message: "请选择时间节点",
        trigger: ["change", "blur"],
      },
      content: {
        required: true,
        message: "请选择计划内容",
        trigger: "blur",
      },
    };
    const formDate = reactive({
      day_id: "",
      tips: [
        {
          tips_id: "",
          day_id: "",
          content: "",
          time: "",
          complete: 0,
        },
      ],
      schedule_date: "",
      summary: "",
    });
    const addTips = () => {
      const obj = {
        tips_id: "",
        day_id: "",
        content: "",
        time: "",
        complete: 0,
      };
      formDate.tips.push({ ...obj });
    };
    const handleSizeChange = (val) => {
      pageSize.value = val;
      getList();
    };
    const handleCurrentChange = (val) => {
      currentPage.value = val;
      getList();
    };
    // 删除
    const deleteTips = (item, index) => {
      if (item.tips_id) {
        delTips(item);
      }
      formDate.tips.splice(index, 1);
    };
    const formTips = reactive({
      tips_id: "",
      day_id: "",
      content: "",
      time: "",
    });
    const getList = () => {
      dayScheduleList({
        currentPage: currentPage.value,
        pageSize: pageSize.value,
        listType: 1,
      }).then((res) => {
        if (res.code == 0) {
          state.dayList = res.data.list;
          pageTotal.value = res.data.pageTotal;
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    onMounted(() => {
      getList();
    });
    //------------以上为列表数据
    //新增计划日期 弹窗
    const addDateGo = () => {
      state.dialogFormDate = true;
      const obj = {
        day_id: "",
        tips: [
          {
            tips_id: "",
            day_id: "",
            content: "",
            time: "",
            complete: 0,
          },
        ],
        schedule_date: "",
        summary: "",
      };
      Object.assign(formDate, { ...obj });
      state.title = "添加计划日期";
      state.goTitle = "添加";
    };
    // 编辑计划
    const editTipsGo = (item) => {
      state.dialogFormDate = true;
      state.goTitle = "更新";
      state.title = "修改计划日期";
      Object.assign(formDate, { ...item });
      formDate.schedule_date = item.schedule_date.split("/").join("-");
    };
    //新增/修改计划日期
    const addDate = async () => {
      if (!outerFormRef.value) return;
      await outerFormRef.value.validate(async (valid) => {
        if (valid) {
          if (innerFormRef.value.length) {
            let innervalidable = true;
            const tempAry = innerFormRef.value.filter((item) => {
              return item;
            });
            for (let i = 0; i < tempAry.length; i++) {
              await tempAry[i].validate((innervalid) => {
                if (!innervalid) {
                  innervalidable = false;
                  ElMessage.error("请填写必填项！");
                }
              });
              if (!innervalidable) {
                break;
              }
            }
            // 校验都通过
            if (innervalidable) {
              const res = await addDaySchedule({ ...formDate });
              if (res.code == 43) {
                ElMessage.success(res.msg);
                state.dialogFormDate = false;
                getList();
              } else {
                ElMessage.error(res.msg);
              }
            }
          }
        } else {
          ElMessage.error("请填写必填项！");
        }
      });
    };
    //新增/修改计划项目 弹窗
    const addTipsGo = (row) => {
      state.dialogFormTips = true;
      if (row.tips_id) {
        Object.assign(formTips, row);
        state.goTitle = "更新";
        state.title = "修改计划项目";
      } else {
        state.title = "添加计划项目";
        formTips.day_id = row.day_id;
      }
    };
    //------------以上为编辑和修改数据

    //设置计划项为删除状态
    const delTips = (row) => {
      if (!row.tips_id) {
        ElMessage("所选数据为空，请重新选择！");
      } else {
        //删除
        ElMessageBox.confirm("确定要删除该条数据吗?", "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            updateScheduleTips({ tips_id: row.tips_id, del_type: 0 }).then(
              (res) => {
                if (res.code == 0) {
                  ElMessage.success(res.msg);
                  getList();
                } else {
                  ElMessage.error(res.msg);
                }
              }
            );
          })
          .catch(() => {
            ElMessage({
              type: "info",
              message: "删除取消",
            });
          });
      }
    };
    //设置计划项为完成状态
    const completeTips = (row) => {
      if (!row.tips_id) {
        ElMessage("所选数据为空，请重新选择！");
      } else {
        //完成
        ElMessageBox.confirm("确定该项计划已经完成了吗?", "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            updateScheduleTips({ tips_id: row.tips_id, del_type: 1 }).then(
              (res) => {
                if (res.code == 0) {
                  ElMessage.success(res.msg);
                  getList();
                } else {
                  ElMessage.error(res.msg);
                }
              }
            );
          })
          .catch(() => {
            ElMessage({
              type: "info",
              message: "完成取消",
            });
          });
      }
    };
    return {
      ...toRefs(state),
      outerFormRef,
      innerFormRef,
      innerFormRule,
      outerFormRule,
      formDate,
      formTips,
      addDateGo,
      addTipsGo,
      delTips,
      completeTips,
      addDate,
      addTips,
      deleteTips,
      editTipsGo,
      currentPage,
      pageSize,
      pageTotal,
      handleSizeChange,
      handleCurrentChange,
    };
  },
};
</script>
<style scoped lang="scss">
.day_schedule {
  padding-bottom: 40px;
  box-sizing: border-box;
  height: 100%;
  text-align: right;
}
.formTipUl {
  max-height: 372px;
  overflow: auto;
  list-style: none;
  padding: 0;
  li {
    position: relative;
    padding: 20px;
    border: 1px dashed #ccc;
    margin-bottom: 10px;
    .right-plusMinus {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      .add {
        margin-right: 20px;
      }
      .el-icon:hover {
        color: var(--el-color-primary);
        cursor: pointer;
      }
    }
    .status {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }
}
.actionBtn {
  margin-bottom: 20px;
  margin-right: 20px;
}
.content {
  height: calc(100% - 80px);
  overflow: auto;
  .cellItem {
    margin-bottom: 20px;
    height: 302px;
  }
}
.el-pagination {
  float: right;
}
.el-row {
  margin-bottom: 20px;
}
.el-row:last-child {
  margin-bottom: 0;
}
.el-col {
  border-radius: 4px;
}
.el-card {
  height: 300px;
  position: relative;
}
::v-deep .el-card__body {
  text-align: left;
  // min-height: 50px;
  height: calc(100% - 103px);
  overflow: auto;
  box-sizing: border-box;
  padding: 15px;
  .footer-button {
    position: absolute;
    bottom: 10px;
    right: 26px;
  }
  .btns {
    text-align: right;
  }
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card-date {
  font-size: 18px;
}
.text {
  font-size: 14px;
}
.item {
  margin-bottom: 18px;
}

.footer-button {
  /* background-color: red; */
  text-align: right;
}
::v-deep .time_sel {
  .el-input__inner {
    padding-left: 32px !important;
  }
}
</style>
