<template>
  <div style="height: 100%">
    <div style="text-align: center; margin: 20px; font-size: 30px">
      付款单编码 {{ pay_no }}
    </div>
    <el-row>
      <el-col :span="24" style="text-align: right; margin-bottom: 20px">
        <el-button type="primary" size="middle" @click="editProduct"
          ><el-icon style="vertical-align: middle"> <EditPen /></el-icon>
          <span style="vertical-align: middle">编辑</span></el-button
        >
        <el-button type="primary" size="middle" @click="saveProduct"
          ><el-icon style="vertical-align: middle"> <Check /></el-icon>
          <span style="vertical-align: middle">保存</span></el-button
        >
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <isdTable
          show-summary
          :loading="tableLoading"
          :rows="totalData"
          :height="600"
          :columns="config.columns"
          :selection="config.selection"
          :summary-method="getSummaries"
          :actions="config.actions"
          @action-click="actionClick"
          style="font-size: 12px"
        >
          <template v-slot:col-append="{ col, row }">
            <div v-if="col.type === 'amount'">
              <span v-if="!isEdit"> {{ row[col.prop] }}</span>
              <el-input v-else v-model="row[col.prop]"></el-input>
            </div>
            <div v-else-if="col.type === 'remark'">
              <span v-if="!isEdit"> {{ row[col.prop] }}</span>
              <el-input v-else v-model="row[col.prop]"></el-input>
            </div>
          </template>
        </isdTable>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <isdPagination
          @size-change="sizeChange"
          :current-page="currentPage"
          @current-change="pageChange"
          layout="total,sizes,prev,pager,next,jumper"
          :page-size="pageSize"
          :page-sizes="[20, 50, 100]"
          :total="pageTotal"
        ></isdPagination>
      </el-col>
    </el-row>
    <!-- 新增/编辑弹窗 -->
    <isdFormDialog
      ref="formDialogIns"
      :config="config.forms"
      :loading="formDialog.loading"
      :label-width="formDialog.labelWidth"
      :row="formDialog.row"
      v-model="formDialog.show"
      :col="formDialog.col"
      :title="formDialog.title"
      :width="formDialog.width"
      @close-dialog="closeFormDialog"
    ></isdFormDialog>
  </div>
</template>
<script>
import { ref, reactive, onMounted } from "vue";
import { useRoute, useRouter, route } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import { EditPen, Check } from "@element-plus/icons-vue";
import {
  getPaymentDetailList,
  addPaymentDetails,
  delPaymentDetails,
} from "@/network/finance.js";
import isdFormDialog from "@/views/components/isdFormDialog/Index.vue";
import isdTable from "@/views/components/isdTable/index.vue";
import configData from "./config";
import { ListClass } from "@/composition/ListClass.js";
import isdPagination from "@/views/components/isdPage/index.vue";
export default {
  components: {
    EditPen,
    Check,
    isdFormDialog,
    isdTable,
    isdPagination,
  },
  setup(props, context) {
    const isEdit = ref(false);
    const formDialogIns = ref(null);
    const config = reactive(configData);
    // 新建/修改弹窗对象
    const formDialog = reactive({
      labelWidth: "90px", // 弹窗label宽度
      loading: false, // 表单加载中显示
      row: {}, // 数据项对象
      show: false, // 显示弹窗
      col: 1, // 弹窗内列数
      title: "", // 弹窗标题
      width: "450px", // 弹窗宽度
    });
    const route = ref(useRoute());
    const pay_id = ref(null);
    const pay_no = ref(null);
    //选中的应付款ID或者修改更新时，传过来的参数（upt+发票id）
    const payInfo = route.value.query.arrData;
    class TableList extends ListClass {
      beforeGetList(condition) {
        condition.pay_id = pay_id.value;
        // condition.pay_no = pay_no.value;
        // condition.payInfo = payInfo; //把勾选的应付款id也传过去
        return condition;
      }
    }
    const {
      currentPage,
      pageSize,
      pageTotal,
      tableLoading,
      totalData,
      getList,
      searchList,
      pageChange,
      sizeChange,
      handleSelectedRow,
    } = new TableList({
      Api: {
        listApi: getPaymentDetailList,
      },
    });
    const getPaymentDetails = () => {
      getList();
    };
    const editProduct = () => {
      isEdit.value = true;
    };
    // 删除
    const removeProduct = (row, index) => {
      if (!row.id) {
        totalData.value.splice(index, 1);
      } else {
        del(row);
      }
    };
    // 保存
    const saveProduct = () => {
      isEdit.value = false;
      addPaymentDetails({
        data: totalData.value,
      }).then((res) => {
        if (res.code == 0) {
          Object.assign(payInfo, res.data); // 获取后台保存后返回的数据
          getList();
          ElMessage.success(res.msg);
        } else if (res.code == 71) {
          ElMessage.error(res.msg);
          getList();
        } else {
          ElMessage.error(res.msg);
          getList();
        }
      });
    };
    // 编辑删除事件
    const actionClick = (event, row, rIndex) => {
      switch (event) {
        case "del":
          removeProduct(row, rIndex);
          break;
      }
    };
    const del = (row) => {
      if (!row.id) {
        ElMessage("所选数据为空，请重新选择！");
      } else {
        ElMessageBox.confirm("确定要删除该条数据吗?", "警告", {
          confirmButtonText: "删除",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            delPaymentDetails({
              detailsInfo: row,
              del_details_type: 0,
              del_list_type: 1,
            }).then((res) => {
              if (res.code == 0) {
                ElMessage.success(res.msg);
                getList();
              } else {
                ElMessage.error(res.msg);
              }
            });
          })
          .catch(() => {
            ElMessage({
              type: "info",
              message: "删除取消",
            });
          });
      }
    };
    const getSummaries = (param) => {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index == 0) {
          sums[0] = "合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (
          !values.every((value) => isNaN(value)) &&
          [2, 3, 4, 5].includes(index)
        ) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        } else {
          sums[index] = "";
        }
      });
      return sums;
    };
    const changeTotal = (val1, val2, row) => {
      if (isNaN(val1) || isNaN(val2)) {
        row.total = 0;
      } else {
        row.total = val1 * val2;
      }
    };
    const closeAction = () => {};
    // 新增弹窗关闭
    const closeFormDialog = (params) => {};
    return {
      changeTotal,
      closeFormDialog,
      formDialog,
      config,
      actionClick,
      currentPage,
      pageSize,
      pageTotal,
      tableLoading,
      totalData,
      getList,
      searchList,
      pageChange,
      sizeChange,
      handleSelectedRow,
      // dataChange,
      formDialogIns,
      getPaymentDetails,
      pay_id,
      pay_no,
      isEdit,
      editProduct,
      saveProduct,
      //changeFormData,
      del,
      getSummaries,
    };
  },
};
</script>
