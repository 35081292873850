<template>
  <div class="order-detail-contain">
    <el-tabs v-model="activeName" @tab-change="handleClick">
      <el-tab-pane label="发票基本信息" name="first">
        <createList @getInvoiceInfo="getInvoiceInfo"></createList>
      </el-tab-pane>
      <el-tab-pane label="发票详情信息" name="second" :disabled="secondAble">
        <createDetails
          ref="detailInfo"
          :createListInfo="createListInfo"
        ></createDetails>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { computed, ref } from "vue";
import createList from "./createList.vue";
import createDetails from "./createDetails.vue";
import { useRoute } from "vue-router";
export default {
  name: "createInvoice",
  components: {
    createDetails,
    createList,
  },
  setup() {
    const inv_id = ref("");
    const inv_no = ref("");
    const detailInfo = ref(null);
    const route = ref(useRoute());
    const secondAble = computed(() => {
      let flag = route.value.query.arrData[0] == "udt" || inv_id.value;
      return !flag;
    });
    const createListInfo = ref(null);
    const activeName = ref("first");
    const handleClick = (TabPanelName) => {
      if (TabPanelName == "second") {
        detailInfo.value.getInvoiceDetails();
      }
    };
    const getInvoiceInfo = (val) => {
      const obj = detailInfo.value;
      obj.inv_id = val.inv_id;
      obj.inv_no = val.inv_no;
      activeName.value = "second";
      obj.getInvoiceDetails();
    };
    return {
      activeName,
      handleClick,
      getInvoiceInfo,
      createListInfo,
      secondAble,
      detailInfo,
    };
  },
};
</script>
<style scoped>
.order-detail-contain {
  padding: 20px 0;
}
</style>
