export default {
  selection: true,
  searchFields: [
    // 搜索组件的配置项
    {
      name: "采购单号",
      value: "pur_no",
      type: "input",
      isFixed: true,
      span: 4,
    },
    {
      name: "采购单备注",
      value: "remark",
      type: "input",
      isFixed: true,
      span: 4,
    },
  ],
  columns: [
    // 表格列的配置
    {
      label: "采购单号",
      prop: "pur_no",
    },
    {
      label: "相关订单",
      prop: "ord_no",
    },
    {
      label: "供应商",
      prop: "spr_id",
    },
    {
      label: "采购类型",
      prop: "pur_type",
    },
    {
      label: "总采购数",
      prop: "num_total",
    },
    {
      label: "已入库数",
      prop: "store_num_total",
    },
    {
      label: "待入库数",
      prop: "wait_store",
    },
    {
      label: "总采金额",
      prop: "amount",
    },
    {
      label: "下单时间",
      prop: "order_time",
    },
    {
      label: "预计到货",
      prop: "expected_time",
    },
    {
      label: "创建人",
      prop: "user_id",
    },
    {
      label: "备注",
      prop: "remark",
    },
    {
      label: "操作",
      type: "operate_btn",
      prop: "",
      width: "260px",
    },
  ],
  forms: [
    // 表单页面的配置
    {
      label: "采购员:",
      prop: "user_id",
      type: "select",
      options: [],
      filterable: true,
      rule: [
        { required: true, message: "选择分配的业务员", trigger: "change" },
      ],
    },
  ],
};
