
const AN_HOUR_SECOND = 3600;
const ONE_MONTH = 30;
const THREE_MONTH = 90;
export default {
    data() {
        return {
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    value: () => {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - AN_HOUR_SECOND * 1000 * 24 * 7);
                        return [start, end];
                    }
                }, {
                    text: '最近一个月',
                    value: () => {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - AN_HOUR_SECOND * 1000 * 24 * ONE_MONTH);
                        return [start, end];
                    }
                }, {
                    text: '最近三个月',
                    value: () => {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - AN_HOUR_SECOND * 1000 * 24 * THREE_MONTH);
                        return [start, end];
                    }
                }]
            }
        };
    }
};
