<template>
  <div style="height: 100%">
    <el-form :model="formState" :rules="rules">
      <el-form-item
        label="账户："
        :label-width="formLabelWidth"
        prop="user_email"
      >
        <el-input
          v-model="formState.user_email"
          placeholder="输入账号：请用邮箱"
          clearable
        />
      </el-form-item>
      <el-form-item label="密码：" :label-width="formLabelWidth">
        <el-input
          v-model="formState.user_psw"
          type="password"
          :placeholder="pswMsg"
          clearable
        />
      </el-form-item>
      <el-form-item
        label="中文名："
        :label-width="formLabelWidth"
        prop="user_name_ch"
      >
        <el-input
          v-model="formState.user_name_ch"
          placeholder="输入中文名"
          clearable
        />
      </el-form-item>
      <el-form-item label="英文名：" :label-width="formLabelWidth">
        <el-input
          v-model="formState.user_name_en"
          placeholder="输入英文名"
          clearable
        />
      </el-form-item>
      <el-form-item
        label="所属部门："
        :label-width="formLabelWidth"
        prop="user_group_id"
      >
        <el-select
          v-model="formState.user_group_id"
          :popper-append-to-body="false"
        >
          <el-option
            v-for="item in getGroupList"
            :key="item.user_group_id"
            :label="item.user_group_name_ch"
            :value="item.user_group_id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="业务等级：" :label-width="formLabelWidth" prop="id">
        <el-select v-model="formState.id" :popper-append-to-body="false">
          <el-option
            v-for="item in getLevelList"
            :key="item.id"
            :label="item.level_name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="入职时间：" :label-width="formLabelWidth">
        <el-date-picker
          type="date"
          v-model="formState.entry_time"
          format="YYYY/MM/DD"
          value-format="YYYY-MM-DD"
        />
      </el-form-item>
      <el-form-item
        label="手机："
        :label-width="formLabelWidth"
        prop="user_phone"
      >
        <el-input v-model="formState.user_phone" clearable />
      </el-form-item>
      <el-form-item
        label="身份证："
        :label-width="formLabelWidth"
        prop="user_id_card"
      >
        <el-input v-model="formState.user_id_card" clearable />
      </el-form-item>
      <el-form-item label="性别：" :label-width="formLabelWidth">
        <el-select
          v-model="formState.user_gender"
          :popper-append-to-body="false"
        >
          <el-option
            v-for="item in sexList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>

      <el-form-item label="工资卡号：" :label-width="formLabelWidth">
        <el-input v-model="formState.user_bank_card" clearable />
      </el-form-item>

      <el-form-item label="居住地址：" :label-width="formLabelWidth">
        <el-input type="textarea" v-model="formState.user_add" />
      </el-form-item>

      <el-form-item label="用户生日：" :label-width="formLabelWidth">
        <el-date-picker
          type="date"
          v-model="formState.user_birthday"
          format="YYYY/MM/DD"
          value-format="YYYY-MM-DD"
        />
      </el-form-item>

      <el-form-item label="生日类型：" :label-width="formLabelWidth">
        <el-switch
          v-model="formState.user_birthday_type"
          class="ml-2"
          active-color="#660066"
          inactive-color="#13ce66"
          active-text="公历"
          inactive-text="农历"
        />
      </el-form-item>

      <el-form-item
        label="个人手机："
        :label-width="formLabelWidth"
        prop="user_self_phone"
      >
        <el-input v-model="formState.user_self_phone" clearable />
      </el-form-item>

      <el-form-item label="紧急联系人：" :label-width="formLabelWidth">
        <el-input v-model="formState.user_emergency_person" clearable />
      </el-form-item>

      <el-form-item
        label="紧急联系号："
        :label-width="formLabelWidth"
        prop="user_emergency_phone"
      >
        <el-input v-model="formState.user_emergency_phone" clearable />
      </el-form-item>

      <el-form-item label="状态：" :label-width="formLabelWidth">
        <el-switch
          v-model="formState.user_status"
          class="ml-2"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-text="开启"
          inactive-text="关闭"
        />
      </el-form-item>
      <el-form-item label="备注：" :label-width="formLabelWidth">
        <el-input type="textarea" v-model="formState.user_remark" />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { ref, reactive, toRefs, onMounted } from "vue";
import {
  userInfo,
  userAdd,
  getGroupList,
  getLevelList,
} from "@/network/user.js";
import { Delete, Edit, Plus } from "@element-plus/icons-vue";
import { ElMessage, ElMessageBox } from "element-plus";

export default {
  components: {
    Delete,
    Edit,
    Plus,
  },
  setup() {
    const sexList = reactive([
      { label: "男", value: 1 },
      { label: "女", value: 0 },
    ]);
    const state = reactive({
      dataSource: [],
      title: "添加用户",
      pswMsg: "请输入密码",
      goTitle: "添加",
      getLevelList: [],
      getGroupList: [],
      user_id: 1,
    });
    const rules = reactive({
      user_email: [
        {
          required: true,
          message: "请输入邮箱号码",
          trigger: "blur",
        },
      ],
      user_name_ch: [
        {
          required: true,
          message: "请输入中文名",
          trigger: "blur",
        },
      ],
      user_group_id: [
        {
          required: true,
          message: "请选择用户所属部门",
          trigger: "change",
        },
      ],
      id: [
        {
          required: true,
          message: "请选择用户所属业务层级",
          trigger: "change",
        },
      ],
      user_phone: [
        {
          required: true,
          message: "请输入分配员工的手机号码",
          trigger: "blur",
        },
        { min: 11, max: 15, message: "长度为11-15位手机号", trigger: "blur" },
      ],
      user_id_card: [
        {
          required: false,
          message: "请输入员工的身份证",
          trigger: "blur",
        },
        { min: 18, max: 18, message: "身份证长度为18位", trigger: "blur" },
      ],
      user_self_phone: [
        {
          required: false,
          message: "请输入分配员工的手机号码",
          trigger: "blur",
        },
        { min: 11, max: 15, message: "长度为11-15位手机号", trigger: "blur" },
      ],
      user_emergency_phone: [
        {
          required: false,
          message: "请输入分配员工的手机号码",
          trigger: "blur",
        },
        { min: 11, max: 15, message: "长度为11-15位手机号", trigger: "blur" },
      ],
    });
    const formState = reactive({
      user_id: "",
      user_group_id: 1,
      user_name_ch: "",
      user_name_en: "",
      id: 1,
      user_email: "",
      user_psw: "",
      user_phone: "",
      user_id_card: "",
      user_gender: 1,
      user_bank_card: "",
      user_add: "",
      user_birthday: "",
      user_birthday_type: true,
      user_self_phone: "",
      user_emergency_person: "",
      user_emergency_phone: "",
      entry_time: "",
      user_status: true,
      user_remark: "",
    });

    //返回业务等级列表
    getLevelList().then((res) => {
      if (res.code == 0) {
        state.getLevelList = res.data;
      } else {
        ElMessage.error(res.msg);
      }
    });
    //返回部门列表
    getGroupList().then((res) => {
      if (res.code == 0) {
        state.getGroupList = res.data;
      } else {
        ElMessage.error(res.msg);
      }
    });
    userInfo({ use_id: state.user_id }).then((res) => {
      Object.assign(formState, res);
      formState.user_psw = ""; //默认将密码置空
      formState.user_birthday_type = Boolean(row.user_birthday_type);
      formState.user_status = Boolean(row.user_status);
    });
    const add = () => {
      //无论新增还是修改，用户名都是必填;
      if (!formState.user_email) {
        ElMessage.info("请输入账号");
        return false;
      }
      userAdd(formState).then((res) => {
        if (res.code == 0) {
          ElMessage.success(res.msg);
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    return {
      ...toRefs(state),
      rules,
      formState,
      add,
      sexList,
    };
  },
};
</script>
