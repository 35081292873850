export default {
  selection: true,
  searchFields: [
    // 搜索组件的配置项
    {
      name: "公司名字",
      value: "spr_comp_name",
      type: "input",
      isFixed: true,
      span: 4,
    },
    {
      name: "联系人",
      value: "contact_person",
      type: "input",
      isFixed: true,
      span: 4,
    },
    {
      name: "供应商主营描述",
      value: "spr_dep",
      type: "input",
      isFixed: true,
      span: 4,
    },
  ],
  columns: [
    // 表格列的配置
    {
      label: "公司名字",
      prop: "spr_comp_name",
    },
    {
      label: "分类",
      prop: "spr_group_name_ch",
    },
    {
      label: "供应商类型",
      prop: "spr_type",
      type: "select",
      options: { 1: "供应商", 2: "服务商" },
    },
    {
      label: "联系人",
      prop: "contact_person",
    },
    {
      label: "联系电话",
      prop: "spr_phone",
    },
    {
      label: "公司对接人",
      prop: "user_name_ch",
    },
    {
      label: "创建时间",
      prop: "create_time",
    },
    {
      label: "状态",
      prop: "spr_status",
      type: "select",
      options: { 1: "有效", 0: "关闭" },
    },
    {
      label: "供应商简介",
      prop: "spr_dep",
    },
    {
      label: "备注",
      prop: "spr_remark",
      mustFill: true,
    },
    {
      label: "操作",
      type: "operate_btn",
      prop: "",
      width: "180px",
    },
  ],
  forms: [
    // 表单页面的配置
    {
      label: "公司名字:",
      prop: "spr_comp_name",
      type: "input",
      span: 11,
      rule: [
        { required: true, message: "输入供应商公司名称", trigger: "change" },
      ],
    },
    {
      label: "联系人:",
      prop: "contact_person",
      type: "input",
      span: 11,
      rule: [
        {
          required: true,
          message: "输入供应商联系人",
          trigger: "blur",
        },
      ],
    },
    {
      label: "所属分组:",
      prop: "spr_group_id",
      type: "select",
      span: 11,
      options: [],
      filterable: true,
      rule: [
        { required: true, message: "请选择供应商分组", trigger: "change" },
      ],
    },
    {
      label: "对接人:",
      prop: "user_id",
      type: "select",
      options: [],
      span: 11,
      filterable: true,
      rule: [
        { required: true, message: "请选择公司对接人", trigger: "change" },
      ],
    },
    {
      label: "供应商类型",
      prop: "spr_type",
      type: "select",
      options: { 1: "供应商", 2: "服务商" },
      span: 11,
      rule: [
        { required: true, message: "请选择供应商类型", trigger: "change" },
      ],
    },
    {
      label: "电话号码:",
      prop: "spr_phone",
      type: "input",
      span: 11,
      rule: [
        {
          required: true,
          message: "输入供应商联系电话",
          trigger: "blur",
        },
      ],
    },
    {
      label: "公司地址:",
      prop: "spr_add",
      type: "input",
      span: 22,
      inputType: "textarea",
      rule: [
        {
          required: true,
          message: "输入供应商地址信息（档口号）",
          trigger: "blur",
        },
      ],
    },
    {
      label: "状态:",
      prop: "spr_status",
      type: "switch",
      modelValue: "1",
      activeText: "是",
      inactiveText: "否",
      rule: [{ required: true }],
    },
    {
      label: "微信:",
      prop: "spr_wechat",
      type: "input",
      span: 11,
      placeholder: "输入供应商微信",
    },
    {
      label: "QQ号:",
      prop: "spr_qq",
      type: "input",
      span: 11,
      placeholder: "请输入QQ号",
    },
    {
      label: "邮箱:",
      prop: "spr_email",
      type: "input",
      span: 11,
      placeholder: "输入供应商邮箱",
    },
    {
      label: "供应商网址:",
      prop: "spr_web",
      type: "input",
      span: 11,
      placeholder: "输入供应商网址（如果有）",
    },
    {
      label: "供应商网站账号:",
      prop: "spr_web_account",
      type: "input",
      span: 11,
      placeholder: "输入供应商网站账号（如果有）",
    },
    {
      label: "供应商网站密码:",
      prop: "spr_web_psw",
      type: "input",
      span: 11,
      placeholder: "输入供应商网站密码（如果有）",
    },
    {
      label: "ISI账号:",
      prop: "spr_account",
      type: "input",
      span: 11,
      placeholder: "输入供应商ISIDUN账号",
    },
    {
      label: "ISI密码:",
      prop: "spr_psw",
      type: "input",
      span: 11,
      placeholder: "输入供应商ISIDUN密码",
    },
    {
      label: "供应商描述:",
      prop: "spr_dep",
      type: "input",
      inputType: "textarea",
      placeholder: "输入供应商主营产品介绍",
      span: 24,
    },
    {
      label: "备注:",
      prop: "spr_remark",
      type: "input",
      inputType: "textarea",
      span: 24,
    },
  ],
};
