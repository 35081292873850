<template>
  <lots-tag-popover
    :isCanEditOrAdd="isCanEditOrAdd"
    :hasSelectedUniqueIdentification="hasSelectedUniqueIdentification"
    :checkTagList="checkTagList"
    :tagsConfig="config.tagsConfig"
    :trigger="config.trigger"
    @check-tag="
      (tag, list) => {
        handleCheckTag(config.value, tag, list);
      }
    "
  >
    <template v-slot:reference>
      <el-input
        readonly
        class="tag-input"
        :disabled="config.disabled || false"
        ref="tagInput"
        :placeholder="getTagPlaceholder(checkTagList)"
      >
        <template #suffix>
          <i
            class="el-input__icon el-icon-circle-close tag-input-close"
            v-show="getTagListLength(checkTagList)"
            @click="handleTagInputClose(config.value)"
          >
          </i>
        </template>
        <template #prefix>
          <el-tag
            v-for="tag in config.collapseTags &&
            getTagListLengthGreaterThanOne(checkTagList)
              ? checkTagList.slice(0, 1)
              : checkTagList"
            :key="tag.id"
            :color="tag.color"
            :closable="config.closable"
            @close.stop="handleCloseTag(config.value, tag)"
            class="tag-item"
            :style="`color:${tag.iconColor};`"
          >
            <el-tooltip
              effect="dark"
              :content="tag.labelName"
              placement="top-start"
            >
              <span>{{ tag.labelName }}</span>
            </el-tooltip>
          </el-tag>
          <el-tag
            v-if="
              config.collapseTags &&
              getTagListLengthGreaterThanOne(checkTagList)
            "
          >
            {{ `+${checkTagList.length - 1}` }}
          </el-tag>
        </template>
      </el-input>
    </template>
  </lots-tag-popover>
</template>
<script>
import lotsTagPopover from "../popover/index";
export default {
  name: "lotsTagInput",
  emits: ["handleTagInputClose", "handleCloseTag", "handleCheckTag"],
  components: { lotsTagPopover },
  props: {
    isCanEditOrAdd: {
      type: Boolean,
      default: false,
    },
    hasSelectedUniqueIdentification: {
      type: String,
      default: "id",
    },
    checkTagList: {
      type: Array,
      default() {
        return [];
      },
    },
    config: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    handleTagInputClose(field) {
      this.$emit("handleTagInputClose", field);
    },
    handleCloseTag(field, tag) {
      this.$emit("handleCloseTag", field, tag);
    },
    handleCheckTag(field, value, list) {
      this.$emit("handleCheckTag", field, value, list);
    },
    handleTagInputPrefixClick() {
      if (this.$refs.tagInput) {
        this.input = this.$refs.tagInput.input;
        this.input.nextElementSibling.addEventListener(
          "click",
          this.tagInputBind
        );
      }
    },
    tagInputBind() {
      this.input && this.input.click();
    },
  },
  computed: {
    // 设置tag内input的Placeholder
    getTagPlaceholder() {
      return (tagList) => {
        return this.getTagListLength(tagList) ? "" : "选择标签";
      };
    },
    // 判断tag列表是否存在
    getTagListLength() {
      return (tagList) => {
        return tagList && tagList.length;
      };
    },
    // 判断tag列表是否大于1，用于折叠显示成【+1】
    getTagListLengthGreaterThanOne() {
      return (tagList) => {
        return tagList && tagList.length > 1;
      };
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.handleTagInputPrefixClick();
    });
  },
  unmounted() {
    this.input &&
      this.input.nextElementSibling.removeEventListener(
        "click",
        this.tagInputBind
      );
  },
};
</script>
<style lang="scss">
.tag-input {
  overflow: hidden;
  .tag-input-close {
    cursor: pointer;
  }
  .tag-item {
    max-width: 77%;
    margin-right: 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    vertical-align: middle;
    box-sizing: border-box;
    padding-right: 18px;
    position: relative;
    .el-tag__close {
      position: absolute;
      right: 3px;
      top: 1px;
    }
  }
  .el-input__inner {
    padding-left: 15px;
  }
  .el-input__prefix {
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    width: 82%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
  }
  .el-input__prefix::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  .el-input__prefix::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1);
    background-clip: padding-box;
    min-height: 28px;
    border-radius: 2em;
  }
}
</style>
