<template>
  <div class="model-table-search btn-bg">
    <el-row class="search-box">
      <el-col
        v-for="(item, index) in fixedList"
        :key="index"
        :span="item.span"
        :class="{ 'is-fussy': item.isFussy }"
      >
        <div class="search">
          <el-tooltip
            v-if="item.type === 'input'"
            effect="dark"
            :content="item.tips"
            :disabled="!item.tips"
            placement="top"
          >
            <el-input
              :placeholder="item.name"
              :disabled="lodash.get(item, 'disabled', false)"
              :clearable="lodash.get(item, 'clearable', true)"
              v-model="searchData[item.value]"
              @keyup.enter="onSearchChange()"
              @input="fieldChange(item.value, $event)"
            >
            </el-input>
          </el-tooltip>
          <el-tooltip
            v-else-if="item.type === 'select'"
            effect="dark"
            :content="item.tips"
            :disabled="!item.tips"
            placement="top"
          >
            <span>
              <el-select
                class="lots-select"
                :clearable="lodash.get(item, 'clearable', true)"
                :filterable="lodash.get(item, 'filterable', true)"
                :disabled="lodash.get(item, 'disabled', false)"
                v-model="searchData[item.value]"
                :multiple="item.multiple ? item.multiple : false"
                :placeholder="item.name"
                collapse-tags
                @change="fieldChange(item.value, $event)"
                @keyup.enter="onSearchChange()"
              >
                <el-option
                  v-for="(val, index) in item.options"
                  :key="index"
                  :disabled="val.disabled"
                  :label="val.label"
                  :value="val.value"
                >
                </el-option>
              </el-select>
            </span>
          </el-tooltip>
          <el-tooltip
            v-else-if="item.type === 'cascader'"
            effect="dark"
            :content="item.tips"
            :disabled="!item.tips"
            placement="top"
          >
            <el-cascader
              @change="fieldChange(item.value, $event)"
              v-model="searchData[item.value]"
              :disabled="lodash.get(item, 'disabled', false)"
              :options="item.options"
              collapse-tags
              :filterable="item.filterable"
              :change-on-select="item.changeOnSelect"
              :placeholder="item.name"
            >
            </el-cascader>
          </el-tooltip>
          <el-tooltip
            v-else-if="
              item.type === 'datetimerange' || item.type === 'daterange'
            "
            effect="dark"
            :content="item.tips"
            :disabled="!item.tips"
            placement="top"
          >
            <span>
              <el-date-picker
                @change="fieldChange(item.value, $event)"
                class="text-box"
                v-model="searchData[item.value]"
                :disabledDate="disabledDate"
                :disabled="lodash.get(item, 'disabled', false)"
                :clearable="lodash.get(item, 'clearable', true)"
                :format="
                  item.type === 'datetimerange'
                    ? 'YYYY-MM-DD HH:mm:ss'
                    : 'YYYY-MM-DD'
                "
                :shortcuts="
                  lodash.get(item, 'shortcuts') || pickerOptions.shortcuts
                "
                :type="item.type"
                unlink-panels
                :range-separator="'至'"
                :start-placeholder="
                  item.startPlaceholder ? item.startPlaceholder : '开始日期'
                "
                :end-placeholder="
                  item.endPlaceholder ? item.endPlaceholder : '结束日期'
                "
                :default-time="[
                  new Date(2000, 1, 1, 0, 0, 0),
                  new Date(2000, 1, 1, 23, 59, 59),
                ]"
              >
              </el-date-picker>
            </span>
          </el-tooltip>
          <el-tooltip
            v-else-if="
              ['date', 'week', 'month', 'dates', 'year'].includes(item.type)
            "
            effect="dark"
            :content="item.tips"
            :disabled="!item.tips"
            placement="top"
          >
            <span>
              <el-date-picker
                @change="fieldChange(item.value, $event)"
                class="text-box"
                v-model="searchData[item.value]"
                :disabled="lodash.get(item, 'disabled', false)"
                :clearable="lodash.get(item, 'clearable', true)"
                :format="item.format"
                :value-format="item.valueFormat"
                :type="item.type"
                unlink-panels
                :placeholder="item.placeholder ? item.placeholder : '选择日期'"
              >
              </el-date-picker>
            </span>
          </el-tooltip>
          <el-tooltip
            v-else-if="item.type === 'advance'"
            effect="dark"
            :content="item.tips"
            :disabled="!item.tips"
            placement="top"
          >
            <basic-data-input
              :label="searchData[item.value]"
              :config="item"
              :disabled="lodash.get(item, 'disabled', false)"
              :cascadeData="searchData"
              @change="handleBasicDataInputChange(item.value, $event)"
            ></basic-data-input>
          </el-tooltip>
          <el-tooltip
            v-else-if="item.type === 'advanceMultiple'"
            effect="dark"
            :content="item.tips"
            :disabled="!item.tips"
            placement="top"
          >
            <basic-data-multi
              :params="item"
              :disabled="lodash.get(item, 'disabled', false)"
              v-model="searchData[item.value]"
              @change="handleBasicDataMultiChange(item.value, $event)"
            ></basic-data-multi>
          </el-tooltip>
          <el-tooltip
            v-else-if="item.type === 'lotsBasicData'"
            effect="dark"
            :content="item.tips"
            :disabled="!item.tips"
            placement="top"
          >
            <span>
              <basic-data-selector
                class="basic-data-selector"
                @change="
                  (cbData, originData) =>
                    fieldChange(item.value, cbData, originData)
                "
                :config="item.config"
                :disabled="lodash.get(item, 'disabled', false)"
                v-model="searchData[item.value]"
              ></basic-data-selector>
            </span>
          </el-tooltip>
          <!-- <el-input v-else-if="item.type === 'advance'"
                        class="advance-input"
                        
                        :placeholder="item.name"
                        readonly
                        v-model="searchData[item.value]">
                        <el-button slot="append"
                            @click="detailSearch(index, item, 1, searchData[item.value])"
                            icon="el-icon-search"></el-button>
                        <em slot="suffix" @click="resetAdvanceParams(item.value)"
                            class="el-input__icon el-icon-circle-close" v-if="searchData[item.value]"></em>
                    </el-input> -->
          <el-tooltip
            v-else-if="item.type === 'addressSelect'"
            effect="dark"
            :content="item.tips"
            :disabled="!item.tips"
            placement="top"
          >
            <addressSelect
              @input="
                (val) => {
                  fieldChange(item.value, val);
                }
              "
              v-model="searchData[item.value]"
              :value="searchData[item.value]"
              :minLayers="item.minLayers"
              :disabled="lodash.get(item, 'disabled', false)"
              :placeholder="item.name"
              :basicProps="item.config"
              :tabsAddress="item.tabsAddress"
              :showNoChoice="item.showNoChoice"
              :hotData="item.hotData"
            >
            </addressSelect>
          </el-tooltip>
          <el-tooltip
            v-else-if="item.type === 'tag'"
            effect="dark"
            :content="item.tips"
            :disabled="!item.tips"
            placement="top"
          >
            <span>
              <lots-tag-input
                :config="item"
                :checkTagList="searchData[item.value]"
                @handleTagInputClose="handleTagInputClose"
                @handleCloseTag="handleCloseTag"
                @handleCheckTag="handleCheckTag"
              >
              </lots-tag-input>
            </span>
          </el-tooltip>
        </div>
        <span class="input-append-fussy" v-if="item.isFussy">
          <el-checkbox v-model="searchData[item.value + 'Fussy']">
            {{ "模糊" }}</el-checkbox
          >
        </span>
      </el-col>
      <el-col :span="4" style="width: 120px">
        <el-button
          class="search-btn"
          type="primary"
          @click="onSearchChange()"
          :loading="loading"
          :icon="Search"
        >
          {{ "搜索" }}
        </el-button>
        <advance
          class="advanced-btn"
          v-if="showAdvanceBtn"
          :search-key="searchKey"
          :initialVal="searchData"
          :fields="fields"
          :loading="loading"
          :cacheIdentifer="cacheIdentifer"
          :advancedWidth="advancedWidth"
          :tenantCode="tenantCode"
          @reset="reset"
          :isShowAllFields="isShowAllFields"
          @search="onSearchChange"
          :keyMaps="keyMap"
          @field-change="fieldChange"
        ></advance>
      </el-col>
    </el-row>
    <basic-data-dialog
      ref="advanceDialog"
      @confirm="detailSearchSure"
    ></basic-data-dialog>
  </div>
</template>

<script>
import { Delete, Edit, Search, Share, Upload } from "@element-plus/icons-vue";
import lodash from "lodash";
import Advance from "./Advance";
import addressSelect from "../lotsAddressCascader";
import lotsButton from "../lotsButton";
import basicDataDialog from "../lotsBasicDataDialog";
import basicDataInput from "../lotsBasicDataInput";
import basicDataSelector from "../lotsBasicDataSelector/index";
import basicDataMulti from "../lotsBasicDataMulti";
import lotsTagInput from "../lotsTag/input";
import advanceMixin from "./AdvanceMixin";
export default {
  name: "isdSearchBox",
  mixins: [advanceMixin],
  components: {
    Advance,
    addressSelect,
    lotsButton,
    basicDataDialog,
    basicDataInput,
    basicDataSelector,
    basicDataMulti,
    lotsTagInput,
  },
  props: {
    isShowAllFields: {
      type: Boolean,
      default: false,
    },
    searchKey: String,
    fields: {
      type: Array,
      default() {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    cacheIdentifer: {
      type: String,
      default() {
        return "";
      },
    },
    advancedWidth: {
      type: [String, Number],
      default() {
        return "850";
      },
    },
    disabledDate: {
      type: Function,
      default: () => {
        return false;
      },
    },
    tenantCode: {
      type: String,
      default: "",
    },
  },
  // 定义抛出的事件名称
  emits: ["search-change", "field-change"],
  data() {
    return {
      searchData: {},
      keyMap: {},
      Search,
    };
  },
  watch: {
    fields: {
      handler() {},
      deep: true,
    },
  },
  computed: {
    lodash() {
      return lodash;
    },
    fixedList() {
      // 默认查询的列表
      return this.fields.filter((o) => o.isFixed);
    },
    showAdvanceBtn() {
      // 如果固定的搜索项小于全部的搜索项，则显示高级按钮
      return this.fixedList.length < this.fields.length;
    },
  },
  methods: {
    handleTagInputClose(field) {
      this.searchData[field] = [];
    },
    handleCloseTag(field, tag) {
      const checkIndex = this.searchData[field].findIndex((item) => {
        return item.id ? item.id === tag.id : item === tag.id;
      });
      if (checkIndex === -1) {
        return false;
      } else {
        this.searchData[field].splice(checkIndex, 1);
      }
    },
    handleCheckTag(field, value, list) {
      this.searchData[field] = list;
      this.fieldChange(field, list);
    },
    reset(searchData) {
      this.$emit("reset", searchData);
      this.searchData = searchData;
    },
    fieldChange(field, value, data) {
      this.$emit("field-change", {
        fieldName: field,
        value: value,
        data,
      });
      this.searchData[field] = value;
    },
    // 设置默认查询参数
    setQueryParams(initialVal) {
      this.searchData = Object.assign({}, lodash.cloneDeep(initialVal));
    },
    submitSearch(isExport = false) {
      return this.onSearchChange(null, isExport);
    },
    detailSearch(index, current /* , type, searchData */) {
      // 高级搜索级联操作，current.advanceCascade为需要级联的参数的key的集合，例如advanceCascade: key1,key2,key3
      const advanceCascade = {};
      if (lodash.isString(current.advanceCascade)) {
        let advanceArray = [];
        advanceArray = current.advanceCascade.split(",");
        advanceArray.forEach((item) => {
          let { 0: sourceProp, 1: destProp } = item.split("#");
          destProp = destProp || sourceProp;
          advanceCascade[destProp] = this.searchData[sourceProp];
        });
      }
      this.$refs.advanceDialog.show(true, current, advanceCascade);
    },
    detailSearchSure(data) {
      // 将返回数据添加到map里，用于高级搜索清空时，顺带清空其他关联属性
      const keyArr = Object.keys(data);
      if (keyArr.length > 0) {
        keyArr.forEach((key) => {
          // this.keyMap[key] = keyArr;
          this.$set(this.keyMap, key, keyArr);
        });
      }
      this.searchData = { ...this.searchData, ...data };
    },
    onSearchChange(data, isExport = false) {
      const searchDataCopy = lodash.cloneDeep(this.searchData);
      const searchData = data ? lodash.cloneDeep(data) : searchDataCopy;
      for (const key in searchData) {
        if (Object.prototype.hasOwnProperty.call(searchData, key)) {
          const temp = searchData[key];
          if (lodash.isString(temp)) {
            // searchData[key] = temp.replace(/\s*/g, '');
            // 只去除前后空格
            searchData[key] = lodash.trim(temp);
          }
        }
      }
      if (isExport) {
        return searchData;
      } else {
        this.$emit("search-change", searchData);
      }
    },
    // 高级输入框的关闭按钮点击
    resetAdvanceParams(prop) {
      const keyArr = this.keyMap[prop];
      // 把属性相关联的字段置空
      keyArr.forEach((key) => {
        this.searchData[key] = "";
      });
    },
    handleBasicDataInputChange(prop, data) {
      this.searchData = { ...this.searchData, ...data };
      Object.keys(data).forEach((key) => {
        this.fieldChange(key, data[key]);
      });
    },
    handleBasicDataMultiChange(prop, data) {
      this.searchData[prop] = data;
      this.fieldChange(prop, data);
    },
  },
};
</script>
<style lang="scss">
.model-table-search {
  .search-box {
    .lots-basic-select-for-lazy {
      width: 100%;
    }
    .el-select {
      .el-input__inner {
        max-height: 28px;
      }
    }
    flex-wrap: wrap;
    .is-fussy {
      display: flex;
      .search {
        flex: auto;
      }
      .input-append-fussy {
        flex: none;
        flex-basis: 65px;
        align-self: baseline;
        margin-top: 4px;
        .el-checkbox__label {
          padding-left: 4px;
        }
      }
    }
    .search {
      margin: 0 8px 10px 0;
    }

    .advanced-btn {
      margin-left: 10px;
    }
    .advance-input {
      .el-input__inner {
        padding-right: 25px;
      }
      .el-input-group__append {
        text-align: center;
        padding: 0 14px;
        .el-button {
          padding: 8px;
        }
      }
    }
    .lots-select {
      width: 100%;
      .el-select__tags {
        flex-wrap: initial;
        & > span {
          display: flex;
        }
      }
    }
    .basic-data-selector {
      width: 100%;
      .el-select__tags {
        flex-wrap: initial;
        & > span {
          display: flex;
          width: 100%;
          span:nth-child(1).el-tag--mini {
            max-width: 90%;
            span.el-select__tags-text {
              max-width: 50%;
            }
          }
          span:nth-child(2).el-tag--mini {
            padding: initial;
            span.el-select__tags-text {
              max-width: 25px;
              text-overflow: clip;
            }
          }
        }
      }
    }
  }
  .text-box {
    position: relative;
    font-size: 12px;
    width: 100%;
  }
}
</style>
