<template>
  <div style="height: 100%; padding: 20px">
    <div style="text-align: center; font-size: 30px; margin-bottom: 30px">
      订单 {{ orderInfo.ord_no }} 出运详情
    </div>
    <div>
      <div class="div_titel">订单基本信息:</div>
      <el-row :gutter="24" style="margin: 20px 0px">
        <el-col :span="8">订单编码：{{ orderInfo.ord_no }} </el-col>
        <el-col :span="8">下单日期：{{ orderInfo.create_time }}</el-col>
        <el-col :span="8">下单状态：{{ orderInfo.ord_status }}</el-col>
      </el-row>
    </div>
    <div class="content">
      <el-table
        :data="dataSource"
        show-summary
        :summary-method="getSummaries"
        style="width: 100%; font-size: 12px"
        height="550px"
      >
        <el-table-column prop="pdt_model" label="产品编码" width="230" />
        <el-table-column prop="pdt_dep" label="产品描述" width="300" />
        <el-table-column prop="pdt_num" label="销售数量" width="100" />
        <el-table-column prop="ship_num" label="已出运量" width="100" />
        <el-table-column prop="wait_ship" label="待出运量" width="100" />
        <el-table-column prop="ship_no" label="相关出运单" />
      </el-table>
    </div>
    <div>
      <el-row :gutter="24" style="margin: 20px 0px">
        <el-col :span="24"
          >订单备注：<b style="color: red">{{
            orderInfo.ord_remark
          }}</b></el-col
        >
      </el-row>
    </div>
  </div>
</template>
<script>
import { ref, reactive, toRefs, onMounted } from "vue";
import { ElMessage } from "element-plus";
import { showShippingDetails } from "@/network/showDetails.js";
import { useRoute } from "vue-router";
export default {
  components: {},
  setup() {
    const route = ref(useRoute());
    const state = reactive({
      dataSource: [],
      orderInfo: [],
    });
    const printInfo = route.value.query.ordId || route.value.query.ordId;
    const getList = () => {
      showShippingDetails({ data: printInfo }).then((res) => {
        if (res.code == 0) {
          state.orderInfo = res.data.orderInfo;
          state.dataSource = res.data.lists;
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    const getSummaries = (param) => {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index == 0) {
          sums[0] = "合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (
          !values.every((value) => isNaN(value)) &&
          [2, 3, 4].includes(index)
        ) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        } else {
          sums[index] = "";
        }
      });

      return sums;
    };
    onMounted(() => {
      getList();
    });
    return {
      ...toRefs(state),
      getSummaries,
    };
  },
};
</script>
<style scoped>
.div_titel {
  font-size: 14px;
  font-weight: 400;
  border-left: 5px solid darkorange;
  padding-left: 5px;
}
.el-table__body-wrapper {
  height: auto !important;
}
</style>
