export default {
  selection: false,
  columns: [
    {
      label: "订单编码",
      prop: "ord_no",
    },
    {
      label: "产品编码",
      prop: "pdt_no",
    },
    {
      label: "产品描述",
      prop: "pdt_dep",
    },
    {
      label: "下单数量",
      prop: "pdt_num",
    },
    {
      label: "已出运数",
      prop: "pdt_ship",
    },
    {
      label: "出运数量",
      prop: "num",
      type: "pdtNum",
    },
  ],
  actions: {
    // 表格操作列的配置
    fixedWidth: 110, // fixedWidth（可选） 设置操作列的固定宽度，不加该参数默认是按钮list长度*50px
    list: [
      {
        label: "删除",
        event: "del",
        displayRule: () => {
          return true;
        },
      },
    ],
  },
};
