// export const dualAxes = {
//   data: [],
//   padding: "auto",
//   xField: "dimension",
//   yField: "value",
//   seriesField: "series",
//   appendPadding: [0, 8, 0, 0],
//   meta: {
//     SendWt: {
//       alias: "本年销量（吨）",
//     },
//     prevYearWT: {
//       alias: "去年销量（吨）",
//     },
//   },
//   yAxis: {
//     title: {
//       text: "重量（吨）",
//       style: {
//         fill: "#fff",
//       },
//     },
//   },
//   label: {
//     layout: [{ type: "hide-overlap" }], // 隐藏重叠label
//     style: {
//       textAlign: "right",
//       fill: "#fff",
//     },
//     formatter: (item) => item.value,
//   },
//   point: {
//     size: 5,
//     style: {
//       lineWidth: 1,
//       fillOpacity: 1,
//     },
//     shape: (item) => {
//       if (item.series === "本年销量（吨）") {
//         return "circle";
//       }
//       return "diamond";
//     },
//   },
//   legend: {
//     position: "top",
//     itemName: {
//       style: {
//         fill: "#fff",
//       },
//     },
//   },
// };

export const dualAxes = {
  data: [],
  xField: "date_time",
  yField: "num",
  label: {},
  point: {
    size: 5,
    shape: "diamond",
    style: {
      fill: "white",
      stroke: "#5B8FF9",
      lineWidth: 2,
    },
  },
  tooltip: { showMarkers: false },
  state: {
    active: {
      style: {
        shadowBlur: 4,
        stroke: "#000",
        fill: "red",
      },
    },
  },
  smooth: true,
  interactions: [{ type: "marker-active" }],
};
