<template>
  <!-- 订单详情页面 -->
  <div class="order-detail-contain">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="订单基本信息" name="first">
        <orderBasicInfo @getOrderListId="getOrderId"></orderBasicInfo>
      </el-tab-pane>
      <el-tab-pane label="订单详情信息" name="second" :disabled="secondAble">
        <orderDetails :orderID="orderID"></orderDetails>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { computed, ref } from "vue";
import orderBasicInfo from "./orderBasicInfo.vue";
import orderDetails from "./orderDetails.vue";
import { useRoute } from "vue-router";
export default {
  name: "addOrder",
  components: {
    orderBasicInfo,
    orderDetails,
  },
  setup() {
    const route = ref(useRoute());
    const secondAble = computed(() => {
      let flag = route.value.query.ordId || orderID.value;
      return !flag;
    });
    const orderID = ref(null);
    const activeName = ref("first");

    const getOrderId = (val) => {
      orderID.value = val;
      activeName.value = "second";
    };
    return {
      activeName,
      getOrderId,
      orderID,
      secondAble,
    };
  },
};
</script>
<style scoped>
.order-detail-contain {
  padding: 20px 0;
}
</style>
