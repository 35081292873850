<template>
  <div class="order-detail-contain">
    <el-tabs v-model="activeName">
      <el-tab-pane label="待发起" name="first">
        <waitGo></waitGo>
      </el-tab-pane>
      <el-tab-pane label="待支付" name="second">
        <waitPay></waitPay>
      </el-tab-pane>
      <el-tab-pane label="已办结" name="three">
        <paidSalary></paidSalary>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { ref } from "vue";
import waitGo from "./waitGo/index.vue";
import waitPay from "./waitPay/index.vue";
import paidSalary from "./paidSalary/index.vue";
export default {
  name: "salaryList",
  components: {
    waitGo,
    waitPay,
    paidSalary,
  },
  setup() {
    const activeName = ref("first");
    const operate_right = ref(false);
    return {
      activeName,
      operate_right,
    };
  },
};
</script>
<style scoped lang="scss">
.order-detail-contain {
  height: 100%;
  padding: 20px 0;
  .el-tabs {
    height: 100%;
    :deep .el-tabs__content {
      height: calc(100% - 100px);
    }
    .el-tab-pane {
      height: 100%;
    }
  }
}
</style>
