export default {
  selection: true,
  searchFields: [
    // 搜索组件的配置项
    {
      name: "设置备注",
      value: "remark",
      type: "input",
      isFixed: true,
      span: 4,
    },
  ],
  columns: [
    // 表格列的配置
    {
      label: "请假人",
      prop: "user_name_ch",
      fixed: true,
    },
    {
      label: "开始时间",
      prop: "start",
    },
    {
      label: "结束时间",
      prop: "end",
    },
    {
      label: "请假天数",
      prop: "leave_days",
    },
    {
      label: "创建时间",
      prop: "create_time",
    },
    {
      label: "状态",
      prop: "status",
      type: "select",
      options: { 0: "作废", 1: "待审核", 2: "已通过" },
    },
    {
      label: "备注",
      prop: "remark",
    },
    {
      label: "操作",
      type: "operate_btn",
      prop: "",
      width: "200px",
    },
  ],
  forms: [
    {
      label: "请假人员：",
      prop: "user_id",
      type: "select",
      placeholder: "- 请选择请假人 -",
      span: 11,
      rule: [
        {
          required: true,
          message: "- 请选择请假人 -",
          trigger: "blur",
        },
      ],
    },
    {
      label: "请假时间",
      prop: "leave_time",
      type: "time",
      timeType: "datetimerange",
      span: 11,
      placeholder: "请选择请假时间",
      rule: [
        {
          required: true,
          message: "请选择请假时间",
          trigger: "blur",
        },
      ],
    },
    {
      label: "备注",
      prop: "remark",
      type: "input",
      span: 22,
      inputType: "textarea",
    },
  ],
};
