import { request } from "./request.js";
export function shippingList(data = {}) {
  return request({
    url: "shipping/shippingList",
    method: "post",
    data,
  });
}
export function getShippingDetails(data = {}) {
  return request({
    url: "shipping/getShippingDetails",
    method: "post",
    data,
  });
}
export function getShippingComp(data = {}) {
  return request({
    url: "shipping/getShippingComp",
    method: "post",
    data,
  });
}
export function createListAdd(data = {}) {
  return request({
    url: "shipping/createListAdd",
    method: "post",
    data,
  });
}
export function createListReview(data = {}) {
  return request({
    url: "shipping/createListReview",
    method: "post",
    data,
  });
}
export function createShippingPlan(data = {}) {
  return request({
    url: "shipping/createShippingPlan",
    method: "post",
    data,
  });
}
export function getShippingDetailsList(data = {}) {
  return request({
    url: "shipping/getShippingDetailsList",
    method: "post",
    data,
  });
}
export function addShippingDetails(data = {}) {
  return request({
    url: "shipping/addShippingDetails",
    method: "post",
    data,
  });
}
export function delShippingDetails(data = {}) {
  return request({
    url: "shipping/delShippingDetails",
    method: "post",
    data,
  });
}
export function shippingPlanList(data = {}) {
  return request({
    url: "shipping/shippingPlanList",
    method: "post",
    data,
  });
}
export function delShippingPlan(data = {}) {
  return request({
    url: "shipping/delShippingPlan",
    method: "post",
    data,
  });
}
export function confirmShippingList(data = {}) {
  return request({
    url: "shipping/confirmShippingList",
    method: "post",
    data,
  });
}
export function getShippingPlanInfo(data = {}) {
  return request({
    url: "shipping/getShippingPlanInfo",
    method: "post",
    data,
  });
}
export function addDeliveryInfo(data = {}) {
  return request({
    url: "shipping/addDeliveryInfo",
    method: "post",
    data,
  });
}
export function getDeliveryDetails(data = {}) {
  return request({
    url: "shipping/getDeliveryDetails",
    method: "post",
    data,
  });
}
export function addDeliveryDetails(data = {}) {
  return request({
    url: "shipping/addDeliveryDetails",
    method: "post",
    data,
  });
}
export function addSignParcel(data = {}) {
  return request({
    url: "shipping/addSignParcel",
    method: "post",
    data,
  });
}
