<template>
  <div class="order-detail-contain">
    <el-tabs v-model="activeName">
      <el-tab-pane label="应收款" name="first">
        <financeCollectionList></financeCollectionList>
      </el-tab-pane>
      <el-tab-pane label="待审核" name="second">
        <financeCollectionWait></financeCollectionWait>
      </el-tab-pane>
      <el-tab-pane label="已完成" name="three">
        <financeCollectionSuccess></financeCollectionSuccess>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import financeCollectionList from "./List/index.vue";
import financeCollectionWait from "./Wait/index.vue";
import financeCollectionSuccess from "./Success/index.vue";
export default {
  name: "financeCollection",
  components: {
    financeCollectionList,
    financeCollectionWait,
    financeCollectionSuccess,
  },
  setup() {
    const activeName = "first";
    return {
      activeName,
    };
  },
};
</script>
<style scoped lang="scss">
.order-detail-contain {
  height: 100%;
  padding: 20px 0;
  .el-tabs {
    height: 100%;
    :deep .el-tabs__content {
      height: calc(100% - 100px);
    }
    .el-tab-pane {
      height: 100%;
    }
  }
}
</style>
