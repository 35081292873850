<template>
  <div style="height: 100%">
    <div style="text-align: center; margin: 1.25rem; font-size: 1.875rem">
      应收款编码 {{ formState.list_no }}
    </div>
    <el-form :model="formState" :rules="rules">
      <div class="div_title">基础信息：</div>
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="收款方式：" prop="method_id">
            <el-select
              v-model="formState.method_id"
              :popper-append-to-body="false"
              placeholder="-请选择-"
            >
              <el-option
                v-for="item in getAccountList"
                :key="item.id"
                :label="item.acc_code"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="div_title">付款方信息：</div>
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="付款人：" prop="payer_id">
            <el-select
              v-model="formState.payer_id"
              :popper-append-to-body="false"
              placeholder="-请选择-"
              @change="changePayer"
              :clearable="true"
            >
              <el-option
                v-for="item in payerList"
                :key="item.id"
                :label="item.account_name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="付款账户：">
            <el-input v-model="formState.account_no" disabled />
          </el-form-item>
        </el-col>
      </el-row>
      <div class="div_title">基本信息：</div>
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="收款日期：" prop="payment_date">
            <el-date-picker
              v-model="formState.payment_date"
              type="date"
              placeholder="选择收款日期"
              format="YYYY/MM/DD"
              value-format="YYYY-MM-DD"
              :disabled-date="disabledDate"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="收款类型：" prop="type">
            <el-select
              v-model="formState.type"
              :popper-append-to-body="false"
              placeholder="-请选择收款类型-"
            >
              <el-option
                v-for="item in getPaymentTypeList"
                :key="item.payment_id"
                :label="item.payment_name_ch"
                :value="item.payment_id"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="收款货币：" prop="currency_id">
            <el-select
              v-model="formState.currency_id"
              :popper-append-to-body="false"
              placeholder="-请选择收款货币-"
            >
              <el-option
                v-for="item in getCurrencyList"
                :key="item.currency_id"
                :label="item.currency_name_ch"
                :value="item.currency_id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <getUserList>
            <el-form-item label="制单人员：" prop="user_id">
              <el-select
                v-model="formState.user_id"
                :popper-append-to-body="false"
              >
                <el-option
                  v-for="item in getUserList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </getUserList>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="24">
          <el-form-item label="收款备注：">
            <el-input
              type="textarea"
              v-model="formState.remark"
              placeholder="请输入阿里支付单号/西联监控码/TT付款单号等"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="24" style="text-align: right">
          <el-button size="middle" type="primary" @click="add(2)">{{
            btn_title
          }}</el-button>
          <el-button
            v-if="operate_type"
            size="middle"
            type="primary"
            @click="add(1)"
            ><span>驳回</span></el-button
          >
          <el-button
            v-if="operate_type"
            size="middle"
            type="primary"
            @click="add(3)"
            ><span>通过</span></el-button
          >
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import { ref, reactive, toRefs, onMounted } from "vue";
import {
  getCollectionListInfo,
  getCurrencyList,
  getAccountList,
  getPaymentTypeList,
  createListAdd,
  createListReview,
} from "@/network/finance.js";
import { getUserList } from "@/network/user.js";
import { useRoute, useRouter } from "vue-router";
import { ElMessage } from "element-plus";
export default {
  emits: ["getCreateDetails"],
  setup(props, context) {
    const route = ref(useRoute());
    const state = reactive({
      dataSource: [],
      getCurrencyList: [],
      getAccountList: [],
      getPaymentTypeList: [],
      getUserList: [],
      payerList: [],
      btn_title: "下一步",
      operate_type: false,
    });
    const rules = reactive({
      method_id: [
        {
          required: true,
          message: "请选择收款渠道/方式",
          trigger: "blur",
        },
      ],
      payer_id: [
        {
          required: true,
          message: "请选择实际付款人",
          trigger: "blur",
        },
      ],
      payment_date: [
        {
          required: true,
          message: "请选择收款日期",
          trigger: "blur",
        },
      ],
      type: [
        {
          required: true,
          message: "请选择该笔收款类型（支付什么费用）",
          trigger: "blur",
        },
      ],
      currency_id: [
        {
          required: true,
          message: "请选择付款币种",
          trigger: "blur",
        },
      ],
      user_id: [
        {
          required: true,
          message: "请选择制单员",
          trigger: "blur",
        },
      ],
    });
    const collectionInfo = route.value.query.arrData; //所有的订单id
    if (collectionInfo[0] == "udt") {
      state.btn_title = "更新";
      state.operate_type = true;
    }
    const formState = reactive({
      id: "",
      list_no: "",
      payer_id: "",
      method_id: "",
      currency_id: "",
      payment_date: "",
      type: "",
      user_id: "",
      create_time: "",
      status: "",
      remark: "",
      account_no: "",
      // collection_list_ids: "",
    });
    const router = useRouter();
    const getList = () => {
      getCollectionListInfo({ data: collectionInfo }).then((res) => {
        if (res.code == 0) {
          state.payerList = res.data.payerList;
          Object.assign(formState, res.data.list);
        } else {
          ElMessage.error(res.msg);
          router.push({
            name: "financeCollection",
          });
        }
      });
      getCurrencyList().then((res) => {
        if (res.code == 0) {
          state.getCurrencyList = res.data;
        } else {
          ElMessage.error(res.msg);
        }
      });
      getAccountList({ symbolType: "=", acc_ownership: 0 }).then((res) => {
        if (res.code == 0) {
          state.getAccountList = res.data;
        } else {
          ElMessage.error(res.msg);
        }
      });
      getPaymentTypeList({ payment_type: 1 }).then((res) => {
        if (res.code == 0) {
          state.getPaymentTypeList = res.data;
        } else {
          ElMessage.error(res.msg);
        }
      });
      getUserList().then((res) => {
        if (res.code == 0) {
          state.getUserList = res.data;
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    onMounted(() => {
      getList();
    });
    // 选择付款人
    const changePayer = (val) => {
      let ary = state.payerList.filter((item) => {
        return val == item.id;
      });
      formState.account_no = ary[0].account_no;
    };
    const add = (val) => {
      if (val == 2) {
        //添加或更新
        createListAdd(formState).then((res) => {
          if (res.code == 0) {
            ElMessage.success(res.msg);
            formState.id = res.data; //将保存后获得的应付款详情id赋值给formState（当用户再次回到此选项卡是，可以顺利更新details页面）,传给关系表页面
            context.emit("getCreateDetails", {
              list_id: res.data,
              list_no: formState.list_no,
            });
          } else {
            ElMessage.error(res.msg);
          }
        });
      } else {
        //审核通过或驳回
        createListReview({
          operate_type: val,
          list_id: collectionInfo[1], //审核是，此为收款单id
        }).then((res) => {
          if (res.code == 0) {
            ElMessage.success(res.msg);
            router.push({
              name: "financeCollection", //审核通过或驳回后，回到应付款列表
              query: {},
            });
          } else {
            ElMessage.error(res.msg);
          }
        });
      }
    };
    return {
      ...toRefs(state),
      formState,
      add,
      changePayer,
      rules,
    };
  },
};
</script>
<style scoped>
.div_title {
  margin: 1.25rem 0;
  padding-left: 0.625rem;
  border-left: 0.625rem solid grey;
  font-size: 1.25rem;
}
</style>
