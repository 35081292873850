<template>
  <div class="order-detail-contain">
    <el-tabs v-model="activeName">
      <el-tab-pane label="待完成" name="first">
        <waitList></waitList>
      </el-tab-pane>
      <el-tab-pane label="创建的" name="second">
        <createdList></createdList>
      </el-tab-pane>
      <el-tab-pane label="所有的" name="three" v-if="operate_right">
        <allList></allList>
      </el-tab-pane>
      <el-tab-pane label="关注的" name="four">
        <focusList></focusList>
      </el-tab-pane>
      <el-tab-pane label="参与的" name="five">
        <partakeList></partakeList>
      </el-tab-pane>
      <el-tab-pane label="回收站" name="six">
        <recycleList></recycleList>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { ref, onMounted } from "vue";
import { getUserRights } from "@/network/schedule.js";
import waitList from "./Wait/index.vue";
import createdList from "./Created/index.vue";
import allList from "./All/index.vue";
import focusList from "./Focus/index.vue";
import partakeList from "./Partake/index.vue";
import recycleList from "./Recycle/index.vue";
export default {
  name: "scheduleList",
  components: {
    waitList,
    createdList,
    allList,
    focusList,
    partakeList,
    recycleList,
  },
  setup() {
    const activeName = ref("first");
    const operate_right = ref(false);
    const getList = () => {
      getUserRights().then((res) => {
        if (res.code == 0) {
          operate_right.value = true;
        }
      });
    };
    onMounted(() => {
      getList();
    });
    return {
      activeName,
      operate_right,
    };
  },
};
</script>
<style scoped lang="scss">
.order-detail-contain {
  height: 100%;
  padding: 20px 0;
  .el-tabs {
    height: 100%;
    :deep .el-tabs__content {
      height: calc(100% - 100px);
    }
    .el-tab-pane {
      height: 100%;
    }
  }
}
</style>
