export default {
  selection: true,
  searchFields: [
    // 搜索组件的配置项
    {
      name: "订单号",
      value: "ord_no",
      type: "input",
      isFixed: true,
      span: 4,
    },
  ],
  columns: [
    // 表格列的配置
    {
      label: "归属月份",
      prop: "month",
      fixed: true,
    },
    {
      label: "订单号",
      prop: "ord_no",
      fixed: true,
    },
    {
      label: "签约日期",
      prop: "create_time",
    },
    {
      label: "客户",
      prop: "cst_id_v",
    },
    {
      label: "归属人",
      prop: "user_id_v",
    },
    {
      label: "订单总收入",
      prop: "orderTotalIncome",
    },
    {
      label: "订单总支出",
      prop: "orderTotalCost",
    },
    {
      label: "订单毛利",
      prop: "grossProfit",
    },
    {
      label: "提成比例",
      prop: "commissionRate",
    },
    {
      label: "提成金额",
      prop: "commission",
    },
    {
      label: "操作",
      type: "operate_btn",
      prop: "",
      width: "220px",
    },
  ],
};
