export default {
  selection: false,
  columns: [
    // 表格列的配置
    {
      label: "订单号",
      prop: "ord_no",
    },
    {
      label: "产品编码",
      prop: "pdt_no",
    },
    {
      label: "产品描述",
      prop: "pdt_dep",
    },
    {
      label: "业务备注",
      prop: "pdt_remark",
    },
    {
      label: "采购单价",
      prop: "price",
    },
    {
      label: "下单数量",
      prop: "num",
    },
    {
      label: "入库数量",
      prop: "store_num",
    },
    {
      label: "小计",
      prop: "total",
    },
  ],
  actions: {
    // 表格操作列的配置
    fixedWidth: 110, // fixedWidth（可选） 设置操作列的固定宽度，不加该参数默认是按钮list长度*50px
    list: [
      {
        label: "入库",
        event: "inspect",
        displayRule: () => {
          return true;
        },
      },
    ],
  },
  forms: [
    {
      label: "产品型号",
      prop: "pdt_no",
      type: "input",
      disabled: true,
    },
    {
      label: "下单数量",
      prop: "num",
      type: "input",
      disabled: true,
    },
    {
      label: "IMEI码",
      prop: "imei",
      type: "input",
      inputType: "textarea",
      autoSize: { minRows: 5 },
      placeholder: "请直接用扫码枪扫描产品条形码",
    },
    {
      label: "入库数量",
      prop: "store_num",
      type: "input",
      rule: [
        {
          required: true,
          message: "请输入产品入库数量",
          trigger: "blur",
        },
        {
          pattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
          message: "请输入非负数",
        },
      ],
    },
  ],
};
