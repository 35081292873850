export default {
  selection: false,
  columns: [
    // 表格列的配置
    {
      label: "订单号",
      prop: "ord_no",
    },
    {
      label: "产品编码",
      prop: "pdt_no",
    },
    {
      label: "产品描述",
      prop: "pdt_dep",
      width: "400",
    },
    {
      label: "业务备注",
      prop: "pdt_remark",
    },
    {
      label: "总商品数",
      prop: "pdt_num",
    },
    {
      label: "已采购数",
      prop: "pdt_pur",
    },
    {
      label: "采购单价",
      prop: "price",
      type: "purPrice",
    },
    {
      label: "下单数量",
      prop: "num",
      type: "pdtNum",
    },
    {
      label: "小计",
      prop: "total",
    },

    {
      label: "采购备注",
      prop: "remark",
      type: "purRemark",
    },
  ],
  actions: {
    // 表格操作列的配置
    fixedWidth: 110, // fixedWidth（可选） 设置操作列的固定宽度，不加该参数默认是按钮list长度*50px
    list: [
      {
        label: "删除",
        event: "del",
        displayRule: () => {
          return true;
        },
      },
    ],
  },
};
