<template>
  <div class="flex-layout order-management">
    <isdListPaper v-loading="tableLoading">
      <template v-slot:header-search>
        <isdSearchBox
          ref="searchBoxIns"
          :search-key="config.name"
          :fields="config.searchFields"
          @search-change="searchList"
          :loading="tableLoading"
          class="track-search"
        ></isdSearchBox>
      </template>
      <template v-slot:list="props">
        <isdTable
          :loading="tableLoading"
          :rows="totalData"
          :height="props.tableHeight"
          :columns="config.columns"
          :selection="config.selection"
          :actions="config.actions"
          @selection-change="handleSelectedRow"
          style="font-size: 12px"
        >
          <template v-slot:col-append="{ col, row }">
            <span v-if="col.type === 'operate_btn'">
              <el-button round size="small" @click="edit(row.inv_id)"
                ><el-icon style="vertical-align: middle"> <EditPen /> </el-icon
                ><span style="vertical-align: middle"> 编辑 </span></el-button
              >
              <el-button round size="small" @click="print(row.inv_id)"
                ><el-icon style="vertical-align: middle"><Printer /></el-icon
                ><span style="vertical-align: middle"> 打印 </span></el-button
              >
            </span>
          </template>
        </isdTable>
      </template>
      <template v-slot:footer-buttons>
        <el-button
          type="danger"
          plain
          @click="deleteItem"
          :disabled="!selectedRows.length"
          ><el-icon><Delete /></el-icon>
          <span style="vertical-align: middle"> 批量删除 </span>
        </el-button>
      </template>
      <template v-slot:footer>
        <isdPagination
          @size-change="sizeChange"
          :current-page="currentPage"
          @current-change="pageChange"
          layout="total,sizes,prev,pager,next,jumper"
          :page-size="pageSize"
          :page-sizes="[20, 50, 100]"
          :total="pageTotal"
        ></isdPagination>
      </template>
    </isdListPaper>
  </div>
</template>
<script>
import { invoiceList, delInvoice } from "@/network/invoice.js";
import { EditPen, Printer, Delete } from "@element-plus/icons-vue";
import { defineComponent, ref, reactive, onMounted } from "vue";
import { useRouter } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import { ListClass } from "@/composition/ListClass.js";
import configData from "./config";
import isdTable from "@/views/components/isdTable/index.vue";
import isdPagination from "@/views/components/isdPage/index.vue";
import isdListPaper from "@/views/components/isdListPaper/index.vue";
import isdSearchBox from "@/views/components/isdSearchBox/index.vue";
export default defineComponent({
  components: {
    Delete,
    EditPen,
    Printer,
    isdTable,
    isdPagination,
    isdSearchBox,
    isdListPaper,
  },
  setup() {
    const config = reactive(configData); // 列表配置
    const searchBoxIns = ref(null); // 搜索实例
    const columnConfigIns = ref(null); // 列配置实例
    const statusDialogIns = ref(null); // 状态弹框实例
    const detailPageIns = ref(null); // 详情编辑页ref
    const tableBox = ref(null);
    // 格式化查询条件
    const dataFormat = (condition) => {
      condition.symbolType = "=";
      condition.listType = 1;
      return condition;
    };
    class TableList extends ListClass {
      beforeGetList(condition) {
        condition = dataFormat(condition);
        return condition;
      }
    }
    const {
      currentPage,
      pageNo,
      pageSize,
      pageTotal,
      tableLoading,
      totalData,
      getList,
      searchList,
      pageChange,
      sizeChange,
      selectedRows,
      handleSelectedRow,
    } = new TableList({
      Api: {
        listApi: invoiceList,
      },
    });
    const router = useRouter();
    const print = (val) => {
      router.push({
        name: "printOrderInvoice",
        query: {
          arrData: val,
        },
      });
    };
    const edit = (val) => {
      const arr = ["udt", val];
      router.push({
        name: "createInvoice",
        query: {
          arrData: arr,
        },
      });
    };
    const deleteItem = () => {
      const map = new Map();
      const arr = selectedRows.value.map((item) => {
        return item.inv_id; // 只返回id号
      });
      //方法
      if (!arr) {
        ElMessage("所选数据为空，请重新选择！");
      } else {
        ElMessageBox.confirm("确定要删除选中的发票吗?", "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            delInvoice({ invIDs: arr, del_type: 0 }).then((res) => {
              if (res.code == 0) {
                ElMessage.success(res.msg);
                getList();
              } else {
                ElMessage.error(res.msg);
                getList();
              }
            });
          })
          .catch(() => {
            ElMessage({
              type: "info",
              message: "删除取消",
            });
          });
      }
    };
    onMounted(() => {
      getList();
    });
    return {
      tableBox,
      config,
      pageNo,
      pageSize,
      pageTotal,
      tableLoading,
      totalData,
      searchList,
      pageChange,
      sizeChange,
      handleSelectedRow,
      searchBoxIns,
      columnConfigIns,
      detailPageIns,
      statusDialogIns,
      currentPage,
      selectedRows,
      print,
      edit,
      deleteItem,
    };
  },
});
</script>

<style lang="scss">
.order-management {
  .el-table th > .cell {
    word-break: keep-all;
  }
}
</style>
