<template>
  <div class="order-detail-contain">
    <el-row>
      <el-col :span="24">
        <div
          style="
            height: 100%;
            line-height: 180px;
            font-size: 55px;
            text-align: center;
          "
        >
          计划与数据
        </div>
      </el-col>
      <el-col :span="12" style="text-align: left; margin-bottom: 20px">
        <el-button @click="addNewDaySchedule" type="primary" class="button"
          ><el-icon> <Plus /> </el-icon>新增计划</el-button
        >
      </el-col>
      <el-col
        :span="12"
        v-if="oprateRights"
        style="text-align: right; margin-bottom: 20px"
      >
        <el-select v-model="userID" @change="changeUser">
          <el-option
            v-for="item in getUserList"
            :key="item.user_id"
            :label="item.user_name_ch"
            :value="item.user_id"
          />
        </el-select>
      </el-col>
    </el-row>
    <!--今日计划-->
    <el-row v-if="!isEmpty">
      <el-col :span="24">
        <el-card class="box-card">
          <template #header>
            <div
              style="
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              <span class="card-date">{{ dayTipsInfo.schedule_date }}</span>
              <el-button
                @click="editTipsGo(dayTipsInfo)"
                class="button"
                v-if="dayTipsInfo.editRight"
                text
                round
                ><el-icon> <Edit /> </el-icon>修改日程</el-button
              >
            </div>
          </template>
          <el-row
            v-for="(item, index) in dayTipsInfo.tips"
            :key="index"
            style="padding: 10px"
          >
            <template v-if="item.complete">
              <el-col
                :span="18"
                style="text-decoration: line-through; color: #bebebe"
              >
                {{ item.time }} | {{ item.content }}
              </el-col>
              <el-col style="text-align: right" :span="6"
                ><el-button size="small" type="success" round disabled
                  ><el-icon style="vertical-align: middle"> <Check /> </el-icon
                  >已完成</el-button
                ></el-col
              >
            </template>
            <template v-else>
              <el-col :span="18"> {{ item.time }} | {{ item.content }} </el-col>
              <el-col style="text-align: right" :span="6">
                <el-button
                  @click="completeTips(item)"
                  size="small"
                  type="success"
                  round
                  ><el-icon style="vertical-align: middle"> <Check /> </el-icon
                  >完成</el-button
                >
                <!-- <el-button
                  @click="addTipsGo(item)"
                  v-if="item.editRight"
                  size="small"
                  type="info"
                  round
                  ><el-icon style="vertical-align: middle"> <Edit /> </el-icon
                  >修改</el-button
                > -->
                <el-button
                  @click="delTips(item)"
                  size="small"
                  type="danger"
                  v-if="item.editRight"
                  round
                  ><el-icon style="vertical-align: middle"> <Delete /> </el-icon
                  >删除</el-button
                >
              </el-col>
            </template>
          </el-row>
          <div style="text-align: right">
            <span
              >创建人：{{ dayTipsInfo.build_user }} | 创建时间：{{
                dayTipsInfo.create_time
              }}</span
            >
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-card v-else>
      <p class="emptMsg">{{ emptyMsg }}</p>
    </el-card>
    <!--数据报表-->
    <el-row style="margin-bottom: 20px" :gutter="20">
      <el-col :span="12">
        <p class="title2">月客户报表</p>
        <el-card>
          <div class="box">
            <columnChart
              v-if="showChart"
              id="dayLine1"
              type="column"
              :configData="dualAxesData_cst_goals"
            ></columnChart>
          </div>
        </el-card>
      </el-col>
      <el-col :span="12">
        <p class="title2">月销售报表</p>
        <el-card>
          <div class="box">
            <columnChart
              v-if="showChart"
              id="dayLine2"
              type="column"
              :configData="dualAxesData_sale_goals"
            ></columnChart>
          </div>
        </el-card>
      </el-col>
      <el-col :span="24">
        <p class="title2">日程报表</p>
        <el-card>
          <div class="box">
            <columnChart
              v-if="showChart"
              id="dayLine3"
              type="line"
              :configData="dualAxesData_schedule"
            ></columnChart>
          </div>
        </el-card>
      </el-col>
      <el-col :span="12">
        <p class="title2">客户日报表</p>
        <el-card>
          <div class="box">
            <columnChart
              v-if="showChart"
              id="dayLine4"
              type="line"
              :configData="dualAxesData_cst"
            ></columnChart>
          </div>
        </el-card>
      </el-col>
      <el-col :span="12">
        <p class="title2">订单日报表</p>
        <el-card>
          <div class="box">
            <columnChart
              v-if="showChart"
              id="dayLine5"
              type="dualAxes"
              :configData="dualAxesData_order"
            ></columnChart>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 新增计划日期 -->
    <el-dialog
      v-model="dialogFormDate"
      :title="title"
      :append-to-body="true"
      z-index="100"
    >
      <el-form ref="outerFormRef" :model="formDate" :rules="outerFormRule">
        <el-form-item
          prop="schedule_date"
          style="padding-left: 20px"
          label="计划日期："
        >
          <el-date-picker
            class="time_sel"
            v-model="formDate.schedule_date"
            type="date"
            placeholder="选择日期"
            :disabled="formDate.day_id ? true : false"
          ></el-date-picker>
        </el-form-item>
        <ul class="formTipUl">
          <li v-for="(item, index) in formDate.tips" :key="index">
            <el-form
              :ref="(el) => (innerFormRef[index] = el)"
              :model="item"
              :rules="innerFormRule"
            >
              <el-row>
                <el-col :span="20">
                  <el-form-item prop="time" label="时间节点：">
                    <el-time-select
                      class="time_sel"
                      v-model="item.time"
                      start="09:00"
                      step="01:00"
                      end="24:00"
                      placeholder="选择时间"
                      :disabled="item.complete"
                    />
                  </el-form-item>
                  <el-form-item
                    prop="content"
                    style="margin-bottom: 0px"
                    label="计划内容："
                  >
                    <el-input
                      type="textarea"
                      :autosize="true"
                      :resize="none"
                      :disabled="item.complete"
                      v-model="item.content"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4" class="right-plusMinus">
                  <el-row>
                    <el-col :span="12">
                      <el-icon
                        v-if="formDate.tips.length - 1 == index"
                        class="add"
                        @click="addTips(index)"
                        ><Plus
                      /></el-icon>
                    </el-col>
                    <el-col :span="12">
                      <el-icon
                        @click="deleteTips(index)"
                        v-if="formDate.tips.length != 1 && !item.complete"
                        ><Minus
                      /></el-icon>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </el-form>
            <div class="status">
              <el-tag v-if="item.complete" type="success">已完成</el-tag>
              <el-tag v-else type="danger">未完成</el-tag>
            </div>
          </li>
        </ul>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormDate = false">取消</el-button>
          <el-button type="primary" @click="addDate">{{ goTitle }}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { ref, reactive, toRefs, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import { getUserList } from "@/network/user.js";
import { getHomeReport } from "@/network/finance.js";
import {
  getUserTheNewestDaySchedule,
  addDaySchedule,
  updateScheduleTips,
} from "@/network/daySchedule.js";
import columnChart from "@/components/columnChart.vue";
import { dualAxes, dualAxes1, dualAxes2, dualCloumn } from "./config.js";
export default {
  components: {
    columnChart,
  },
  setup() {
    const dialogFormDate = ref(false);
    const outerFormRef = ref();
    const innerFormRef = ref([]);
    const formDate = reactive({
      day_id: "",
      tips: [
        {
          tips_id: "",
          day_id: "",
          content: "",
          time: "",
          complete: 0,
        },
      ],
      schedule_date: "",
      summary: "",
    });
    const outerFormRule = {
      schedule_date: {
        required: true,
        message: "请选择计划日期",
        trigger: "blur",
      },
    };

    const innerFormRule = {
      time: {
        required: true,
        message: "请选择时间节点",
        trigger: ["change", "blur"],
      },
      content: {
        required: true,
        message: "请选择计划内容",
        trigger: "blur",
      },
    };
    const showChart = ref(false);
    const dualAxesData_cst = ref(dualAxes1);
    const dualAxesData_schedule = ref(JSON.parse(JSON.stringify(dualAxes)));
    const dualAxesData_order = ref(dualAxes2);
    const dualAxesData_cst_goals = ref(JSON.parse(JSON.stringify(dualCloumn)));
    const dualAxesData_sale_goals = ref(JSON.parse(JSON.stringify(dualCloumn)));
    const loading = ref(false);
    const isEmpty = ref(true);
    const emptyMsg = ref("");
    const state = reactive({
      title: "新增",
      goTitle: "添加",
      oprateRights: 0,
      getUserList: [],
      userID: "",
      dayTipsInfo: [],
    });
    const changeUser = (val) => {
      state.userID = val;
      getList();
    };
    const router = useRouter();
    const addNewDaySchedule = (val) => {
      router.push({
        name: "dayScheduleList",
      });
    };
    const getList = () => {
      loading.value = true;
      showChart.value = false;
      getUserList().then((res) => {
        if (res.code == 0) {
          state.getUserList = res.data;
        } else {
          ElMessage.error(res.msg);
        }
      });
      getHomeReport({
        userID: state.userID,
      }).then((res) => {
        loading.value = false;
        showChart.value = true;
        if (res.code == 0) {
          dualAxesData_cst.value.data = res.data.cstList;
          dualAxesData_schedule.value.data = res.data.scheduleList;
          dualAxesData_order.value.data[0] = res.data.orderList;
          dualAxesData_order.value.data[1] = res.data.orderList;
          dualAxesData_cst_goals.value.data = res.data.customerGoals;
          dualAxesData_sale_goals.value.data = res.data.salesGoals;
          state.oprateRights = res.data.oprateRights;
        } else {
          ElMessage.error(res.msg);
        }
      });
      getUserTheNewestDaySchedule({
        userID: state.userID,
      }).then((res) => {
        if (res.code == 0) {
          state.dayTipsInfo = res.data;
          isEmpty.value = false;
        } else if (res.code == 44) {
          isEmpty.value = true;
          emptyMsg.value = res.msg;
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    const addTips = () => {
      const obj = {
        tips_id: "",
        day_id: "",
        content: "",
        time: "",
        complete: 0,
      };
      formDate.tips.push({ ...obj });
    };
    //新增/修改计划日期
    const addDate = async () => {
      if (!outerFormRef.value) return;
      await outerFormRef.value.validate(async (valid) => {
        if (valid) {
          if (innerFormRef.value.length) {
            let innervalidable = true;
            const tempAry = innerFormRef.value.filter((item) => {
              return item;
            });
            for (let i = 0; i < tempAry.length; i++) {
              await tempAry[i].validate((innervalid) => {
                if (!innervalid) {
                  innervalidable = false;
                  ElMessage.error("请填写必填项！");
                }
              });
              if (!innervalidable) {
                break;
              }
            }
            // 校验都通过
            if (innervalidable) {
              const res = await addDaySchedule({ ...formDate });
              if (res.code == 43) {
                ElMessage.success(res.msg);
                dialogFormDate.value = false;
                getList();
              } else {
                ElMessage.error(res.msg);
              }
            }
          }
        } else {
          ElMessage.error("请填写必填项！");
        }
      });
    };

    // 编辑计划
    const editTipsGo = (item) => {
      console.log(item, "item");
      dialogFormDate.value = true;
      state.goTitle = "更新";
      state.title = "修改计划日期";
      Object.assign(formDate, { ...item });
      formDate.schedule_date = item.schedule_date.split("/").join("-");
    };
    // 删除
    const deleteTips = (index) => {
      formDate.tips.splice(index, 1);
    };
    onMounted(() => {
      getList();
    });
    //设置计划项为完成状态
    const completeTips = (row) => {
      if (!row.tips_id) {
        ElMessage("所选数据为空，请重新选择！");
      } else {
        //完成
        ElMessageBox.confirm("确定该项计划已经完成了吗?", "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            updateScheduleTips({ tips_id: row.tips_id, del_type: 1 }).then(
              (res) => {
                if (res.code == 0) {
                  ElMessage.success(res.msg);
                  getList();
                } else {
                  ElMessage.error(res.msg);
                }
              }
            );
          })
          .catch(() => {
            ElMessage({
              type: "info",
              message: "完成取消",
            });
          });
      }
    };
    //设置计划项为删除状态
    const delTips = (row) => {
      if (!row.tips_id) {
        ElMessage("所选数据为空，请重新选择！");
      } else {
        //删除
        ElMessageBox.confirm("确定要删除该条数据吗?", "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            updateScheduleTips({ tips_id: row.tips_id, del_type: 0 }).then(
              (res) => {
                if (res.code == 0) {
                  ElMessage.success(res.msg);
                  getList();
                } else {
                  ElMessage.error(res.msg);
                }
              }
            );
          })
          .catch(() => {
            ElMessage({
              type: "info",
              message: "删除取消",
            });
          });
      }
    };
    return {
      ...toRefs(state),
      dialogFormDate,
      outerFormRef,
      innerFormRef,
      formDate,
      outerFormRule,
      innerFormRule,
      changeUser,
      dualAxesData_cst,
      dualAxesData_schedule,
      dualAxesData_order,
      dualAxesData_cst_goals,
      dualAxesData_sale_goals,
      loading,
      showChart,
      addNewDaySchedule,
      addTips,
      deleteTips,
      editTipsGo,
      addDate,
      completeTips,
      delTips,
      isEmpty,
      emptyMsg,
    };
  },
};
</script>
<style lang="scss" scoped>
.title2 {
  font-size: 24px;
}
.emptMsg {
  color: red;
  font-size: 30px;
  font-weight: bold;
}
.formTipUl {
  max-height: 372px;
  overflow: auto;
  list-style: none;
  padding: 0;
  li {
    position: relative;
    padding: 20px;
    border: 1px dashed #ccc;
    margin-bottom: 10px;
    .right-plusMinus {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      .add {
        margin-right: 20px;
      }
      .el-icon:hover {
        color: var(--el-color-primary);
        cursor: pointer;
      }
    }
    .status {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }
}
</style>
