<template>
  <div class="customerInfo">
    <el-form v-model="cstInfo" class="custom-base-info">
      <el-row :gutter="110" style="flex-wrap: nowrap">
        <el-col :span="2">
          <div class="base-info-logo">
            <el-icon>
              <UserFilled />
            </el-icon>
          </div>
        </el-col>
        <el-col :span="22">
          <el-row justify="space-between">
            <el-col :span="12" class="row1">
              <el-form-item prop="name" label class="name">{{
                cstInfo.company_name
              }}</el-form-item>
              <el-switch
                v-model="cstInfo.focus"
                size="large"
                inline-prompt
                @change="setFocus($event)"
                active-text="关注"
                inactive-text="取消"
              />
            </el-col>
            <el-col :span="12" class="btns">
              <el-button type="primary" round @click="setTags(1)"
                >添加标签</el-button
              >
              <el-button
                type="primary"
                round
                v-if="operate_right"
                @click="assignCustomers(2)"
                >分配给</el-button
              >
            </el-col>
          </el-row>
          <el-row class="row2">
            <el-form-item label="跟进人:">{{
              cstInfo.user_name_ch
            }}</el-form-item>
          </el-row>
          <el-row class="row3">
            <el-form-item label="客户标签:">
              <el-tag
                v-for="tag in cstTagsList"
                :key="tag.id"
                style="margin-right: 10px"
                closable
                :type="tag.type"
                @close="delTag(tag.id)"
              >
                {{ tag.tag_name_ch }}
              </el-tag>
            </el-form-item>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="客户阶段:" class="sel">
                <el-select
                  class="stage"
                  id="no-border"
                  v-model="cstInfo.stage_id"
                  placeholder="-请选择-"
                  @visible-change="setStage"
                >
                  <el-option
                    v-for="item in getStages"
                    :key="item.stage_id"
                    :label="item.stage_name_ch"
                    :value="item.stage_id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="客户分组:" class="sel">
                <el-select
                  class="stage"
                  id="no-border1"
                  v-model="cstInfo.group_id"
                  placeholder="-请选择-"
                  @visible-change="setGroup"
                >
                  <el-option
                    v-for="item in getGroupList"
                    :key="item.group_id"
                    :label="item.group_name_ch"
                    :value="item.group_id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="星级:" class="sel">
                <el-rate
                  v-model="cstInfo.level_id"
                  @change="setLevel(cstInfo.level_id)"
                ></el-rate>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-form>
    <div class="customer-tabs">
      <el-tabs
        class="fuction-card"
        v-model="activeName"
        @tab-click="handleClick"
      >
        <el-tab-pane label="动态" name="first">
          <dynamic />
        </el-tab-pane>
        <el-tab-pane label="公司" name="second">
          <compInfo />
        </el-tab-pane>
        <el-tab-pane label="订单" name="third">
          <orderList />
        </el-tab-pane>
        <el-tab-pane label="轨迹" name="four">
          <trackList />
        </el-tab-pane>
      </el-tabs>
      <el-tabs
        class="contacts-history"
        v-model="activeName"
        @tab-click="handleClick"
      >
        <el-tab-pane label="联系人" name="first">
          <contactPersonList />
        </el-tab-pane>
        <el-tab-pane label="操作历史" name="second">待开发...</el-tab-pane>
      </el-tabs>
    </div>
    <el-dialog v-model="customersFormDialog" :title="formDate.title">
      <el-form :model="formDate">
        <el-row :gutter="20" v-if="formDate.type == 1">
          <el-col :span="1"></el-col>
          <el-col :span="19">
            <el-form-item label="选择客户标签：">
              <el-select
                placeholder="请选择客户标签（可以多选）"
                multiple
                style="width: 100%"
                v-model="formDate.tag_id"
                :popper-append-to-body="false"
              >
                <el-option-group
                  v-for="group in getTags"
                  :key="group.tag_id"
                  :label="group.tag_name_ch"
                >
                  <el-option
                    v-for="item in group.children"
                    :key="item.tag_id"
                    :label="item.tag_name_ch"
                    :value="item.tag_id"
                  />
                </el-option-group>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" v-if="formDate.type == 2">
          <el-col :span="1"></el-col>
          <el-col :span="19">
            <el-form-item label="选择业务员：">
              <el-select
                style="width: 100%"
                v-model="formDate.user_id"
                :popper-append-to-body="false"
              >
                <el-option
                  v-for="item in userList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="customersFormDialog = false">取消</el-button>
          <el-button type="primary" @click="confirmBtn">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { UserFilled } from "@element-plus/icons-vue";
import dynamic from "./dynamic/index.vue";
import compInfo from "./compInfo/index.vue";
import orderList from "./order/index.vue";
import trackList from "./track/index.vue";
import contactPersonList from "./contactPerson/index.vue";
import { ref, reactive, toRefs, onMounted } from "vue";
import {
  getCustomerBaseInfo,
  setCustomerFocus,
  getTagsList,
  delCustomerTag,
  getStageList,
  setCustomerStage,
  getGroupList,
  setCustomerGroup,
  setCustomerInfo,
  setCustomerLevel,
} from "@/network/customer.js";
import { useRoute, useRouter } from "vue-router";
import { getUserList } from "@/network/user.js";
import { Delete, Edit, Plus, Avatar } from "@element-plus/icons-vue";
import { ElMessage, ElMessageBox } from "element-plus";
export default {
  name: "getCustomerInfo",
  components: {
    UserFilled,
    dynamic,
    compInfo,
    orderList,
    trackList,
    contactPersonList,
  },
  setup() {
    const activeName = "first";
    const route = ref(useRoute());
    const cst_id = route.value.query.cstId;
    const state = reactive({
      customersFormDialog: false,
      operate_right: false,
      cstTagsList: [],
      getTags: [],
      getStages: [],
      getGroupList: [],
      userList: [],
    });
    const cstInfo = reactive({
      cst_id: "",
      user_id: "",
      source_id: "",
      level_id: "",
      stage_id: "",
      group_id: "",
    });
    const tagsFormState = reactive({
      tag_id: "",
    });
    const formDate = reactive({
      title: "",
      type: "",
      user_id: "",
      tag_id: "",
    });
    const setFocus = (e) => {
      setCustomerFocus({ cst_id: cst_id, type: e }).then((res) => {
        if (res.code == 0) {
          ElMessage.success(res.msg);
          getList();
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    const setStage = (val) => {
      if (!val) {
        setCustomerStage({
          stage_id: cstInfo.stage_id,
          cst_id: cst_id,
        }).then((res) => {
          if (res.code == 0) {
            ElMessage.success(res.msg);
            getList();
          } else {
            ElMessage.error(res.msg);
          }
        });
      }
    };
    const setGroup = (val) => {
      if (!val) {
        setCustomerGroup({
          group_id: cstInfo.group_id,
          cst_id: cst_id,
        }).then((res) => {
          if (res.code == 0) {
            ElMessage.success(res.msg);
            getList();
          } else {
            ElMessage.error(res.msg);
          }
        });
      }
    };
    const setTags = (val) => {
      formDate.title = "设置客户标签";
      formDate.type = val;
      state.customersFormDialog = true;
      getTagsList().then((res) => {
        if (res.code == 0) {
          state.getTags = res.data;
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    const assignCustomers = (val) => {
      formDate.title = "客户分配设置";
      formDate.type = val;
      state.customersFormDialog = true;
      getUserList().then((res) => {
        if (res.code == 0) {
          state.userList = res.data;
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    const confirmBtn = () => {
      setCustomerInfo({
        data: formDate,
        cst_id: cst_id,
        pre_user: cstInfo.user_id,
      }).then((res) => {
        if (res.code == 0) {
          ElMessage.success(res.msg);
          getList();
        } else {
          ElMessage.error(res.msg);
        }
        state.customersFormDialog = false;
      });
    };
    const delTag = (val) => {
      delCustomerTag({ cst_id: cst_id, id: val }).then((res) => {
        if (res.code == 0) {
          ElMessage.success(res.msg);
          getList();
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    const setLevel = (val) => {
      setCustomerLevel({ cst_id: cst_id, val: val }).then((res) => {
        if (res.code == 0) {
          ElMessage.success(res.msg);
          getList();
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    const getList = () => {
      getCustomerBaseInfo({ cst_id: cst_id }).then((res) => {
        if (res.code == 0) {
          Object.assign(cstInfo, res.data.cstInfo);
          state.cstTagsList = res.data.cstInfo.tags;
          state.operate_right = res.data.operate_right;
        } else {
          ElMessage.error(res.msg);
        }
      });
      getStageList().then((res) => {
        if (res.code == 0) {
          state.getStages = res.data;
        } else {
          ElMessage.error(res.msg);
        }
      });
      getGroupList().then((res) => {
        if (res.code == 0) {
          state.getGroupList = res.data;
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    onMounted(() => {
      getList();
    });
    return {
      ...toRefs(state),
      cstInfo,
      activeName,
      setFocus,
      setTags,
      tagsFormState,
      setStage,
      setGroup,
      assignCustomers,
      formDate,
      confirmBtn,
      delTag,
      setLevel,
    };
  },
};
</script>
<style scoped lang="scss">
:deep #no-border,
:deep #no-border1 {
  box-shadow: none !important;
}
.customerInfo {
  background-color: #ececec;
  padding: 20px;
  box-sizing: border-box;
  .custom-base-info {
    margin-bottom: 23px;
    background: #fff;
    -webkit-box-shadow: 0px 4px 8px rgb(0 0 0 / 4%);
    box-shadow: 0 4px 8px #0000000a;
    border-radius: 8px;
    padding: 30px 24px 27px 30px;
    .base-info-logo {
      width: 105px;
      padding-right: 20px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      .el-icon {
        width: 85px;
        height: 85px;
        margin-right: 10px;
        border-radius: 4px;
        background: #00b8d9;
        color: #fff;
        svg {
          font-size: 50px;
        }
      }
    }
    .name {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #000;
      margin-right: 15px;
    }
    .row1,
    .row2 .row3 {
      display: flex;
      align-items: flex-start;
      height: 28px;
      .el-switch--large {
        height: 28px;
      }
    }
    .row2 {
      position: relative;
      top: -4px;
    }
    .row3 {
      position: relative;
      top: -4px;
    }
    .btns {
      text-align: right;
    }
    :deep .el-rate__item {
      display: flex;
      align-items: center;
    }
    .el-form-item {
      margin-bottom: 0px;
    }
    .sel {
      margin-right: 15px;
    }
    :deep .el-form-item__label {
      line-height: 32px;
    }
    :deep .stage {
      .el-input__inner {
        width: 8em;
      }
    }
  }
  :deep .el-switch__core {
    width: 50px !important;
  }
  .customer-tabs {
    display: flex;
    .contacts-history,
    .fuction-card {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      background: #fff;
      -webkit-box-shadow: 0px 4px 8px rgb(0 0 0 / 4%);
      box-shadow: 0 4px 8px #0000000a;
      border-radius: 8px;
      padding: 20px;
    }
    .contacts-history {
      width: 400px;
    }
    .fuction-card {
      flex: 1;
      margin-right: 20px;
    }
  }
}
</style>
