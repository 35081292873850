<template>
  <div>
    <el-dialog
      :custom-class="'advance-dialog'"
      width="800px"
      :append-to-body="true"
      v-model="dialogVisible"
      @close="dialogClose"
    >
      <template v-slot:title>
        <div class="advance-dialog_title" v-el-drag-dialog>
          {{ dialogTitle }}
        </div>
      </template>
      <div class="component-container">
        <div class="top-search-group">
          <el-form :inline="true" :model="searchObj">
            <el-form-item>
              <el-select v-model="searchObj.key" :placeholder="'请选择'">
                <el-option
                  v-for="option in selectOptions"
                  :key="option.prop"
                  :label="option.value"
                  :value="option.prop"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-input
                v-model="searchObj.keyword"
                :placeholder="'请输入'"
                @keyup.enter="search"
                @change="inputChange"
              >
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button class="search-btn" type="primary" @click="search">
                <em class="el-icon-search"></em>{{ "查询" }}
              </el-button>
              <el-button @click="reset" type="primary" plain>{{
                "重置"
              }}</el-button>
            </el-form-item>
          </el-form>
        </div>
        <table-box
          highlight-current-row
          :selection="false"
          :rows="tableData"
          :columns="columns"
          height="300"
          v-loading="loading"
          @current-change="currentRowChange"
          @row-dblclick="rowDbClick"
        >
        </table-box>
        <div class="pager">
          <el-pagination
            layout="total, prev, pager, next"
            :current-page="page.pageNo"
            :page-size="page.pageSize"
            :total="pageTotal"
            @current-change="currentChange"
          >
          </el-pagination>
        </div>
      </div>
      <template v-slot:footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">{{ "取消" }}</el-button>
          <el-button
            type="primary"
            @click="confirm"
            :disabled="disabledCancel"
            >{{ "确定" }}</el-button
          >
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import request from "@/utils/service";
import tableBox from "../isdTable/index.vue";
import _ from "lodash";
export default {
  name: "lotsBasicDataDialog",
  components: { tableBox },
  props: {
    draggable: {
      type: Boolean,
      default: false,
    },
    disabledDbClick: {
      type: Boolean,
      default: false,
    },
    rejectClose: {
      type: Boolean,
      default: false,
    },
  },
  // 定义抛出的事件名称
  emits: ["confirm"],
  computed: {
    selectOptions() {
      return this.config.advanceConfig || this.config.tableConfig;
    },
    dialogTitle() {
      return this.config.advanceLabel || this.config.name || this.config.label;
    },
  },
  data() {
    return {
      disabledCancel: false,
      dialogVisible: false,
      loading: false,
      page: {
        pageSize: 10,
        pageNo: 1,
      },
      pageTotal: 0,
      searchObj: {},
      tableData: [],
      config: {},
      columns: null,
    };
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.disabledCancel = false;
      }
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      // let params = { ...this.advanceCascade, ...this.page, [this.searchObj.key]: this.searchObj.keyword, tenantCode: this.$store.getters.currentTenant.tenantCode };
      let params = {
        ...this.advanceCascade,
        ...this.page,
        [this.searchObj.key]: this.searchObj.keyword,
      };
      // 添加请求前钩子，用于非标准化基础数据接口对请求参数做数据转换
      const beforeReq = _.get(this.config, "beforeRequest");
      if (beforeReq && _.isFunction(beforeReq)) {
        params = beforeReq(params);
      }
      let res = null;
      if (this.config.method === "post") {
        res = await request({
          method: "post",
          url: `${this.prefix}${this.config.advanceUrl}`,
          data: params,
        });
      } else {
        res = await request({
          method: "get",
          url: `${this.prefix}${this.config.advanceUrl}`,
          params,
        });
      }
      // 添加响应后钩子，用于非标准化基础数据接口做数据转换
      const afterRes = _.get(this.config, "afterResponse");
      if (afterRes && _.isFunction(afterRes)) {
        res = afterRes(res);
      }
      if (res && res.code === "0") {
        if (res.data.totalPage !== 0 && this.page.pageNo > res.data.totalPage) {
          this.page.pageNo = res.data.totalPage;
          this.fetchData();
        } else {
          this.tableData = res.data.list;
          this.pageTotal = res.data.totalCount;
        }
      } else {
        this.tableData = [];
      }
      this.loading = false;
    },
    show(status = true, config, advanceCascade = {}, keyword = "") {
      this.prefix = config.prefix || "/api-mdm/";
      this.dialogVisible = status;
      if (status === false) return;
      if (!config) {
        this.$message.error("弹窗配置对象为空！");
        return;
      }
      this.config = config;
      this.columns = config.tableConfig.map((val) => {
        return {
          ...val,
          label: val.value,
        };
      });
      this.searchObj.key = this.selectOptions[0].prop;
      this.searchObj.keyword = keyword;
      this.advanceCascade = advanceCascade;
      this.fetchData();
    },
    // 页码变化
    currentChange(val) {
      this.page.pageNo = val;
      this.fetchData();
    },
    // 搜索
    search() {
      this.page.pageNo = 1;
      this.fetchData();
    },
    // 重置
    reset() {
      this.searchObj.keyword = "";
      this.fetchData();
    },
    // 表格
    currentRowChange(row) {
      this.currentRow = row;
    },
    // 表格双击
    rowDbClick(row) {
      if (this.disabledDbClick) return false;
      this._emitResult(row);
    },
    confirm() {
      if (!this.currentRow) {
        this.$message.warning("没选中数据");
        return;
      }
      this._emitResult(this.currentRow);
    },
    // 返回选中值
    _emitResult(row) {
      const data = {};
      if (!this.config.cbParams) {
        this.$message.error("弹窗没定义cbParams配置!");
        return;
      }
      for (const item of this.config.cbParams) {
        let { 0: sourceProp, 1: destProp } = item.split("#");
        destProp = destProp || sourceProp;
        data[destProp] = row[sourceProp];
      }
      this.$emit("confirm", data);
      if (this.rejectClose) {
        this.disabledCancel = true;
        return false;
      }
      this.dialogVisible = false;
    },
    inputChange() {},
    dialogClose() {
      // 重置属性
      this.page = {
        pageSize: 10,
        pageNo: 1,
      };
      this.pageTotal = 0;
      this.searchObj = {};
    },
  },
};
</script>

<style lang="scss">
.advance-dialog {
  .el-dialog__header {
    border-bottom: 1px solid #e9e9e9;
  }
  .el-dialog__headerbtn .el-dialog__close {
    color: #000;
  }
}
.advance-dialog_title {
  font-size: 14px;
  color: #333333;
  padding: 0 0 5px;
}
.component-container {
  padding: 0 10px;
  .dialog-footer {
    margin-top: 11px;
    margin-bottom: 6px;
    text-align: right;
  }
  .pager {
    text-align: right;
    margin-top: 13px;
    padding-bottom: 13px;
  }
  .search-btn {
    padding: 7px;
  }
  .iconsearch_Inquire {
    font-size: 12px;
    margin-right: 7px;
  }
}
</style>
