export default {
  selection: false,
  columns: [
    // 表格列的配置
    {
      label: "状态码",
      prop: "ord_status",
    },
    {
      label: "状态中文名",
      prop: "status_ch",
    },
    {
      label: "状态英文名",
      prop: "status_en",
    },
    {
      label: "状态",
      prop: "status",
      mustFill: true,
      type: "select",
      options: { 1: "有效", 0: "关闭" },
    },
    {
      label: "备注",
      prop: "remark",
      mustFill: true,
    },
  ],
  actions: {
    // 表格操作列的配置
    fixedWidth: 110, // fixedWidth（可选） 设置操作列的固定宽度，不加该参数默认是按钮list长度*50px
    list: [
      {
        label: "编辑",
        event: "edit",
        displayRule: () => {
          return true;
        },
      },
      {
        label: "删除",
        event: "del",
        displayRule: () => {
          return true;
        },
      },
    ],
  },
  forms: [
    {
      label: "状态码",
      prop: "ord_status",
      type: "input",
      placeholder: "请输入订单状态码：1,2,3...",
      rule: [
        {
          required: true,
          message: "请输入订单状态码：1,2,3...",
          trigger: "blur",
        },
      ],
    },
    {
      label: "状态中文名",
      prop: "status_ch",
      type: "input",
      rule: [
        {
          required: true,
          message: "请输入订单状态中文名",
          trigger: "blur",
        },
      ],
    },
    {
      label: "状态英文名",
      prop: "status_en",
      type: "input",
      rule: [
        {
          required: true,
          message: "请输入订单状态英文名",
          trigger: "blur",
        },
      ],
    },
    {
      label: "账户状态:",
      prop: "status",
      type: "switch",
      modelValue: "1",
      activeText: "是",
      inactiveText: "否",
      rule: [{ required: true }],
    },
    {
      label: "备注",
      prop: "remark",
      type: "input",
      inputType: "textarea",
    },
  ],
};
