import { request } from "./request.js";

export function Login(data = {}) {
  return request({
    url: "login/Login",
    method: "post",
    data,
  });
}
export function userList(data = {}) {
  return request({
    url: "index/userList",
    method: "post",
    data,
  });
}
export function userAdd(data = {}) {
  return request({
    url: "index/userAdd",
    method: "post",
    data,
  });
}
export function userDel(data = {}) {
  return request({
    url: "index/userDel",
    method: "post",
    data,
  });
}
export function userInfo(data = {}) {
  return request({
    url: "index/userInfo",
    method: "post",
    data,
  });
}
export function getUserList(data = {}) {
  return request({
    url: "index/getUserList",
    method: "post",
    data,
  });
}
export function getUserS(data = {}) {
  return request({
    url: "index/getUserS",
    method: "post",
    data,
  });
}
export function groupList(data = {}) {
  return request({
    url: "index/groupList",
    method: "post",
    data,
  });
}
export function groupAdd(data = {}) {
  return request({
    url: "index/groupAdd",
    method: "post",
    data,
  });
}
export function groupDel(data = {}) {
  return request({
    url: "index/groupDel",
    method: "post",
    data,
  });
}
export function getGroupList(data = {}) {
  return request({
    url: "index/getGroupList",
    method: "post",
    data,
  });
}
export function getLevelList(data = {}) {
  return request({
    url: "index/getLevelList",
    method: "post",
    data,
  });
}
