<template>
  <div style="height: 100%; padding: 10px">
    <div style="text-align: center; font-size: 30px; margin-bottom: 30px">
      {{ userInfo.user_name_ch }} {{ userInfo.month }} 工资详情
    </div>
    <div>
      <div class="div_titel">员工信息:</div>
      <el-row :gutter="24" style="margin: 20px 0px">
        <el-col :span="8">员工名字：{{ userInfo.user_name_ch }}</el-col>
        <el-col :span="8">业务等级：{{ userInfo.level_name }} </el-col>
        <el-col :span="8">归属部门：{{ userInfo.group_name_ch }}</el-col>
      </el-row>
      <el-row :gutter="24" style="margin: 20px 0px">
        <el-col :span="8">开户名称：{{ userInfo.user_name_ch }}</el-col>
        <el-col :span="8">开户银行：中国工商银行 </el-col>
        <el-col :span="8">工资卡号：{{ userInfo.user_bank_card }}</el-col>
      </el-row>
    </div>
    <div class="div_titel">提成详情:</div>
    <div class="content">
      <el-table
        :data="orderInfo"
        show-summary
        style="width: 100%; font-size: 12px"
        height="500px"
      >
        <el-table-column prop="ord_no" label="订单编码" width="300" />
        <el-table-column prop="orderTotalIncome" label="订单总收入" />
        <el-table-column prop="orderProductCost" label="采购总支出" />
        <el-table-column prop="orderFinanceCost" label="财务总支出" />
        <el-table-column prop="orderShippingCost" label="物流总支出" />
        <el-table-column prop="orderTotalCost" label="订单总支出" />
        <el-table-column prop="grossProfit" label="订单毛利率" />
        <el-table-column prop="ratio" label="提成比例" />
        <el-table-column prop="commission" label="提成金额" />
      </el-table>
    </div>
    <div class="div_titel" style="margin: 20px 0px">提成汇总:</div>
    <el-row :gutter="24" style="margin: 20px 0px">
      <el-col :span="8">本月总销售额：{{ total_info.total_income }}</el-col>
      <el-col :span="8">本月总毛利率：{{ total_info.total_gross }}</el-col>
      <el-col :span="8">本月总提成额：{{ total_info.total_commission }}</el-col>
    </el-row>
    <div style="margin: 20px 0px">&nbsp;</div>
  </div>
</template>
<script>
import { ref, reactive, toRefs, onMounted } from "vue";
import { ElMessage } from "element-plus";
import { showSalaryDetails } from "@/network/showDetails.js";
import { useRoute } from "vue-router";
export default {
  components: {},
  setup() {
    const route = ref(useRoute());
    const state = reactive({
      userInfo: [],
      orderInfo: [],
      total_info: [],
      userInfo: [],
    });
    const printInfo = route.value.query.id;
    const getList = () => {
      showSalaryDetails({ data: printInfo }).then((res) => {
        if (res.code == 0) {
          state.userInfo = res.data.userInfo;
          state.orderInfo = res.data.orderInfo;
          state.total_info = res.data.total_info;
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    onMounted(() => {
      getList();
    });
    return {
      ...toRefs(state),
    };
  },
};
</script>
<style scoped>
.div_titel {
  font-size: 14px;
  font-weight: 400;
  border-left: 5px solid darkorange;
  padding-left: 5px;
}
.el-table__body-wrapper {
  height: auto !important;
}
</style>
