<template>
  <div class="flex-layout order-management">
    <isdListPaper v-loading="tableLoading">
      <template v-slot:header-button>
        <el-button type="primary" @click="editCustomer">
          <el-icon style="vertical-align: middle"> <Plus /> </el-icon
          ><span style="vertical-align: middle"> 新建客户 </span>
        </el-button>
      </template>
      <template v-slot:header-search>
        <seach
          :searchConfig="searchConfig"
          @queryClick="searchList"
          @resetClick="searchList"
        ></seach>
      </template>
      <template v-slot:list="props">
        <isdTable
          :loading="tableLoading"
          :rows="totalData"
          :height="props.tableHeight"
          :columns="config.columns"
          :selection="config.selection"
          :actions="config.actions"
          @action-click="actionClick"
          @selection-change="handleSelectedRow"
          style="font-size: 12px"
        >
          <template v-slot:col-append="{ col, row }">
            <div
              v-if="col.type === 'compName'"
              @click="showCstInfo(row.cst_id)"
              style="color: #4285f5; cursor: pointer"
            >
              {{ row[col.prop] }}
            </div>
            <span v-if="col.type === 'cstStar'">
              <el-rate v-model="row.level_id" @change="setLevel(row)"></el-rate>
            </span>
            <span v-if="col.type === 'operate_btn'">
              <el-button round size="small" @click="editCustomer(row)"
                ><el-icon style="vertical-align: middle"> <EditPen /> </el-icon
                ><span style="vertical-align: middle"> 编辑 </span></el-button
              >
              <el-button round size="small" @click="addOrder(row.cst_id)"
                ><el-icon style="vertical-align: middle"><Plus /></el-icon
                ><span style="vertical-align: middle"> 做单 </span></el-button
              >
              <el-button round size="small" @click="customerSet(row.cst_id)"
                ><el-icon style="vertical-align: middle"><Setting /></el-icon
                ><span style="vertical-align: middle"> 设置 </span></el-button
              >
            </span>
            <span v-if="col.type === 'cstStags'">
              <el-row>
                <el-tag
                  v-for="tag in row.tags"
                  :key="tag.id"
                  style="margin-right: 10px"
                  :type="tag.type"
                  @close="delTag(tag.id)"
                >
                  {{ tag.tag_name_ch }}
                </el-tag>
              </el-row>
            </span>
          </template>
        </isdTable>
      </template>
      <template v-slot:footer-buttons>
        <el-button
          type="danger"
          plain
          @click="deleteItem"
          :disabled="!selectedRows.length"
          ><el-icon><Delete /></el-icon>
          <span style="vertical-align: middle"> 移入公海 </span>
        </el-button>
      </template>
      <template v-slot:footer>
        <isdPagination
          @size-change="sizeChange"
          :current-page="currentPage"
          @current-change="pageChange"
          layout="total,sizes,prev,pager,next,jumper"
          :page-size="pageSize"
          :page-sizes="[20, 50, 100]"
          :total="pageTotal"
        ></isdPagination>
      </template>
    </isdListPaper>
    <!-- 新增/编辑弹窗 -->
    <isdFormDialog
      ref="formDialogIns"
      :config="config.forms"
      :loading="formDialog.loading"
      :label-width="formDialog.labelWidth"
      :row="formDialog.row"
      v-model="formDialog.show"
      :col="formDialog.col"
      :title="formDialog.title"
      :width="formDialog.width"
      @close-dialog="closeFormDialog"
    ></isdFormDialog>
  </div>
</template>
<script>
import {
  customerList,
  customerAdd,
  customerDel,
  getCstNationList,
  getCstSourceList,
  getCstLevelList,
  setCustomerLevel,
} from "@/network/customer.js";
import { getUserS } from "@/network/user.js";
import { EditPen, Delete, Plus, Setting } from "@element-plus/icons-vue";
import { defineComponent, ref, reactive, onMounted, toRefs } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import { ListClass } from "@/composition/ListClass.js";
import { searchConfig } from "@/common/customerSearchConfig.js";
import configData from "./config";
import isdTable from "@/views/components/isdTable/index.vue";
import isdPagination from "@/views/components/isdPage/index.vue";
import isdListPaper from "@/views/components/isdListPaper/index.vue";
import isdFormDialog from "@/views/components/isdFormDialog/Index.vue";
import isdSearchBox from "@/views/components/isdSearchBox/index.vue";
import seach from "@/components/seach.vue";
export default defineComponent({
  components: {
    EditPen,
    Delete,
    Plus,
    Setting,
    isdTable,
    isdPagination,
    isdSearchBox,
    isdListPaper,
    isdFormDialog,
    seach,
  },
  setup() {
    const formDialogIns = ref(null);
    const config = reactive(configData); // 列表配置
    const searchBoxIns = ref(null); // 搜索实例
    const columnConfigIns = ref(null); // 列配置实例
    const statusDialogIns = ref(null); // 状态弹框实例
    const detailPageIns = ref(null); // 详情编辑页ref
    const tableBox = ref(null);
    // 新建/修改弹窗对象
    const formDialog = reactive({
      labelWidth: "110px", // 弹窗label宽度
      loading: false, // 表单加载中显示
      row: {}, // 数据项对象
      show: false, // 显示弹窗
      col: 1, // 弹窗内列数
      title: "新建", // 弹窗标题
      width: "70%", // 弹窗宽度
    });
    // 格式化查询条件
    const dataFormat = (condition) => {
      condition.symbolType = "=";
      condition.listType = 1;
      condition.inquiryType = "allCustomerList";
      return condition;
    };
    class TableList extends ListClass {
      beforeGetList(condition) {
        condition = dataFormat(condition);
        return condition;
      }
    }
    const {
      currentPage,
      pageNo,
      pageSize,
      pageTotal,
      tableLoading,
      totalData,
      getList,
      searchList,
      pageChange,
      sizeChange,
      selectedRows,
      handleSelectedRow,
    } = new TableList({
      Api: {
        listApi: customerList,
      },
    });
    const actionClick = (event, row) => {
      console.log(row, 999999);
      switch (event) {
        case "add":
          addOrder(row.cst_id);
          break;
        case "edit":
          editCustomer(row);
          break;
        case "set":
          customerSet(row.cst_id);
          break;
      }
    };
    const router = useRouter();
    const showCstInfo = (val) => {
      let routeUrl = router.resolve({
        name: "getCustomerInfo",
        query: {
          cstId: val,
        },
      });
      window.open(routeUrl.href, "_blank");
    };
    const addOrder = (val) => {
      router.push({
        name: "addOrder",
        params: {
          cstId: val,
        },
      });
    };
    const customerSet = (val) => {
      router.push({
        name: "customerSet",
        query: {
          cstId: val,
        },
      });
    };
    const setLevel = (val) => {
      setCustomerLevel({ cst_id: val.cst_id, val: val.level_id }).then(
        (res) => {
          if (res.code == 0) {
            ElMessage.success(res.msg);
            getList();
          } else {
            ElMessage.error(res.msg);
          }
        }
      );
    };
    const editCustomer = (val) => {
      getUserS().then((res) => {
        if (res.code == 0) {
          config.forms[4].options = res.data;
        } else {
          ElMessage.error(res.msg);
        }
      });
      getCstNationList().then((res) => {
        if (res.code == 0) {
          config.forms[3].options = res.data;
        } else {
          ElMessage.error(res.msg);
        }
      });
      getCstSourceList().then((res) => {
        if (res.code == 0) {
          config.forms[5].options = res.data;
        } else {
          ElMessage.error(res.msg);
        }
      });
      getCstLevelList().then((res) => {
        if (res.code == 0) {
          config.forms[6].options = res.data;
        } else {
          ElMessage.error(res.msg);
        }
      });
      formDialog.show = true;
      formDialog.title = "新增/更新客户信息";
      formDialog.row = val;
    };
    const closeFormDialog = (params) => {
      if (!params) {
        closeAction();
        return;
      }
      saveItem(params);
    };
    const closeAction = () => {
      formDialog.show = false;
      formDialog.row = {};
      formDialog.loading = false;
    };
    const saveItem = (params) => {
      // 新增/更新
      if (!params) {
        closeAction();
        return;
      }
      formDialog.loading = true;
      // 编辑
      customerAdd(params).then((res) => {
        if (res.code == 0) {
          formDialog.loading = false;
          getList();
          ElMessage.success(res.msg);
          closeAction();
        } else {
          ElMessage.error(res.msg);
          formDialog.loading = false;
        }
      });
    };
    const deleteItem = () => {
      const map = new Map();
      const arr = selectedRows.value.map((item) => {
        return item.cst_id; // 只返回id号
      });
      //方法
      if (!arr) {
        ElMessage("所选数据为空，请重新选择！");
      } else {
        ElMessageBox.confirm("确定要选中的客户移入公海吗?", "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            customerDel({ cstIDs: arr, del_type: 2 }).then((res) => {
              if (res.code == 0) {
                ElMessage.success(res.msg);
                getList();
              } else {
                ElMessage.error(res.msg);
                getList();
              }
            });
          })
          .catch(() => {
            ElMessage({
              type: "info",
              message: "移除取消",
            });
          });
      }
    };
    onMounted(() => {
      getList();
    });
    return {
      tableBox,
      config,
      pageNo,
      pageSize,
      pageTotal,
      tableLoading,
      totalData,
      searchList,
      pageChange,
      sizeChange,
      handleSelectedRow,
      searchBoxIns,
      columnConfigIns,
      detailPageIns,
      actionClick,
      statusDialogIns,
      formDialog,
      closeFormDialog,
      editCustomer,
      currentPage,
      formDialogIns,
      deleteItem,
      selectedRows,
      addOrder,
      showCstInfo,
      customerSet,
      setLevel,
      searchConfig,
    };
  },
});
</script>

<style lang="scss">
.order-management {
  .el-table th > .cell {
    word-break: keep-all;
  }
}
</style>
../../../components/seach.vue
