export default {
  selection: false,
  columns: [
    // 表格列的配置
    {
      label: "归属月份",
      prop: "month",
      fixed: true,
    },
    {
      label: "归属人",
      prop: "user_name_ch",
    },
    {
      label: "基本工资",
      prop: "basic_salary",
    },
    {
      label: "计薪天数",
      prop: "paid_days",
    },
    {
      label: "请假天数",
      prop: "leave_days",
    },
    {
      label: "提成收入",
      prop: "commission_income",
    },
    {
      label: "其他收入",
      prop: "other_salary",
    },
    {
      label: "社保支出",
      prop: "social_security",
    },
    {
      label: "公积金",
      prop: "housing_fund",
    },
    {
      label: "工资税费",
      prop: "taxes",
    },
    {
      label: "实际工资",
      prop: "received_salary",
    },
    {
      label: "状态",
      prop: "status",
      type: "select",
      options: { 0: "作废", 1: "待发起", 2: "待支付", 3: "已办结" },
    },
    {
      label: "创建时间",
      prop: "create_time",
    },
    {
      label: "操作",
      type: "operate_btn",
      prop: "",
      width: "220px",
    },
  ],
};
