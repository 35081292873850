<template>
  <div class="flex-layout order-management">
    <isdListPaper v-loading="tableLoading">
      <template v-slot:header-button>
        <el-button
          type="primary"
          :disabled="!selectedRows.length"
          @click="createInvoice"
        >
          生成发票
        </el-button>
      </template>
      <template v-slot:header-search>
        <isdSearchBox
          ref="searchBoxIns"
          :search-key="config.name"
          :fields="config.searchFields"
          @search-change="searchList"
          :loading="tableLoading"
          class="track-search"
        ></isdSearchBox>
      </template>
      <template v-slot:list="props">
        <isdTable
          :loading="tableLoading"
          :rows="totalData"
          :height="props.tableHeight"
          :columns="config.columns"
          :selection="config.selection"
          :actions="config.actions"
          @selection-change="handleSelectedRow"
          style="font-size: 12px"
        >
          <template v-slot:col-append="{ col, row }">
            <span v-if="col.type === 'operate_btn'">
              <el-button
                round
                size="small"
                @click="confirmInvoice(row.ord_list_id)"
                ><el-icon style="vertical-align: middle"><Select /></el-icon>
                <span style="vertical-align: middle"> 已生成 </span>
              </el-button>
            </span>
          </template>
        </isdTable>
      </template>
      <template v-slot:footer>
        <isdPagination
          @size-change="sizeChange"
          :current-page="currentPage"
          @current-change="pageChange"
          layout="total,sizes,prev,pager,next,jumper"
          :page-size="pageSize"
          :page-sizes="[20, 50, 100]"
          :total="pageTotal"
        ></isdPagination>
      </template>
    </isdListPaper>
  </div>
</template>
<script>
import { invoiceWaitList, confirmInvoiceList } from "@/network/invoice.js";
import { defineComponent, ref, reactive, onMounted } from "vue";
import { Select } from "@element-plus/icons-vue";
import { useRouter } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import { ListClass } from "@/composition/ListClass.js";
import configData from "./config";
import isdTable from "@/views/components/isdTable/index.vue";
import isdPagination from "@/views/components/isdPage/index.vue";
import isdListPaper from "@/views/components/isdListPaper/index.vue";
import isdFormDialog from "@/views/components/isdFormDialog/Index.vue";
import isdSearchBox from "@/views/components/isdSearchBox/index.vue";
export default defineComponent({
  components: {
    Select,
    isdTable,
    isdPagination,
    isdSearchBox,
    isdListPaper,
  },
  setup() {
    const config = reactive(configData); // 列表配置
    const searchBoxIns = ref(null); // 搜索实例
    const columnConfigIns = ref(null); // 列配置实例
    const statusDialogIns = ref(null); // 状态弹框实例
    const detailPageIns = ref(null); // 详情编辑页ref
    const tableBox = ref(null);
    // 格式化查询条件
    const dataFormat = (condition) => {
      condition.symbolType = ">";
      condition.listType = 1;
      return condition;
    };
    class TableList extends ListClass {
      beforeGetList(condition) {
        condition = dataFormat(condition);
        return condition;
      }
    }
    const {
      currentPage,
      pageNo,
      pageSize,
      pageTotal,
      tableLoading,
      totalData,
      getList,
      searchList,
      pageChange,
      sizeChange,
      selectedRows,
      handleSelectedRow,
    } = new TableList({
      Api: {
        listApi: invoiceWaitList,
      },
    });
    const router = useRouter();
    const createInvoice = () => {
      // 生成发票
      const map = new Map();
      const qc = selectedRows.value.filter(
        (key) => !map.has(key.cst_id) && map.set(key.cst_id, 1)
      );
      const arr = selectedRows.value.map((item) => {
        return item.ord_list_id; // 只返回运单id号
      });
      if (qc.length == 1) {
        router.push({
          name: "createInvoice",
          query: {
            arrData: arr,
          },
        });
      } else {
        ElMessage.error("不同客户不能合并生成发票！");
      }
    };
    const confirmInvoice = (val) => {
      ElMessageBox.confirm("确定要将该笔订单设置为已做发票吗?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          confirmInvoiceList({ id: val, type: 1 }).then((res) => {
            if (res.code == 0) {
              ElMessage.success(res.msg);
              getList();
            } else {
              ElMessage.error(res.msg);
            }
          });
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "设置取消",
          });
        });
    };
    onMounted(() => {
      getList();
    });
    return {
      tableBox,
      config,
      pageNo,
      pageSize,
      pageTotal,
      tableLoading,
      totalData,
      searchList,
      pageChange,
      sizeChange,
      handleSelectedRow,
      searchBoxIns,
      columnConfigIns,
      detailPageIns,
      statusDialogIns,
      currentPage,
      selectedRows,
      createInvoice,
      confirmInvoice,
    };
  },
});
</script>

<style lang="scss">
.order-management {
  .el-table th > .cell {
    word-break: keep-all;
  }
}
</style>
