<template>
    <div class="lots-tabs-address">
        <div class="select-container">
            <el-popover
                v-model:visible="visible"
                popper-class="del-popper-class"
                placement="bottom-start"
                :width="480"
                :disabled="disabled"
                :trigger="triggerEvent"
                @show="showClick"
                @hide="show = false;">
                <div class="address-tab-container">
                    <el-tabs v-model="activeName" type="card" ref="tabs">
                        <el-tab-pane v-if="hotData.length" label="热门省市" :key="0" name="0">
                            <div class="ulSorll">
                                <ul>
                                    <li v-for="(item) in hotData" :key="item.label">
                                        <span :class="{ active: item.leaf }" @click="hotCityClick(item)">{{item.label}}</span>
                                    </li>
                                </ul>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane v-for="(item, index) in showTabItem" :key="hotData.length ? index + 1 : index" :label="item.label" :name="String(hotData.length ? index + 1 : index)">
                            <div class="ulSorll">
                                <ul>
                                    <li v-if="showNoChoiceBtn">
                                        <span class="Cactive" @click="confirmSuccess(1)">暂不选择</span>
                                    </li>
                                    <li v-for="(listitem, index) in item.dataArr" :key="index">
                                        <span :class="{ active: listitem.leaf }" :title="listitem.label.length > 6 ? listitem.label : ''"
                                            @click="nextLevel(listitem, item.name)">{{listitem.label}}</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="footer-btn" v-if="item.sumMeuber">
                                <div class="right-btn" style="float: right;">
                                    <el-button @click="visible = false">取消</el-button>
                                    <el-button type="primary" @click="confirmSuccess(1)">确认</el-button>
                                </div>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>
                <template v-slot:reference>
                    <el-input
                        @blur="show=false"
                        @mouseenter="haveValue"
                        :suffix-icon="hasVal ? '': icon"
                        class="del-input-class"
                        :placeholder="placeholder"
                        :clearable="clearable"
                        v-model="inputVal"
                        :disabled="disabled"
                        @clear="visibleChange">
                    </el-input>
                </template>
            </el-popover>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import { getAreaApi } from '@/utils/api.js';
import { ElMessage } from 'element-plus';
import { defineComponent, reactive, ref, watch, computed, nextTick } from 'vue';
const AREA_TYPE = ['PLACE_PROVINCE', 'PLACE_CITY', 'PLACE_DISTRICT', 'PLACE_STREET'];
const PROVINCE_DEFAULT_PMCODE = '100000'; // 省的 ebplParentPmCode
const LEAF_INDEX = 4;
export default defineComponent({
    name: 'lotsTabsAddress',
    props: {
        modelValue: {
            type: [Array, String],
            defalut() {
                return [];
            }
        },
        basicProps: {
            type: Object,
            default() {
                return {};
            }
        },
        minLayers: {
            type: Number,
            default() {
                return 0;
            }
        },
        placeholder: String,
        disabled: {
            type: Boolean,
            default() {
                return false;
            }
        },
        clearable: {
            type: Boolean,
            default() {
                return true;
            }
        },
        triggerEvent: {
            type: String,
            default() {
                return 'click';
            }
        },
        hotData: {
            type: Array,
            default() {
                return [];
            }
        },
        showNoChoice: {
            type: Boolean,
            default: false
        }
    },
    emits: ['update:modelValue', 'input', 'getAddress'],
    // eslint-disable-next-line max-lines-per-function
    setup(props, context) {
        const tabs = ref(null);
        const addressArr = ref([]);
        const showbtn = ref(true);
        const AREA_MAP = ref(new Map());
        const inputVal = ref('');
        const visible = ref(false);
        const show = ref(false);
        const activeName = ref('0');
        const checkActive = ref(Number('0'));
        const labelName = ref('请选择');
        const hotObj = ref({});
        const tabObj = ref({
            label: '请选择',
            name: '0',
            tabPane: true,
            sumMeuber: false,
            dataArr: []
        });
        const tabArr = ref([{
            label: '请选择',
            name: '0',
            tabPane: true,
            sumMeuber: false,
            dataArr: []
        }]);
        const addressOptions = ref([]);
        const addressLabel = ref([]);
        const hasVal = ref(false);
        const goodset = reactive({
            addressArr, showbtn, AREA_MAP, inputVal, visible, show, activeName, checkActive, labelName, hotObj, tabObj, tabArr, addressOptions, addressLabel, hasVal
        });
        const optionsFormat = (item, isLeaf) => {
            return {
                label: item.ebplNameCn,
                value: item.ebplCode,
                ebPCode: item.ebplParentPmCode,
                leaf: isLeaf
            };
        };
        const propsConfig = computed(() => {
            return { ...props.basicProps };
        });
        const leafIndex = computed(() => {
            return propsConfig.value.checkStrictly ? LEAF_INDEX : props.minLayers;
        });
        const showRightBtn = () => {
            // 根据配置是否满足条件
            goodset.tabArr.forEach((items, index) => {
                if ((index + 1) >= props.minLayers && propsConfig.value.checkStrictly) {
                    items.sumMeuber = true;
                } else if ((index + 1) > props.minLayers && !propsConfig.value.checkStrictly) {
                    items.tabPane = false;
                } else {
                    items.sumMeuber = false;
                }
            });
        };
        const addressFormat = (arr) => {
            let result = [];
            if (_.isArray(arr)) {
                result = arr.map(item => goodset.AREA_MAP.get(item));
            }
            return result;
        };
        // 输入框变化监听事件
        const changeInput = (val) => {
            context.emit('input', val);
            context.emit('update:modelValue', val);
            context.emit('getAddress', addressFormat(val));
            if (props.basicProps.checkStrictly) {
                goodset.visible = false;
                goodset.show = false;
            }
        };
        const confirmSuccess = (status) => {
            const strValArr = [];
            const strValLabel = [];
            let getIndex = '';
            const addressOption = JSON.parse(JSON.stringify(goodset.addressOptions));
            if (addressOption.length) {
                if (status === 1 && addressOption[addressOption.length - 1].leaf) {
                    getIndex = Number(goodset.activeName) + 1;
                } else {
                    getIndex = status === 1 ? Number(goodset.activeName) : Number(goodset.activeName) + 1;
                }
                addressOption.slice(0, getIndex).forEach((item) => {
                    strValArr.push(item.value);
                    strValLabel.push(item.label);
                });
                goodset.inputVal = '';
                goodset.inputVal = strValLabel.join('/');
                changeInput(strValArr);
                goodset.visible = false;
                goodset.show = false;
            } else {
                ElMessage.warning('请选择地址');
            }
        };
        const getLazyLoad = (data, levNum) => {
            const level = levNum;
            const params = {
                ebplType: AREA_TYPE[level]
            };
            if (level === 0) {
                params.ebplParentPmCode = PROVINCE_DEFAULT_PMCODE;
            } else {
                params.ebplParentPmCode = data.value;
            }
            getAreaApi(params).then((res) => {
                if (res && res.code === '0') {
                    const nodes = res.data.map((item) => {
                    // 将查询之后的区域对象放到一个map中，给地址code解析地址名称用
                        AREA_MAP.value.set(item.ebplCode, item.ebplNameCn);
                        return optionsFormat(item, false);
                    });
                    const getNum = leafIndex.value;
                    showRightBtn(getNum);
                    goodset.tabArr[levNum].dataArr = nodes;
                    if (nodes.length === 0) {
                        confirmSuccess();
                    }
                }
            });
        };
        const nextLevel = (data, name) => {
            const tabArr = goodset.tabArr;
            const newsIndex = Number(name) + 1;
            goodset.newsIndex = newsIndex;
            const tabItem = tabArr.find(item => item.name === name);
            if (tabItem) {
                setChildrenData(tabItem, data);// 多了个参数
                data.leaf = true;
                tabItem.label = data.label.substring(Number('0'), Number('6'));
                // 判断是否存在下一级
                if (newsIndex < leafIndex.value) {
                    goodset.showbtn = true;
                    goodset.tabArr[newsIndex] = listItemObj(newsIndex);
                    getLazyLoad(data, newsIndex);
                    goodset.activeName = props.hotData.length > 0 ? String(Number(tabArr[newsIndex].name) + 1) : tabArr[newsIndex].name;
                    data.leaf = tabArr[newsIndex].tabPane = true;
                    tabArr[newsIndex].label = goodset.labelName;
                    tabArr.forEach((itemSum, indexSum) => {
                        if (indexSum > newsIndex) {
                            itemSum.tabPane = false;
                        }
                    });
                }
                goodset.addressOptions = goodset.addressOptions.slice(0, newsIndex - 1);
                goodset.addressOptions.push(data);
                if (newsIndex >= leafIndex.value) {
                    confirmSuccess();
                }
            }
        };
        // 初始化样式
        const setChildrenData = (item) => {
            item.dataArr.forEach(itemKey => {
                itemKey.leaf = false;
            });
        };
        const listItemObj = (index) => {
            return {
                label: '请选择',
                name: String(index),
                tabPane: true,
                sumMeuber: false,
                dataArr: []
            };
        };
        const visibleChange = () => {
            goodset.visible = false;
            goodset.hasVal = false;
            goodset.inputVal = '';
            context.emit('input', []);
            context.emit('getAddress', []);
            context.emit('update:modelValue', []);
        };
        // 3 5 7 10 对应省、市、县镇、街截取的长度，处理成如下格式: ['144', '14410']
        const handleAddressArr = (val) => {
            return [Number('3'), Number('5'), Number('7'), Number('10')].map(item => val.length >= item ? val.substr(0, item) : '').filter(item => item);
        };
        // 处理兼容val可能为['1441010110']、['1441010']
        const isAddressArray = (val) => {
            if (val.length && val[0].length > Number('3')) {
                // val[0]为省code， 一般为三位数，超过3位数则为需要兼容的数据
                // 对错误数据不做处理
                goodset.addressArr = handleAddressArr(val[0]);
            } else {
                goodset.addressArr = val;
            }
        };
        const showClick = () => {
            goodset.show = true;
            goodset.showbtn = false;
            goodset.tabArr = [];
            goodset.activeName = '0';
            if (!props.modelValue || props.modelValue === '' || props.modelValue.length === 0) {
                goodset.tabArr.push(goodset.tabObj);
                setChildrenData(goodset.tabArr[0]);
                goodset.tabArr[0].label = (props.hotData.length > 0) ? '省份' : '请选择';
                getLazyLoad({}, 0);
                if (props.hotData) {
                    props.hotData.forEach((item) => {
                        item.leaf = false;
                    });
                }
            } else {
                init(props.modelValue);
            }
        };
        /**
         * @param {String、Array} value  地址code
         * String： 地址code 如： "1441010"、 '1441010110'
         * Array：省、市、县、镇街的code  如： [144, 10, 10, 110]
         */
        const init = async (value) => {
            goodset.addressOptions = [];
            goodset.addressLabel = [];
            // 判断传进来的v-model的值是数组或者字符串
            if (_.isString(value)) {
                // 对传入的字符串进行是省市区哪个级别的处理
                goodset.addressArr = handleAddressArr(value);
            } else if (_.isArray(value)) {
                isAddressArray(value);
            }
            // 递归获取默认值options
            await getArea(+PROVINCE_DEFAULT_PMCODE, goodset.addressArr, 0);
        };
        // 获取地址列表---可以优化
        const getArea = async (ebplParentPmCode, addressArr, level) => {
            goodset.activeName = (props.hotData.length > 0) ? String(level) : String(level - 1); // 回显打开最后项
            // 根据地址的层级获取数据
            if (!addressArr[level]) { return []; };
            const res = await getAreaApi({
                ebplParentPmCode,
                ebplType: AREA_TYPE[level]
            });
            if (res && res.code === '0') {
                const nodes = res.data.map(item => {
                    goodset.AREA_MAP.set(item.ebplCode, item.ebplNameCn);
                    return optionsFormat(item, false);
                });
                goodset.tabArr[level] = listItemObj(level);
                // 获取当前选中的项被获取子地址列表
                const nextNode = nodes.find(item => item.value === addressArr[level]);
                if (nextNode) {
                    nextNode.leaf = true;
                    goodset.tabArr[level].dataArr = nodes;
                    goodset.tabArr[level].label = nextNode.label;
                    goodset.addressOptions.push(nextNode);
                    goodset.addressLabel[level] = nextNode.label;
                    goodset.hotObj = nextNode;
                    if (addressArr.length - 1 === level) {
                        goodset.inputVal = goodset.addressLabel.join('/');
                    }
                    await getArea(nextNode.value, addressArr, ++level);
                }
                if (nextNode) {
                    const getNum = leafIndex.value;
                    showRightBtn(getNum);
                }
                return nodes;
            }
        };
        const hotCityClick = async (quary) => {
            const { data } = quary;
            const vindex = String(data.length - 1);
            await init(data);
            props.hotData.forEach((item) => {
                if (item.label === quary.label) {
                    item.leaf = true;
                } else {
                    item.leaf = false;
                }
            });
            nextLevel(goodset.hotObj, vindex); // 点击单击热门城市则跳转到所选城市对应的区县级选择页面 ok
        };
        const showTabItem = computed(() => {
            return goodset.tabArr.filter(item => item.tabPane);
        });
        const haveValue = () => {
            if (goodset.inputVal) {
                goodset.hasVal = true;
            }
        };
        const icon = computed(() => {
            return goodset.show === false ? 'el-icon-arrow-down' : 'el-icon-arrow-up';
        });
        const showNoChoiceBtn = computed(() => {
            const sunMeuberBtnm = showTabItem.value[((props.hotData.length > 0) ? Number(goodset.activeName) - 1 : Number(goodset.activeName)) || 0];
            const state = (sunMeuberBtnm && sunMeuberBtnm.sumMeuber) && goodset.showbtn && ((Number(goodset.activeName) + 1) === goodset.checkActive) && props.showNoChoice;
            return state;
        });
        watch(() => props.modelValue,
            (newValue) => {
                if (newValue) {
                    goodset.inputVal = '';
                    init(newValue);
                } else {
                    goodset.addressArr = [];
                    goodset.inputVal = '';
                    context.emit('input', []);
                }
            });
        watch(activeName, (newValue) => {
            nextTick(() => {
                goodset.checkActive = tabs.value.panes.length; // 可选总层数
            });
        });
        return {
            tabs,
            addressArr,
            showbtn,
            AREA_MAP,
            inputVal,
            visible,
            show,
            activeName,
            checkActive,
            labelName,
            hotObj,
            tabObj,
            tabArr,
            addressOptions,
            addressLabel,
            goodset,
            optionsFormat,
            propsConfig,
            leafIndex,
            showRightBtn,
            addressFormat,
            changeInput,
            confirmSuccess,
            getLazyLoad,
            nextLevel,
            setChildrenData,
            listItemObj,
            visibleChange,
            handleAddressArr,
            isAddressArray,
            showClick,
            init,
            getArea,
            hotCityClick,
            showTabItem,
            icon,
            showNoChoiceBtn,
            haveValue,
            hasVal
        };
    }
});
</script>
<style lang="scss">
.lots-tabs-address {
    position: relative;
    .del-input-class {
        .el-input__inner{
            cursor: pointer;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}
.del-popper-class{
    .address-tab-container{
        background: #fff;
        .ulSorll{
            height: 256px;
            ul{
                display: flex;
                flex-wrap: wrap;
                padding: 0;
                list-style-type: none;
            li{
                width: 25%;
                font-size: 12px;
                color: #666666;
                cursor: pointer;
                margin-bottom: 4px;
                span{
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    max-width: 80%;
                    display: inline-block;
                    padding: 2px;
                }
                .active{
                    background: #66b1ff;
                    color: #fff;
                }
                .Cactive{
                    color: #409EFF;
                }
                .Cactive:active{
                    background: #66b1ff;
                    color: #fff;
                }
            }
        }
     }
    }
    .el-tabs__item {
        padding: 0 16px;
        height: 32px;
        line-height: 32px;
        font-size: 12px;
    }
    .el-tabs__nav-next, .el-tabs__nav-prev {
        line-height: 32px;
    }
}
</style>
