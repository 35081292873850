<template>
  <div style="height: 100%">
    <div style="text-align: center; margin: 20px; font-size: 30px">
      应收款编码 {{ list_no }}
    </div>
    <el-row>
      <el-col :span="24">
        <isdTable
          show-summary
          :loading="tableLoading"
          :rows="totalData"
          :height="600"
          :columns="config.columns"
          :selection="config.selection"
          :actions="config.actions"
          style="font-size: 12px"
        >
          <template v-slot:col-append="{ col, row }">
            <span
              class="actionBtn"
              v-if="col.type === 'operate_btn'"
              @click="editAmount(row)"
            >
              <el-button type="text">{{ btn_title }}</el-button>
            </span>
          </template>
        </isdTable>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <isdPagination
          @size-change="sizeChange"
          :current-page="currentPage"
          @current-change="pageChange"
          layout="total,sizes,prev,pager,next,jumper"
          :page-size="pageSize"
          :page-sizes="[20, 50, 100]"
          :total="pageTotal"
        ></isdPagination>
      </el-col>
    </el-row>
    <!-- 新增/编辑弹窗 -->
    <isdFormDialog
      ref="formDialogIns"
      :config="config.forms"
      :loading="formDialog.loading"
      :label-width="formDialog.labelWidth"
      :row="formDialog.row"
      v-model="formDialog.show"
      :col="formDialog.col"
      :title="formDialog.title"
      :width="formDialog.width"
      @close-dialog="closeFormDialog"
      @data-changed="changeFormData"
    ></isdFormDialog>
  </div>
</template>
<script>
import { ref, reactive } from "vue";
import { useRoute } from "vue-router";
import { ElMessage } from "element-plus";
import {
  getCollectionDetailList,
  addAmountRelative,
} from "@/network/finance.js";
import isdFormDialog from "@/views/components/isdFormDialog/Index.vue";
import isdTable from "@/views/components/isdTable/index.vue";
import configData from "./config";
import { ListClass } from "@/composition/ListClass.js";
import isdPagination from "@/views/components/isdPage/index.vue";
export default {
  components: {
    isdFormDialog,
    isdTable,
    isdPagination,
  },
  setup(props, context) {
    const formDialogIns = ref(null);
    const config = reactive(configData);
    const btn_title = ref("录入");
    // 新建/修改弹窗对象
    const formDialog = reactive({
      labelWidth: "90px", // 弹窗label宽度
      loading: false, // 表单加载中显示
      row: {}, // 数据项对象
      show: false, // 显示弹窗
      col: 1, // 弹窗内列数
      title: "录入实际收款金额", // 弹窗标题
      width: "450px", // 弹窗宽度
    });
    const route = ref(useRoute());
    const list_id = ref(null);
    const list_no = ref(null);
    //选中的订单ID或者修改更新时，传过来的参数（upt+应付款id）
    const collectionInfo = route.value.query.arrData;
    class TableList extends ListClass {
      beforeGetList(condition) {
        condition.list_id = list_id.value;
        condition.list_no = list_no.value;
        condition.collectionInfo = collectionInfo;
        return condition;
      }
    }
    const {
      currentPage,
      pageSize,
      pageTotal,
      tableLoading,
      totalData,
      getList,
      searchList,
      pageChange,
      sizeChange,
      handleSelectedRow,
    } = new TableList({
      Api: {
        listApi: getCollectionDetailList,
      },
    });
    const getCollectionDetails = () => {
      getList();
    };
    const changeFormData = (obj) => {
      const form = formDialogIns.value.form;
      if ("bank_fee" == obj.itemSetting.prop) {
        form.hand_fee = (
          (form.receivables - form.received_amount - obj.data) *
          0.004
        ).toFixed(2);
        form.amount = (
          form.receivables -
          form.received_amount -
          form.hand_fee -
          obj.data
        ).toFixed(2);
      } else if ("hand_fee" == obj.itemSetting.prop) {
        form.amount = (
          form.receivables -
          form.received_amount -
          form.bank_fee -
          obj.data
        ).toFixed(2);
      }
    };
    const editAmount = (val) => {
      const row = JSON.parse(JSON.stringify(val));
      row.bank_fee = ((row.receivables - row.received_amount) * 0.02).toFixed(
        2
      );
      row.hand_fee = (
        (row.receivables - row.received_amount) *
        (1 - 0.02) *
        0.004
      ).toFixed(2);
      row.amount = (
        row.receivables -
        row.received_amount -
        row.bank_fee -
        row.hand_fee
      ).toFixed(2);
      formDialog.show = true;
      formDialog.title = "录入实际收款金额";
      formDialog.row = row;
    };
    const closeAction = () => {
      formDialog.show = false;
      formDialog.row = {};
      formDialog.loading = false;
    };
    // 新增弹窗关闭
    const closeFormDialog = (params) => {
      if (!params) {
        closeAction();
        return;
      }
      formDialog.loading = true;
      // 编辑
      addAmountRelative({
        list_id: list_id.value,
        relativeInfo: params,
      }).then((res) => {
        if (res.code == 0) {
          btn_title.value = "更新";
          formDialog.loading = false;
          getList();
          ElMessage.success(res.msg);
          closeAction();
        } else if (res.code == 71) {
          ElMessage.error(res.msg);
          formDialog.loading = false;
          getList();
          closeAction();
        } else {
          ElMessage.error(res.msg);
          formDialog.loading = false;
          getList();
          closeAction();
        }
      });
    };
    return {
      closeFormDialog,
      formDialog,
      config,
      currentPage,
      pageSize,
      pageTotal,
      tableLoading,
      totalData,
      getList,
      searchList,
      pageChange,
      sizeChange,
      handleSelectedRow,
      formDialogIns,
      getCollectionDetails,
      list_id,
      list_no,
      changeFormData,
      editAmount,
      btn_title,
    };
  },
};
</script>
