// 按钮防抖
export const preventReClick = {
  mounted(el, binding) {
    // value：传递给指令的值。
    // arg：传递给指令的参数 (如果有的话)。
    const { arg } = binding;
    let time = 1000;
    if (!isNaN(Number(arg))) {
      time = Number(arg);
    }
    let timer = null;
    el.addEventListener("click", () => {
      let firstClick = !timer;
      if (firstClick) {
        binding.value();
      }
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        timer = null;
        if (!firstClick) {
          binding.value();
        }
      }, time);
    });
  },
};
