import { request } from "./request.js";
export function getUserList(data = {}) {
  return request({
    url: "schedule/getUserList",
    method: "post",
    data,
  });
}
export function getScheduleLevelList(data = {}) {
  return request({
    url: "schedule/getScheduleLevelList",
    method: "post",
    data,
  });
}
export function addScheduleLevel(data = {}) {
  return request({
    url: "schedule/addScheduleLevel",
    method: "post",
    data,
  });
}
export function deleteScheduleLevel(data = {}) {
  return request({
    url: "schedule/deleteScheduleLevel",
    method: "post",
    data,
  });
}
/**获取日程列表（id + name_ch） */
export function getScheduleLevel(data = {}) {
  return request({
    url: "schedule/getScheduleLevel",
    method: "post",
    data,
  });
}
export function getObjectValue(data = {}) {
  return request({
    url: "schedule/getObjectValue",
    method: "post",
    data,
  });
}
export function addScheduleList(data = {}) {
  return request({
    url: "schedule/addScheduleList",
    method: "post",
    data,
  });
}
export function delScheduleList(data = {}) {
  return request({
    url: "schedule/delScheduleList",
    method: "post",
    data,
  });
}
export function completeScheduleList(data = {}) {
  return request({
    url: "schedule/completeScheduleList",
    method: "post",
    data,
  });
}
export function myScheduleList(data = {}) {
  return request({
    url: "schedule/myScheduleList",
    method: "post",
    data,
  });
}
export function scheduleList(data = {}) {
  return request({
    url: "schedule/scheduleList",
    method: "post",
    data,
  });
}
export function getUserRights(data = {}) {
  return request({
    url: "schedule/getUserRights",
    method: "post",
    data,
  });
}
export function allScheduleList(data = {}) {
  return request({
    url: "schedule/allScheduleList",
    method: "post",
    data,
  });
}
export function setScheduleFocus(data = {}) {
  return request({
    url: "schedule/setScheduleFocus",
    method: "post",
    data,
  });
}
export function delScheduleItem(data = {}) {
  return request({
    url: "schedule/delScheduleItem",
    method: "post",
    data,
  });
}
