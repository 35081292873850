import { request } from "./request.js";
export function getNomalApi(data = {}) {
  return request({
    url: "basic/getNomalApi",
    method: "post",
    data,
  });
}
export function basicList(data = {}) {
  return request({
    url: "basic/basicList",
    method: "post",
    data,
  });
}
export function getSetGroupList(data = {}) {
  return request({
    url: "basic/getSetGroupList",
    method: "post",
    data,
  });
}
export function groupAdd(data = {}) {
  return request({
    url: "basic/groupAdd",
    method: "post",
    data,
  });
}
/**国家地区配置 开始*/
export function nationList(data = {}) {
  return request({
    url: "basic/nationList",
    method: "post",
    data,
  });
}
export function nationAdd(data = {}) {
  return request({
    url: "basic/nationAdd",
    method: "post",
    data,
  });
}
export function nationDel(data = {}) {
  return request({
    url: "basic/nationDel",
    method: "post",
    data,
  });
}
export function getContinentList(data = {}) {
  return request({
    url: "basic/getContinentList",
    method: "post",
    data,
  });
}
/**国家地区配置 结束*/
/**颜色配置 开始*/
export function colorList(data = {}) {
  return request({
    url: "basic/colorList",
    method: "post",
    data,
  });
}
export function colorAdd(data = {}) {
  return request({
    url: "basic/colorAdd",
    method: "post",
    data,
  });
}
export function colorDel(data = {}) {
  return request({
    url: "basic/colorDel",
    method: "post",
    data,
  });
}
export function getColorList(data = {}) {
  return request({
    url: "basic/getColorList",
    method: "post",
    data,
  });
}

/**颜色配置 结束*/
/**大洲配置 开始*/
export function continentList(data = {}) {
  return request({
    url: "basic/continentList",
    method: "post",
    data,
  });
}
export function continentAdd(data = {}) {
  return request({
    url: "basic/continentAdd",
    method: "post",
    data,
  });
}
export function continentDel(data = {}) {
  return request({
    url: "basic/continentDel",
    method: "post",
    data,
  });
}
/**大洲配置 结束*/
/**支付货币配置 开始*/
export function currencyList(data = {}) {
  return request({
    url: "basic/currencyList",
    method: "post",
    data,
  });
}
export function currencyAdd(data = {}) {
  return request({
    url: "basic/currencyAdd",
    method: "post",
    data,
  });
}
export function currencyDel(data = {}) {
  return request({
    url: "basic/currencyDel",
    method: "post",
    data,
  });
}
/**支付货币配置 结束*/
/**支付方式配置 开始*/
export function methodList(data = {}) {
  return request({
    url: "basic/methodList",
    method: "post",
    data,
  });
}
export function methodAdd(data = {}) {
  return request({
    url: "basic/methodAdd",
    method: "post",
    data,
  });
}
export function methodDel(data = {}) {
  return request({
    url: "basic/methodDel",
    method: "post",
    data,
  });
}
/**支付方式配置 结束*/
/**社交媒体配置 开始*/
export function socialList(data = {}) {
  return request({
    url: "basic/socialList",
    method: "post",
    data,
  });
}
export function socialAdd(data = {}) {
  return request({
    url: "basic/socialAdd",
    method: "post",
    data,
  });
}
export function socialDel(data = {}) {
  return request({
    url: "basic/socialDel",
    method: "post",
    data,
  });
}
/**社交媒体配置 结束*/
/**客户分组配置 开始*/
export function customerGroupList(data = {}) {
  return request({
    url: "basic/customerGroupList",
    method: "post",
    data,
  });
}
export function customerGroupAdd(data = {}) {
  return request({
    url: "basic/customerGroupAdd",
    method: "post",
    data,
  });
}
export function customerGroupDel(data = {}) {
  return request({
    url: "basic/customerGroupDel",
    method: "post",
    data,
  });
}
/**客户分组配置 结束*/
/**客户星级配置 开始*/
export function customerLevelList(data = {}) {
  return request({
    url: "basic/customerLevelList",
    method: "post",
    data,
  });
}
export function customerLevelAdd(data = {}) {
  return request({
    url: "basic/customerLevelAdd",
    method: "post",
    data,
  });
}
export function customerLevelDel(data = {}) {
  return request({
    url: "basic/customerLevelDel",
    method: "post",
    data,
  });
}
/**客户星级配置 结束*/
/**客户跟进阶段配置 开始*/
export function customerStageList(data = {}) {
  return request({
    url: "basic/customerStageList",
    method: "post",
    data,
  });
}
export function customerStageAdd(data = {}) {
  return request({
    url: "basic/customerStageAdd",
    method: "post",
    data,
  });
}
export function customerStageDel(data = {}) {
  return request({
    url: "basic/customerStageDel",
    method: "post",
    data,
  });
}
/**客户跟进阶段配置 结束*/
/**客户标记配置 开始*/
export function customerTagsList(data = {}) {
  return request({
    url: "basic/customerTagsList",
    method: "post",
    data,
  });
}
export function customerTagsAdd(data = {}) {
  return request({
    url: "basic/customerTagsAdd",
    method: "post",
    data,
  });
}
export function customerTagsDel(data = {}) {
  return request({
    url: "basic/customerTagsDel",
    method: "post",
    data,
  });
}
export function getTagsGroupList(data = {}) {
  return request({
    url: "basic/getTagsGroupList",
    method: "post",
    data,
  });
}
/**客户标记配置 结束*/
/**客户来源配置 开始*/
export function customerSourceList(data = {}) {
  return request({
    url: "basic/customerSourceList",
    method: "post",
    data,
  });
}
export function customerSourceAdd(data = {}) {
  return request({
    url: "basic/customerSourceAdd",
    method: "post",
    data,
  });
}
export function customerSourceDel(data = {}) {
  return request({
    url: "basic/customerSourceDel",
    method: "post",
    data,
  });
}
/**客户来源配置 结束*/
/**付款类型配置 开始*/
export function paymentTypeList(data = {}) {
  return request({
    url: "basic/paymentTypeList",
    method: "post",
    data,
  });
}
export function paymentTypeAdd(data = {}) {
  return request({
    url: "basic/paymentTypeAdd",
    method: "post",
    data,
  });
}
export function paymentTypeDel(data = {}) {
  return request({
    url: "basic/paymentTypeDel",
    method: "post",
    data,
  });
}
/**付款类型配置 结束*/
/**付款状态配置 开始*/
export function financeStatusList(data = {}) {
  return request({
    url: "basic/financeStatusList",
    method: "post",
    data,
  });
}
export function financeStatusAdd(data = {}) {
  return request({
    url: "basic/financeStatusAdd",
    method: "post",
    data,
  });
}
export function financeStatusDel(data = {}) {
  return request({
    url: "basic/financeStatusDel",
    method: "post",
    data,
  });
}
/**付款状态配置 结束*/
/**出运状态配置 开始*/
export function shippingStatusList(data = {}) {
  return request({
    url: "basic/shippingStatusList",
    method: "post",
    data,
  });
}
export function shippingStatusAdd(data = {}) {
  return request({
    url: "basic/shippingStatusAdd",
    method: "post",
    data,
  });
}
export function shippingStatusDel(data = {}) {
  return request({
    url: "basic/shippingStatusDel",
    method: "post",
    data,
  });
}
/**出运状态配置 结束*/
/**公司基本信息配置 开始*/
export function compInfo(data = {}) {
  return request({
    url: "basic/compInfo",
    method: "post",
    data,
  });
}
export function getCompAddList(data = {}) {
  return request({
    url: "basic/getCompAddList",
    method: "post",
    data,
  });
}
export function addCompAdd(data = {}) {
  return request({
    url: "basic/addCompAdd",
    method: "post",
    data,
  });
}
export function deleteAdd(data = {}) {
  return request({
    url: "basic/deleteAdd",
    method: "post",
    data,
  });
}
/**公司基本信息配置 结束*/
/**账户信息配置 开始*/
export function getPaymentAccountList(data = {}) {
  return request({
    url: "basic/getPaymentAccountList",
    method: "post",
    data,
  });
}
export function addPaymentAccount(data = {}) {
  return request({
    url: "basic/addPaymentAccount",
    method: "post",
    data,
  });
}
export function deletePaymentAccount(data = {}) {
  return request({
    url: "basic/deletePaymentAccount",
    method: "post",
    data,
  });
}
/**账户信息配置 结束*/
/**订单状态配置 开始*/
export function getOrderStatusList(data = {}) {
  return request({
    url: "basic/getOrderStatusList",
    method: "post",
    data,
  });
}
export function addOrderStatus(data = {}) {
  return request({
    url: "basic/addOrderStatus",
    method: "post",
    data,
  });
}
export function deleteOrderStatus(data = {}) {
  return request({
    url: "basic/deleteOrderStatus",
    method: "post",
    data,
  });
}
/**订单状态配置 结束*/
/**物流渠道配置 开始*/
export function shippingComp(data = {}) {
  return request({
    url: "basic/shippingComp",
    method: "post",
    data,
  });
}
export function addShippingComp(data = {}) {
  return request({
    url: "basic/addShippingComp",
    method: "post",
    data,
  });
}
export function delShippingComp(data = {}) {
  return request({
    url: "basic/delShippingComp",
    method: "post",
    data,
  });
}
/**物流渠道配置 结束*/
/**数据字典配置 开始*/
export function getDicList(data = {}) {
  return request({
    url: "basic/getDicList",
    method: "post",
    data,
  });
}
export function addDic(data = {}) {
  return request({
    url: "basic/addDic",
    method: "post",
    data,
  });
}
export function delDic(data = {}) {
  return request({
    url: "basic/delDic",
    method: "post",
    data,
  });
}
/**数据字典配置 结束*/
