<template>
  <div class="flex-layout order-management">
    <isdListPaper v-loading="tableLoading">
      <!-- <template v-slot:header-button>
        <el-button type="primary" @click="add"> 新增订单 </el-button>
      </template> -->
      <template v-slot:header-search>
        <isdSearchBox
          ref="searchBoxIns"
          :search-key="config.name"
          :fields="config.searchFields"
          @search-change="searchList"
          :loading="tableLoading"
          class="track-search"
        ></isdSearchBox>
      </template>
      <template v-slot:list="props">
        <isdTable
          :loading="tableLoading"
          :rows="totalData"
          :height="props.tableHeight"
          :columns="config.columns"
          :selection="config.selection"
          :actions="config.actions"
          @selection-change="handleSelectedRow"
          style="font-size: 12px"
        >
          <template v-slot:col-append="{ col, row }">
            <span v-if="col.type === 'operate_btn'">
              <el-button round size="small" @click="editOrder(row)"
                ><el-icon style="vertical-align: middle"> <EditPen /> </el-icon
                ><span style="vertical-align: middle"> 编辑 </span></el-button
              >
              <el-button round size="small" @click="printOrder(row)"
                ><el-icon style="vertical-align: middle"><View /></el-icon
                ><span style="vertical-align: middle"> 详情 </span></el-button
              >
              <el-button round size="small" @click="showOrderIMEI(row)"
                ><el-icon style="vertical-align: middle"><InfoFilled /></el-icon
                ><span style="vertical-align: middle"> IMEI </span></el-button
              >
            </span>
          </template>
        </isdTable>
      </template>
      <template v-slot:footer-buttons>
        <el-button
          type="danger"
          plain
          @click="deleteItem"
          :disabled="!selectedRows.length"
          ><el-icon><Delete /></el-icon>
          <span style="vertical-align: middle"> 批量删除 </span>
        </el-button>
      </template>
      <template v-slot:footer>
        <isdPagination
          @size-change="sizeChange"
          :current-page="currentPage"
          @current-change="pageChange"
          layout="total,sizes,prev,pager,next,jumper"
          :page-size="pageSize"
          :page-sizes="[20, 50, 100]"
          :total="pageTotal"
        ></isdPagination>
      </template>
    </isdListPaper>
    <!-- 新增/编辑弹窗 -->
    <isdFormDialog
      ref="formDialogIns"
      :config="config.forms"
      :loading="formDialog.loading"
      :label-width="formDialog.labelWidth"
      :row="formDialog.row"
      v-model="formDialog.show"
      :col="formDialog.col"
      :title="formDialog.title"
      :width="formDialog.width"
      @close-dialog="closeFormDialog"
    ></isdFormDialog>
  </div>
</template>
<script>
import { orderList, orderDel } from "@/network/order.js";
import {
  EditPen,
  Printer,
  Delete,
  Ship,
  View,
  InfoFilled,
} from "@element-plus/icons-vue";
import { getCustomerList } from "@/network/customer.js";
import { defineComponent, ref, reactive, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import { ListClass } from "@/composition/ListClass.js";
import configData from "./config";
import isdTable from "@/views/components/isdTable/index.vue";
import isdPagination from "@/views/components/isdPage/index.vue";
import isdListPaper from "@/views/components/isdListPaper/index.vue";
import isdFormDialog from "@/views/components/isdFormDialog/Index.vue";
import isdSearchBox from "@/views/components/isdSearchBox/index.vue";
export default defineComponent({
  components: {
    Delete,
    EditPen,
    Printer,
    Ship,
    View,
    InfoFilled,
    isdTable,
    isdPagination,
    isdSearchBox,
    isdListPaper,
    isdFormDialog,
  },
  setup() {
    const formDialogIns = ref(null);
    const config = reactive(configData); // 列表配置
    const searchBoxIns = ref(null); // 搜索实例
    const columnConfigIns = ref(null); // 列配置实例
    const statusDialogIns = ref(null); // 状态弹框实例
    const detailPageIns = ref(null); // 详情编辑页ref
    const tableBox = ref(null);
    // 新建/修改弹窗对象
    const formDialog = reactive({
      labelWidth: "6.875rem", // 弹窗label宽度
      loading: false, // 表单加载中显示
      row: {}, // 数据项对象
      show: false, // 显示弹窗
      col: 1, // 弹窗内列数
      title: "新建", // 弹窗标题
      width: "28.125rem", // 弹窗宽度
    });
    // 格式化查询条件
    const dataFormat = (condition) => {
      condition.symbolType = ">";
      condition.listType = 1;
      return condition;
    };
    class TableList extends ListClass {
      beforeGetList(condition) {
        condition = dataFormat(condition);
        return condition;
      }
    }
    const {
      currentPage,
      pageNo,
      pageSize,
      pageTotal,
      tableLoading,
      totalData,
      getList,
      searchList,
      pageChange,
      sizeChange,
      selectedRows,
      handleSelectedRow,
    } = new TableList({
      Api: {
        listApi: orderList,
      },
    });
    /**
     * @description: 方法映射，对应操作列的一系列操作
     * @param {string} eve 对应config配置文件里actions.list的方法名event
     * @param {object} row 所点击的行数据对象
     */
    const router = useRouter();
    const editOrder = (val) => {
      router.push({
        name: "addOrder",
        query: {
          ordId: val.ord_list_id, //编辑订单
        },
      });
    };
    const printOrder = (val) => {
      let routeUrl = router.resolve({
        name: "printOrder",
        query: {
          ordId: val.ord_list_id,
        },
      });
      window.open(routeUrl.href, "_blank");
    };
    const showOrderIMEI = (val) => {
      let routeUrl = router.resolve({
        name: "showOrderIMEI",
        query: {
          ordId: val.ord_list_id,
        },
      });
      window.open(routeUrl.href, "_blank");
    };
    //新增订单
    const add = () => {
      formDialog.show = true;
      formDialog.title = "新增订单";
      getCustomerList().then((res) => {
        if (res.code == 0) {
          config.forms[0].options = res.data;
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    /**
     * @description: 点击新建按钮，打开详情弹窗并设置默认值
     */
    const closeFormDialog = (params) => {
      if (!params) {
        closeAction();
        return;
      }
      saveItem(params);
    };
    const closeAction = () => {
      formDialog.show = false;
      formDialog.row = {};
      formDialog.loading = false;
    };
    const saveItem = (params) => {
      // 新增订单跳转
      router.push({
        name: "addOrder",
        query: {
          cstId: params.cst_id,
        },
      });
    };
    /**
     * @description: 删除已选择数据，仅支持单行操作
     */
    const deleteItem = () => {
      const map = new Map();
      const arr = selectedRows.value.map((item) => {
        return item.ord_list_id; // 只返回id号
      });
      //方法
      if (!arr) {
        ElMessage("所选数据为空，请重新选择！");
      } else {
        ElMessageBox.confirm("确定要删除选中的订单吗?", "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            orderDel({ ordIDs: arr, list_type: 1, details_type: 0 }).then(
              (res) => {
                if (res.code == 0) {
                  ElMessage.success(res.msg);
                  getList();
                } else {
                  ElMessage.error(res.msg);
                  getList();
                }
              }
            );
          })
          .catch(() => {
            ElMessage({
              type: "info",
              message: "删除取消",
            });
          });
      }
    };
    onMounted(() => {
      getList();
    });
    return {
      tableBox,
      config,
      pageNo,
      pageSize,
      pageTotal,
      tableLoading,
      totalData,
      searchList,
      pageChange,
      sizeChange,
      handleSelectedRow,
      searchBoxIns,
      columnConfigIns,
      detailPageIns,
      statusDialogIns,
      formDialog,
      closeFormDialog,
      editOrder,
      printOrder,
      showOrderIMEI,
      currentPage,
      formDialogIns,
      deleteItem,
      selectedRows,
      add,
    };
  },
});
</script>

<style lang="scss">
.order-management {
  .el-table th > .cell {
    word-break: keep-all;
  }
}
</style>
