<template>
  <div style="height: 100%">
    <div style="text-align: center; margin: 20px; font-size: 30px">
      出运编码 {{ ship_no }}
    </div>
    <el-row>
      <el-col :span="24" style="text-align: right; margin-bottom: 20px">
        <el-button type="primary" size="middle" @click="editFee"
          ><el-icon style="vertical-align: middle"> <EditPen /></el-icon>
          <span style="vertical-align: middle">编辑</span></el-button
        >
        <el-button type="primary" size="middle" @click="saveFee"
          ><el-icon style="vertical-align: middle"> <Check /></el-icon>
          <span style="vertical-align: middle">保存</span></el-button
        >
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <isdTable
          :loading="tableLoading"
          :rows="totalData"
          :height="600"
          :columns="config.columns"
          :selection="config.selection"
          :actions="config.actions"
          style="font-size: 12px"
        >
          <template v-slot:col-append="{ col, row }">
            <div v-if="col.type === 's_cost'">
              <span v-if="!isEdit"> {{ row[col.prop] }}</span>
              <el-input v-else v-model="row[col.prop]"></el-input>
            </div>
            <div v-else-if="col.type === 'in_cost'">
              <span v-if="!isEdit"> {{ row[col.prop] }}</span>
              <el-input v-else v-model="row[col.prop]"></el-input>
            </div>
            <div v-else-if="col.type === 'pa_fee'">
              <span v-if="!isEdit"> {{ row[col.prop] }}</span>
              <el-input v-else v-model="row[col.prop]"></el-input>
            </div>
            <div v-else-if="col.type === 're_fee'">
              <span v-if="!isEdit"> {{ row[col.prop] }}</span>
              <el-input v-else v-model="row[col.prop]"></el-input>
            </div>
            <div v-else-if="col.type === 'ot_fee'">
              <span v-if="!isEdit"> {{ row[col.prop] }}</span>
              <el-input v-else v-model="row[col.prop]"></el-input>
            </div>
            <div v-else-if="col.type === 'remark'">
              <span v-if="!isEdit"> {{ row[col.prop] }}</span>
              <el-input v-else v-model="row[col.prop]"></el-input>
            </div>
          </template>
        </isdTable>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <isdPagination
          @size-change="sizeChange"
          :current-page="currentPage"
          @current-change="pageChange"
          layout="total,sizes,prev,pager,next,jumper"
          :page-size="pageSize"
          :page-sizes="[20, 50, 100]"
          :total="pageTotal"
        ></isdPagination>
      </el-col>
    </el-row>
    <!-- 新增/编辑弹窗 -->
    <isdFormDialog
      ref="formDialogIns"
      :config="config.forms"
      :loading="formDialog.loading"
      :label-width="formDialog.labelWidth"
      :row="formDialog.row"
      v-model="formDialog.show"
      :col="formDialog.col"
      :title="formDialog.title"
      :width="formDialog.width"
    ></isdFormDialog>
  </div>
</template>
<script>
import { ref, reactive, onMounted } from "vue";
import { useRoute, useRouter, route } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import { EditPen, Check } from "@element-plus/icons-vue";
import { addDeliveryDetails, getDeliveryDetails } from "@/network/shipping.js";
import isdFormDialog from "@/views/components/isdFormDialog/Index.vue";
import isdTable from "@/views/components/isdTable/index.vue";
import configData from "./config";
import { ListClass } from "@/composition/ListClass.js";
import isdPagination from "@/views/components/isdPage/index.vue";
import lodash from "lodash";
export default {
  components: {
    EditPen,
    Check,
    isdFormDialog,
    isdTable,
    isdPagination,
  },
  setup() {
    const isEdit = ref(false);
    const ship_no = ref(null);
    const formDialogIns = ref(null);
    const config = reactive(lodash.cloneDeep(configData));
    // 新建/修改弹窗对象
    const formDialog = reactive({
      labelWidth: "90px", // 弹窗label宽度
      loading: false, // 表单加载中显示
      row: {}, // 数据项对象
      show: false, // 显示弹窗
      col: 1, // 弹窗内列数
      title: "", // 弹窗标题
      width: "450px", // 弹窗宽度
    });
    const route = ref(useRoute());
    const ship_id = route.value.query.arrData;
    class TableList extends ListClass {
      beforeGetList(condition) {
        condition.ship_id = ship_id;
        return condition;
      }
      // afterGetList(res) {
      //   ship_no.value = res.data.ship_no;
      //   super.afterGetList(res);
      // }
    }
    const {
      currentPage,
      pageSize,
      pageTotal,
      tableLoading,
      totalData,
      getList,
      searchList,
      pageChange,
      sizeChange,
      handleSelectedRow,
    } = new TableList({
      Api: {
        listApi: getDeliveryDetails,
      },
    });
    const editFee = () => {
      isEdit.value = true;
    };
    // 保存
    const saveFee = () => {
      isEdit.value = false;
      addDeliveryDetails({
        data: totalData.value,
      }).then((res) => {
        if (res.code == 0) {
          getList();
          ElMessage.success(res.msg);
        } else {
          ElMessage.error(res.msg);
          getList();
        }
      });
    };
    onMounted(() => {
      getList();
    });
    return {
      formDialog,
      config,
      currentPage,
      pageSize,
      pageTotal,
      tableLoading,
      totalData,
      getList,
      searchList,
      pageChange,
      sizeChange,
      handleSelectedRow,
      formDialogIns,
      isEdit,
      editFee,
      saveFee,
      ship_no,
    };
  },
};
</script>
