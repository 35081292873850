<template>
  <el-container class="layout-container-demo">
    <el-menu
      class="menu"
      active-text-color="#ffd04b"
      background-color="#304156"
      :collapse-transition="true"
      :unique-opened="true"
      :collapse="isCollapse"
      text-color="#fff"
    >
      <el-menu-item index="/">
        <el-icon><house /></el-icon>
        <template #title>
          <router-link to="home">首页</router-link></template
        ></el-menu-item
      >
      <el-sub-menu v-for="item in menuList" :key="item.mid" :index="item.label">
        <template #title>
          <span>
            <!-- <el-icon><house /></el-icon> -->
            <span>{{ item.label }}</span>
          </span>
        </template>
        <el-menu-item v-for="items in item.son" :key="items.mid">
          <div v-if="items.url">
            <router-link :to="items.url">
              <span>{{ items.label }}</span>
            </router-link>
          </div>
          <div v-if="!items.url">
            {{ items.label }}
          </div>
        </el-menu-item>
      </el-sub-menu>
    </el-menu>

    <el-container>
      <el-header style="text-align: right; font-size: 12px">
        <div
          @click="isCollapse = !isCollapse"
          class="folder_expand"
          :title="isCollapse ? '展开' : '收起'"
        >
          <el-icon v-if="!isCollapse"><Expand /></el-icon>
          <el-icon v-else><Fold /></el-icon>
        </div>
        <div class="tabs">
          <el-tabs
            v-model="activeTab"
            @tab-remove="removeTab"
            @tab-change="handleChangeTab"
            :closable="tabList.length != 1"
          >
            <el-tab-pane
              v-for="(item, index) in tabList"
              :key="item.name"
              :label="item.title"
              :name="item.name"
            >
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="toolbar">
          <el-dropdown>
            <el-icon style="margin-right: 8px; margin-top: 1px"
              ><setting
            /></el-icon>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  ><router-link to="" style="color: black"
                    >个人中心</router-link
                  ></el-dropdown-item
                >
                <el-dropdown-item @click="loginOut">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <span>您好，{{ userName }}</span>
        </div>
      </el-header>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
import { reactive, ref, toRefs } from "vue";
import {
  Menu as IconMenu,
  Message,
  Setting,
  House,
} from "@element-plus/icons-vue";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import useTabViews from "@/store/modules/tabViews.js";
export default {
  components: {
    IconMenu,
    Message,
    Setting,
    House,
  },
  setup() {
    const { tabList, activeTab, menuList, userName } = storeToRefs(
      useTabViews()
    );
    const homeMenuItem = { url: "/", label: "首页" };
    const router = useRouter();
    const removeTab = (targetName) => {
      const tabs = tabList.value;
      let activeName = activeTab.value;
      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.name === targetName) {
            const nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              activeName = nextTab.name;
            }
          }
        });
      }
      activeTab.value = activeName;
      tabList.value = tabs.filter((tab) => tab.name !== targetName);
      router.push({ path: activeTab.value });
    };
    const handleChangeTab = (path) => {
      router.push({ path });
    };
    const isCollapse = ref(false);
    const loginOut = () => {
      localStorage.removeItem("token");
      location.reload();
    };
    return {
      menuList,
      userName,
      loginOut,
      isCollapse,
      activeTab,
      removeTab,
      tabList,
      handleChangeTab,
      homeMenuItem,
    };
  },
};
</script>

<style scoped lang="scss">
.el-container {
  flex-basis: fit-content;
}
.layout-container-demo {
  height: 100vh;
}
.menu {
  height: 100%;
  overflow: auto;
}
.el-menu {
}
::-webkit-scrollbar {
  display: none;
}
.layout-container-demo .el-header {
  position: relative;
  box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
}
.layout-container-demo .el-aside {
  color: var(--el-text-color-primary);
  background: var(--el-color-primary-light-8);
}
:deep .el-scrollbar__view {
  height: 100%;
}
.layout-container-demo .el-menu {
  border-right: none;
}
a {
  color: #fff;
  text-decoration: none;
}
.layout-container-demo .el-main {
  padding: 0 20px;
}
.layout-container-demo .toolbar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  right: 20px;
}
.el-main {
  height: calc(100% - 60px);
}
.el-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .folder_expand {
    font-size: 18px;
    cursor: pointer;
  }
  .tabs {
    width: calc(100% - 200px);
    padding: 0 10px;
    ::v-deep .el-tabs {
      .el-tabs__header {
        margin: 0;
      }
    }
  }
}
</style>
<style>
:root {
  --el-aside-width: 200px;
}
.menu:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
</style>
