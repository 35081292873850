export default {
  selection: true,
  searchFields: [
    // 搜索组件的配置项
    {
      name: "订单号",
      value: "ord_no",
      type: "input",
      isFixed: true,
      span: 4,
    },
    {
      name: "订单备注",
      value: "ord_remark",
      type: "input",
      isFixed: true,
      span: 4,
    },
  ],
  columns: [
    // 表格列的配置
    {
      label: "订单号",
      prop: "ord_no",
      fixed: true,
    },
    {
      label: "签约日期",
      prop: "create_time",
    },
    {
      label: "客户",
      prop: "cst_id_v",
    },
    {
      label: "归属人",
      prop: "user_id_v",
    },
    {
      label: "订单金额",
      prop: "receivables_amount",
    },
    {
      label: "订单状态",
      prop: "ord_status_v",
    },
    {
      label: "结算状态",
      prop: "ord_approval_id_v",
    },
    {
      label: "订单备注",
      prop: "ord_remark",
    },
  ],
};
