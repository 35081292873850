export const dualAxes = {
  //多条曲线
  data: [],
  xField: "date_time",
  yField: "num",
  label: {},
  seriesField: "type",
  point: {
    size: 5,
    shape: "diamond",
    style: {
      fill: "white",
      stroke: "#5B8FF9",
      lineWidth: 2,
    },
  },
  tooltip: { showMarkers: false },
  state: {
    active: {
      style: {
        shadowBlur: 4,
        stroke: "#000",
        fill: "red",
      },
    },
  },
  smooth: true,
  interactions: [{ type: "marker-active" }],
};
export const dualAxes1 = {
  //单条曲线
  data: [],
  xField: "date_time",
  yField: "num",
  label: {},
  point: {
    size: 5,
    shape: "diamond",
    style: {
      fill: "white",
      stroke: "#5B8FF9",
      lineWidth: 2,
    },
  },
  tooltip: { showMarkers: false },
  state: {
    active: {
      style: {
        shadowBlur: 4,
        stroke: "#000",
        fill: "red",
      },
    },
  },
  smooth: true,
  interactions: [{ type: "marker-active" }],
};
export const dualAxes2 = {
  //双轴曲线
  data: [],
  xField: "date_time",
  yField: ["num", "sale"],
  geometryOptions: [
    {
      geometry: "line",
      color: "#5B8FF9",
    },
    {
      geometry: "line",
      color: "#5AD8A6",
    },
  ],
};
export const dualCloumn = {
  //复合柱状图
  data: [],
  xField: "date_time",
  yField: "num",
  seriesField: "type",
  isGroup: "true",
  columnStyle: {
    radius: [20, 20, 0, 0],
  },
};
