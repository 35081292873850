<template>
  <div style="height: 100%; padding: 10px">
    <el-row :gutter="24" class="row_class">
      <el-col :span="12">公司编码：{{ compInfo.cst_no }} </el-col>
      <el-col :span="12">公司名字：{{ compInfo.company_name }} </el-col>
    </el-row>
    <el-row :gutter="24" class="row_class">
      <el-col :span="12">客户来源：{{ compInfo.source_id }} </el-col>
      <el-col :span="12">国家/地区：{{ compInfo.nation_id }}</el-col>
    </el-row>
    <el-row :gutter="24" class="row_class">
      <el-col :span="12">公司邮箱：{{ compInfo.company_email }}</el-col>
      <el-col :span="12">客户创建：{{ compInfo.original_user_id }}</el-col>
    </el-row>
    <el-row :gutter="24" class="row_class">
      <el-col :span="12">公司网址：{{ compInfo.company_website }} </el-col>
      <el-col :span="12">公司邮编：{{ compInfo.company_post_code }}</el-col>
    </el-row>
    <el-row :gutter="24" class="row_class">
      <el-col :span="24">公司地址：{{ compInfo.company_add }} </el-col>
    </el-row>
    <el-row :gutter="24" class="row_class">
      <el-col :span="24">公司备注：{{ compInfo.cst_remark }} </el-col>
    </el-row>
  </div>
</template>
<script>
import { ref, reactive, toRefs, onMounted, markRaw } from "vue";
import { ElMessage } from "element-plus";
import { getCompInfo } from "@/network/customer.js";
import { useRoute, useRouter } from "vue-router";
export default {
  components: {},
  setup() {
    const route = ref(useRoute());
    const cst_id = route.value.query.cstId;
    const state = reactive({
      compInfo: [],
    });
    const getList = () => {
      getCompInfo({ cst_id: cst_id }).then((res) => {
        if (res.code == 0) {
          state.compInfo = res.data;
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    onMounted(() => {
      getList();
    });
    return {
      ...toRefs(state),
    };
  },
};
</script>
<style scoped>
.row_class {
  font-size: 14px;
  line-height: 28px;
  font-weight: 500;
  color: #2a2a2a;
  margin: 20px 0px;
}
</style>
