export default {
  selection: false,
  searchFields: [
    // 搜索组件的配置项
    {
      name: "日程内容",
      value: "content",
      type: "input",
      isFixed: true,
      span: 4,
    },
    {
      name: "日程备注",
      value: "remark",
      type: "input",
      isFixed: true,
      span: 4,
    },
  ],
  columns: [
    // 表格列的配置
    {
      label: "日程主题",
      prop: "theme",
      fixed: true,
      type: "theme",
    },
    {
      label: "提醒时间",
      prop: "notice_time",
    },
    {
      label: "创建人",
      prop: "build_user",
    },
    {
      label: "参与人",
      prop: "relation_user",
    },
    {
      label: "创建时间",
      prop: "create_time",
    },
    {
      label: "日程内容",
      prop: "content",
    },
    {
      label: "关注",
      prop: "focus",
      type: "select",
      options: { 1: "是", 0: "否" },
    },
    {
      label: "对象类型",
      prop: "obj_type_name",
    },
    {
      label: "关联对象",
      prop: "obj_value_name",
    },
    {
      label: "备注",
      prop: "remark",
    },
    // {
    //   label: "操作",
    //   type: "operate_btn",
    //   prop: "",
    //   width: "200px",
    // },
  ],
  forms: [
    {
      label: "参与人",
      prop: "relation_user",
      type: "select",
      options: "",
      placeholder: "请选择日程参与人",
      multiple: true,
    },
    {
      label: "日程等级",
      prop: "level",
      type: "select",
      options: "",
      rule: [
        {
          required: true,
          message: "请选择日程等级",
          trigger: "blur",
        },
      ],
    },
    {
      label: "提示时间",
      prop: "notice_time",
      type: "time",
      rule: [
        {
          required: true,
          message: "请选择日程提示时间",
          trigger: "blur",
        },
      ],
    },
    {
      label: "对象类型",
      prop: "obj_type",
      type: "select",
      options: [
        { label: "客户", value: 1 },
        { label: "订单", value: 2 },
        { label: "线索", value: 3 },
        { label: "协同", value: 4 },
      ],
      rule: [
        {
          required: true,
          message: "请选择对象类型",
          trigger: "blur",
        },
      ],
    },
    {
      label: "关联对象",
      prop: "obj_value",
      type: "select",
      options: "",
      filterable: true,
      rule: [
        {
          required: true,
          message: "请选择关联对象",
          trigger: "blur",
        },
      ],
    },
    {
      label: "跟进内容：",
      prop: "content",
      type: "input",
      inputType: "textarea",
      rule: [{ required: true, message: "请输入跟进信息", trigger: "blur" }],
    },
    {
      label: "关注:",
      prop: "focus",
      type: "switch",
      modelValue: "0",
      activeText: "是",
      inactiveText: "否",
    },
    {
      label: "备注",
      prop: "remark",
      type: "input",
      inputType: "textarea",
    },
  ],
};
