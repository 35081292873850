<template>
  <div class="order-detail-contain">
    <el-tabs v-model="activeName">
      <el-tab-pane label="待审核" name="first">
        <waitAuditList></waitAuditList>
      </el-tab-pane>
      <el-tab-pane label="已结算" name="second">
        <successList></successList>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { ref } from "vue";
import waitAuditList from "./waitAudit/index.vue";
import successList from "./Success/index.vue";
export default {
  name: "commissionManage",
  components: {
    waitAuditList,
    successList,
  },
  setup() {
    const activeName = ref("first");

    return {
      activeName,
    };
  },
};
</script>
<style scoped lang="scss">
.order-detail-contain {
  height: 100%;
  padding: 20px 0;
  .el-tabs {
    height: 100%;
    :deep .el-tabs__content {
      height: calc(100% - 100px);
    }
    .el-tab-pane {
      height: 100%;
    }
  }
}
</style>
