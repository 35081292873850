export default {
  selection: true,
  searchFields: [
    // 搜索组件的配置项
    {
      name: "公司名字",
      value: "company_name",
      type: "input",
      isFixed: true,
      span: 4,
    },
    {
      name: "客户简码",
      value: "cst_short_code",
      type: "input",
      isFixed: true,
      span: 4,
    },
    {
      name: "公司备注",
      value: "cst_remark",
      type: "input",
      isFixed: true,
      span: 4,
    },
  ],
  columns: [
    // 表格列的配置
    {
      label: "公司名字",
      prop: "company_name",
      type: "compName",
      fixed: true,
    },
    {
      label: "主联系人",
      prop: "cst_main_person",
    },
    {
      label: "客户标签",
      prop: "cst_stags",
      type: "cstStags",
    },
    {
      label: "客户归属",
      prop: "user_id_v",
    },
    {
      label: "客户分组",
      prop: "group_id_v",
    },
    {
      label: "客户阶段",
      prop: "stage_id_v",
    },
    {
      label: "客户来源",
      prop: "source_id_v",
    },
    {
      label: "客户星级",
      prop: "level_id",
      type: "cstStar",
    },
    {
      label: "国家/地区",
      prop: "nation_id_v",
    },
    {
      label: "创建时间",
      prop: "create_time",
    },
    {
      label: "备注",
      prop: "cst_remark",
    },
    {
      label: "操作",
      type: "operate_btn",
      prop: "",
      width: "260px",
    },
  ],
  forms: [
    {
      label: "公司名字：",
      prop: "company_name",
      type: "input",
      span: 11,
      placeholder: "输入客户公司名字，如无，就直接用客户全名，必须唯一",
      rule: [
        {
          required: true,
          message: "输入客户公司名字，如无，就直接用客户全名，必须唯一",
          trigger: "blur",
        },
      ],
    },
    {
      label: "客户简码：",
      prop: "cst_short_code",
      type: "input",
      span: 11,
      placeholder: "用于订单号生成，建议填写客户主联系人姓名，如Harvey",
      rule: [
        {
          required: true,
          message: "用于订单号生成，建议填写客户主联系人姓名，如Harvey",
          trigger: "blur",
        },
      ],
    },
    {
      label: "客户邮箱：",
      prop: "company_email",
      type: "input",
      span: 11,
      placeholder: "输入客户邮箱，必须唯一",
      rule: [
        {
          type: "email",
          message: "请输入正确的邮箱地址，必须唯一",
          trigger: ["blur", "change"],
        },
      ],
    },
    {
      label: "国家/地区：",
      prop: "nation_id",
      type: "select",
      span: 11,
      options: "",
      filterable: true,
      placeholder: "选择客户来源国家/地区",
      rule: [
        {
          required: true,
          message: "选择客户来源国家/地区",
          trigger: "blur",
        },
      ],
    },
    {
      label: "跟进用户：",
      prop: "user_id",
      type: "select",
      span: 11,
      options: "",
      placeholder: "选择客户归属人",
      rule: [
        {
          required: true,
          message: "选择客户归属人",
          trigger: "blur",
        },
      ],
    },
    {
      label: "客户来源：",
      prop: "source_id",
      type: "select",
      span: 11,
      options: "",
      placeholder: "选择客户来源",
      rule: [
        {
          required: true,
          message: "选择客户来源",
          trigger: "blur",
        },
      ],
    },
    {
      label: "客户等级：",
      prop: "level_id",
      type: "select",
      span: 11,
      options: "",
      placeholder: "选择客户等级",
      rule: [
        {
          required: true,
          message: "选择客户等级",
          trigger: "blur",
        },
      ],
    },
    {
      label: "是否关注：:",
      prop: "focus",
      type: "switch",
      span: 11,
      modelValue: "1",
      activeText: "是",
      inactiveText: "否",
      rule: [{ required: true }],
    },
    {
      label: "公司地址：",
      prop: "company_add",
      type: "input",
      span: 22,
      inputType: "textarea",
      autoSize: { minRows: 5 },
      placeholder: "输入客户公司地址",
    },
    {
      label: "公司邮编：",
      prop: "company_post_code",
      type: "input",
      span: 11,
      placeholder: "输入客户公司邮编",
    },
    {
      label: "公司网址：",
      prop: "company_website",
      type: "input",
      span: 11,
      placeholder: "输入客户公司网址",
    },
    {
      label: "公司备注：",
      prop: "cst_remark",
      type: "input",
      span: 22,
      inputType: "textarea",
      autoSize: { minRows: 5 },
      placeholder: "公司主营业务、特殊需求等等",
    },
  ],
};
