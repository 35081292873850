<template>
  <el-form v-model="addForm" class="add">
    <el-input
      style="margin-bottom: 20px"
      v-if="!show"
      placeholder="记录跟进细节，同步最新动态"
      @focus="addDynamic"
      disabled
    ></el-input>
    <div class="add-item" v-if="show">
      <el-form-item style="margin-bottom: 0px">
        <el-input
          class="content"
          type="textarea"
          :autosize="true"
          :resize="none"
          v-model="addForm.content"
        ></el-input>
      </el-form-item>
      <div class="more">
        <el-form-item style="margin-bottom: 0px">
          <el-select
            class="stage"
            v-model="addForm.contact_person"
            placeholder="请选择联系人"
            clearable
          >
            <el-option
              v-for="item in contactPersonList"
              :key="item.person_id"
              :label="item.name_en"
              :value="item.person_id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="margin-bottom: 0px">
          <span>{{ addForm.create_time }}</span>
        </el-form-item>
      </div>
      <div class="box">
        <el-tooltip content="设置后，将通过日程进行提醒" placement="top">
          <el-form-item label="下次跟进时间" class="fTime">
            <el-date-picker
              v-model="addForm.notice_time"
              type="datetime"
              placeholder="选择日期时间"
            ></el-date-picker>
          </el-form-item>
        </el-tooltip>
      </div>
      <div
        style="display: flex; justify-content: flex-end; margin-bottom: 20px"
      >
        <el-button @click="addFollow" type="primary">添加</el-button>
        <el-button @click="cancel">取消</el-button>
      </div>
    </div>
    <div class="dynamic-list">
      <div class="dynamic-header">
        <span class="totalNum">共{{ followNum }}条</span>
        <el-select
          v-model="searchPersonForm.contact_person"
          placeholder="联系人筛选"
          @change="selectFollowList"
          clearable
          disabled
        >
          <el-option
            v-for="item in searchPersonList"
            :key="item.person_id"
            :label="item.name_en"
            :value="item.person_id"
          ></el-option>
        </el-select>
      </div>
      <el-collapse v-model="activeNames" accordion>
        <el-collapse-item
          v-for="(item, index) in followList"
          :title="`${item.month}(${item.activity.length})`"
          :name="index"
          :key="index"
        >
          <el-timeline>
            <el-timeline-item
              v-for="(it, idx) in item.activity"
              placement="top"
              :key="idx"
              :timestamp="it.create_time"
              :icon="CollectionTag"
              color="rgb(83, 180, 124)"
              type="primary"
              size="large"
            >
              <el-card class="card-contend">
                <span class="contacts">联系人：{{ it.name_en }}</span>
                <span>{{ it.content }}</span>
                <span
                  >{{ `创建人：${it.user_name_ch}` }}&nbsp;&nbsp;|&nbsp;&nbsp;
                  {{ `跟进时间：${it.notice_time}` }}</span
                >
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </el-collapse-item>
      </el-collapse>
    </div>
  </el-form>
</template>
<script>
import {
  addFollowList,
  customerContactPersonList,
  customerFollowList,
} from "@/network/customer.js";
import { ElMessage, ElMessageBox } from "element-plus";
import { ref, reactive, toRefs, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { CollectionTag } from "@element-plus/icons-vue";
import * as dayjs from "dayjs";
export default {
  components: {
    CollectionTag,
  },
  setup() {
    const route = ref(useRoute());
    const cst_id = route.value.query.cstId;
    const show = ref(false);
    const activeNames = ref(0);
    const state = reactive({
      contactPersonList: [],
      searchPersonList: [],
      followList: [],
      followNum: 0,
    });
    const addForm = reactive({
      id: "",
      cst_id: cst_id,
      content: "",
      create_time: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      notice_time: "",
      contact_person: "",
      build_user: "",
    });
    const searchPersonForm = reactive({
      contact_person: "",
    });
    const addFollow = () => {
      addFollowList({
        data: addForm,
      }).then((res) => {
        if (res.code == 0) {
          ElMessage.success(res.msg);
          getList(cst_id);
          show.value = false;
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    const addDynamic = () => {
      addForm.content = "";
      addForm.notice_time = "";
      addForm.contact_person = "";
      show.value = true;
    };
    const cancel = () => {
      show.value = false;
    };
    const selectFollowList = () => {
      customerFollowList({
        cst_id: cst_id,
        contact_person: searchPersonForm.contact_person,
      }).then((res) => {
        if (res.code == 0) {
          let listData = [];
          for (let key in res.data.list) {
            listData.push(res.data.list[key]);
          }
          state.followList = listData;
          state.followNum = res.data.total;
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    const router = useRouter();
    const getList = (val) => {
      customerContactPersonList({ cst_id: val }).then((res) => {
        if (res.code == 0) {
          state.contactPersonList = res.data;
          state.searchPersonList = res.data;
        } else {
          ElMessage.error(res.msg);
        }
      });
      customerFollowList({ cst_id: val }).then((res) => {
        if (res.code == 0) {
          let listData = [];
          for (let key in res.data.list) {
            listData.push(res.data.list[key]);
          }
          state.followList = listData;
          state.followNum = res.data.total;
        } else {
          ElMessage.error(res.msg);
        }
      });
      router.push({
        name: "getOpenCustomerInfo",
        params: {
          cstId: val,
        },
      });
    };
    onMounted(() => {
      getList(cst_id);
    });
    return {
      ...toRefs(state),
      activeNames,
      addForm,
      addDynamic,
      show,
      cancel,
      addFollow,
      selectFollowList,
      searchPersonForm,
      CollectionTag,
    };
  },
};
</script>
<style scoped lang="scss">
:deep .is-active {
  max-height: 500px;
}
.el-collapse-item {
  padding-right: 10px;
}
.el-card__body {
  position: relative;
}
.contacts {
  position: absolute;
  right: 10px;
  top: 0;
  color: #999;
  font-size: 12px;
}
.add-item {
  .content {
    :deep .el-textarea__inner {
      border: 1px solid #d9d9d9;
      border-bottom: none;
      box-shadow: none;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      resize: none;
      min-height: 140px !important;
    }
  }
  .more {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 36px;
    border: 1px solid #d9d9d9;
    border-top: none;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    background: #f2f2f2;
    line-height: 36px;
    margin-bottom: 15px;
    .stage {
      width: 200px;
    }
  }
  .box {
    .fTime {
      display: flex;
      justify-content: flex-end;
      :deep .el-form-item__content {
        flex: none;
      }
    }
  }
}
.dynamic-list {
  .dynamic-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #d9d9d9;
    padding: 10px 0;
    .totalNum {
      display: inline-block;
      width: auto;
      padding: 0 10px;
    }
    .el-select {
      flex: 1;
    }
  }
  :deep .el-timeline-item__node {
    text-align: center;
    line-height: 22px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
  }
  :deep .el-timeline-item__tail {
    left: 8px;
  }
  .card-contend {
    :deep .el-card__body {
      padding: 30px;
      padding-top: 15px;
    }
    span:last-child {
      position: absolute;
      bottom: 2px;
      right: 4px;
      color: #999;
    }
  }
}
</style>
