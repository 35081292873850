<template>
  <div style="height: 100%">
    <div style="text-align: center; margin: 20px; font-size: 30px">
      出运编码 {{ formState.ship_no }}
    </div>
    <div class="div_title">基本信息：</div>
    <el-row
      :gutter="24"
      style="margin: 20px 0px; font-size: 14px; line-height: 35px"
    >
      <el-col :span="8">发票编码：{{ formState.inv_list_id }} </el-col>
      <el-col :span="8" style="color: red"
        >发货渠道：{{ formState.shipping_id }}
      </el-col>
      <el-col :span="8">预期发货：{{ formState.expected_time }} </el-col>
    </el-row>
    <el-row :gutter="24" style="margin: 20px 0px; font-size: 14px">
      <el-col :span="24">发货备注：{{ formState.remark }} </el-col>
    </el-row>
    <el-form :model="formState" :rules="rules">
      <div class="div_title">发货信息：</div>
      <el-row :gutter="24" style="margin: 20px 0px">
        <el-col :span="12">
          <el-form-item label="货代公司：" prop="spr_id">
            <el-select
              v-model="formState.spr_id"
              :popper-append-to-body="false"
              placeholder="-请选择货代公司-"
            >
              <el-option
                v-for="item in forwardList"
                :key="item.spr_id"
                :label="item.spr_comp_name"
                :value="item.spr_id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="货代单号：" :label-width="formLabelWidth">
            <el-input
              v-model="formState.frd_waybill_no"
              placeholder="输入货代公司的运单单号"
              clearable
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24" style="margin: 20px 0px">
        <el-col :span="12">
          <el-form-item label="发货日期：" prop="shipping_time">
            <el-date-picker
              v-model="formState.shipping_time"
              type="date"
              placeholder="请选择发货日期"
              format="YYYY/MM/DD"
              value-format="YYYY-MM-DD"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="转单单号："
            :label-width="formLabelWidth"
            prop="shipping_waybill_no"
          >
            <el-input
              v-model="formState.shipping_waybill_no"
              placeholder="输入实际发货的单号"
              clearable
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24" style="margin: 20px 0px">
        <el-col :span="12">
          <el-form-item
            label="包裹实重："
            :label-width="formLabelWidth"
            prop="pkg_weight"
          >
            <el-input
              v-model="formState.pkg_weight"
              placeholder="打包后包裹实重：KG"
              clearable
            />
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item :label-width="formLabelWidth">
            <el-input
              v-model="formState.pkg_l"
              placeholder="输入打包后包裹长度：cm"
              clearable
            />
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item :label-width="formLabelWidth">
            <el-input
              v-model="formState.pkg_w"
              placeholder="输入打包后包裹宽度：cm"
              clearable
            />
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item :label-width="formLabelWidth">
            <el-input
              v-model="formState.pkg_h"
              placeholder="输入打包后包裹高度：cm"
              clearable
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="24"
          ><el-form-item :label-width="formLabelWidth" label="发货备注：">
            <el-input
              v-model="formState.delivery_remark"
              type="textarea"
              placeholder="输入发货时，需要备注的特殊信息（对物流服务商）"
              clearable
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-row :gutter="24">
      <el-col :span="4" :offset="20">
        <el-button size="middle" type="primary" @click="add()">{{
          btn_title
        }}</el-button>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { ref, reactive, toRefs, onMounted } from "vue";
import { getShippingPlanInfo, addDeliveryInfo } from "@/network/shipping.js";
import { useRoute, useRouter, route } from "vue-router";
import { ElMessage } from "element-plus";
export default {
  emits: ["getOderListId"],
  setup(props, context) {
    const formState = reactive({
      id: "",
      spr_id: "",
      frd_waybill_no: "",
      pkg_weight: "",
      pkg_l: "",
      pkg_w: "",
      pkg_h: "",
      shipping_waybill_no: "",
      shipping_time: "",
      delivery_remark: "",
    });
    const rules = reactive({
      spr_id: [
        {
          required: true,
          message: "请选择货代",
          trigger: "blur",
        },
      ],
      pkg_weight: [
        {
          required: true,
          message: "请输入包裹实重（kg）",
          trigger: "blur",
        },
      ],
      shipping_waybill_no: [
        {
          required: true,
          message: "请输入最终的物流单号",
          trigger: "blur",
        },
      ],
      shipping_time: [
        {
          required: true,
          message: "请设置发货日期",
          trigger: "blur",
        },
      ],
    });
    const route = ref(useRoute());
    const state = reactive({
      dataSource: [],
      forwardList: [],
      btn_title: "保存",
    });
    const ship_id = route.value.query.arrData;
    const router = useRouter();
    const getList = () => {
      getShippingPlanInfo({ data: ship_id }).then((res) => {
        if (res.code == 0) {
          state.forwardList = res.data.forwardList;
          Object.assign(formState, res.data.list);
          if (formState.shipping_waybill_no) {
            state.btn_title = "更新";
          }
        } else {
          ElMessage.error(res.msg);
          router.push({
            name: "deliveryOrder",
          });
        }
      });
    };
    onMounted(() => {
      getList();
    });
    const add = () => {
      //添加或更新
      addDeliveryInfo({
        formState: formState,
      }).then((res) => {
        if (res.code == 0) {
          ElMessage.success(res.msg);
          context.emit("getOrderListId", formState.shipping_waybill_no);
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    return {
      ...toRefs(state),
      formState,
      add,
      rules,
    };
  },
};
</script>
<style scoped>
.div_title {
  margin: 20px 0;
  padding-left: 10px;
  border-left: 10px solid grey;
  font-size: 20px;
}
</style>
