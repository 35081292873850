<template>
  <el-table
    class="table-box"
    ref="elTable"
    @header-dragend="headerDragendHandler"
    highlight-current-row
    header-cell-class-name="table-box-header-cell"
    :data="newRows"
    border
    style="width: 100%"
    tooltip-effect="dark"
    :row-class-name="rowClassHandler"
    v-bind="{ ...$attrs }"
  >
    <el-table-column
      v-if="selection"
      type="selection"
      fixed="left"
      width="40"
      align="center"
      :selectable="selectable"
    ></el-table-column>
    <el-table-column
      v-if="index"
      type="index"
      fixed="left"
      :index="1"
      width="50"
      :label="indexLable"
    ></el-table-column>
    <template v-for="(col, cIndex) in activeColumns" :key="col.prop + cIndex">
      <el-table-column
        :fixed="false"
        :show-overflow-tooltip="true"
        v-bind="{ ...col }"
      >
        <template v-slot:header>
          <slot name="col-header" v-bind="{ col }">
            <el-tooltip
              v-if="headerTooltip"
              class="item"
              effect="dark"
              :content="col.label"
              placement="top"
            >
              <span>{{ col.label }}</span>
            </el-tooltip>
            <span v-else :title="col.label">{{ col.label }}</span>
          </slot>
        </template>
        <template v-slot="{ row, $index: rIndex }">
          <template v-if="!col.type">{{ row[col.prop] }}</template>
          <template v-else-if="col.type === 'select'">{{
            col.optionsKey
              ? keyMapFormat(row[col.prop], col.options, col)
              : dictFormat(row[col.prop], col.options)
          }}</template>
          <slot v-else name="col-append" v-bind="{ col, row, rIndex }"></slot>
        </template>
      </el-table-column>
    </template>
    <el-table-column
      label="操作"
      fixed="right"
      :min-width="actionColWidth"
      v-if="actionColShow"
    >
      <template v-slot="{ row, $index: rIndex }">
        <template v-for="(e, eIndex) in actions.list">
          <el-button
            v-if="e.displayRule(row)"
            type="text"
            :key="e.event + eIndex"
            @click.stop="(ev) => actionClick(e.event, row, rIndex, ev)"
            :loading="row[e.event + 'loading'] || false"
            >{{ e.label }}</el-button
          >
          <slot
            v-else
            name="action-append"
            v-bind="{ actionClick: actionClick, event: e, row, rIndex }"
          ></slot>
        </template>
      </template>
    </el-table-column>
  </el-table>
</template>
<script>
import lodash from "lodash";
import { setLocal, getLocal } from "@/utils/storage";
const HEIGHTOFFSET = 146;
export default {
  name: "isdTable",
  inheritAttrs: false,
  props: {
    actions: {
      type: Object,
      default() {
        return {
          list: [],
        };
      },
      validator: function (obj) {
        return !!obj.list;
      },
    },
    columns: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    rows: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    selection: {
      type: Boolean,
      default() {
        return false;
      },
    },
    selectable: {
      type: Function,
      default() {
        return true;
      },
    },
    index: {
      type: Boolean,
      default() {
        return false;
      },
    },
    // 前端缓存表格宽度标识，需唯一
    columnWidthCacheIdentifer: {
      type: String,
      default() {
        return window.location.hash;
      },
    },
    showSearch: {
      type: Boolean,
      default() {
        return false;
      },
    },
    indexLable: {
      type: String,
      default() {
        return "行号";
      },
    },
    initTagsColor: {
      type: Array,
      default() {
        return [
          { iconColor: "#A12525", color: "#FAA7A7" },
          { iconColor: "#503996", color: "#C8BBF0" },
          { iconColor: "#7A5C00", color: "#FADB7D" },
          { iconColor: "#0B62A1", color: "#89CBFA" },
          { iconColor: "#0D8046", color: "#80E0B0" },
          { iconColor: "#304CA1", color: "#B2C3F7" },
          { iconColor: "#3A6310", color: "#BCE396" },
          { iconColor: "#AB6116", color: "#FFCC99" },
          { iconColor: "#7B3991", color: "#E1BBED" },
          { iconColor: "#A1356B", color: "#F7BAD8" },
        ];
      },
    },
    tagsConfig: {
      type: Object,
      default() {
        return {};
      },
    },
    headerTooltip: {
      type: Boolean,
      default: false,
    },
    needReserveTableScrollTop: {
      type: Boolean,
      default: false,
    },
  },
  // 定义抛出的事件名称
  emits: ["action-click", "do-search", "search-field-change"],
  watch: {
    actions: {
      handler(val) {
        if (val.list) {
          lodash.forEach(val.list, (ele) => {
            if (!lodash.isFunction(ele.displayRule)) {
              ele.displayRule = () => true;
            }
          });
        }
      },
      immediate: true,
    },
    rows: {
      handler(val) {
        // fix 修正elementui多个tab都包含表格，切换tab时table复选框绝对定位计算错误的问题
        this.$refs.elTable.debouncedUpdateLayout();
        this.newRows = val;
      },
      deep: true,
      immediate: false,
    },
    activeColumns: {
      handler() {
        // 对newRows进行延迟赋值是为了修正列变化时右fix的操作列top属性计算异常的问题
        if (this.rows.length > 0) {
          this.newRows = [];
        }
        this.$nextTick(() => {
          this.newRows = this.rows;
          this.$refs.elTable.debouncedUpdateLayout();
        });
      },
      immediate: false,
    },
  },
  computed: {
    lodash() {
      return lodash;
    },
    actionColShow() {
      return this.actions.list && this.actions.list.length > 0;
    },
    actionColWidth() {
      const btnWidth = 50;
      return this.actions.fixedWidth || btnWidth * this.actions.list.length;
    },
    activeColumns() {
      return this.columns.filter((item) => !item.hiddenColumn);
    },
    tagListFilter() {
      if (!this.tagListFilterWords.trim()) {
        return this.tagList;
      } else {
        const list = this.tagList.filter((item) => {
          return item.labelName.indexOf(this.tagListFilterWords) >= 0;
        });
        return list;
      }
    },
  },
  data() {
    return {
      defaultHeight: document.body.height - HEIGHTOFFSET,
      dictFormat: (val, options) => {
        if (!options) return val;
        return lodash.get(options, val, val);
      },
      // keyMapFormat: (val) => val,
      newRows: [],
      columnsWidthConfig: null,
      searchData: {},
    };
  },
  methods: {
    keyMapFormat(val, options) {
      // return val;
      if (!(val === 0 ? "0" : val) || !options) {
        return val;
      } else if (Array.isArray(val)) {
        return val
          .map((item) => {
            for (let i = 0; i < options.length; i++) {
              if (options[i].value + "" === item + "") {
                return options[i].label;
              }
            }
            return options;
          })
          .join(",");
      } else {
        for (let i = 0; i < options.length; i++) {
          if (options[i].value + "" === val + "") {
            return options[i].label;
          }
        }
      }
    },
    doSearch() {
      this.$emit("do-search", this.searchData);
    },
    searchFieldChange({ fieldName, value, field }) {
      this.searchData[fieldName] = value;
      this.$emit("search-field-change", fieldName, value);
      // 过滤有multiple 有多选的选项[lotsSelect+lotsBasicDataSelector] 减少开销
      if (
        ["select", "lotsBasicData"].includes(field.type) &&
        ((field.config && field.config.multiple) || field.multiple)
      ) {
        this.$nextTick(() => {
          // 解决表格错位
          this.$refs.elTable.debouncedUpdateLayout();
        });
      }
    },
    // 重新配置列宽度
    resetColumnWidth(columnsList) {
      this.columnsWidthConfig &&
        Object.keys(this.columnsWidthConfig).forEach((key) => {
          const col = columnsList.find((e) => e.prop === key);
          if (col) {
            col.width = this.columnsWidthConfig[key];
          }
        });
      return columnsList;
    },
    headerDragendHandler(newWidth, oldWidth, column) {
      this.columnsWidthConfig = this.columnsWidthConfig || {};
      this.columnsWidthConfig[column.property] = newWidth;
      setLocal(this.columnWidthCacheIdentifer, this.columnsWidthConfig);
      this.$nextTick(() => {
        // 重新渲染表格，解决表格错位问题
        this.$refs.elTable.doLayout();
      });
    },
    actionClick(event, row, rIndex, ev) {
      this.$emit("action-click", event, row, rIndex);
      if (ev.target.nodeName === "SPAN") {
        ev.target.parentNode.blur();
      }
    },
    getTable() {
      return this.$refs.elTable;
    },
    rowClassHandler({ row }) {
      return this.getTable().selection
        ? this.getTable().selection.includes(row)
          ? "selected-row"
          : ""
        : "";
    },
    setTableBoxQueryParams(initialVal) {
      this.searchData = Object.assign({}, lodash.cloneDeep(initialVal));
      // Object.assign(this.searchData, initialVal);
    },

    listenerScroll(ev) {
      const delay = 500;
      if (this.antiShake) {
        clearTimeout(this.antiShake);
      }
      this.antiShake = setTimeout(() => {
        this.TableScrollTop = ev.target.scrollTop;
      }, delay);
    },
  },
  activated() {
    if (this.TableScrollTop && this.needReserveTableScrollTop) {
      const delay = 50;
      setTimeout(() => {
        this.$refs.elTable.$refs.bodyWrapper.scrollTop = this.TableScrollTop;
      }, delay);
    }
  },
  beforeUnmount() {
    if (this.needReserveTableScrollTop) {
      this.TableScrollTop = undefined;
      const dom = this.$refs.elTable.$refs.bodyWrapper;
      dom.removeEventListener("scroll", this.listenerScroll);
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.needReserveTableScrollTop) {
        const dom = this.$refs.elTable.$refs.bodyWrapper;
        dom.addEventListener("scroll", this.listenerScroll);
      }
    });
  },
  created() {
    this.columnsWidthConfig = getLocal(this.columnWidthCacheIdentifer);
    this.resetColumnWidth(this.activeColumns);
  },
};
</script>
<style lang="scss">
@import "./style.scss";
</style>
