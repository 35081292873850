export default {
  selection: false,
  columns: [
    // 表格列的配置
    {
      label: "订单编码",
      prop: "ord_no",
    },
    {
      label: "产品型号",
      prop: "pdt_model",
    },
    {
      label: "海关编码",
      prop: "hs_code",
      type: "hsCode",
    },
    {
      label: "总商品数",
      prop: "ord_total",
    },
    {
      label: "已做发票数",
      prop: "inv_num",
    },
    {
      label: "申报数量",
      prop: "num",
      type: "pdtNum",
    },
    {
      label: "申报价值",
      prop: "declared_value",
      type: "declaredValue",
    },
    {
      label: "申报货币",
      prop: "currency_id",
    },
    {
      label: "小计",
      prop: "total",
    },
    {
      label: "申报描述",
      prop: "dsp",
      type: "pdtDsp",
    },
  ],
  actions: {
    // 表格操作列的配置
    fixedWidth: 110, // fixedWidth（可选） 设置操作列的固定宽度，不加该参数默认是按钮list长度*50px
    list: [
      {
        label: "删除",
        event: "del",
        displayRule: () => {
          return true;
        },
      },
    ],
  },
  forms: [
    {
      label: "产品型号",
      prop: "pdt_model",
      type: "input",
      disabled: true,
    },
    {
      label: "海关编码",
      prop: "hs_code",
      type: "input",
    },
    {
      label: "显示编码",
      prop: "hs_code_show",
      type: "switch",
      activeText: "是",
      inactiveText: "否",
    },

    {
      label: "出运数量",
      prop: "num",
      type: "input",
      rule: [
        {
          required: true,
          message: "请输入发票中该产品发货数量",
          trigger: "blur",
        },
        {
          pattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
          message: "请输入非负数",
        },
      ],
    },
    {
      label: "申报价值",
      prop: "declared_value",
      type: "input",
      rule: [
        {
          required: true,
          message: "请输入发票中该产品申报单价",
          trigger: "blur",
        },
        {
          pattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
          message: "请输入非负数",
        },
      ],
    },
    {
      label: "申报描述",
      prop: "dsp",
      type: "input",
      inputType: "textarea",
      rule: [{ required: true, message: "请输入英文", trigger: "blur" }],
    },
  ],
};
