/**
 * 公共组件所需的接口api文件
 */
import localforage from 'localforage';
import _ from 'lodash';
import request from '../utils/service';
import { getSession, setSession, getLocal, setLocal } from '../utils/storage';
import DictQueue from './DictQueue';
const LINKSYM = '#';
/**
 * 省市区相关接口
 */
// 初始化indexedDb数据表
const addressDb = localforage.createInstance({
    storeName: 'address'
});
// 省市四级联动
export const getAreaApi = async (params) => {
    const areaList = await addressDb.getItem(String(params.ebplParentPmCode));
    if (areaList) {
        return {
            code: '0',
            data: areaList
        };
    } else {
        params.ebplIsAble = 'ENABLE';
        return request({
            url: '/api-mdm/searchEbPlace',
            method: 'get',
            params: params
        }).then(res => {
            if (res && res.code === '0' && !_.isEmpty(res.data)) {
                addressDb.setItem(String(params.ebplParentPmCode), res.data);
            }
            return res;
        });
    }
};

/**
 * 列配置相关接口
 */
// 列配置
export const columnsConfigApi = {
    save(params) {
        return request({
            url: '/api-auth/userIndividualConfigs/saveOrUpdate',
            method: 'post',
            data: params
        });
    },
    get(params) {
        return request({
            url: '/api-auth/userIndividualConfigs/param',
            method: 'post',
            data: params
        });
    }
};

/**
 * 列配置和自定义搜索条件配置相关接口
 */
export const getList = {
    post(url, params) {
        const key = [params.id, params.tenantCode, params.subjectType, params.moduleCode].join(LINKSYM);
        Reflect.deleteProperty(params, 'id');
        Reflect.deleteProperty(params, 'tenantCode');
        setLocal(key, params.configName);
        return request({
            url: url + '/userIndividualConfigs/saveOrUpdate',
            method: 'post',
            data: params
        });
    },
    get(url, params) {
        const key = [params.id, params.tenantCode, params.subjectType, params.moduleCode].join(LINKSYM);
        Reflect.deleteProperty(params, 'id');
        Reflect.deleteProperty(params, 'tenantCode');
        if (getLocal(key)) {
            return Promise.resolve({
                code: '0',
                data: {
                    configName: getLocal(key)
                }
            });
        } else {
            return request({
                url: url + '/userIndividualConfigs/param',
                method: 'post',
                data: params
            }).then(res => {
                if (res.code === '0' && res.data.configName) {
                    setLocal(key, res.data.configName);
                }
                return res;
            });
        }
    }
};
export const fieldConfig = {
    set: (params) => {
        return getList.post('/api-auth', params);
    },
    get: (params) => {
        return getList.get('/api-auth', params);
    }
};

/**
 *  数据字典相关接口
 */
function getDictCache(keysStr) {
    if (!keysStr) {
        return {
            result: {},
            passKeys: ''
        };
    }
    const tenantCode = getSession('tenantCode');
    const locale = getSession('locale') || 'zh-CN';
    const keys = keysStr.split(',');
    const result = {};
    const passKeys = [];
    keys.forEach(key => {
        const sessionKey = [tenantCode, locale, key].join(LINKSYM);
        const val = getSession(sessionKey);
        if (val) {
            result[key] = val;
        } else {
            passKeys.push(key);
        }
    });
    return {
        result,
        passKeys: passKeys.join(',')
    };
}
function setDictCache(map) {
    const tenantCode = getSession('tenantCode');
    const locale = getSession('locale') || 'zh-CN';
    Object.keys(map).forEach(key => {
        if (_.isEmpty(map[key])) return;
        const sessionKey = [tenantCode, locale, key].join(LINKSYM);
        setSession(sessionKey, map[key]);
    });
}
// 字典值
export const dict = (popPrefix, params) => {
    let dictCodes = null;
    if (_.isString(params)) {
        dictCodes = params;
    } else {
        dictCodes = params.dictCodes;
    }
    const data = getDictCache(dictCodes);
    if (data.passKeys === '') {
        return new Promise(resolve => {
            resolve({
                code: '0',
                data: data.result
            });
        });
    } else {
        return request({
            url: popPrefix,
            method: 'get',
            params: {
                // 不区分租户
                tenantCode: getSession('tenantCode'),
                applicationCode: params.applicationCode || getSession('applicationCode'),
                dictCodes: data.passKeys
            }
        }).then(res => {
            setDictCache(res.data);
            res.data = { ...data.result, ...res.data };
            return res;
        });
    }
};

// 排队
const dictQueue = new DictQueue();
export const dictWithUrl = (params, url = '/api-mdm/dictionaryDetail/dict') => {
    dictQueue.setFetchFn((params) => dict(url, params));
    const dictCodes = _.isString(params) ? params : params.dictCodes;
    return dictQueue.queueUp(dictCodes, url);
};
// 根据字典编码，key获取name
export const dictNameByCode = (code, value, url) => {
    return new Promise((resolve) => {
        let dictName = '';
        dictWithUrl(code, url).then(res => {
            if (res.data && res.data[code]) {
                Object.keys(res.data[code]).forEach(key => {
                    if ((key + '') === (value + '')) {
                        dictName = res.data[code][key];
                    }
                });
            }
            resolve(dictName);
        });
    });
};
