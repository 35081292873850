import { request } from "./request.js";
export function purchaseWaitList(data = {}) {
  return request({
    url: "purchase/purchaseWaitList",
    method: "post",
    data,
  });
}
export function createPurchase(data = {}) {
  return request({
    url: "purchase/createPurchase",
    method: "post",
    data,
  });
}
export function addPurchase(data = {}) {
  //新增采购订单
  return request({
    url: "purchase/addPurchase",
    method: "post",
    data,
  });
}
export function getSupplierList(data = {}) {
  //新增采购订单
  return request({
    url: "purchase/getSupplierList",
    method: "post",
    data,
  });
}
export function getSupplierAccountList(data = {}) {
  return request({
    url: "purchase/getSupplierAccountList",
    method: "post",
    data,
  });
}
export function getPaymentTypeList(data = {}) {
  return request({
    url: "purchase/getPaymentTypeList",
    method: "post",
    data,
  });
}
export function getPurchaseDetailList(data = {}) {
  return request({
    url: "purchase/getPurchaseDetailList",
    method: "post",
    data,
  });
}
export function addPurchaseDetails(data = {}) {
  return request({
    url: "purchase/addPurchaseDetails",
    method: "post",
    data,
  });
}
export function delPurchaseDetails(data = {}) {
  return request({
    url: "purchase/delPurchaseDetails",
    method: "post",
    data,
  });
}
export function purchasingList(data = {}) {
  return request({
    url: "purchase/purchasingList",
    method: "post",
    data,
  });
}
export function getPurchaserList(data = {}) {
  return request({
    url: "purchase/getPurchaserList",
    method: "post",
    data,
  });
}
export function updateAssignPurchaser(data = {}) {
  return request({
    url: "purchase/updateAssignPurchaser",
    method: "post",
    data,
  });
}
export function deletePurchaseOrder(data = {}) {
  return request({
    url: "purchase/deletePurchaseOrder",
    method: "post",
    data,
  });
}
export function inspectPurchaseOrder(data = {}) {
  return request({
    url: "purchase/inspectPurchaseOrder",
    method: "post",
    data,
  });
}
export function getIMEICodeByPurchaseDetails(data = {}) {
  return request({
    url: "purchase/getIMEICodeByPurchaseDetails",
    method: "post",
    data,
  });
}
export function confirmOrderPlan(data = {}) {
  return request({
    url: "purchase/confirmOrderPlan",
    method: "post",
    data,
  });
}
