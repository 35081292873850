<template>
  <div style="height: 100%">
    <div class="actionBtn">
      <el-button type="primary" @click="nationGo(0)"
        ><el-icon> <plus /> </el-icon><span>新增</span></el-button
      >
    </div>
    <el-dialog v-model="dialogFormVisible" :title="title">
      <el-form :model="formState" :rules="rules">
        <el-row :gutter="20">
          <el-col :span="10">
            <el-form-item
              label="中文名："
              :label-width="formLabelWidth"
              prop="nation_name_ch"
            >
              <el-input
                v-model="formState.nation_name_ch"
                placeholder="输入国家/地区中文名"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item
              label="英文名："
              prop="nation_name_en"
              :label-width="formLabelWidth"
            >
              <el-input
                v-model="formState.nation_name_en"
                placeholder="输入国家/地区英文名"
                clearable
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="10">
            <el-form-item
              label="国家简码："
              :label-width="formLabelWidth"
              prop="nation_code"
            >
              <el-input
                v-model="formState.nation_code"
                placeholder="输入国家/地区简码（US）"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item
              label="电话简码："
              prop="nation_brief_code"
              :label-width="formLabelWidth"
            >
              <el-input
                v-model="formState.nation_brief_code"
                placeholder="输入国家/地区电话简码（+86）"
                clearable
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="10">
            <el-form-item
              label="所属大洲："
              :label-width="formLabelWidth"
              prop="continent_id"
            >
              <el-select
                v-model="formState.continent_id"
                :popper-append-to-body="false"
              >
                <el-option
                  v-for="item in getContinentList"
                  :key="item.continent_id"
                  :label="item.continent_name_ch"
                  :value="item.continent_id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item
              label="版本编码："
              prop="nation_is_version"
              :label-width="formLabelWidth"
            >
              <el-select
                v-model="formState.nation_is_version"
                :popper-append-to-body="false"
              >
                <el-option
                  v-for="item in isVersion"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="状态：" :label-width="formLabelWidth">
          <el-switch
            v-model="formState.nation_status"
            class="ml-2"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-text="开启"
            inactive-text="关闭"
          />
        </el-form-item>
        <el-form-item label="备注：" :label-width="formLabelWidth">
          <el-input type="textarea" v-model="formState.nation_remark" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取消</el-button>
          <el-button type="primary" @click="add">{{ goTitle }}</el-button>
        </span>
      </template>
    </el-dialog>

    <div class="content">
      <el-table
        :data="dataSource"
        style="width: 100%; font-size: 12px"
        height="100%"
        stripe:true
      >
        <el-table-column
          fixed
          prop="nation_id"
          label="ID"
          width="100"
          sortable
        />
        <el-table-column
          prop="nation_name_ch"
          label="中文名"
          width="200"
          fixed
        />
        <el-table-column prop="nation_name_en" label="英文名" width="200" />
        <el-table-column prop="nation_code" label="国家简码" width="120" />
        <el-table-column
          prop="nation_brief_code"
          label="电话简码"
          width="120"
        />
        <el-table-column prop="continent_id_v" label="大洲" width="120" />
        <el-table-column prop="nation_is_version" label="版本编码" width="120">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <span>{{ scope.row.nation_is_version == 1 ? "是" : "否" }}</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="nation_status" label="状态" width="120">
          <template #default="scope">
            <span
              :style="{ color: scope.row.nation_status == 1 ? 'green' : 'red' }"
            >
              {{ scope.row.nation_status == 1 ? "开启" : "关闭" }}</span
            >
          </template>
        </el-table-column>

        <el-table-column fixed="right" label="操作" width="200">
          <template #default="scope">
            <el-button type="primary" @click="nationGo(scope.row)">
              <el-icon> <edit /> </el-icon><span>编辑</span></el-button
            >
            <el-button type="danger" @click="del(scope.row.nation_id)"
              ><el-icon> <delete /> </el-icon><span>删除</span></el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="demo-pagination-block">
        <el-pagination
          :currentPage="currentPage"
          :page-size="pageSize"
          :page-sizes="[20, 50, 100]"
          :background="true"
          layout="sizes, prev, pager, next, jumper,total"
          :total="pageTotal"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, toRefs, onMounted } from "vue";
import {
  nationList,
  nationAdd,
  nationDel,
  getContinentList,
} from "@/network/basic.js";
import { Delete, Edit, Plus, Search } from "@element-plus/icons-vue";
import { ElMessage, ElMessageBox } from "element-plus";

export default {
  components: {
    Delete,
    Edit,
    Plus,
    Search,
  },
  setup() {
    const currentPage = ref(1);
    const pageSize = ref(10);
    const pageTotal = ref(0);
    const isVersion = reactive([
      { label: "否", value: 0 },
      { label: "是", value: 1 },
    ]);
    const state = reactive({
      dataSource: [],
      title: "添加国家/地区",
      goTitle: "添加",
      getContinentList: [],
      dialogFormVisible: false,
      formLabelWidth: "140px",
    });
    const rules = reactive({
      nation_name_en: [
        {
          required: true,
          message: "请输入国家/地区英文名",
          trigger: "blur",
        },
      ],
      nation_name_ch: [
        {
          required: true,
          message: "请输入国家/地区中文名",
          trigger: "blur",
        },
      ],
      nation_code: [
        {
          required: true,
          message: "请输入国家/地区英文简码",
          trigger: "blur",
        },
      ],
      nation_brief_code: [
        {
          required: true,
          message: "请输入国家/地区电话简码",
          trigger: "blur",
        },
      ],
      nation_is_version: [
        {
          required: true,
          message: "请国家/地区是否设置为产品版本",
          trigger: "blur",
        },
      ],
      continent_id: [
        {
          required: true,
          message: "请选择所属大洲",
          trigger: "blur",
        },
      ],
    });
    const formState = reactive({
      nation_id: 0,
      nation_name_en: "",
      nation_name_ch: "",
      nation_code: "",
      nation_brief_code: "",
      continent_id: "",
      nation_is_version: 0,
      nation_status: 1,
      nation_remark: "",
    });
    const getList = () => {
      nationList({
        currentPage: currentPage.value,
        pageSize: pageSize.value,
      }).then((res) => {
        if (res.code == 0) {
          state.dataSource = res.data.lists;
          pageTotal.value = res.data.pageTotal;
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    const handleSizeChange = (val) => {
      pageSize.value = val;
      getList();
    };
    const handleCurrentChange = (val) => {
      currentPage.value = val;
      getList();
    };
    onMounted(() => {
      getList();
    });
    //------------以上为列表数据

    const nationGo = (row) => {
      state.dialogFormVisible = true;
      //返回大洲列表列表
      getContinentList().then((res) => {
        if (res.code == 0) {
          state.getContinentList = res.data;
        } else {
          ElMessage.error(res.msg);
        }
      });

      if (row) {
        Object.assign(formState, row);
        state.goTitle = "更新";
        state.title = "修改国家/地区信息";
        formState.nation_status = Boolean(row.nation_status);
      } else {
        state.goTitle = "添加";
        state.title = "添加国家/地区";
        formState.nation_id = 0;
        formState.nation_name_en = "";
        formState.nation_name_ch = "";
        formState.nation_code = "";
        formState.nation_brief_code = "";
        formState.continent_id = "";
        formState.nation_is_version = "";
        formState.nation_status = true;
        formState.nation_remark = "";
      }
    };

    const add = () => {
      if (!formState.nation_name_en) {
        ElMessage.info("请国家/地区名称");
        return false;
      }
      nationAdd(formState).then((res) => {
        if (res.code == 0) {
          ElMessage.success(res.msg);
          state.dialogFormVisible = false;
          getList();
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    //------------以上为编辑和修改数据
    const del = (nation_id) => {
      if (!nation_id) {
        ElMessage("所选数据为空，请重新选择！");
      } else {
        ElMessageBox.confirm("确定要删除该条数据吗?", "警告", {
          confirmButtonText: "删除",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            nationDel({ nation_id: nation_id }).then((res) => {
              if (res.code == 0) {
                ElMessage.success(res.msg);
                getList();
              } else {
                ElMessage.error(res.msg);
              }
            });
          })
          .catch(() => {
            ElMessage({
              type: "info",
              message: "删除取消",
            });
          });
      }
    };

    return {
      ...toRefs(state),
      rules,
      formState,
      isVersion,
      nationGo,
      add,
      del,
      currentPage,
      pageSize,
      pageTotal,
      handleSizeChange,
      handleCurrentChange,
    };
  },
};
</script>
<style scoped>
.el-select :deep .select-popper {
  z-index: 8888 !important;
  top: auto !important;
  left: auto !important;
}
.actionBtn {
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;
}
.content {
  height: calc(100% - 180px);
  position: relative;
}
.el-table {
  position: absolute;
}
.el-table__header {
  width: 100%;
}
.el-table__body {
  height: calc(100% - 40px);
}
.el-pagination {
  position: absolute;
  bottom: -56px;
  right: 20px;
}
</style>
