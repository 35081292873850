<template>
  <div style="height: 100%; padding: 10px">
    <div style="text-align: center; font-size: 30px; margin-bottom: 30px">
      应付单 {{ baseInfo.pay_no }} {{ operate_text }}
    </div>
    <div>
      <div class="div_title">基本信息：</div>
      <el-row :gutter="24" style="margin: 20px 0px">
        <el-col :span="8">生成日期：{{ baseInfo.create_time }} </el-col>
        <el-col :span="8">付款编码：{{ baseInfo.pay_no }} </el-col>
        <el-col :span="8">付款货币 {{ baseInfo.currency_id }} </el-col>
      </el-row>
      <el-row :gutter="24" style="margin: 20px 0px">
        <el-col :span="8">支付日期：{{ baseInfo.pay_time }} </el-col>
        <el-col :span="8">账单状态：{{ baseInfo.status }} </el-col>
      </el-row>
      <div class="div_title" style="margin-top: 20px">付款信息：</div>
      <el-row :gutter="24" style="margin: 20px 0px">
        <el-col :span="8"
          >&nbsp;&nbsp;&nbsp;付款人员：{{ baseInfo.pay_person }}
        </el-col>
        <el-col :span="8">账单负责人：{{ baseInfo.charge_person }} </el-col>
        <el-col :span="8">付款日期：{{ baseInfo.pay_time }} </el-col>
      </el-row>
      <el-row :gutter="24" style="margin: 20px 0px">
        <el-col :span="8"
          >&nbsp;&nbsp;&nbsp;账户简码：{{ baseInfo.acc_code }}
        </el-col>
        <el-col :span="8">账户名称：{{ baseInfo.acc_name }} </el-col>
        <el-col :span="8">账户号码：{{ baseInfo.acc_no }} </el-col>
      </el-row>
      <el-row :gutter="24" style="margin: 20px 0px"> </el-row>
      <div class="div_title" style="margin-top: 20px">收款公司：</div>
      <el-row :gutter="24" style="margin: 20px 0px">
        <el-col :span="8">账户简码：{{ baseInfo.r_acc_code }} </el-col>
        <el-col :span="8">账户名称：{{ baseInfo.r_acc_name }} </el-col>
        <el-col :span="8">账户号码：{{ baseInfo.r_acc_no }} </el-col>
      </el-row>
    </div>
    <div class="div_title" style="margin-top: 20px">付款详情：</div>
    <div class="content">
      <el-table
        :data="dataSource"
        show-summary
        style="width: 100%; font-size: 12px"
        height="300px"
      >
        <el-table-column prop="payment_no" label="相关账单" width="200" />
        <el-table-column prop="payable_amount" label="应付总额" width="100" />
        <el-table-column prop="paid_amount" label="已付总额" width="100" />
        <el-table-column prop="wait_amount" label="待付余额" width="100" />
        <el-table-column prop="amount" label="实付金额" width="100" />
        <el-table-column prop="remark" label="支付备注" />
      </el-table>
    </div>
    <div>
      <el-row :gutter="20" style="margin-top: 20px">
        <el-col> 付款备注：{{ baseInfo.remark }} </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="24" style="text-align: right">
          <el-button
            v-if="operate_type"
            size="middle"
            type="success"
            @click="add(1)"
            ><el-icon style="vertical-align: middle"> <RefreshRight /></el-icon
            ><span style="vertical-align: middle">通过</span></el-button
          >
          <el-button
            v-if="operate_type"
            size="middle"
            type="info"
            @click="add(2)"
            ><el-icon style="vertical-align: middle"> <RefreshLeft /></el-icon
            ><span style="vertical-align: middle">驳回</span></el-button
          >
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { ref, reactive, toRefs, onMounted, markRaw } from "vue";
import { ElMessage } from "element-plus";
import { paymentReview } from "@/network/finance.js";
import { Refresh, RefreshLeft, RefreshRight } from "@element-plus/icons-vue";
import { showPaymentInfo } from "@/network/showDetails.js";
import { useRoute, useRouter } from "vue-router";
export default {
  components: {
    Refresh: markRaw(Refresh),
    RefreshLeft: markRaw(RefreshLeft),
    RefreshRight: markRaw(RefreshRight),
  },
  setup() {
    const route = ref(useRoute());
    const router = useRouter();
    const state = reactive({
      dataSource: [],
      baseInfo: [],
      operate_type: false,
      operate_text: "详情",
    });
    const printInfo = route.value.query.arrData;
    const getList = () => {
      if (printInfo[0] == 1) {
        state.operate_type = true;
        state.operate_text = "审核";
      }
      showPaymentInfo({ printInfo: printInfo, status: printInfo[2] }).then(
        (res) => {
          if (res.code == 0) {
            state.baseInfo = res.data.baseInfo;
            state.dataSource = res.data.lists;
          } else {
            ElMessage.error(res.msg);
          }
        }
      );
    };
    const add = (val) => {
      //审核通过或驳回
      paymentReview({
        operate_type: val,
        pay_id: printInfo[1], //审核是，此为付款单id
      }).then((res) => {
        if (res.code == 0) {
          ElMessage.success(res.msg);
          router.push({
            name: "financePayment", //审核通过或驳回后，回到付款单列表
            query: {},
          });
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    onMounted(() => {
      getList();
    });
    return {
      ...toRefs(state),
      add,
    };
  },
};
</script>
<style scoped>
.div_title {
  font-size: 14px;
  font-weight: 400;
  border-left: 5px solid darkorange;
  padding-left: 5px;
}
</style>
