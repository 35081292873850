<template>
  <div class="order-detail-contain">
    <el-tabs v-model="activeName">
      <el-tab-pane label="待采购" name="first">
        <purchaseWait></purchaseWait>
      </el-tab-pane>
      <el-tab-pane label="待入库" name="second">
        <purchasing></purchasing>
      </el-tab-pane>
      <el-tab-pane label="已完成" name="four">
        <purchaseComplete></purchaseComplete>
      </el-tab-pane>
      <el-tab-pane label="回收站" name="five">
        <purchaseDelete></purchaseDelete>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { ref } from "vue";
import purchaseWait from "./Wait/index.vue";
import purchasing from "./List/index.vue";
import purchaseComplete from "./Complete/index.vue";
import purchaseDelete from "./Delete/index.vue";
export default {
  name: "purchase",
  components: {
    purchaseWait,
    purchasing,
    purchaseComplete,
    purchaseDelete,
  },
  setup() {
    const activeName = ref("first");

    return {
      activeName,
    };
  },
};
</script>
<style scoped lang="scss">
.order-detail-contain {
  height: 100%;
  padding: 20px 0;
  .el-tabs {
    height: 100%;
    :deep .el-tabs__content {
      height: calc(100% - 100px);
    }
    .el-tab-pane {
      height: 100%;
    }
  }
}
</style>
