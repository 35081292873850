export const searchConfig = {
  isAdvanced: true,
  formItems: [],
  filedOptions: [
    {
      fieldName: "company_name",
      displayName: "公司名字",
      condition: [
        {
          code: "=",
          name: "等于",
        },
        {
          code: "like",
          name: "包含",
        },
      ],
      textType: "input",
    },
    {
      fieldName: "cst_short_code",
      displayName: "客户简码",
      condition: [
        {
          code: "=",
          name: "等于",
        },
        {
          code: "like",
          name: "包含",
        },
      ],
      textType: "input",
    },
    {
      fieldName: "cst_remark",
      displayName: "客户备注",
      condition: [
        {
          code: "=",
          name: "等于",
        },
        {
          code: "like",
          name: "包含",
        },
      ],
      textType: "input",
    },

    {
      fieldName: "group_id",
      displayName: "客户分组",
      condition: [
        {
          code: "=",
          name: "等于",
        },
      ],
      textType: "select",
      dictCode: "getCstGroupList",
    },
    {
      fieldName: "stage_id",
      displayName: "客户阶段",
      condition: [
        {
          code: "=",
          name: "等于",
        },
      ],
      textType: "select",
      dictCode: "getCstStageList",
    },
    {
      fieldName: "source_id",
      displayName: "客户来源",
      condition: [
        {
          code: "=",
          name: "等于",
        },
      ],
      textType: "select",
      dictCode: "getCstSourceList",
    },
    {
      fieldName: "level_id",
      displayName: "客户星级",
      condition: [
        {
          code: "=",
          name: "等于",
        },
      ],
      textType: "select",
      dictCode: "getCstLevelList",
    },
    {
      fieldName: "nation_id",
      displayName: "国家地区",
      condition: [
        {
          code: "=",
          name: "等于",
        },
      ],
      textType: "select",
      dictCode: "getCstNationList",
    },
  ],
};
