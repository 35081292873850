<template>
  <div style="height: 100%">
    <div class="div_title">{{ companyName }}新增联系人</div>
    <el-row :gutter="20">
      <el-col :span="4" :offset="21" style="margin-bottom: 20px">
        <el-button type="primary" @click="addCustomerConfig"
          ><span>新增联系人</span></el-button
        >
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <isdTable
          :loading="tableLoading"
          :rows="totalData"
          :height="600"
          :columns="config.columns"
          :selection="config.selection"
          :actions="config.actions"
          @action-click="actionClick"
          style="font-size: 12px"
        >
        </isdTable>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <isdPagination
          @size-change="sizeChange"
          :current-page="currentPage"
          @current-change="pageChange"
          layout="total,sizes,prev,pager,next,jumper"
          :page-size="pageSize"
          :page-sizes="[20, 50, 100]"
          :total="pageTotal"
        ></isdPagination>
      </el-col>
    </el-row>
    <!-- 新增/编辑弹窗 -->
    <isdFormDialog
      ref="formDialogIns"
      :config="config.forms"
      :loading="formDialog.loading"
      :label-width="formDialog.labelWidth"
      :row="formDialog.row"
      v-model="formDialog.show"
      :col="formDialog.col"
      :title="formDialog.title"
      :width="formDialog.width"
      @close-dialog="closeFormDialog"
    ></isdFormDialog>
  </div>
</template>
<script>
import { ref, reactive, onMounted } from "vue";
import {
  contactPersonList,
  addcontactPerson,
  delcontactPerson,
  getCstNationList,
} from "@/network/customer.js";
import { useRoute, useRouter, route } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import isdFormDialog from "@/views/components/isdFormDialog/Index.vue";
import isdTable from "@/views/components/isdTable/index.vue";
import configData from "./config";
import { ListClass } from "@/composition/ListClass.js";
import isdPagination from "@/views/components/isdPage/index.vue";

export default {
  components: {
    isdFormDialog,
    isdTable,
    isdPagination,
  },
  setup() {
    const companyName = ref(null);
    const formDialogIns = ref(null);
    const config = reactive(configData);
    // 新建/修改弹窗对象
    const formDialog = reactive({
      labelWidth: "90px", // 弹窗label宽度
      loading: false, // 表单加载中显示
      row: {}, // 数据项对象
      show: false, // 显示弹窗
      col: 1, // 弹窗内列数
      title: "新增付款信息", // 弹窗标题
      width: "70%", // 弹窗宽度
    });
    class TableList extends ListClass {
      beforeGetList(condition) {
        condition.cst_id = Number(cst_id);
        return condition;
      }
      afterGetList(res) {
        companyName.value = res.data.company_name;
        super.afterGetList(res);
      }
    }
    const {
      currentPage,
      pageSize,
      pageTotal,
      tableLoading,
      totalData,
      getList,
      searchList,
      pageChange,
      sizeChange,
      handleSelectedRow,
    } = new TableList({
      Api: {
        listApi: contactPersonList,
      },
    });
    const route = ref(useRoute());
    const cst_id = route.value.params.cstId || route.value.query.cstId;
    console.log(route.value, "route.value");
    onMounted(() => {
      getList();
    });
    // 表格编辑删除事件
    const actionClick = (event, row) => {
      switch (event) {
        case "edit":
          editCustomerSet(row);
          break;
        case "del":
          deleteCustomerSet(row);
          break;
      }
    };
    const editCustomerSet = (row) => {
      formDialog.show = true;
      formDialog.title = "编辑联系人信息";
      formDialog.row = row;
      getCstNationList().then((res) => {
        if (res.code == 0) {
          config.forms[5].options = res.data;
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    const closeAction = () => {
      formDialog.show = false;
      formDialog.row = {};
      formDialog.loading = false;
    };
    // 删除设置
    const deleteCustomerSet = (row) => {
      ElMessageBox.confirm("确定要删除该条数据吗?", "警告", {
        confirmButtonText: "删除",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delcontactPerson({ person_id: row.person_id, del_type: 0 }).then(
            (res) => {
              if (res.code == 0) {
                ElMessage.success(res.msg);
                getList();
              } else {
                ElMessage.error(res.msg);
              }
            }
          );
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "删除取消",
          });
        });
    };
    // 新增弹窗关闭
    const closeFormDialog = (params) => {
      if (!params) {
        closeAction();
        return;
      }
      formDialog.loading = true;
      // 新增、编辑
      addcontactPerson({
        cst_id: cst_id,
        detailInfo: formDialogIns.value.form,
      }).then((res) => {
        if (res.code == 0) {
          formDialog.loading = false;
          getList();
          ElMessage.success(res.msg);
          closeAction();
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    const addCustomerConfig = () => {
      // 新增弹窗
      formDialog.show = true;
      formDialog.title = "新增联系人";
      getCstNationList().then((res) => {
        if (res.code == 0) {
          config.forms[5].options = res.data;
        } else {
          ElMessage.error(res.msg);
        }
      });
      formDialog.row = {
        company_name: companyName.value,
        id: "",
        type: 0,
        status: 1,
        gender: 1,
      };
    };
    return {
      addCustomerConfig,
      closeFormDialog,
      formDialog,
      config,
      actionClick,
      currentPage,
      pageSize,
      pageTotal,
      tableLoading,
      totalData,
      getList,
      searchList,
      pageChange,
      sizeChange,
      handleSelectedRow,
      formDialogIns,
      companyName,
    };
  },
};
</script>
<style scoped>
.div_title {
  text-align: center;
  padding: 20px 0;
  font-size: 24px;
}
</style>
