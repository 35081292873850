export default {
  selection: false,
  columns: [
    // 表格列的配置
    {
      label: "订单编号",
      prop: "ord_no",
    },
    {
      label: "往来币种",
      prop: "currency_name_ch",
    },
    {
      label: "应收总额",
      prop: "receivables",
    },
    {
      label: "已收总额",
      prop: "received_amount",
    },
    {
      label: "当前收款",
      prop: "amount",
      mustFill: true,
    },
    {
      label: "外币费用",
      prop: "bank_fee",
      mustFill: true,
    },
    {
      label: "提现费用",
      prop: "hand_fee",
      mustFill: true,
    },
    {
      label: "收款备注",
      prop: "remark",
      mustFill: true,
    },
    {
      label: "操作",
      type: "operate_btn",
      prop: "",
    },
  ],
  forms: [
    {
      label: "应收总额",
      prop: "receivables",
      type: "input",
      disabled: true,
    },
    {
      label: "外币费用",
      prop: "bank_fee",
      type: "input",
      rule: [
        {
          required: true,
          message: "请输入外币收款产生的中间行费用",
          trigger: "blur",
        },
        {
          pattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
          message: "请输入非负数",
        },
      ],
    },
    {
      label: "提现费用",
      prop: "hand_fee",
      type: "input",
      rule: [
        { required: true, message: "请输入提现费用", trigger: "blur" },
        {
          pattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
          message: "请输入非负数",
        },
      ],
    },
    {
      label: "实际收款",
      prop: "amount",
      type: "input",
      rule: [
        { required: true, message: "请输入实际到账金额", trigger: "blur" },
        {
          pattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
          message: "请输入非负数",
        },
      ],
    },
    {
      label: "备注",
      prop: "remark",
      type: "input",
      inputType: "textarea",
    },
  ],
};
