<template>
  <div style="height: 100%">
    <div class="actionBtn">
      <el-button type="primary" @click="groupGo(0)"
        ><el-icon> <plus /> </el-icon><span>新增</span></el-button
      >
    </div>
    <el-dialog v-model="dialogFormVisible" :title="title">
      <el-form :model="formState" :rules="rules">
        <el-form-item
          label="中文名："
          :label-width="formLabelWidth"
          prop="group_name_ch"
        >
          <el-input
            v-model="formState.group_name_ch"
            placeholder="客户分组中文名"
            clearable
          />
        </el-form-item>
        <el-form-item
          label="英文名："
          prop="group_name_en"
          :label-width="formLabelWidth"
        >
          <el-input
            v-model="formState.group_name_en"
            placeholder="客户分组英文名"
            clearable
          />
        </el-form-item>
        <el-form-item label="状态：" :label-width="formLabelWidth">
          <el-switch
            v-model="formState.group_status"
            class="ml-2"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-text="开启"
            inactive-text="关闭"
          />
        </el-form-item>
        <el-form-item label="备注：" :label-width="formLabelWidth">
          <el-input type="textarea" v-model="formState.group_remark" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取消</el-button>
          <el-button type="primary" @click="add">{{ goTitle }}</el-button>
        </span>
      </template>
    </el-dialog>
    <div class="content">
      <el-table
        :data="dataSource"
        style="width: 100%; font-size: 12px"
        height="100%"
        stripe:true
      >
        <el-table-column
          fixed
          prop="group_id"
          label="ID"
          width="100"
          sortable
        />
        <el-table-column
          prop="group_name_ch"
          label="中文名"
          width="200"
          fixed
        />
        <el-table-column prop="group_name_en" label="英文名" width="200" />
        <el-table-column prop="group_status" label="状态" width="120">
          <template #default="scope">
            <span
              :style="{
                color: scope.row.group_status == 1 ? 'green' : 'red',
              }"
            >
              {{ scope.row.group_status == 1 ? "开启" : "关闭" }}</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="group_remark" label="备注" width="200" />
        <el-table-column fixed="right" label="操作" width="200">
          <template #default="scope">
            <el-button type="primary" @click="groupGo(scope.row)">
              <el-icon> <edit /> </el-icon><span>编辑</span></el-button
            >
            <el-button type="danger" @click="del(scope.row.group_id)"
              ><el-icon> <delete /> </el-icon><span>删除</span></el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="demo-pagination-block">
        <el-pagination
          :currentPage="currentPage"
          :page-size="pageSize"
          :page-sizes="[20, 50, 100]"
          :background="true"
          layout="sizes, prev, pager, next, jumper,total"
          :total="pageTotal"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { ref, reactive, toRefs, onMounted } from "vue";
import {
  customerGroupList,
  customerGroupAdd,
  customerGroupDel,
} from "@/network/basic.js";
import { Delete, Edit, Plus, Search } from "@element-plus/icons-vue";
import { ElMessage, ElMessageBox } from "element-plus";

export default {
  components: {
    Delete,
    Edit,
    Plus,
    Search,
  },
  setup() {
    const currentPage = ref(1);
    const pageSize = ref(10);
    const pageTotal = ref(0);
    const state = reactive({
      dataSource: [],
      title: "添加客户分组设置",
      goTitle: "添加",
      dialogFormVisible: false,
      formLabelWidth: "140px",
    });
    const rules = reactive({
      group_name_en: [
        {
          required: true,
          message: "请客户分组英文名",
          trigger: "blur",
        },
      ],
      group_name_ch: [
        {
          required: true,
          message: "请客户分组中文名",
          trigger: "blur",
        },
      ],
    });
    const formState = reactive({
      group_id: 0,
      group_name_en: "",
      group_name_ch: "",
      group_status: 1,
      group_remark: "",
    });
    const getList = () => {
      customerGroupList({
        currentPage: currentPage.value,
        pageSize: pageSize.value,
      }).then((res) => {
        if (res.code == 0) {
          state.dataSource = res.data.lists;
          pageTotal.value = res.data.pageTotal;
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    const handleSizeChange = (val) => {
      pageSize.value = val;
      getList();
    };
    const handleCurrentChange = (val) => {
      currentPage.value = val;
      getList();
    };
    onMounted(() => {
      getList();
    });
    //------------以上为列表数据

    const groupGo = (row) => {
      state.dialogFormVisible = true;
      if (row) {
        Object.assign(formState, row);
        state.goTitle = "更新";
        state.title = "修改客户分组信息";
        formState.group_status = Boolean(row.group_status);
      } else {
        state.goTitle = "添加";
        state.title = "添加客户分组设置";
        formState.group_id = 0;
        formState.group_name_en = "";
        formState.group_name_ch = "";
        formState.group_status = true;
        formState.group_remark = "";
      }
    };
    const add = () => {
      if (!formState.group_name_ch) {
        ElMessage.info("请填写客户分组名称");
        return false;
      }
      customerGroupAdd(formState).then((res) => {
        if (res.code == 0) {
          ElMessage.success(res.msg);
          state.dialogFormVisible = false;
          getList();
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    //------------以上为编辑和修改数据
    const del = (group_id) => {
      if (!group_id) {
        ElMessage("所选数据为空，请重新选择！");
      } else {
        ElMessageBox.confirm("确定要删除该条数据吗?", "警告", {
          confirmButtonText: "删除",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            customerGroupDel({ group_id: group_id }).then((res) => {
              if (res.code == 0) {
                ElMessage.success(res.msg);
                getList();
              } else {
                ElMessage.error(res.msg);
              }
            });
          })
          .catch(() => {
            ElMessage({
              type: "info",
              message: "删除取消",
            });
          });
      }
    };

    return {
      ...toRefs(state),
      rules,
      formState,
      groupGo,
      add,
      del,
      currentPage,
      pageSize,
      pageTotal,
      handleSizeChange,
      handleCurrentChange,
    };
  },
};
</script>
<style scoped>
.el-select :deep .select-popper {
  z-index: 8888 !important;
  top: auto !important;
  left: auto !important;
}
.actionBtn {
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;
}
.content {
  height: calc(100% - 180px);
  position: relative;
}
.el-table {
  position: absolute;
}
.el-table__header {
  width: 100%;
}
.el-table__body {
  height: calc(100% - 40px);
}
.el-pagination {
  position: absolute;
  bottom: -56px;
  right: 20px;
}
</style>
