<template>
  <div class="flex-layout order-management">
    <isdListPaper v-loading="tableLoading">
      <template v-slot:header-button>
        <el-button type="primary" @click="updateOpenLeads" v-if="operate_right">
          <el-icon style="vertical-align: middle"> <Refresh /> </el-icon>
          <span style="vertical-align: middle"> 更新公海 </span>
        </el-button>
      </template>
      <template v-slot:header-search>
        <seach
          :searchConfig="searchConfig"
          @queryClick="searchList"
          @resetClick="searchList"
        ></seach>
      </template>
      <template v-slot:list="props">
        <isdTable
          :loading="tableLoading"
          :rows="totalData"
          :height="props.tableHeight"
          :columns="config.columns"
          :selection="config.selection"
          :actions="config.actions"
          @selection-change="handleSelectedRow"
          style="font-size: 12px"
        >
          <template v-slot:col-append="{ col, row }">
            <div
              v-if="col.type === 'compName'"
              @click="showCstInfo(row.cst_id)"
              style="color: #4285f5; cursor: pointer"
            >
              {{ row[col.prop] }}
            </div>
            <span v-if="col.type === 'cstStar'">
              <el-rate v-model="row.level_id"></el-rate>
            </span>
            <span v-if="col.type === 'cstStags'">
              <el-row>
                <el-tag
                  v-for="tag in row.tags"
                  :key="tag.id"
                  style="margin-right: 10px"
                  :type="tag.type"
                  @close="delTag(tag.id)"
                >
                  {{ tag.tag_name_ch }}
                </el-tag>
              </el-row>
            </span>
          </template>
        </isdTable>
      </template>
      <template v-slot:footer>
        <isdPagination
          @size-change="sizeChange"
          :current-page="currentPage"
          @current-change="pageChange"
          layout="total,sizes,prev,pager,next,jumper"
          :page-size="pageSize"
          :page-sizes="[20, 50, 100]"
          :total="pageTotal"
        ></isdPagination>
      </template>
    </isdListPaper>
    <!-- 新增/编辑弹窗 -->
    <isdFormDialog
      ref="formDialogIns"
      :config="config.forms"
      :loading="formDialog.loading"
      :label-width="formDialog.labelWidth"
      :row="formDialog.row"
      v-model="formDialog.show"
      :col="formDialog.col"
      :title="formDialog.title"
      :width="formDialog.width"
      @close-dialog="closeFormDialog"
    ></isdFormDialog>
  </div>
</template>
<script>
import { customerList, setCustomerToOpenLeads } from "@/network/customer.js";
import { getUserS } from "@/network/user.js";
import { Refresh } from "@element-plus/icons-vue";
import { defineComponent, ref, reactive, onMounted, toRefs } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import { ListClass } from "@/composition/ListClass.js";
import { searchConfig } from "@/common/customerSearchConfig.js";
import configData from "./config";
import isdTable from "@/views/components/isdTable/index.vue";
import isdPagination from "@/views/components/isdPage/index.vue";
import isdListPaper from "@/views/components/isdListPaper/index.vue";
import isdFormDialog from "@/views/components/isdFormDialog/Index.vue";
import isdSearchBox from "@/views/components/isdSearchBox/index.vue";
import seach from "@/components/seach.vue";
export default defineComponent({
  components: {
    Refresh,
    isdTable,
    isdPagination,
    isdSearchBox,
    isdListPaper,
    isdFormDialog,
    seach,
  },
  setup() {
    const formDialogIns = ref(null);
    const config = reactive(configData); // 列表配置
    const searchBoxIns = ref(null); // 搜索实例
    const columnConfigIns = ref(null); // 列配置实例
    const statusDialogIns = ref(null); // 状态弹框实例
    const detailPageIns = ref(null); // 详情编辑页ref
    const tableBox = ref(null);
    // 新建/修改弹窗对象
    const formDialog = reactive({
      labelWidth: "110px", // 弹窗label宽度
      loading: false, // 表单加载中显示
      row: {}, // 数据项对象
      show: false, // 显示弹窗
      col: 1, // 弹窗内列数
      title: "新建", // 弹窗标题
      width: "70%", // 弹窗宽度
    });
    // 格式化查询条件
    const dataFormat = (condition) => {
      condition.symbolType = "=";
      condition.listType = 2;
      condition.inquiryType = "openCustomerList";
      return condition;
    };
    class TableList extends ListClass {
      beforeGetList(condition) {
        condition = dataFormat(condition);
        return condition;
      }
      afterGetList(res) {
        state.operate_right = res.data.operate_right;
        super.afterGetList(res);
      }
    }
    const {
      currentPage,
      pageNo,
      pageSize,
      pageTotal,
      tableLoading,
      totalData,
      getList,
      searchList,
      pageChange,
      sizeChange,
      selectedRows,
      handleSelectedRow,
    } = new TableList({
      Api: {
        listApi: customerList,
      },
    });
    const router = useRouter();
    const state = reactive({
      operate_right: false,
    });
    const showCstInfo = (val) => {
      let routeUrl = router.resolve({
        name: "getOpenCustomerInfo",
        query: {
          cstId: val,
        },
      });
      window.open(routeUrl.href, "_blank");
    };
    const updateOpenLeads = (val) => {
      setCustomerToOpenLeads().then((res) => {
        if (res.code == 0) {
          ElMessage.success(res.data.msg);
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    const closeFormDialog = (params) => {
      if (!params) {
        closeAction();
        return;
      }
      saveItem(params);
    };
    const closeAction = () => {
      formDialog.show = false;
      formDialog.row = {};
      formDialog.loading = false;
    };
    const saveItem = (params) => {
      // 新增/更新
      if (!params) {
        closeAction();
        return;
      }
      formDialog.loading = true;
    };
    onMounted(() => {
      getList();
    });
    return {
      ...toRefs(state),
      tableBox,
      config,
      pageNo,
      pageSize,
      pageTotal,
      tableLoading,
      totalData,
      searchList,
      pageChange,
      sizeChange,
      handleSelectedRow,
      searchBoxIns,
      columnConfigIns,
      detailPageIns,
      statusDialogIns,
      formDialog,
      closeFormDialog,
      currentPage,
      formDialogIns,
      selectedRows,
      showCstInfo,
      updateOpenLeads,
      searchConfig,
    };
  },
});
</script>
<style lang="scss">
.order-management {
  .el-table th > .cell {
    word-break: keep-all;
  }
}
</style>
../../../components/seach.vue
