<template>
  <div class="dynamic-list">
    <div class="dynamic-header">
      <span class="totalNum">当前客户历史被转了{{ total }}次</span>
    </div>
    <div style="padding-top: 20px">
      <el-timeline>
        <el-timeline-item
          v-for="(it, idx) in followList"
          placement="top"
          :key="idx"
          :timestamp="it.operate_time"
          :icon="CollectionTag"
          color="rgb(83, 180, 124)"
          type="primary"
          size="large"
        >
          <el-card class="card-contend">
            <span class="contacts">原归属：{{ it.pre_user }}</span>
            <span>新跟进人：{{ it.new_user }}</span>
            <span>{{ `操作人：${it.new_user}` }}</span>
          </el-card>
        </el-timeline-item>
      </el-timeline>
    </div>
  </div>
</template>
<script>
import { getTrackInfo } from "@/network/customer.js";
import { ElMessage, ElMessageBox } from "element-plus";
import { ref, reactive, toRefs, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { CollectionTag } from "@element-plus/icons-vue";
export default {
  components: {
    CollectionTag,
  },
  setup() {
    const route = ref(useRoute());
    const cst_id = route.value.query.cstId;

    const state = reactive({
      followList: [],
      total: 0,
    });
    const router = useRouter();
    const getList = (val) => {
      getTrackInfo({ val: val }).then((res) => {
        if (res.code == 0) {
          state.followList = res.data.list;
          state.total = res.data.total;
        } else {
          ElMessage.error(res.msg);
        }
      });
      router.push({
        name: "getCustomerInfo",
        params: {
          cstId: val,
        },
      });
    };
    onMounted(() => {
      getList(cst_id);
    });
    return {
      ...toRefs(state),
      CollectionTag,
    };
  },
};
</script>
<style scoped lang="scss">
:deep .is-active {
  max-height: 500px;
  overflow: auto;
}
.el-collapse-item {
  padding-right: 10px;
}
.el-card__body {
  position: relative;
}
.contacts {
  position: absolute;
  right: 10px;
  top: 0;
  color: #999;
  font-size: 12px;
}
.add-item {
  .content {
    :deep .el-textarea__inner {
      border: 1px solid #d9d9d9;
      border-bottom: none;
      box-shadow: none;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      resize: none;
      min-height: 140px !important;
    }
  }
  .more {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 36px;
    border: 1px solid #d9d9d9;
    border-top: none;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    background: #f2f2f2;
    line-height: 36px;
    margin-bottom: 15px;
    .stage {
      :deep .el-input__inner {
        width: auto;
      }
    }
  }
  .box {
    .fTime {
      display: flex;
      justify-content: flex-end;
      :deep .el-form-item__content {
        flex: none;
      }
    }
  }
}
.dynamic-list {
  .dynamic-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #d9d9d9;
    padding: 10px 0;
  }
  :deep .el-timeline-item__node {
    text-align: center;
    line-height: 22px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
  }
  :deep .el-timeline-item__tail {
    left: 8px;
  }
  .card-contend {
    :deep .el-card__body {
      padding: 30px;
      padding-top: 15px;
      font-size: 12px;
    }
    span:last-child {
      position: absolute;
      bottom: 5px;
      right: 6px;
      color: #999;
    }
  }
}
</style>
