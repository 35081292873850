<template>
  <div>
    <el-dialog
      width="800px"
      :custom-class="'advance-multi-dialog'"
      v-model="dialogVisible"
      :append-to-body="true"
      @close="dialogCloseAction"
    >
      <template v-slot:title>
        <div class="column-config_title" v-el-drag-dialog>
          {{ dialogTitle }}
        </div>
      </template>
      <div class="component-container">
        <div class="top-search-group">
          <el-form :inline="true" :model="searchObj">
            <el-form-item>
              <el-select
                v-model="searchObj.key"
                :popper-append-to-body="false"
                :placeholder="
                  $translate('lotsUI.please_select', { defaultText: '请选择' })
                "
              >
                <el-option
                  v-for="option in selectOptions"
                  :key="option.prop"
                  :label="option.value"
                  :value="option.prop"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-input
                v-model.trim="searchObj.keyword"
                :placeholder="
                  $translate('lotsUI.please_enter', { defaultText: '请输入' })
                "
                @keyup.enter="search"
                @change="inputChange"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="search">{{
                $translate("lotsUI.query", { defaultText: "查询" })
              }}</el-button>
              <el-button @click="reset">{{
                $translate("lotsUI.reset", { defaultText: "重置" })
              }}</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="center-container">
          <el-table
            :data="tableData"
            highlight-current-row
            v-loading="loading"
            height="300"
            ref="listTable"
            @row-click="rowClick"
            @select="changeSelect"
            @select-all="changeSelectAll"
            @current-change="currentRowChange"
            class="left-table"
          >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column
              v-for="(column, index) in config.tableConfig"
              :key="column.prop + index"
              :prop="column.prop"
              :label="column.value"
              :width="column.width"
              show-overflow-tooltip
            >
            </el-table-column>
          </el-table>
          <div class="right-checked-list">
            <div class="checked-list-title">
              <span class="checked-num"
                >{{ $translate("lotsUI.selected", { defaultText: "已选" }) }}
                <i>&nbsp;{{ checkedList.length }}&nbsp;</i
                >{{ $translate("lotsUI.item", { defaultText: "项" }) }}</span
              >
              <span class="clear-all" @click="clearAll(true)">{{
                $translate("lotsUI.clear", { defaultText: "清空" })
              }}</span>
            </div>
            <div class="checked-items">
              <div v-for="(item, index) in checkedList" :key="index">
                <el-tooltip effect="dark" placement="left">
                  <template v-slot:content>
                    <div>
                      <span
                        v-for="(attrItem, attrIndex) in item"
                        :key="attrIndex"
                      >
                        <template
                          v-if="tableConfigColumn.indexOf(attrIndex) >= 0"
                        >
                          {{ attrItem }}<br />
                        </template>
                      </span>
                    </div>
                  </template>

                  <div class="checked-every">
                    <span class="text">{{
                      displayColName === ""
                        ? Object.values(item)[0]
                        : item[displayColName]
                    }}</span>
                    <span class="close" @click="clearOne(item, index)"
                      ><i class="el-icon-close" style="font-size: 12px"></i
                    ></span>
                  </div>
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
        <div class="config-footer">
          <el-row>
            <el-col :span="18">
              <el-pagination
                background
                class="table-pagination"
                layout="total, prev, pager, next"
                v-model:current-page="page.pageNo"
                :page-size="page.pageSize"
                :total="pageTotal"
                @current-change="currentChange"
              >
              </el-pagination>
            </el-col>
            <el-col :span="6" style="text-align: right">
              <el-button type="text" @click="closeDialog">{{
                $translate("lotsUI.cancel", { defaultText: "取消" })
              }}</el-button>
              <el-button type="primary" @click="confirm">{{
                $translate("lotsUI.confirm", { defaultText: "确定" })
              }}</el-button>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import request from "@/utils/service";
import { ElMessage } from "element-plus";
export default {
  name: "lotsBasicDataMultiDialog",
  props: {
    draggable: {
      type: Boolean,
      default: false,
    },
    setCheckedList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  computed: {
    selectOptions() {
      // 可选搜索项
      return this.config.advanceConfig || this.config.tableConfig;
    },
    dialogTitle() {
      // 弹窗标题
      return this.config.advanceLabel || this.config.name || this.config.label;
    },
    // checkedList() {
    //     return JSON.parse(JSON.stringify(this.setCheckedList));
    // }
  },
  watch: {
    setCheckedList: {
      handler(newValue) {
        this.checkedList = JSON.parse(JSON.stringify(newValue));
        this.tableSelect();
      },
      immediate: true,
    },
  },
  data() {
    return {
      dialogVisible: false, // 弹窗显示开关
      loading: false, // 加载中显示
      page: {
        pageSize: 10, // 每页条数
        pageNo: 1, // 当前页码
      },
      pageTotal: 0, // 数据总记录数
      searchObj: {}, // 搜索配置
      tableData: [], // 表格数据列表
      config: {}, // 本地化配置
      displayColName: "", // 确定显示在已选列表中的是哪一列的字段值
      judgeEqualKey: "",
      checkedList: [],
      tableConfigColumn: [], // 列表配置显示的列数组
      maxCheckRow: 10,
    };
  },
  emits: ["getCheckedList", "confirm"],
  methods: {
    /**
     * @description: 获取数据列表
     */
    async fetchData() {
      this.loading = true;
      const search =
        this.searchObj.keyword === ""
          ? {}
          : {
              [this.searchObj.key]: encodeURIComponent(this.searchObj.keyword),
            };
      const params = { ...this.advanceCascade, ...this.page, ...search };
      const res = await request({
        method: "get",
        url: `${this.prefix}${this.config.advanceUrl}`,
        params,
      });
      if (res && res.code === "0") {
        if (res.data.totalPage !== 0 && this.page.pageNo > res.data.totalPage) {
          this.page.pageNo = res.data.totalPage;
          this.fetchData();
        } else {
          this.tableData = res.data.list;
          this.pageTotal = res.data.totalCount;
        }
      } else {
        this.tableData = [];
      }
      this.tableSelect();
      this.loading = false;
    },
    /**
     * @description: 初始化显示弹窗
     * @param {boolean} status 打开弹窗还是关闭弹窗
     * @param {object} config 高级弹窗的配置项对象
     * @param {object} advanceCascade 级联参数（ 弹窗表格查询时候，添加查询参数）
     * @param {array} checkedList 设置选中数组
     * @return {null} 弹窗配置对象为空 或 设置status关闭弹窗，则停止执行下面操作
     */
    show(status = true, config, advanceCascade = {}) {
      this.tableConfigColumn = [];
      config.tableConfig.forEach((item) => {
        if (item.displayColumn) this.displayColName = item.prop;
        if (item.judgeEqualKey) this.judgeEqualKey = item.prop;
        this.tableConfigColumn.push(item.prop);
      });
      this.displayColumn = this.displayColumn || this.config.value;
      this.judgeEqualKey = this.judgeEqualKey || this.config.value;
      this.prefix = config.prefix || "/api-lcp-idms/";
      this.maxCheckRow = config.maxCheckRow || 10;
      this.dialogVisible = status;
      if (status === false) return;
      if (!config) {
        this.$message.error("弹窗配置对象为空！");
        return;
      }
      this.config = config;
      this.searchObj.key = this.selectOptions[0].prop;
      this.searchObj.keyword = "";
      this.advanceCascade = advanceCascade;
      this.checkedList = JSON.parse(JSON.stringify(this.setCheckedList));
      this.fetchData();
    },
    /**
     * @description: 判断表格的对象与选中列表对象是否相等
     * @param {array} array 需要操作比较的数组 checklist
     * @param {object} theItem 需要比较的单个数据项对象
     * @param {function} callback 判断完成后的回调函数
     */
    judgeEqual(checklist, tableDataRow, callback) {
      checklist.some((item, index) => {
        if (item[this.judgeEqualKey] === tableDataRow[this.judgeEqualKey]) {
          typeof callback === "function" && callback(item, index);
          return true;
        } else {
          return false;
        }
        // let count = 0;
        // for (const [key, value] of Object.entries(item)) {
        //     if (tableDataRow[key] === value) count++;
        // }
        // if (count === length) {
        //     callback && typeof callback === 'function' && callback(item, index);
        //     return true;
        // }
      });
    },
    /**
     * @description: 对表格进行选择操作
     */
    tableSelect() {
      this.tableData.forEach((item) => {
        this.$refs.listTable.toggleRowSelection(item, false);
        this.judgeEqual(this.checkedList, item, () => {
          this.$nextTick(() => {
            this.$refs.listTable.toggleRowSelection(item, true);
          });
        });
      });
    },
    debounce(idle, action) {
      let last;
      return function (...args) {
        clearTimeout(last);
        last = setTimeout(() => {
          action.apply(this, args);
        }, idle);
      };
    },
    /**
     * @description: 单选操作
     * @param {array} data 当前选中的数据数组
     * @param {object} row 当前操作行对象
     */
    changeSelect(data, row) {
      const inOrDel = data.indexOf(row);
      if (this.checkedList.length >= this.maxCheckRow) {
        this.$refs.listTable.clearSelection();
        for (let i = 0; i < this.checkedList.length; i++) {
          this.$refs.listTable.toggleRowSelection(this.checkedList[i]);
        }
        this.debounce(100, ElMessage.warning("最多选择{num}条数据"));
        return;
      }
      if (inOrDel < 0) {
        this.judgeEqual(this.checkedList, row, (cItem, cIndex) => {
          this.clearOne(cItem, cIndex);
        });
      } else {
        let flag = true;
        this.judgeEqual(this.checkedList, row, () => {
          flag = false;
        });
        if (flag) this.checkedList.push(row);
      }
    },
    /**
     * @description: 全选操作
     * @param {array} data 当前选中的数据数组
     */
    changeSelectAll(data) {
      if (data.length > 0) {
        data.forEach((item) => {
          this.changeSelect(data, item);
        });
      } else {
        this.tableData.forEach((item) => {
          this.judgeEqual(this.checkedList, item, (cItem, cIndex) => {
            this.clearOne(cItem, cIndex);
          });
        });
      }
    },
    /**
     * @description: 清除所有已选（可供外部调用）
     * @param {boolean} clearTable 是否需要清除表格（只有内部调用时需要true）
     */
    clearAll(clearTable) {
      this.checkedList = [];
      if (clearTable) this.$refs.listTable.clearSelection();
    },
    /**
     * @description: 清除单个已选
     * @param {object} cItem 当前操作行对象
     * @param {number} cIndex 当前操作行索引
     */
    clearOne(cItem, cIndex) {
      this.checkedList.splice(cIndex, 1);
      this.judgeEqual(this.tableData, cItem, (item) => {
        this.$refs.listTable.toggleRowSelection(item, false);
      });
    },
    /**
     * @description: 点击行操作
     * @param {object} row 当前操作行对象
     */
    rowClick(row) {
      this.$refs.listTable.toggleRowSelection(row, true);
      this.changeSelect(this.tableData, row);
    },
    /**
     * @description: 页码变化
     * @param {number} val 页码数字
     */
    currentChange(val) {
      this.page.pageNo = val;
      this.fetchData();
    },
    /**
     * @description: 搜索按钮操作
     */
    search() {
      this.page.pageNo = 1;
      this.fetchData();
    },
    /**
     * @description: 重置按钮操作
     */
    reset() {
      this.searchObj.keyword = "";
      this.fetchData();
    },
    /**
     * @description: 表格当前行操作
     * @param {object} row 当前操作行对象
     */
    currentRowChange(row) {
      this.currentRow = row;
    },
    /**
     * @description: 确认按钮操作
     * @return {null} 没选中取消执行下面操作
     */
    confirm() {
      this._emitResult();
    },
    /**
     * @description: 返回选中值
     * @return {null} 弹窗没定义cbParams，执行下面操作
     */
    _emitResult() {
      const data = [];
      if (!this.config.cbParams) {
        this.$message.error("弹窗没定义cbParams配置!");
        return;
      }
      this.checkedList.forEach((checkedItem, index) => {
        data[index] = {};
        for (const item of this.config.cbParams) {
          let { 0: sourceProp, 1: destProp } = item.split("#");
          destProp = destProp || sourceProp;
          data[index][destProp] = checkedItem[sourceProp];
        }
      });
      this.$emit("confirm", data);
      this.$emit(
        "getCheckedList",
        JSON.parse(JSON.stringify(this.checkedList))
      );
      this.closeDialog();
    },
    /**
     * @description: 关闭弹窗
     */
    closeDialog() {
      this.dialogCloseAction();
      this.dialogVisible = false;
    },
    inputChange() {},
    /**
     * @description: 页面关闭重置参数操作
     */
    dialogCloseAction() {
      this.checkedList = [];
      this.page = {
        pageSize: 10,
        pageNo: 1,
      };
      this.pageTotal = 0;
      this.searchObj = {};
      this.tableData = [];
    },
  },
};
</script>

<style lang="scss">
.column-config_title {
  small {
    margin-left: 25px;
  }
}
.advance-multi-dialog {
  .column-config_title {
    font-size: 12px;
    color: #333333;
    padding: 0 0 5px;
  }
  .el-dialog__header {
    border-bottom: 1px solid #e9e9e9;
  }
  .el-dialog__headerbtn .el-dialog__close {
    color: #000;
  }
  .component-container {
    .center-container {
      width: 100%;
      height: 300px;
      .left-table {
        width: 550px;
        float: left;
      }
      .right-checked-list {
        width: 200px;
        margin: 0 0 0 10px;
        height: 100%;
        float: left;
        box-sizing: border-box;
        .checked-num i {
          font-style: normal;
          color: #3ea4eb;
        }
        .clear-all {
          float: right;
          cursor: pointer;
        }
        .checked-list-title {
          width: 100%;
          height: 38px;
          background-color: #f2f2f2;
          line-height: 38px;
          color: #666666;
          padding: 0 10px 0 20px;
          box-sizing: border-box;
        }
        .checked-items {
          width: 100%;
          overflow: auto;
          height: 262px;
        }
        .checked-every {
          width: 100%;
          height: 38px;
          line-height: 38px;
          color: #666666;
          padding: 0 10px 0 20px;
          box-sizing: border-box;
          &:hover {
            background-color: #f0f8fa;
          }
          .text {
            width: 145px;
            float: left;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
          .close {
            float: right;
            cursor: pointer;
            color: #aaaaaa;
            font-size: 18px;
          }
        }
      }
      &:after {
        clear: both;
      }
    }
    .config-footer {
      margin-top: 18px;
      margin-bottom: 10px;
    }
    .table-pagination {
      float: left;
      padding: 0;
    }
  }
}
</style>
