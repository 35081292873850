<template>
  <div class="advance-multi" :title="title">
    <el-badge
      :hidden="dialogCheckedList.length <= 1"
      :title="title"
      :value="dialogCheckedList.length > 9 ? '9+' : dialogCheckedList.length"
      class="item"
      type="primary"
    >
      <el-input
        readonly
        class="advance-multi-input"
        v-model="value"
        :placeholder="params.name"
        :disabled="disabled"
        @focus="$event.target.blur()"
        @clear="clear"
        clearable
      >
        <template v-slot:append>
          <el-button
            icon="el-icon-search"
            @click="show"
            :disabled="disabled"
          ></el-button>
        </template>
        <template v-slot:suffix>
          <i
            class="el-input__icon el-icon-circle-close"
            v-if="dialogCheckedList.length > 0"
            @click="clear"
          ></i>
        </template>
      </el-input>
    </el-badge>
    <basic-data-multi-dialog
      :setCheckedList="dialogCheckedList"
      ref="advanceMultiDialog"
      :draggable="params.draggable"
      @confirm="detaliSearchSure"
      @getCheckedList="detailCheckedList"
    >
    </basic-data-multi-dialog>
  </div>
</template>

<script>
import basicDataMultiDialog from "./lotsBasicDataMultiDialog";
export default {
  name: "lotsBasicDataMulti",
  components: { basicDataMultiDialog },
  props: {
    params: Object, // 传入配置参数
    modelValue: {
      // 传入绑定值
      type: Array,
      default() {
        return [];
      },
    },
    disabled: {
      // 是否启动组件
      type: Boolean,
      default: false,
    },
    formData: {
      // 弹窗表格查询时候，添加查询参数
      type: Object,
      default() {
        return {};
      },
    },
  },
  emits: ["clear", "change", "update:modelValue"],
  watch: {
    // 回显数据 ，格式化成cdParams内（源）的字段
    modelValue: {
      handler(newValue) {
        const dialogCheckedList = JSON.parse(JSON.stringify(newValue));
        const data = [];
        dialogCheckedList.forEach((checkedItem, index) => {
          data[index] = {};
          for (const item of this.params.cbParams) {
            let { 0: sourceProp, 1: destProp } = item.split("#");
            destProp = destProp || sourceProp;
            data[index][sourceProp] = checkedItem[destProp];
          }
        });
        this.dialogCheckedList = data;
        this.handleDisplay(dialogCheckedList);
      },
      immediate: true,
    },
  },
  data() {
    return {
      value: "", // 显示值
      title: "", // 显示title
      displayColName: "", // 确定显示在输入框中的是哪一列的字段值，不传即默认为配置中value值
      dialogCheckedList: [], // 弹窗关闭时候获得已选列表
    };
  },
  methods: {
    /**
     * @description: 获取显示在输入框中的是哪一列的字段值
     */
    getDisplayColName() {
      this.params.tableConfig.forEach((item) => {
        if (item.displayColumn) {
          for (const cbItem of this.params.cbParams) {
            let { 0: sourceProp, 1: destProp } = cbItem.split("#");
            destProp = destProp || sourceProp;
            if (sourceProp === item.prop) this.displayColName = destProp;
          }
        }
      });
      if (this.displayColName === "") this.displayColName = this.params.value;
    },
    /**
     * @description: 格式化显示值
     * @param {array} val 字符串数组 或 对象数组
     */
    handleDisplay(val) {
      const displayVal = [];
      val.forEach((item) => {
        displayVal.push(
          typeof item === "object" ? item[this.displayColName] : item
        );
      });
      // this.title = displayVal.join(this.valueJoin);
      if (displayVal.length > 0) {
        this.value = displayVal[0];
      } else {
        this.value = "";
      }
      // if (displayVal.length > 0) {
      //     if (displayVal.length === 1) {
      //         this.value = displayVal[0];
      //     } else if (displayVal.length > 1) {
      //         this.value = `${displayVal[0]} + ${displayVal.length - 1}`;
      //     }
      // } else {
      //     this.value = '';
      // }
    },
    /**
     * @description: 初始化弹窗组件
     */
    show() {
      const current = this.params;
      const advanceCascade = {};
      if (
        current.advanceCascade &&
        Object.prototype.toString.call(current.advanceCascade) ===
          "[object String]"
      ) {
        let advanceArray = [];
        advanceArray = current.advanceCascade.split(",");
        advanceArray.forEach((item) => {
          let { 0: sourceProp, 1: destProp } = item.split("#");
          destProp = destProp || sourceProp;
          advanceCascade[destProp] = this.formData[sourceProp];
        });
      }
      this.$refs.advanceMultiDialog.show(true, current, advanceCascade);
    },
    /**
     * @description: 弹窗确认回调函数（点击确认时触发）
     * @param {array} data 确认回调接收的对象数组
     */
    detaliSearchSure(data) {
      this.value = "";
      this.handleDisplay(data);
      this.$emit("change", data);
      this.$emit("update:modelValue", data);
    },
    /**
     * @description: 弹窗已选列表回调函数（点击确认时触发）
     * @param {array} data 已选列表回调接收的对象数组
     */
    detailCheckedList(data) {
      this.dialogCheckedList = data;
    },
    /**
     * @description: 点击清除按钮操作
     */
    clear() {
      this.dialogCheckedList = [];
      this.$refs.advanceMultiDialog.clearAll();
      this.detaliSearchSure([]);
      this.$emit("update:modelValue", []);
    },
  },
  mounted() {
    if (this.modelValue.length > 0) this.handleDisplay(this.modelValue);
  },
  created() {
    this.getDisplayColName();
  },
};
</script>

<style lang="scss">
.advance-multi {
  .el-badge {
    width: 100%;
  }
  .el-input__inner {
    cursor: pointer;
  }
  .advance-multi-input {
    cursor: pointer;
  }
  .el-badge__content {
    padding: 2px 2px;
    border-radius: 10px;
    color: #fff;
    display: inline-block;
    font-size: 10px;
    height: 10px;
    line-height: 10px;
    padding: 2px 4px;
    text-align: center;
    white-space: nowrap;
    border: 1px solid #fff;
    right: 40px;
  }
}
</style>
