<template>
  <div class="flex-layout order-management">
    <isdListPaper v-loading="tableLoading">
      <template v-slot:header-button>
        <el-button type="primary" @click="addSupplier()">
          <el-icon style="vertical-align: middle"> <Plus /> </el-icon
          ><span style="vertical-align: middle"> 新增供应商</span>
        </el-button>
      </template>
      <template v-slot:header-search>
        <isdSearchBox
          ref="searchBoxIns"
          :search-key="config.name"
          :fields="config.searchFields"
          @search-change="searchList"
          :loading="tableLoading"
          class="track-search"
        ></isdSearchBox>
      </template>
      <template v-slot:list="props">
        <isdTable
          :loading="tableLoading"
          :rows="totalData"
          :height="props.tableHeight"
          :columns="config.columns"
          :selection="config.selection"
          :actions="config.actions"
          @selection-change="handleSelectedRow"
          style="font-size: 12px"
        >
          <template v-slot:col-append="{ col, row }">
            <div v-if="col.type === 'theme'">
              <span v-if="row.complete" style="text-decoration: line-through">
                {{ row[col.prop] }}</span
              >
              <span v-else> {{ row[col.prop] }}</span>
            </div>
            <span v-if="col.type === 'operate_btn'">
              <el-button round size="small" @click="addSupplier(row)"
                ><el-icon style="vertical-align: middle"> <EditPen /> </el-icon
                ><span style="vertical-align: middle"> 编辑 </span></el-button
              >
              <el-button round size="small" @click="accountList(row.spr_id)"
                ><el-icon style="vertical-align: middle"><Wallet /></el-icon
                ><span style="vertical-align: middle"> 账户 </span></el-button
              >
            </span>
          </template>
        </isdTable>
      </template>
      <template v-slot:footer-buttons>
        <el-button
          type="danger"
          plain
          @click="deleteItem"
          :disabled="!selectedRows.length"
          ><el-icon><Delete /></el-icon>
          <span style="vertical-align: middle"> 批量删除 </span>
        </el-button>
      </template>
      <template v-slot:footer>
        <isdPagination
          @size-change="sizeChange"
          :current-page="currentPage"
          @current-change="pageChange"
          layout="total,sizes,prev,pager,next,jumper"
          :page-size="pageSize"
          :page-sizes="[20, 50, 100]"
          :total="pageTotal"
        ></isdPagination>
      </template>
    </isdListPaper>
    <!-- 新增/编辑弹窗 -->
    <isdFormDialog
      ref="formDialogIns"
      :config="config.forms"
      :loading="formDialog.loading"
      :label-width="formDialog.labelWidth"
      :row="formDialog.row"
      v-model="formDialog.show"
      :col="formDialog.col"
      :title="formDialog.title"
      :width="formDialog.width"
      @close-dialog="closeFormDialog"
    ></isdFormDialog>
  </div>
</template>
<script>
import {
  supplierList,
  supplierAdd,
  supplierDel,
  getSupplierGroupList,
} from "@/network/supplier.js";
import { getUserList } from "@/network/user.js";
import { useRouter } from "vue-router";
import {
  EditPen,
  Printer,
  Delete,
  Check,
  Plus,
  Wallet,
} from "@element-plus/icons-vue";
import { defineComponent, ref, reactive, onMounted } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { ListClass } from "@/composition/ListClass.js";
import configData from "./config";
import isdTable from "@/views/components/isdTable/index.vue";
import isdPagination from "@/views/components/isdPage/index.vue";
import isdListPaper from "@/views/components/isdListPaper/index.vue";
import isdFormDialog from "@/views/components/isdFormDialog/Index.vue";
import isdSearchBox from "@/views/components/isdSearchBox/index.vue";
export default defineComponent({
  components: {
    EditPen,
    Printer,
    Delete,
    Check,
    Plus,
    Wallet,
    isdTable,
    isdPagination,
    isdSearchBox,
    isdListPaper,
    isdFormDialog,
  },
  setup() {
    const formDialogIns = ref(null);
    const config = reactive(configData); // 列表配置
    const searchBoxIns = ref(null); // 搜索实例
    const columnConfigIns = ref(null); // 列配置实例
    const statusDialogIns = ref(null); // 状态弹框实例
    const detailPageIns = ref(null); // 详情编辑页ref
    const tableBox = ref(null);
    const userList = reactive({
      val: [],
    });
    const supplierGroupList = reactive({
      val: [],
    });
    // 新建/修改弹窗对象
    const formDialog = reactive({
      labelWidth: "110px", // 弹窗label宽度
      loading: false, // 表单加载中显示
      row: {}, // 数据项对象
      show: false, // 显示弹窗
      col: 1, // 弹窗内列数
      title: "新建", // 弹窗标题
      width: "60%", // 弹窗宽度
    });
    // 格式化查询条件
    const dataFormat = (condition) => {
      condition.symbolType = "=";
      condition.listType = 1;
      return condition;
    };
    class TableList extends ListClass {
      beforeGetList(condition) {
        condition = dataFormat(condition);
        return condition;
      }
    }
    const {
      currentPage,
      pageNo,
      pageSize,
      pageTotal,
      tableLoading,
      totalData,
      getList,
      searchList,
      pageChange,
      sizeChange,
      selectedRows,
      handleSelectedRow,
    } = new TableList({
      Api: {
        listApi: supplierList,
      },
    });
    //新增供应商
    const addSupplier = (row) => {
      getUserList().then((res) => {
        if (res.code == 0) {
          userList.val = res.data;
          const ary = userList.val.map((item) => {
            return {
              label: item.user_name_ch,
              value: item.user_id,
            };
          });
          config.forms[3].options = ary;
        } else {
          ElMessage.error(res.msg);
        }
      });
      getSupplierGroupList().then((res) => {
        if (res.code == 0) {
          supplierGroupList.val = res.data;
          const ary = supplierGroupList.val.map((item) => {
            return {
              label: item.spr_group_name_ch,
              value: item.spr_group_id,
            };
          });
          config.forms[2].options = ary;
        } else {
          ElMessage.error(res.msg);
        }
      });
      formDialog.show = true;
      if (row) {
        formDialog.title = "修改供应商信息";
        if (row.spr_status == 1) {
          row.spr_status == true;
        } else {
          row.spr_status == false;
        }
        formDialog.row = row;
      } else {
        formDialog.title = "新增供应商";
        formDialog.row = {};
        formDialog.row.spr_id = "";
        formDialog.row.spr_status = 1;
      }
    };
    const deleteItem = () => {
      const map = new Map();
      const arr = selectedRows.value.map((item) => {
        return item.spr_id; // 只返回id号
      });
      if (!arr) {
        ElMessage("所选数据为空，请重新选择！");
      } else {
        ElMessageBox.confirm("确定要删除该条数据吗?", "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            supplierDel({ spr_ids: arr, del_type: 0 }).then((res) => {
              if (res.code == 0) {
                ElMessage.success(res.msg);
                getList();
              } else {
                ElMessage.error(res.msg);
                getList();
              }
            });
          })
          .catch(() => {
            ElMessage({
              type: "info",
              message: "删除取消",
            });
          });
      }
    };
    const closeFormDialog = (params) => {
      if (!params) {
        closeAction();
        return;
      }
      saveItem(params);
    };
    const closeAction = () => {
      formDialog.show = false;
      formDialog.row = {};
      formDialog.loading = false;
    };
    const saveItem = (params) => {
      // 新增/更新成功后对数据操作
      if (!params) {
        closeAction();
        return;
      }
      formDialog.loading = true;
      // 保存、编辑
      supplierAdd(params).then((res) => {
        if (res.code == 0) {
          formDialog.loading = false;
          getList();
          ElMessage.success(res.msg);
          closeAction();
        } else {
          ElMessage.error(res.msg);
          formDialog.loading = false;
          getList();
          closeAction();
        }
      });
    };
    const router = useRouter();
    const accountList = (val) => {
      router.push({
        name: "compAccountList",
        query: {
          acc_ownership: val,
        },
      });
    };
    onMounted(() => {
      getList();
    });
    return {
      tableBox,
      config,
      pageNo,
      pageSize,
      pageTotal,
      tableLoading,
      totalData,
      searchList,
      pageChange,
      sizeChange,
      handleSelectedRow,
      searchBoxIns,
      columnConfigIns,
      detailPageIns,
      statusDialogIns,
      formDialog,
      closeFormDialog,
      currentPage,
      formDialogIns,
      deleteItem,
      selectedRows,
      addSupplier,
      accountList,
    };
  },
});
</script>

<style lang="scss">
.order-management {
  .el-table th > .cell {
    word-break: keep-all;
  }
}
</style>
