export default {
  selection: false,
  searchFields: [
    // 搜索组件的配置项
    {
      name: "字典名称",
      value: "name",
      type: "input",
      isFixed: true,
      span: 4,
    },
  ],
  columns: [
    // 表格列的配置
    {
      label: "字典名称",
      prop: "name",
    },
    {
      label: "字典编码",
      prop: "code",
    },
    {
      label: "添加时间",
      prop: "update_time",
    },
    {
      label: "最近更新",
      prop: "update_time",
    },
    {
      label: "描述",
      prop: "remark",
      mustFill: true,
    },
    {
      label: "操作",
      type: "operate_btn",
      prop: "",
      width: "120px",
    },
  ],
  forms: [
    {
      label: "字典名称：",
      prop: "name",
      type: "input",
      placeholder: "请输入字典名称",
      rule: [
        {
          required: true,
          message: "请输入字典名称",
          trigger: "blur",
        },
      ],
    },
    {
      label: "字典编码：",
      prop: "code",
      type: "input",
      placeholder: "请输入字典编码（不可重复）",
      rule: [
        {
          required: true,
          message: "请输入字典编码",
          trigger: "blur",
        },
      ],
    },
    {
      label: "状态:",
      prop: "status",
      type: "switch",
      modelValue: "1",
      activeText: "是",
      inactiveText: "否",
      rule: [{ required: true }],
    },
    {
      label: "字典描述",
      prop: "remark",
      type: "input",
      inputType: "textarea",
    },
  ],
};
