import { createApp } from "vue";
import App from "./App.vue";
import pinia from "./store";
import ElementPlus from "element-plus";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import "./style/base.css";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";
import "normalize.css";
import { loadDirectives } from "./directives/index.js";
import "@/icon-font/iconfont.css";
import "element-plus/dist/index.css";
import router from "./router";
const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
app.use(pinia);
app.use(router);
app.use(ElementPlus, ElementPlusIconsVue, { locale: zhCn });
app.use(loadDirectives).mount("#app");
