export default {
  selection: false,
  columns: [
    // 表格列的配置
    {
      label: "分组名称",
      prop: "spr_group_name_ch",
    },
    {
      label: "分组简码",
      prop: "spr_group_code",
    },
    {
      label: "分组类型",
      prop: "group_type",
      type: "select",
      options: { 1: "供应商", 2: "服务商" },
    },
    {
      label: "对接人",
      prop: "user_name_ch",
    },
    {
      label: "状态",
      prop: "spr_group_status",
      type: "select",
      options: { 1: "有效", 0: "关闭" },
    },
    {
      label: "备注",
      prop: "spr_group_remark",
      mustFill: true,
    },
  ],
  actions: {
    // 表格操作列的配置
    fixedWidth: 110, // fixedWidth（可选） 设置操作列的固定宽度，不加该参数默认是按钮list长度*50px
    list: [
      {
        label: "编辑",
        event: "edit",
        displayRule: () => {
          return true;
        },
      },
      {
        label: "删除",
        event: "del",
        displayRule: () => {
          return true;
        },
      },
    ],
  },
  forms: [
    // 表单页面的配置
    {
      label: "分组中文名:",
      prop: "spr_group_name_ch",
      type: "input",
      placeholder: "请输入分组中文名称",
      rule: [
        { required: true, message: "输入分组中文名称", trigger: "change" },
      ],
    },
    {
      label: "分组英文名:",
      prop: "spr_group_name_en",
      type: "input",
      placeholder: "输入分组英文名称",
    },
    {
      label: "分组简码:",
      prop: "spr_group_code",
      type: "input",
      placeholder: "请输入分组简码（RFD/SHD...)",
      rule: [
        {
          required: true,
          message: "请输入分组简码（RFD/SHD...)",
          trigger: "change",
        },
      ],
    },
    {
      label: "分组类型:",
      prop: "group_type",
      type: "select",
      options: { 1: "供应商", 2: "服务商" },
      filterable: true,
      rule: [{ required: true, message: "请选择分组类型", trigger: "change" }],
    },
    {
      label: "对接人:",
      prop: "user_id",
      type: "select",
      options: [],
      filterable: true,
      rule: [
        { required: true, message: "请选择公司对接人", trigger: "change" },
      ],
    },
    {
      label: "分类图片:",
      prop: "spr_group_pic",
      type: "input",
    },
    {
      label: "状态:",
      prop: "spr_group_status",
      type: "switch",
      modelValue: "1",
      activeText: "是",
      inactiveText: "否",
      rule: [{ required: true }],
    },
    {
      label: "备注:",
      prop: "spr_group_remark",
      type: "input",
      inputType: "textarea",
    },
  ],
};
