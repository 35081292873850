<template>
  <div style="height: 100%">
    <div class="actionBtn">
      <el-button type="primary" @click="groupGo(0)"
        ><el-icon> <plus /> </el-icon><span>新增</span></el-button
      >
    </div>
    <el-dialog v-model="dialogFormVisible" :title="title">
      <el-form :model="formState" :rules="rules">
        <el-form-item
          label="部门中文名："
          :label-width="formLabelWidth"
          prop="user_group_name_ch"
        >
          <el-input
            v-model="formState.user_group_name_ch"
            :placeholder="nameMsg"
            clearable
          />
        </el-form-item>
        <el-form-item label="部门英文名：" :label-width="formLabelWidth">
          <el-input
            v-model="formState.user_group_name_en"
            placeholder="请输入部门英文名"
            clearable
          />
        </el-form-item>

        <el-form-item
          label="部门主管："
          :label-width="formLabelWidth"
          prop="user_group_leader"
        >
          <el-select
            v-model="formState.user_group_leader"
            :popper-append-to-body="false"
          >
            <el-option
              v-for="item in getUserList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="部门图片：" :label-width="formLabelWidth">
          <el-input v-model="formState.user_group_pic" />
        </el-form-item>

        <el-form-item label="状态：" :label-width="formLabelWidth">
          <el-switch
            v-model="formState.user_group_status"
            class="ml-2"
            active-color="#13ce66"
            inactive-color="#ff4949"
          />
        </el-form-item>
        <el-form-item label="备注：" :label-width="formLabelWidth">
          <el-input type="textarea" />
        </el-form-item>

        <el-form-item label="部门权限">
          <el-tree
            v-model="formState.user_group_rights"
            :data="menu"
            check-on-click-node
            :props="defaultProps"
            show-checkbox
            ref="tree"
            node-key="mid"
            :default-checked-keys="formState.user_group_rights"
            :default-expanded-keys="formState.user_group_rights"
            @check-change="handleCheckChange"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取消</el-button>
          <el-button type="primary" @click="add">{{ goTitle }}</el-button>
        </span>
      </template>
    </el-dialog>

    <div class="content">
      <el-table :data="dataSource" style="width: 100%" width="100%" stripe:true>
        <el-table-column prop="user_group_id" label="ID" width="80px" />
        <el-table-column
          prop="user_group_name_ch"
          label="部门中文名"
          width="200px"
        />
        <el-table-column
          prop="user_group_name_en"
          label="部门英文名"
          width="200px"
        />
        <el-table-column
          prop="user_group_leader_v"
          label="部门主管"
          width="200px"
        />
        <el-table-column label="状态" width="200px">
          <template #default="scope">
            <span
              :style="{
                color: scope.row.user_group_status == 1 ? 'green' : 'red',
              }"
            >
              {{ scope.row.user_group_status == 1 ? "开启" : "关闭" }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="user_group_remark"
          label="部门备注"
          width="300px"
        />

        <el-table-column fixed="right" label="操作" width="200">
          <template #default="scope">
            <el-button type="primary" @click="groupGo(scope.row)">
              <el-icon> <edit /> </el-icon><span>编辑</span></el-button
            >
            <el-button type="danger" @click="del(scope.row.user_group_id)"
              ><el-icon> <delete /> </el-icon><span>删除</span></el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="demo-pagination-block">
        <el-pagination
          :currentPage="currentPage"
          :page-size="pageSize"
          :page-sizes="[20, 50, 100]"
          :background="true"
          layout="sizes, prev, pager, next, jumper,total"
          :total="pageTotal"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, toRefs, onMounted } from "vue";
import { groupList, groupAdd, groupDel, getUserList } from "@/network/user.js";
import { Delete, Edit, Plus } from "@element-plus/icons-vue";
import { ElMessage, ElMessageBox } from "element-plus";

export default {
  components: {
    Delete,
    Edit,
    Plus,
  },
  methods: {
    handleCheckChange() {
      this.formState.user_group_rights = this.$refs.tree
        .getCheckedNodes()
        .map((item) => item.mid);
    },
  },

  setup() {
    const currentPage = ref(1);
    const pageSize = ref(10);
    const pageTotal = ref(0);
    const state = reactive({
      dataSource: [],
      title: "添加部门",
      nameMsg: "请输入部门名称",
      goTitle: "添加",
      dialogFormVisible: false,
      formLabelWidth: "140px",
      menu: [],
      getUserList: [],
      checkAll: false,
      isIndeterminate: true,
      checkedCities: "",
      cities: "",
    });

    const rules = reactive({
      user_group_name_ch: [
        {
          required: true,
          message: "请输入部门名称",
          trigger: "blur",
        },
      ],
      user_group_leader: [
        {
          required: true,
          message: "请选择部门负责人",
          trigger: "change",
        },
      ],
    });
    const formState = reactive({
      user_group_id: 0,
      user_group_name_en: "",
      user_group_name_ch: "",
      user_group_leader: 1,
      user_group_pic: "",
      user_group_status: true,
      user_group_rights: "",
      user_group_remark: "",
    });
    const getList = () => {
      groupList({
        currentPage: currentPage.value,
        pageSize: pageSize.value,
      }).then((res) => {
        if (res.code == 0) {
          state.dataSource = res.data.lists;
          pageTotal.value = res.data.pageTotal;
          state.menu = res.data.menus;
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    const handleSizeChange = (val) => {
      pageSize.value = val;
      getList();
    };
    const handleCurrentChange = (val) => {
      currentPage.value = val;
      getList();
    };
    onMounted(() => {
      getList();
    });
    //------------以上为列表数据

    const groupGo = (row) => {
      state.dialogFormVisible = true;

      //返回业务员列表
      getUserList().then((res) => {
        if (res.code == 0) {
          state.getUserList = res.data;
        } else {
          ElMessage.error(res.msg);
        }
      });

      if (row) {
        Object.assign(formState, row);
        state.goTitle = "更新";
        state.title = "修改部门信息";
        state.nameMsg = "不修改部门名，则留空";
        formState.user_group_rights = JSON.parse(row.user_group_rights);
        formState.user_group_status = Boolean(row.user_group_status);
      } else {
        state.title = "添加部门";
        state.nameMsg = "请输入部门名称";
        formState.user_group_id = 0;
        formState.user_group_name_ch = "";
        formState.user_group_name_en = "";
        formState.user_group_leader = 1;
        formState.user_group_pic = "";
        formState.user_group_status = true;
        formState.user_group_rights = [];
        formState.user_group_remark = "";
      }
    };

    const add = () => {
      //无论新增还是修改，部门名都是必填;
      if (!formState.user_group_name_ch) {
        ElMessage.info("请输入部门名称");
        return false;
      }
      if (!formState.user_group_leader) {
        ElMessage.info("请选择部门负责人");
        return false;
      }
      groupAdd(formState).then((res) => {
        if (res.code == 0) {
          ElMessage.success(res.msg);
          state.dialogFormVisible = false;
          getList();
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    //------------以上为编辑和修改数据
    const del = (user_group_id) => {
      if (!user_group_id) {
        ElMessage("所选数据为空，请重新选择！");
      } else {
        ElMessageBox.confirm("确定要删除该条数据吗?", "警告", {
          confirmButtonText: "删除",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            groupDel({ user_group_id: user_group_id }).then((res) => {
              if (res.code == 0) {
                ElMessage.success(res.msg);
                getList();
              } else {
                ElMessage.error(res.msg);
              }
            });
          })
          .catch(() => {
            ElMessage({
              type: "info",
              message: "删除取消",
            });
          });
      }
    };

    const defaultProps = {
      children: "children",
      label: "label",
    };

    return {
      ...toRefs(state),
      rules,
      formState,
      groupGo,
      add,
      del,
      currentPage,
      pageSize,
      pageTotal,
      handleSizeChange,
      handleCurrentChange,
      defaultProps,
    };
  },
};
</script>
<style scoped>
.el-select :deep .select-popper {
  z-index: 8888 !important;
  top: auto !important;
  left: auto !important;
}
.actionBtn {
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;
}
.content {
  height: calc(100% - 180px);
  position: relative;
}
.el-table {
  position: absolute;
}
.el-table__header {
  width: 100%;
}
.el-table__body {
  height: calc(100% - 40px);
}
.el-pagination {
  position: absolute;
  bottom: -56px;
  right: 20px;
}
</style>
