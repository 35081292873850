<template>
  <div class="order-detail-contain">
    <el-tabs v-model="activeName">
      <el-tab-pane label="考勤" name="first">
        <attendanceList></attendanceList>
      </el-tab-pane>
      <el-tab-pane label="请假" name="second">
        <leaveList></leaveList>
      </el-tab-pane>
      <el-tab-pane label="假条回收" name="three">
        <recycleList></recycleList>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { ref } from "vue";
import attendanceList from "./Attendance/index.vue";
import leaveList from "./Leave/index.vue";
import recycleList from "./Recycle/index.vue";
export default {
  name: "monthOASet",
  components: {
    attendanceList,
    leaveList,
    recycleList,
  },
  setup() {
    const activeName = ref("first");
    return {
      activeName,
    };
  },
};
</script>
<style scoped lang="scss">
.order-detail-contain {
  height: 100%;
  padding: 20px 0;
  .el-tabs {
    height: 100%;
    :deep .el-tabs__content {
      height: calc(100% - 100px);
    }
    .el-tab-pane {
      height: 100%;
    }
  }
}
</style>
