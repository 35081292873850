export default {
  selection: false,
  columns: [
    {
      label: "订单编码",
      prop: "ord_no",
    },
    {
      label: "出运数量",
      prop: "total",
    },
    {
      label: "物流费",
      prop: "shipping_cost",
      type: "s_cost",
    },
    {
      label: "保险费",
      prop: "insurance_fee",
      type: "in_cost",
    },
    {
      label: "打包费",
      prop: "packing_fee",
      type: "pa_fee",
    },
    {
      label: "超区费",
      prop: "remote_fee",
      type: "re_fee",
    },
    {
      label: "其他费",
      prop: "other_fee",
      type: "ot_fee",
    },
    {
      label: "分摊备注",
      prop: "remark",
      type: "remark",
    },
  ],
};
