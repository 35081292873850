// 工具函数
// 合并表格行方法
export function mergeTableRows(tableData, id) {
  let formatObj = {};
  if (tableData.length) {
    // 1.给table数据排序
    tableData.sort((a, b) => {
      return a[id] - b[id];
    });
    // 2.筛选出id
    let groupIds = tableData.map((item) => {
      return item[id];
    });
    // 3.去重id号
    let ids = Array.from(new Set(groupIds));
    let objIds = {};
    if (ids.length) {
      // 4.获得一个对象数据如：{id:相同id个数}的对象
      ids.forEach((item, index) => {
        objIds[item] = tableData.filter((it) => {
          return it[id] == item;
        }).length;
      });
    }
    let keys = Object.keys(objIds);
    if (keys.length) {
      // 5.获得一个对象数据如：{第一个出现相同id的下标号:相同id个数}的对象
      for (let key in objIds) {
        let i = tableData.findIndex((data) => {
          return data[id] == key;
        });
        formatObj[i] = objIds[key];
      }
    }
  }
  return formatObj;
}
export function objectSpanMethod({
  row,
  column,
  rowIndex,
  columnIndex,
  formatObj,
  colAry,
}) {
  if (colAry.includes(columnIndex)) {
    if (Object.keys(formatObj).includes(String(rowIndex))) {
      if (formatObj[rowIndex] > 1) {
        return {
          rowspan: formatObj[rowIndex],
          colspan: 1,
        };
      }
    } else {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
  }
}
