import { request } from "./request.js";

export function monthSetList(data = {}) {
  return request({
    url: "MonthSet/monthSetList",
    method: "post",
    data,
  });
}
export function addSetList(data = {}) {
  return request({
    url: "MonthSet/addSetList",
    method: "post",
    data,
  });
}
export function delSetList(data = {}) {
  return request({
    url: "MonthSet/delSetList",
    method: "post",
    data,
  });
}
export function exchangeList(data = {}) {
  return request({
    url: "MonthSet/exchangeList",
    method: "post",
    data,
  });
}
export function addExchangeList(data = {}) {
  return request({
    url: "MonthSet/addExchangeList",
    method: "post",
    data,
  });
}
export function getMonthList(data = {}) {
  return request({
    url: "MonthSet/getMonthList",
    method: "post",
    data,
  });
}
export function attendanceList(data = {}) {
  return request({
    url: "MonthSet/attendanceList",
    method: "post",
    data,
  });
}
export function addAttendanceList(data = {}) {
  return request({
    url: "MonthSet/addAttendanceList",
    method: "post",
    data,
  });
}
export function getUserList(data = {}) {
  return request({
    url: "MonthSet/getUserList",
    method: "post",
    data,
  });
}
export function getLevelList(data = {}) {
  return request({
    url: "MonthSet/getLevelList",
    method: "post",
    data,
  });
}
export function confirmSetInfo(data = {}) {
  return request({
    url: "MonthSet/confirmSetInfo",
    method: "post",
    data,
  });
}
export function leaveList(data = {}) {
  return request({
    url: "MonthSet/leaveList",
    method: "post",
    data,
  });
}
export function addLeaveList(data = {}) {
  return request({
    url: "MonthSet/addLeaveList",
    method: "post",
    data,
  });
}
export function delLeaveList(data = {}) {
  return request({
    url: "MonthSet/delLeaveList",
    method: "post",
    data,
  });
}
export function confirmLeaveList(data = {}) {
  return request({
    url: "MonthSet/confirmLeaveList",
    method: "post",
    data,
  });
}
export function goalsList(data = {}) {
  return request({
    url: "MonthSet/goalsList",
    method: "post",
    data,
  });
}
export function addGoalsList(data = {}) {
  return request({
    url: "MonthSet/addGoalsList",
    method: "post",
    data,
  });
}
export function confirmGoalsInfo(data = {}) {
  return request({
    url: "MonthSet/confirmGoalsInfo",
    method: "post",
    data,
  });
}
