import { request } from "./request.js";
export function printPurchaseOrder(data = {}) {
  return request({
    url: "showDetails/printPurchaseOrder",
    method: "post",
    data,
  });
}
export function printShippingOrder(data = {}) {
  return request({
    url: "showDetails/printShippingOrder",
    method: "post",
    data,
  });
}
export function printOrderInvoice(data = {}) {
  return request({
    url: "showDetails/printOrderInvoice",
    method: "post",
    data,
  });
}
export function printOrder(data = {}) {
  return request({
    url: "showDetails/printOrder",
    method: "post",
    data,
  });
}
export function showPurchaseDetails(data = {}) {
  return request({
    url: "showDetails/showPurchaseDetails",
    method: "post",
    data,
  });
}
export function showShippingDetails(data = {}) {
  return request({
    url: "showDetails/showShippingDetails",
    method: "post",
    data,
  });
}
export function showOrderIMEI(data = {}) {
  return request({
    url: "showDetails/showOrderIMEI",
    method: "post",
    data,
  });
}
export function showDeliveredInfo(data = {}) {
  return request({
    url: "showDetails/showDeliveredInfo",
    method: "post",
    data,
  });
}
export function showCollectionInfo(data = {}) {
  return request({
    url: "showDetails/showCollectionInfo",
    method: "post",
    data,
  });
}
export function showPaymentInfo(data = {}) {
  return request({
    url: "showDetails/showPaymentInfo",
    method: "post",
    data,
  });
}
export function showOrderProfitDetails(data = {}) {
  return request({
    url: "showDetails/showOrderProfitDetails",
    method: "post",
    data,
  });
}
export function showSalaryDetails(data = {}) {
  return request({
    url: "showDetails/showSalaryDetails",
    method: "post",
    data,
  });
}
