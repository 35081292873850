<template>
  <div class="order-detail-contain">
    <el-tabs v-model="activeName">
      <el-tab-pane label="发货基本信息" name="first">
        <create-list @getOrderListId="getCreateDetails"></create-list>
      </el-tab-pane>
      <el-tab-pane label="发货详情信息" name="second">
        <createDetails></createDetails>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { ref } from "vue";
import createDetails from "./createDetails.vue";
import createList from "./createList.vue";
export default {
  name: "deliveryOrder",
  components: {
    createDetails,
    createList,
  },
  setup() {
    const activeName = ref("first");
    const getCreateDetails = (val) => {
      if (val) {
        activeName.value = "second";
      }
    };
    return { activeName, getCreateDetails };
  },
};
</script>
<style scoped>
.order-detail-contain {
  padding: 20px 0;
}
</style>
