<template>
  <div style="height: 100%">
    <div style="text-align: center; margin: 20px; font-size: 30px">
      发票编码 {{ formState.inv_no }}
    </div>
    <el-form :model="formState" :rules="rules">
      <div class="div_title">发货信息：</div>
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="发货人员：" prop="shipper_add_id">
            <el-select
              v-model="formState.shipper_add_id"
              :popper-append-to-body="false"
              placeholder="-请选择发货人-"
            >
              <el-option
                v-for="item in getCompShippingAdd"
                :key="item.id"
                :label="item.shipper"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="div_title">收件信息：</div>
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="收货人员：" prop="consignee_add_id">
            <el-select
              v-model="formState.consignee_add_id"
              :popper-append-to-body="false"
              placeholder="-请选择收货人-"
            >
              <el-option
                v-for="item in getCustomerShippingAdd"
                :key="item.id"
                :label="item.consignee_name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="一单多票：">
            <el-switch
              v-model="formState.is_create_inv"
              :popper-append-to-body="false"
              inline-prompt
              active-text="是"
              inactive-text="否"
            >
            </el-switch>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="申报币种：" prop="currency_id">
            <el-select
              v-model="formState.currency_id"
              :popper-append-to-body="false"
              placeholder="-请选择申报币种-"
            >
              <el-option
                v-for="item in getCurrencyList"
                :key="item.currency_id"
                :label="item.currency_name_ch"
                :value="item.currency_id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="运费申报：" prop="shipping_cost">
            <el-input
              v-model="formState.shipping_cost"
              :popper-append-to-body="false"
              placeholder="请输入客户申报的运费金额，留空则不显示"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="24">
          <el-form-item label="发票备注：">
            <el-input
              type="textarea"
              v-model="formState.remark"
              placeholder="这些信息将显示在最终发货发票的备注栏（请用英语）"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="4" :offset="20">
          <el-button size="middle" type="primary" @click="add()">{{
            btn_title
          }}</el-button>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import { ref, reactive, toRefs, onMounted } from "vue";
import {
  createInvoice,
  addInvoice,
  getCompShippingAdd,
  getCurrencyList,
} from "@/network/invoice.js";
import { useRoute, useRouter, route } from "vue-router";
import { ElMessage } from "element-plus";
export default {
  emits: ["getInvoiceInfo"],
  setup(props, context) {
    const route = ref(useRoute());
    const state = reactive({
      dataSource: [],
      getCompShippingAdd: [],
      getCustomerShippingAdd: [],
      getCurrencyList: [],
      btn_title: "下一步",
    });
    const orderInfo = route.value.query.arrData;
    if (orderInfo[0] == "udt") {
      state.btn_title = "更新";
    }
    const formState = reactive({
      inv_id: "",
      inv_no: "",
      shipper_add_id: 2,
      consignee_add_id: "",
      currency_id: 1,
      shipping_cost: "",
      is_create_inv: "0",
      remark: "",
    });
    const rules = reactive({
      shipper_add_id: [
        {
          required: true,
          message: "请选择发货人信息",
          trigger: "blur",
        },
      ],
      consignee_add_id: [
        {
          required: true,
          message: "请选择收件人信息",
          trigger: "blur",
        },
      ],
      currency_id: [
        {
          required: true,
          message: "请选择往来货币",
          trigger: "blur",
        },
      ],
    });
    const router = useRouter();
    const getList = () => {
      createInvoice({ data: orderInfo }).then((res) => {
        //仅用于加载发票生成页面
        if (res.code == 0) {
          state.getCustomerShippingAdd = res.data.getCustomerShippingAdd;
          Object.assign(formState, res.data.list);
        } else {
          ElMessage.error(res.msg);
          router.push({
            name: "invoice",
          });
        }
      });
      getCompShippingAdd().then((res) => {
        if (res.code == 0) {
          state.getCompShippingAdd = res.data;
        } else {
          ElMessage.error(res.msg);
        }
      });
      getCurrencyList().then((res) => {
        if (res.code == 0) {
          state.getCurrencyList = res.data;
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    onMounted(() => {
      getList();
    });
    const add = () => {
      //添加或更新
      addInvoice({ formState: formState, orderInfo: orderInfo }).then((res) => {
        if (res.code == 0) {
          ElMessage.success(res.msg);
          formState.inv_id = res.data; //将生成或者更新成功后返回的发票id号传给关系表页面
          context.emit("getInvoiceInfo", {
            inv_id: formState.inv_id,
            inv_no: formState.inv_no,
          });
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    return {
      ...toRefs(state),
      formState,
      add,
      rules,
    };
  },
};
</script>
<style scoped>
.div_title {
  margin: 20px 0;
  padding-left: 10px;
  border-left: 10px solid grey;
  font-size: 20px;
}
</style>
