<template>
  <div class="flex-layout order-management">
    <isdListPaper v-loading="tableLoading">
      <template v-slot:header-search>
        <seach
          :searchConfig="searchConfig"
          @queryClick="searchList"
          @resetClick="searchList"
        ></seach>
      </template>
      <template v-slot:list="props">
        <isdTable
          :loading="tableLoading"
          :rows="totalData"
          :height="props.tableHeight"
          :columns="config.columns"
          :selection="config.selection"
          :actions="config.actions"
          @selection-change="handleSelectedRow"
          style="font-size: 12px"
        >
          <template v-slot:col-append="{ col, row }">
            <div v-if="col.type === 'compName'">
              {{ row[col.prop] }}
            </div>
            <span v-if="col.type === 'cstStar'">
              <el-rate v-model="row.level_id"></el-rate>
            </span>
            <span v-if="col.type === 'cstStags'">
              <el-row>
                <el-tag
                  v-for="tag in row.tags"
                  :key="tag.id"
                  style="margin-right: 10px"
                  :type="tag.type"
                  @close="delTag(tag.id)"
                >
                  {{ tag.tag_name_ch }}
                </el-tag>
              </el-row>
            </span>
          </template>
        </isdTable>
      </template>
      <template v-slot:footer>
        <isdPagination
          @size-change="sizeChange"
          :current-page="currentPage"
          @current-change="pageChange"
          layout="total,sizes,prev,pager,next,jumper"
          :page-size="pageSize"
          :page-sizes="[20, 50, 100]"
          :total="pageTotal"
        ></isdPagination>
      </template>
    </isdListPaper>
  </div>
</template>
<script>
import { customerList } from "@/network/customer.js";
import { EditPen, Delete, Plus, Setting } from "@element-plus/icons-vue";
import { defineComponent, ref, reactive, onMounted } from "vue";
import { ListClass } from "@/composition/ListClass.js";
import { searchConfig } from "@/common/customerSearchConfig.js";
import configData from "./config";
import isdTable from "@/views/components/isdTable/index.vue";
import isdPagination from "@/views/components/isdPage/index.vue";
import isdListPaper from "@/views/components/isdListPaper/index.vue";
import isdSearchBox from "@/views/components/isdSearchBox/index.vue";
import seach from "@/components/seach.vue";
export default defineComponent({
  components: {
    EditPen,
    Delete,
    Plus,
    Setting,
    isdTable,
    isdPagination,
    isdSearchBox,
    isdListPaper,
    seach,
  },
  setup() {
    const config = reactive(configData); // 列表配置
    const searchBoxIns = ref(null); // 搜索实例
    const columnConfigIns = ref(null); // 列配置实例
    const statusDialogIns = ref(null); // 状态弹框实例
    const detailPageIns = ref(null); // 详情编辑页ref
    const tableBox = ref(null);
    // 格式化查询条件(除了删除之外的所有客户)
    const dataFormat = (condition) => {
      condition.symbolType = ">";
      condition.listType = 0;
      condition.inquiryType = "customerListSearch";
      return condition;
    };
    class TableList extends ListClass {
      beforeGetList(condition) {
        condition = dataFormat(condition);
        return condition;
      }
    }
    const {
      currentPage,
      pageNo,
      pageSize,
      pageTotal,
      tableLoading,
      totalData,
      getList,
      searchList,
      pageChange,
      sizeChange,
      selectedRows,
      handleSelectedRow,
    } = new TableList({
      Api: {
        listApi: customerList,
      },
    });
    onMounted(() => {
      getList();
    });
    return {
      tableBox,
      config,
      pageNo,
      pageSize,
      pageTotal,
      tableLoading,
      totalData,
      searchList,
      pageChange,
      sizeChange,
      handleSelectedRow,
      searchBoxIns,
      columnConfigIns,
      detailPageIns,
      statusDialogIns,
      currentPage,
      selectedRows,
      searchConfig,
    };
  },
});
</script>

<style lang="scss">
.order-management {
  .el-table th > .cell {
    word-break: keep-all;
  }
}
</style>
../../../components/seach.vue
