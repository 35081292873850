<template>
  <div style="height: 100%">
    <div style="text-align: center; margin: 20px; font-size: 30px">
      付款单编码 {{ formState.pay_no }}
    </div>
    <el-form :model="formState" :rules="rules">
      <div class="div_title">收款方：</div>
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="收款公司：">
            <el-input v-model="formState.spr_comp_name" disabled> </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="主联系人：">
            <el-input v-model="formState.contact_person" disabled> </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="24">
          <el-form-item label="收款账户：" prop="col_acc_id">
            <el-select
              v-model="formState.col_acc_id"
              :popper-append-to-body="false"
              placeholder="-请选择收款账户-"
            >
              <el-option
                v-for="item in getSupplierAccount"
                :key="item.id"
                :label="item.acc_name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="div_title">付款方：</div>
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="付款人员：" prop="pay_person">
            <el-select
              v-model="formState.pay_person"
              :popper-append-to-body="false"
              placeholder="-请选择付款人-"
            >
              <el-option
                v-for="item in getUserList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="付款账号：" prop="pay_acc_id">
            <el-select
              v-model="formState.pay_acc_id"
              :popper-append-to-body="false"
              placeholder="-请选择付款账号-"
            >
              <el-option
                v-for="item in getCompAccount"
                :key="item.id"
                :label="item.acc_name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="div_title">支付信息：</div>
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="预付日期：" prop="pay_time">
            <el-date-picker
              v-model="formState.pay_time"
              type="date"
              placeholder="设置付款日期日期"
              format="YYYY/MM/DD"
              value-format="YYYY-MM-DD"
              :disabled-date="disabledDate"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="支付货币：" prop="currency_id">
            <el-select
              v-model="formState.currency_id"
              :popper-append-to-body="false"
              placeholder="-请选择支付货币：-"
            >
              <el-option
                v-for="item in getCurrencyList"
                :key="item.currency_id"
                :label="item.currency_name_ch"
                :value="item.currency_id"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="24">
          <el-form-item label="付款备注：">
            <el-input
              type="textarea"
              v-model="formState.remark"
              placeholder="付款单备注信息"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="4" :offset="20">
          <el-button size="middle" type="primary" @click="add()">{{
            btn_title
          }}</el-button>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import { ref, reactive, toRefs, onMounted } from "vue";
import {
  createPayment,
  addPayment,
  getCurrencyList,
  getUserList,
} from "@/network/finance.js";
import { useRoute, useRouter, route } from "vue-router";
import { ElMessage } from "element-plus";
export default {
  emits: ["getPaymentInfo"],
  setup(props, context) {
    const route = ref(useRoute());
    const state = reactive({
      dataSource: [],
      getSupplierAccount: [],
      getCompAccount: [],
      getUserList: [],
      getCurrencyList: [],
      btn_title: "下一步",
    });
    const payInfo = route.value.query.arrData;
    if (payInfo[0] == "udt") {
      state.btn_title = "更新";
    }
    const formState = reactive({
      id: "",
      pay_no: "",
      currency_id: 4,
      col_acc_id: "",
      pay_acc_id: "",
      pay_person: 1,
      pay_time: "",
      create_person: "",
      charge_person: "",
      create_time: "",
      status: true,
      remark: "",
    });
    const rules = reactive({
      col_acc_id: [
        {
          required: true,
          message: "请选择收款账户信息",
          trigger: "blur",
        },
      ],
      pay_person: [
        {
          required: true,
          message: "请选择付款人信息",
          trigger: "blur",
        },
      ],
      pay_acc_id: [
        {
          required: true,
          message: "请选择付款账户",
          trigger: "blur",
        },
      ],
      pay_time: [
        {
          required: true,
          message: "请设置付款日期",
          trigger: "blur",
        },
      ],
      currency_id: [
        {
          required: true,
          message: "请选择付款货币",
          trigger: "blur",
        },
      ],
    });
    const router = useRouter();
    const getList = () => {
      createPayment({ data: payInfo }).then((res) => {
        //仅用于加载生成页面
        if (res.code == 0) {
          state.getSupplierAccount = res.data.getSupplierAccount;
          state.getCompAccount = res.data.getCompAccount;
          Object.assign(formState, res.data.list);
        } else {
          ElMessage.error(res.msg);
          router.push({
            name: "financePayment",
          });
        }
      });
      getCurrencyList().then((res) => {
        if (res.code == 0) {
          state.getCurrencyList = res.data;
        } else {
          ElMessage.error(res.msg);
        }
      });
      getUserList().then((res) => {
        if (res.code == 0) {
          state.getUserList = res.data;
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    onMounted(() => {
      getList();
    });
    const add = () => {
      //添加或更新
      addPayment({ formState: formState, payInfo: payInfo }).then((res) => {
        if (res.code == 0) {
          ElMessage.success(res.msg);
          formState.id = res.data;
          context.emit("getPaymentInfo", {
            pay_id: formState.id,
            pay_no: formState.pay_no,
          });
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    return {
      ...toRefs(state),
      formState,
      add,
      rules,
    };
  },
};
</script>
<style scoped>
.div_title {
  margin: 20px 0;
  padding-left: 10px;
  border-left: 10px solid grey;
  font-size: 20px;
}
</style>
