<template>
  <div>
    <el-dialog
      top="4%"
      v-bind="$attrs"
      :before-close="handleClose"
      :custom-class="'form-dialog'"
    >
      <div class="top-append">
        <slot
          name="top-append"
          v-bind="{ form, config, refForm: $refs.form }"
        ></slot>
      </div>
      <el-form
        @submit.prevent
        v-if="$attrs.modelValue"
        :model="form"
        :rules="rules"
        ref="form"
        @submit="newsubmit"
        v-loading="loading || localLoading"
      >
        <el-row class="form-box">
          <el-col
            v-for="(item, index) in config"
            :key="index"
            :class="['form-item', item.class]"
            :span="lodash.get(item, 'span', Math.ceil(24 / col))"
          >
            <slot
              v-if="item.type === 'custom' && lodash.get(item, 'visible', true)"
              name="form-append"
              v-bind="{ form, itemSetting: item, rIndex: index }"
            >
            </slot>
            <el-form-item
              v-else-if="
                item.type === 'input' && lodash.get(item, 'visible', true)
              "
              :label="item.label"
              :prop="item.prop"
              :label-width="labelWidth"
              :title="item.label"
            >
              <div
                class="wrap"
                :style="{ width: lodash.get(item, 'width', '100%') }"
              >
                <el-input
                  size="default"
                  v-model="form[item.prop]"
                  :type="lodash.get(item, 'inputType', 'text')"
                  :maxlength="lodash.get(item, 'maxlength', null)"
                  :show-word-limit="lodash.get(item, 'showWordLimit', false)"
                  :show-password="lodash.get(item, 'showPassword', false)"
                  :placeholder="lodash.get(item, 'placeholder', '请输入')"
                  :clearable="lodash.get(item, 'clearable', true)"
                  :disabled="lodash.get(item, 'disabled', false)"
                  :rows="lodash.get(item, 'rowS', 5)"
                  :autosize="lodash.get(item, 'autoSize', false)"
                  @change="
                    (data) => {
                      dataChanged(data, form, item);
                    }
                  "
                >
                </el-input>
              </div>
            </el-form-item>
            <el-form-item
              v-else-if="
                item.type === 'select' && lodash.get(item, 'visible', true)
              "
              :title="item.label"
              :label="item.label"
              :prop="item.prop"
              :label-width="labelWidth"
            >
              <div
                class="wrap"
                :style="{ width: lodash.get(item, 'width', '100%') }"
              >
                <el-select
                  v-model="form[item.prop]"
                  :filterable="lodash.get(item, 'filterable', false)"
                  :multiple="lodash.get(item, 'multiple', false)"
                  :multiple-limit="lodash.get(item, 'multipleLimit', 0)"
                  :clearable="lodash.get(item, 'clearable', true)"
                  :disabled="lodash.get(item, 'disabled', false)"
                  :placeholder="lodash.get(item, 'placeholder', '请选择')"
                  @change="
                    (data) => {
                      dataChanged(data, form, item);
                    }
                  "
                >
                  <el-option
                    v-for="(selectItem, selectKey) in item.options"
                    :key="
                      lodash.isPlainObject(selectItem)
                        ? selectItem.value
                        : selectKey
                    "
                    :label="
                      lodash.isPlainObject(selectItem)
                        ? selectItem.label
                        : selectItem
                    "
                    :value="
                      lodash.isPlainObject(selectItem)
                        ? selectItem.value
                        : selectKey
                    "
                    :disabled="
                      lodash.isPlainObject(selectItem)
                        ? selectItem.disabled
                        : false
                    "
                  >
                  </el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item
              v-else-if="
                item.type === 'time' && lodash.get(item, 'visible', true)
              "
              :label="item.label"
              :title="item.label"
              :prop="item.prop"
              :label-width="labelWidth"
            >
              <div
                class="wrap"
                :style="{ width: lodash.get(item, 'width', '100%') }"
              >
                <el-date-picker
                  v-model="form[item.prop]"
                  :type="lodash.get(item, 'timeType', 'datetime')"
                  :clearable="lodash.get(item, 'clearable', true)"
                  :disabled="lodash.get(item, 'disabled', false)"
                  :format="lodash.get(item, 'format', 'YYYY-MM-DD HH:mm:ss')"
                  unlink-panels
                  range-separator="至"
                  :disabledDate="lodash.get(item, 'disabledDate', null)"
                  :start-placeholder="
                    lodash.get(item, 'startPlaceholder', '开始日期')
                  "
                  :end-placeholder="
                    lodash.get(item, 'endPlaceholder', '结束日期')
                  "
                  :placeholder="lodash.get(item, 'placeholder', '选择日期')"
                  @change="
                    (data) => {
                      dataChanged(data, form, item);
                    }
                  "
                >
                </el-date-picker>
              </div>
            </el-form-item>
            <el-form-item
              v-else-if="
                item.type === 'switch' && lodash.get(item, 'visible', true)
              "
              :label="item.label"
              :title="item.label"
              :prop="item.prop"
              :label-width="labelWidth"
            >
              <el-switch
                v-model="form[item.prop]"
                :active-value="lodash.get(item, 'activeValue', 1)"
                :inactive-value="lodash.get(item, 'inactiveValue', 0)"
                :active-text="item.activeText"
                :inactive-text="item.inactiveText"
                :inline-prompt="true"
                @change="
                  (data) => {
                    dataChanged(data, form, item);
                  }
                "
              >
              </el-switch>
            </el-form-item>
            <el-form-item
              v-else-if="
                item.type === 'radio' && lodash.get(item, 'visible', true)
              "
              :label="item.label"
              :title="item.label"
              :prop="item.prop"
              :label-width="labelWidth"
            >
              <div
                class="wrap"
                :style="{ width: lodash.get(item, 'width', '100%') }"
              >
                <el-radio-group
                  v-model="form[item.prop]"
                  :disabled="lodash.get(item, 'disabled', false)"
                  @change="
                    (data) => {
                      dataChanged(data, form, item);
                    }
                  "
                >
                  <el-radio
                    v-for="(selectItem, selectKey) in item.options"
                    :key="
                      lodash.isPlainObject(selectItem)
                        ? selectItem.value
                        : selectKey
                    "
                    :label="
                      lodash.isPlainObject(selectItem)
                        ? selectItem.value
                        : selectKey
                    "
                    :disabled="
                      lodash.isPlainObject(selectItem)
                        ? selectItem.disabled
                        : false
                    "
                  >
                    {{
                      lodash.isPlainObject(selectItem)
                        ? selectItem.label
                        : selectItem
                    }}
                  </el-radio>
                </el-radio-group>
              </div>
            </el-form-item>
            <el-form-item
              v-else-if="
                item.type === 'checkbox' && lodash.get(item, 'visible', true)
              "
              :label="item.label"
              :title="item.label"
              :prop="item.prop"
              :label-width="labelWidth"
            >
              <div
                class="wrap"
                :style="{ width: lodash.get(item, 'width', '100%') }"
              >
                <el-checkbox-group
                  v-model="form[item.prop]"
                  :disabled="lodash.get(item, 'disabled', false)"
                  @change="
                    (data) => {
                      dataChanged(data, form, item);
                    }
                  "
                >
                  <el-checkbox
                    v-for="(selectItem, selectKey) in item.options"
                    :key="
                      lodash.isPlainObject(selectItem)
                        ? selectItem.value
                        : selectKey
                    "
                    :label="
                      lodash.isPlainObject(selectItem)
                        ? selectItem.value
                        : selectKey
                    "
                    :disabled="
                      lodash.isPlainObject(selectItem)
                        ? selectItem.disabled
                        : false
                    "
                  >
                    {{
                      lodash.isPlainObject(selectItem)
                        ? selectItem.label
                        : selectItem
                    }}
                  </el-checkbox>
                </el-checkbox-group>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template v-slot:footer>
        <span class="dialog-footer">
          <div class="footer-append">
            <slot
              name="footer-append"
              v-bind="{ form, config, refForm: $refs.form }"
            ></slot>
          </div>
          <div class="footer-btn-list">
            <el-button
              v-for="(item, index) in btnList"
              :key="index"
              :loading="
                item.fixType === 'cancel' ? false : loading || localLoading
              "
              @click="footerBtnClick(item)"
              :class="item.class"
              :type="item.type"
            >
              {{ item.text }}
            </el-button>
          </div>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import lodash from "lodash";
export default {
  name: "isdFormDialog",
  inheritAttrs: false,
  props: {
    draggable: {
      type: Boolean,
      default: false,
    },
    config: {
      // form表单项的配置
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    row: {
      // 数据项对象
      type: Object,
      default() {
        return {};
      },
    },
    loading: {
      // 表单加载中显示
      type: Boolean,
      default: false,
    },
    col: {
      // 弹窗内列数
      type: Number,
      default: 3,
    },
    labelWidth: {
      // 弹窗label宽度
      type: String,
      default: "110px",
    },
    btnList: {
      // 底部按钮配置
      type: Array,
      default() {
        return [
          {
            text: "取消",
            fixType: "cancel",
          },
          {
            text: "确认",
            fixType: "comfirm",
            type: "primary",
          },
        ];
      },
    },
  },
  // 定义抛出的事件名称
  emits: ["close-dialog", "data-changed"],
  data() {
    return {
      localLoading: false, // 本地的表单加载中显示
      rules: {}, // 判断表单输入规则集合
      form: {}, // 本地化传入的数据
    };
  },
  computed: {
    lodash() {
      return lodash;
    },
  },
  watch: {
    row: {
      // 监听数据变化，初始化数据
      handler(val) {
        this.form = lodash.cloneDeep(val);
        this.config.forEach((item) => {
          // 设置表单的验证规则
          if (item.rule) this.rules[item.prop] = item.rule;
          this.setDefaultVal(item);
        });
        // 表格渲染前先判断表格列中使用到的数据字典是否都在浏览器端有备份，没有的进行接口获取，保证表格的字典转义
        const keys = lodash
          .chain(this.config)
          .map((obj) => obj.optionsKey)
          .filter((str) => !lodash.isNil(str))
          .value();
        if (keys.length > 0) {
          this.localLoading = true;
        }
      },
      deep: true,
    },
  },
  methods: {
    newsubmit(val) {
      console.log(val, "vallll");
    },
    /**
     * @description: 键盘按输入键操作
     * @method: enter输入操作
     */
    confirmDialog() {
      this.submitForm();
    },
    /**
     * @description: 去除空格后，抛出事件
     * @method: 提交表单
     */
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          for (const key of Object.keys(this.form)) {
            const val = this.form[key];
            if (typeof val === "string")
              this.form[key] = val.replace(/(^\s*)|(\s*$)/g, "");
          }
          this.$emit("close-dialog", this.form);
        }
      });
    },
    /**
     * @description: 抛出关闭事件
     * @method: 处理关闭弹窗
     */
    handleClose() {
      this.$emit("close-dialog");
    },
    /**
     * @description: 监听每个值改变时候，对外暴露方法
     * @method: 值改变事件
     * @param {any} data 当前操作项的值
     * @param {object} form form表单数据项对象
     * @param {Object} item 当前操作项的配置项
     */
    dataChanged(data, form, item) {
      this.$emit("data-changed", { data, form, itemSetting: item });
    },
    /**
     * @description: 为form表单每一项配置数据或默认值
     * @method: 设置默认值
     * @param {Object} item 单个config配置项对象
     */
    setDefaultVal(item) {
      const defaultValue =
        item.defaultValue === "" ||
        item.defaultValue === undefined ||
        item.defaultValue === null
          ? ""
          : item.defaultValue;
      if (
        this.form[item.prop] === "" ||
        this.form[item.prop] === undefined ||
        this.form[item.prop] === null
      ) {
        this.form[item.prop] = defaultValue;
      }
      this.setAdaptVal(item);
    },
    /**
     * @description: 对特定类型配置的值进行适配
     * @method: 特殊值适配
     * @param {Object} item 单个config配置项对象
     */
    setAdaptVal(item) {
      // 强转字符串，适配select只认字符串
      if (
        item.type === "select" &&
        this.form[item.prop] !== "" &&
        typeof this.form[item.prop] === "number"
      )
        this.form[item.prop] = String(this.form[item.prop]);
      // 适配checkbox没有默认值时候，设置为空数组
      if (item.type === "checkbox" && !(this.form[item.prop] instanceof Array))
        this.form[item.prop] = [];
    },
    /**
     * @description: 底部按钮的操作合集
     * @method: 自定义底部按钮
     * @param {Object} item 按钮对象
     */
    footerBtnClick(item) {
      if (item.fixType === "comfirm") {
        this.submitForm("form");
      } else if (item.fixType === "cancel") {
        this.handleClose();
      } else {
        item.action({
          form: this.form,
          config: this.config,
          refForm: this.$refs.form,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.form-dialog {
  .form-item {
    width: 100%;
    .el-form-item__label,
    .el-form-item__content {
      line-height: 28px;
      min-height: 29px;
    }
    .el-form-item__content {
      .wrap > div {
        width: 100%;
      }
    }
    .el-date-editor.el-input,
    .el-select {
      width: 100%;
    }
    .el-switch {
      vertical-align: initial;
    }
  }
  .el-dialog__footer {
    border-top: 1px solid #e6e6e6;
    .dialog-footer {
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
