<template>
  <div>
    <el-button type="primary" @click="compAddList()">
      <el-icon> <list /> </el-icon><span>地址维护</span></el-button
    >
    <el-button type="primary" @click="compAccountList()">
      <el-icon> <list /> </el-icon><span>账户维护</span></el-button
    >
  </div>
</template>
<script>
import { useRouter } from "vue-router";
import { List } from "@element-plus/icons-vue";

export default {
  components: {
    List,
  },
  setup() {
    const router = useRouter();
    const compAddList = () => {
      router.push({
        name: "compAddList",
        params: {},
      });
    };
    const compAccountList = () => {
      router.push({
        name: "compAccountList",
        params: {},
      });
    };
    return {
      compAddList,
      compAccountList,
    };
  },
};
</script>
