<template>
  <div class="flex-layout order-management">
    <isdListPaper v-loading="tableLoading">
      <template v-slot:header-button>
        <el-button type="primary" @click="addSchedule">
          <el-icon style="vertical-align: middle"> <Plus /> </el-icon
          ><span style="vertical-align: middle"> 新增日程</span>
        </el-button>
      </template>
      <template v-slot:header-search>
        <isdSearchBox
          ref="searchBoxIns"
          :search-key="config.name"
          :fields="config.searchFields"
          @search-change="searchList"
          :loading="tableLoading"
          class="track-search"
        ></isdSearchBox>
      </template>
      <template v-slot:list="props">
        <isdTable
          :loading="tableLoading"
          :rows="totalData"
          :height="props.tableHeight"
          :columns="config.columns"
          :selection="config.selection"
          :actions="config.actions"
          @selection-change="handleSelectedRow"
          style="font-size: 12px"
        >
        </isdTable>
      </template>
      <template v-slot:footer-buttons>
        <el-button
          type="primary"
          @click="deleteItem"
          :disabled="!selectedRows.length"
          ><el-icon><RefreshLeft /></el-icon>
          <span style="vertical-align: middle"> 批量恢复 </span>
        </el-button>
      </template>
      <template v-slot:footer>
        <isdPagination
          @size-change="sizeChange"
          :current-page="currentPage"
          @current-change="pageChange"
          layout="total,sizes,prev,pager,next,jumper"
          :page-size="pageSize"
          :page-sizes="[20, 50, 100]"
          :total="pageTotal"
        ></isdPagination>
      </template>
    </isdListPaper>
    <!-- 新增/编辑弹窗 -->
    <isdFormDialog
      ref="formDialogIns"
      :config="config.forms"
      :loading="formDialog.loading"
      :label-width="formDialog.labelWidth"
      :row="formDialog.row"
      v-model="formDialog.show"
      :col="formDialog.col"
      :title="formDialog.title"
      :width="formDialog.width"
      @close-dialog="closeFormDialog"
      @data-changed="changeObjectValue"
    ></isdFormDialog>
  </div>
</template>
<script>
import {
  myScheduleList,
  getUserList,
  getScheduleLevel,
  getObjectValue,
  addScheduleList,
  delScheduleList,
} from "@/network/schedule.js";
import {
  EditPen,
  Printer,
  Delete,
  Check,
  Plus,
  AlarmClock,
  RefreshLeft,
} from "@element-plus/icons-vue";
import { useRouter } from "vue-router";
import { defineComponent, ref, reactive, onMounted } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { ListClass } from "@/composition/ListClass.js";
import configData from "./config";
import isdTable from "@/views/components/isdTable/index.vue";
import isdPagination from "@/views/components/isdPage/index.vue";
import isdListPaper from "@/views/components/isdListPaper/index.vue";
import isdFormDialog from "@/views/components/isdFormDialog/Index.vue";
import isdSearchBox from "@/views/components/isdSearchBox/index.vue";
export default defineComponent({
  name: "scheduleList",
  components: {
    EditPen,
    Printer,
    Delete,
    Check,
    Plus,
    AlarmClock,
    RefreshLeft,
    isdTable,
    isdPagination,
    isdSearchBox,
    isdListPaper,
    isdFormDialog,
  },
  setup() {
    const formDialogIns = ref(null);
    const config = reactive(configData); // 列表配置
    const searchBoxIns = ref(null); // 搜索实例
    const columnConfigIns = ref(null); // 列配置实例
    const statusDialogIns = ref(null); // 状态弹框实例
    const detailPageIns = ref(null); // 详情编辑页ref
    const tableBox = ref(null);
    // 新建/修改弹窗对象
    const formDialog = reactive({
      labelWidth: "6.875rem", // 弹窗label宽度
      loading: false, // 表单加载中显示
      row: {}, // 数据项对象
      show: false, // 显示弹窗
      col: 1, // 弹窗内列数
      title: "新建", // 弹窗标题
      width: "60%", // 弹窗宽度
    });
    // 格式化查询条件
    const dataFormat = (condition) => {
      condition.symbolType = "=";
      condition.listType = 0;
      return condition;
    };
    class TableList extends ListClass {
      beforeGetList(condition) {
        condition = dataFormat(condition);
        return condition;
      }
    }
    const {
      currentPage,
      pageNo,
      pageSize,
      pageTotal,
      tableLoading,
      totalData,
      getList,
      searchList,
      pageChange,
      sizeChange,
      selectedRows,
      handleSelectedRow,
    } = new TableList({
      Api: {
        listApi: myScheduleList,
      },
    });
    const changeObjectValue = (val) => {
      if (val.itemSetting.prop == "obj_type") {
        getObjectValue({ obj_type: val.data }).then((res) => {
          if (res.code == 0) {
            //切换类型后，置空obj_value
            formDialogIns.value.form.obj_value = "";
            config.forms[4].options = res.data;
          } else {
            ElMessage.error(res.msg);
          }
        });
      }
    };
    //新增日程
    const addSchedule = (row) => {
      //获取参与人列表
      getUserList().then((res) => {
        if (res.code == 0) {
          config.forms[0].options = res.data;
        } else {
          ElMessage.error(res.msg);
        }
      });
      //日程等级
      getScheduleLevel().then((res) => {
        if (res.code == 0) {
          config.forms[1].options = res.data;
        } else {
          ElMessage.error(res.msg);
        }
      });
      formDialog.show = true;
      if (row.id) {
        formDialog.title = "修改日程";
        if (row.focus == 1) {
          row.focus == true;
        } else {
          row.focus == false;
        }
        formDialog.row = row;
      } else {
        formDialog.title = "新增日程";
        formDialog.row = {};
        formDialog.row.source = 1;
      }
    };
    const deleteItem = () => {
      const map = new Map();
      const arr = selectedRows.value.map((item) => {
        return item.id; // 只返回id号
      });
      //方法
      if (!arr) {
        ElMessage("所选数据为空，请重新选择！");
      } else {
        ElMessageBox.confirm("确定要恢复选择的日程吗?", "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            delScheduleList({ data: arr, type: 1 }).then((res) => {
              if (res.code == 0) {
                ElMessage.success(res.msg);
                getList();
              } else {
                ElMessage.error(res.msg);
                getList();
              }
            });
          })
          .catch(() => {
            ElMessage({
              type: "info",
              message: "恢复取消",
            });
          });
      }
    };
    const closeFormDialog = (params) => {
      if (!params) {
        closeAction();
        return;
      }
      saveItem(params);
    };
    const closeAction = () => {
      formDialog.show = false;
      formDialog.row = {};
      formDialog.loading = false;
    };
    const saveItem = (params) => {
      // 新增/更新成功后对数据操作
      if (!params) {
        closeAction();
        return;
      }
      formDialog.loading = true;
      // 保存、编辑
      addScheduleList(params).then((res) => {
        if (res.code == 0) {
          formDialog.loading = false;
          getList();
          ElMessage.success(res.msg);
          closeAction();
        } else {
          ElMessage.error(res.msg);
          formDialog.loading = false;
          getList();
          closeAction();
        }
      });
    };
    onMounted(() => {
      getList();
    });
    return {
      tableBox,
      config,
      pageNo,
      pageSize,
      pageTotal,
      tableLoading,
      totalData,
      searchList,
      pageChange,
      sizeChange,
      handleSelectedRow,
      searchBoxIns,
      columnConfigIns,
      detailPageIns,
      statusDialogIns,
      formDialog,
      closeFormDialog,
      addSchedule,
      currentPage,
      changeObjectValue,
      formDialogIns,
      deleteItem,
      selectedRows,
    };
  },
});
</script>

<style scoped lang="scss">
.wait-pop {
  margin-left: 120px !important;
  .el-popper__arrow {
    margin-left: -140px !important;
  }
}
.el-tooltip__trigger {
  display: inline-block;
  width: 100%;
}
.order-management {
  .el-table th > .cell {
    word-break: keep-all;
  }
}
</style>
