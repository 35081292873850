<template>
  <div class="flex-layout order-management">
    <isdListPaper v-loading="tableLoading">
      <template v-slot:header-search>
        <isdSearchBox
          ref="searchBoxIns"
          :search-key="config.name"
          :fields="config.searchFields"
          @search-change="searchList"
          :loading="tableLoading"
          class="track-search"
        ></isdSearchBox>
      </template>
      <template v-slot:list="props">
        <isdTable
          :loading="tableLoading"
          :rows="totalData"
          :height="props.tableHeight"
          :columns="config.columns"
          :selection="config.selection"
          :actions="config.actions"
          @selection-change="handleSelectedRow"
        >
        </isdTable>
      </template>
      <template v-slot:footer-buttons>
        <el-button
          type="primary"
          @click="deleteItem"
          :disabled="!selectedRows.length"
          ><el-icon><RefreshLeft /></el-icon>
          <span style="vertical-align: middle"> 批量恢复 </span>
        </el-button>
      </template>
      <template v-slot:footer>
        <isdPagination
          @size-change="sizeChange"
          :current-page="currentPage"
          @current-change="pageChange"
          layout="total,sizes,prev,pager,next,jumper"
          :page-size="pageSize"
          :page-sizes="[20, 50, 100]"
          :total="pageTotal"
        ></isdPagination>
      </template>
    </isdListPaper>
  </div>
</template>
<script>
import { leaveList, delLeaveList } from "@/network/monthSet.js";
import { RefreshLeft } from "@element-plus/icons-vue";
import { defineComponent, ref, reactive, onMounted } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { ListClass } from "@/composition/ListClass.js";
import configData from "./config";
import isdTable from "@/views/components/isdTable/index.vue";
import isdPagination from "@/views/components/isdPage/index.vue";
import isdListPaper from "@/views/components/isdListPaper/index.vue";
import isdSearchBox from "@/views/components/isdSearchBox/index.vue";
export default defineComponent({
  name: "monthOASet",
  components: {
    RefreshLeft,
    isdTable,
    isdPagination,
    isdSearchBox,
    isdListPaper,
  },
  setup() {
    const config = reactive(configData); // 列表配置
    const searchBoxIns = ref(null); // 搜索实例
    const columnConfigIns = ref(null); // 列配置实例
    const statusDialogIns = ref(null); // 状态弹框实例
    const detailPageIns = ref(null); // 详情编辑页ref
    const tableBox = ref(null);
    // 格式化查询条件
    const dataFormat = (condition) => {
      condition.fieldName = "status";
      condition.fieldSymbol = "=";
      condition.fieldVal = 0;
      return condition;
    };
    class TableList extends ListClass {
      beforeGetList(condition) {
        condition = dataFormat(condition);
        return condition;
      }
    }
    const {
      currentPage,
      pageNo,
      pageSize,
      pageTotal,
      tableLoading,
      totalData,
      getList,
      searchList,
      pageChange,
      sizeChange,
      selectedRows,
      handleSelectedRow,
    } = new TableList({
      Api: {
        listApi: leaveList,
      },
    });
    const deleteItem = () => {
      const map = new Map();
      const arr = selectedRows.value.map((item) => {
        return item.id; // 只返回id号
      });
      //方法
      if (!arr) {
        ElMessage("所选数据为空，请重新选择！");
      } else {
        ElMessageBox.confirm("确定要删除选中的请假条吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "info",
        })
          .then(() => {
            delLeaveList({ id: arr, type: 1 }).then((res) => {
              if (res.code == 0) {
                ElMessage.success(res.msg);
                getList();
              } else {
                ElMessage.error(res.msg);
                getList();
              }
            });
          })
          .catch(() => {
            ElMessage({
              type: "info",
              message: "删除取消",
            });
          });
      }
    };
    onMounted(() => {
      getList();
    });
    return {
      tableBox,
      config,
      pageNo,
      pageSize,
      pageTotal,
      tableLoading,
      totalData,
      searchList,
      pageChange,
      sizeChange,
      handleSelectedRow,
      searchBoxIns,
      columnConfigIns,
      detailPageIns,
      statusDialogIns,
      currentPage,
      selectedRows,
      deleteItem,
    };
  },
});
</script>

<style lang="scss">
.order-management {
  .el-table th > .cell {
    word-break: keep-all;
  }
}
</style>
