<template>
  <div style="height: 100%">
    <div class="actionBtn">
      <el-button type="primary" @click="updateGo()"
        ><el-icon> <plus /> </el-icon><span>更新公海</span></el-button
      >
    </div>
    <div>最新数据：{{ dateInfo }}</div>
  </div>
</template>

<script>
import { ref, reactive, toRefs, onMounted } from "vue";
import {
  setCustomerToOpenLeads,
} from "@/network/customer.js";
import { Delete, Edit, Plus } from "@element-plus/icons-vue";
import { ElMessage, ElMessageBox } from "element-plus";

export default {
  components: {
    Delete,
    Edit,
    Plus,
  },
  setup() {
    const state = reactive({
      dateInfo:""
    });
    const getList = () => {
      setCustomerToOpenLeads({
      }).then((res) => {

        console.log(res.data,"999999")

        if (res.code == 0) {
          state.dateInfo = res.data;
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    onMounted(() => {
      getList();
    });
    //------------以上为列表数据

    const updateGo = () => {
      setCustomerToOpenLeads().then((res) => {

        console.log(res.data,"888888")

        if (res.code == 0) {
          state.dateInfo = res.data;
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    return {
      ...toRefs(state),
      updateGo,
    };
  },
};
</script>
