<template>
  <div class="order-detail-contain">
    <el-tabs v-model="activeName">
      <el-tab-pane label="基础设置" name="first">
        <basicList></basicList>
      </el-tab-pane>
      <el-tab-pane label="汇率设置" name="second">
        <rateList></rateList>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { ref } from "vue";
import basicList from "./Basic/index.vue";
import rateList from "./Rate/index.vue";
export default {
  name: "monthSet",
  components: {
    basicList,
    rateList,
  },
  setup() {
    const activeName = ref("first");
    return {
      activeName,
    };
  },
};
</script>
<style scoped lang="scss">
.order-detail-contain {
  height: 100%;
  padding: 20px 0;
  .el-tabs {
    height: 100%;
    :deep .el-tabs__content {
      height: calc(100% - 100px);
    }
    .el-tab-pane {
      height: 100%;
    }
  }
}
</style>
