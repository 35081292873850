export default {
  selection: true,
  searchFields: [
    // 搜索组件的配置项
    {
      name: "发票编码",
      value: "inv_no",
      type: "input",
      isFixed: true,
      span: 4,
    },
    {
      name: "发票备注（业务）",
      value: "remark",
      type: "input",
      isFixed: true,
      span: 4,
    },
  ],
  columns: [
    // 表格列的配置
    {
      label: "发票编码",
      prop: "inv_no",
    },
    {
      label: "生成日期",
      prop: "create_time",
    },
    {
      label: "客户名字",
      prop: "company_name",
    },
    {
      label: "收货人",
      prop: "consignee_name",
    },
    {
      label: "申报总数量",
      prop: "pdt_sum",
    },
    {
      label: "申报总价值",
      prop: "declared_sum",
    },
    {
      label: "使用次数",
      prop: "use_times",
    },
    {
      label: "状态",
      prop: "status",
      type: "select",
      options: { 1: "有效", 0: "作废" },
    },
    {
      label: "发票备注（业务）",
      prop: "remark",
    },
  ],
};
