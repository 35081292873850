<template>
  <div>
    <el-input
      readonly
      v-model="value"
      :placeholder="config.name"
      :disabled="disabled"
      @keyup.enter="show"
      @clear="clear"
      clearable
    >
      <template v-slot:append>
        <el-button
          icon="el-icon-search"
          class="search-btn"
          @click="show"
          :disabled="disabled"
        ></el-button>
      </template>
      <template v-slot:suffix>
        <i
          class="el-input__icon el-icon-circle-close"
          v-if="value"
          @click="clear"
        ></i>
      </template>
    </el-input>
    <basic-data-dialog
      ref="advanceDialog"
      :draggable="config.draggable"
      @confirm="detaliSearchSure"
    ></basic-data-dialog>
  </div>
</template>

<script>
import lodash from "lodash";
import basicDataDialog from "..//lotsBasicDataDialog";
export default {
  name: "lotsBasicDataInput",
  components: { basicDataDialog },
  props: {
    config: Object,
    label: [String, Number],
    disabled: {
      type: Boolean,
      default: false,
    },
    cascadeData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  // 定义抛出的事件名称
  emits: ["change"],
  data() {
    return {
      value: "",
    };
  },
  created() {
    this.init();
    this.value = this.label;
  },
  watch: {
    label(val) {
      this.value = val;
    },
  },
  methods: {
    init() {
      // 初始化空的返回对象
      this.defaultCbObj = {};
      lodash.forEach(this.config.cbParams, (item) => {
        if (!item) return;
        const arr = item.split("#");
        this.defaultCbObj[arr.pop()] = "";
      });
    },
    show() {
      const config = this.config;
      const advanceCascade = {};
      if (lodash.isString(config.advanceCascade)) {
        let advanceArray = [];
        advanceArray = config.advanceCascade.split(",");
        advanceArray.forEach((item) => {
          let { 0: sourceProp, 1: destProp } = item.split("#");
          destProp = destProp || sourceProp;
          advanceCascade[destProp] = lodash.get(
            this.cascadeData,
            sourceProp,
            ""
          );
        });
      }
      this.$refs.advanceDialog.show(true, config, advanceCascade, this.value);
    },
    handleChange() {
      // this.value = '';
      this.clear();
    },
    detaliSearchSure(data) {
      const prop = this.config.prop || this.config.value;
      this.value = lodash.get(data, prop, "");
      this.$emit("change", data);
    },
    clear() {
      this.value = "";
      this.$emit("change", this.defaultCbObj);
    },
  },
};
</script>
<style lang="scss" scoped>
.el-input__suffix {
  transform: translateX(-29px) !important;
}
.search-btn {
  padding: 12px 7px;
}
</style>
