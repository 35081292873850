<template>
  <div class="flex-layout order-management">
    <isdListPaper v-loading="tableLoading">
      <template v-slot:header-search>
        <isdSearchBox
          ref="searchBoxIns"
          :search-key="config.name"
          :fields="config.searchFields"
          @search-change="searchList"
          :loading="tableLoading"
          class="track-search"
        ></isdSearchBox>
      </template>
      <template v-slot:list="props">
        <isdTable
          :loading="tableLoading"
          :rows="totalData"
          :height="props.tableHeight"
          :columns="config.columns"
          :selection="config.selection"
          :actions="config.actions"
          @selection-change="handleSelectedRow"
          style="font-size: 12px"
        >
          <template v-slot:col-append="{ col, row }">
            <span v-if="col.type === 'operate_btn'">
              <el-button
                round
                size="small"
                @click="startAudit(row.ord_list_id, 2)"
                v-if="row.ord_approval_id == 1"
                ><el-icon style="vertical-align: middle"><VideoPlay /></el-icon
                ><span style="vertical-align: middle">
                  发起结算
                </span></el-button
              >
              <el-button
                round
                size="small"
                @click="startAudit(row.ord_list_id, 3)"
                v-else-if="operate_right"
                ><el-icon style="vertical-align: middle"><Notebook /></el-icon
                ><span style="vertical-align: middle">
                  审核账单
                </span></el-button
              >
              <el-button round size="small" disabled v-else
                ><el-icon style="vertical-align: middle"><AlarmClock /></el-icon
                ><span style="vertical-align: middle">
                  等待审核
                </span></el-button
              >
              <el-button
                round
                size="small"
                @click="showDetails(row.ord_list_id)"
                ><el-icon style="vertical-align: middle"><View /></el-icon
                ><span style="vertical-align: middle"> 详情 </span></el-button
              >
            </span>
          </template>
        </isdTable>
      </template>
      <template v-slot:footer>
        <isdPagination
          @size-change="sizeChange"
          :current-page="currentPage"
          @current-change="pageChange"
          layout="total,sizes,prev,pager,next,jumper"
          :page-size="pageSize"
          :page-sizes="[10, 30, 50, 100]"
          :total="pageTotal"
        ></isdPagination>
      </template>
    </isdListPaper>
    <!-- 新增/编辑弹窗 -->
    <isdFormDialog
      ref="formDialogIns"
      :config="config.forms"
      :loading="formDialog.loading"
      :label-width="formDialog.labelWidth"
      :row="formDialog.row"
      v-model="formDialog.show"
      :col="formDialog.col"
      :title="formDialog.title"
      :width="formDialog.width"
      @close-dialog="closeFormDialog"
    ></isdFormDialog>
  </div>
</template>
<script>
import { orderProfitList, updateApproval } from "@/network/finance.js";
import { getMonthList } from "@/network/monthSet.js";
import {
  VideoPlay,
  AlarmClock,
  Delete,
  Notebook,
  View,
} from "@element-plus/icons-vue";
import { defineComponent, ref, reactive, onMounted, markRaw } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import { ListClass } from "@/composition/ListClass.js";
import configData from "./config";
import isdTable from "@/views/components/isdTable/index.vue";
import isdPagination from "@/views/components/isdPage/index.vue";
import isdListPaper from "@/views/components/isdListPaper/index.vue";
import isdFormDialog from "@/views/components/isdFormDialog/Index.vue";
import isdSearchBox from "@/views/components/isdSearchBox/index.vue";
export default defineComponent({
  name: "orderProfit",
  components: {
    Delete: markRaw(Delete),
    VideoPlay: markRaw(VideoPlay),
    AlarmClock: markRaw(AlarmClock),
    Notebook: markRaw(Notebook),
    View: markRaw(View),
    isdTable,
    isdPagination,
    isdSearchBox,
    isdListPaper,
    isdFormDialog,
  },
  setup() {
    const formDialogIns = ref(null);
    const operate_right = ref(true); // 搜索实例
    const config = reactive(configData); // 列表配置
    const searchBoxIns = ref(null); // 搜索实例
    const columnConfigIns = ref(null); // 列配置实例
    const statusDialogIns = ref(null); // 状态弹框实例
    const detailPageIns = ref(null); // 详情编辑页ref
    const tableBox = ref(null);
    // 新建/修改弹窗对象
    const formDialog = reactive({
      labelWidth: "110px", // 弹窗label宽度
      loading: false, // 表单加载中显示
      row: {}, // 数据项对象
      show: false, // 显示弹窗
      col: 1, // 弹窗内列数
      title: "新建", // 弹窗标题
      width: "60%", // 弹窗宽度
    });
    // 格式化查询条件
    const dataFormat = (condition) => {
      condition.fieldName = "ord_approval_id";
      condition.fieldSymbol = "=";
      condition.fieldVal = 1;
      return condition;
    };
    class TableList extends ListClass {
      beforeGetList(condition) {
        condition = dataFormat(condition);
        return condition;
      }
      afterGetList(res) {
        operate_right.value = res.data.operate_right;
        super.afterGetList(res);
      }
    }
    const {
      currentPage,
      pageNo,
      pageSize,
      pageTotal,
      tableLoading,
      totalData,
      getList,
      searchList,
      pageChange,
      sizeChange,
      selectedRows,
      handleSelectedRow,
    } = new TableList({
      Api: {
        listApi: orderProfitList,
      },
    });
    const router = useRouter();
    const showDetails = (val) => {
      router.push({
        name: "showOrderProfitDetails",
        query: {
          ordId: val,
        },
      });
    };
    //发起审核
    const startAudit = (ord_list_id, operate_type) => {
      getMonthList().then((res) => {
        if (res.code == 0) {
          config.forms[0].options = res.data;
        } else {
          ElMessage.error(res.msg);
        }
      });
      formDialog.show = true;
      if (operate_type == 2) {
        formDialog.title =
          "请确保改笔订单已经签收且所有信息已经录入? 确定发起结算？";
        formDialog.row.ord_list_id = ord_list_id;
        formDialog.row.operate_type = operate_type;
      } else {
        formDialog.title = "确定该笔订单可以结算?";
        formDialog.row = row;
      }
    };
    const closeFormDialog = (params) => {
      if (!params) {
        closeAction();
        return;
      }
      saveItem(params);
    };
    const closeAction = () => {
      formDialog.show = false;
      formDialog.row = {};
      formDialog.loading = false;
    };
    const saveItem = (params) => {
      // 新增/更新成功后对数据操作
      if (!params) {
        closeAction();
        return;
      }
      formDialog.loading = true;
      // 保存、编辑
      updateApproval(params).then((res) => {
        if (res.code == 0) {
          formDialog.loading = false;
          getList();
          ElMessage.success(res.msg);
          closeAction();
        } else {
          ElMessage.error(res.msg);
          formDialog.loading = false;
          getList();
          closeAction();
        }
      });
    };
    onMounted(() => {
      getList();
    });
    return {
      tableBox,
      config,
      pageNo,
      pageSize,
      pageTotal,
      tableLoading,
      totalData,
      searchList,
      pageChange,
      sizeChange,
      handleSelectedRow,
      searchBoxIns,
      columnConfigIns,
      detailPageIns,
      statusDialogIns,
      showDetails,
      formDialog,
      closeFormDialog,
      currentPage,
      formDialogIns,
      selectedRows,
      operate_right,
      startAudit,
    };
  },
});
</script>

<style lang="scss">
.order-management {
  .el-table th > .cell {
    word-break: keep-all;
  }
}
</style>
