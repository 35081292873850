export default {
  selection: false,
  columns: [
    // 表格列的配置
    {
      label: "识别码",
      prop: "add_no",
    },
    {
      label: "发货人",
      prop: "shipper",
    },
    {
      label: "电话",
      prop: "phone",
    },
    {
      label: "地址",
      prop: "add",
    },
    {
      label: "创建时间",
      prop: "create_time",
    },
    {
      label: "状态",
      prop: "status",
    },
    {
      label: "备注",
      prop: "remark",
    },
    {
      label: "操作",
      mustFill: true,
      type: "action",
      prop: "",
    },
  ],
  forms: [
    {
      label: "识别：",
      prop: "add_no",
      type: "input",
      rule: [
        {
          required: true,
          message: "地址识别码，方便使用时识别",
          trigger: "blur",
        },
      ],
    },
    {
      label: "姓名：",
      prop: "shipper",
      type: "input",
      rule: [
        {
          required: true,
          message: "请输入发货人名字",
          trigger: "blur",
        },
      ],
    },
    {
      label: "电话：",
      prop: "phone",
      type: "input",
      rule: [{ required: true, message: "请输入电话号码", trigger: "blur" }],
    },
    {
      label: "国家：",
      prop: "country",
      type: "input",
      rule: [{ required: true, message: "请输入国家名称", trigger: "blur" }],
    },
    {
      label: "省份：",
      prop: "province",
      type: "input",
      rule: [{ required: true, message: "请输入省份", trigger: "blur" }],
    },
    {
      label: "城市：",
      prop: "city",
      type: "input",
      rule: [{ required: true, message: "请输入城市", trigger: "blur" }],
    },
    {
      label: "街道：",
      prop: "district",
      type: "input",
      rule: [{ required: true, message: "请输入街道", trigger: "blur" }],
    },
    {
      label: "地址：",
      prop: "add",
      type: "input",
      inputType: "textarea",
      rule: [
        { required: true, message: "请输入详细地址信息", trigger: "blur" },
      ],
    },
    {
      label: "邮编：",
      prop: "post_code",
      type: "input",
      rule: [{ required: true, message: "请输入邮编", trigger: "blur" }],
    },
    {
      label: "状态：",
      prop: "status",
      type: "switch",
      rule: [{ required: true, message: "请设置地址状态", trigger: "blur" }],
    },
    {
      label: "备注",
      prop: "remark",
      type: "input",
      inputType: "textarea",
    },
  ],
};
