import { request } from "./request.js";

export function menuList(data = {}) {
  return request({
    url: "index/menuList",
    method: "post",
    data,
  });
}

export function menuAdd(data = {}) {
  return request({
    url: "index/menuAdd",
    method: "post",
    data,
  });
}

export function menuDel(data = {}) {
  return request({
    url: "index/menuDel",
    method: "post",
    data,
  });
}

export function getFatherMenuList(data = {}) {
  return request({
    url: "index/getFatherMenuList",
    method: "post",
    data,
  });
}

export function homeMenu(data = {}) {
  return request({
    url: "index/homeMenu",
    method: "post",
    data,
  });
}
