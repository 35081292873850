export default {
  selection: true,
  searchFields: [
    // 搜索组件的配置项
    {
      name: "设置备注",
      value: "remark",
      type: "input",
      isFixed: true,
      span: 4,
    },
  ],
  columns: [
    // 表格列的配置
    {
      label: "归属月份",
      prop: "month",
      fixed: true,
    },
    {
      label: "业务员",
      prop: "user_name_ch",
    },
    {
      label: "新客销售额",
      prop: "new_sales",
    },
    {
      label: "复购销售额",
      prop: "old_sales",
    },
    {
      label: "新开客户数",
      prop: "new_client",
    },
    {
      label: "复购客户数",
      prop: "old_client",
    },
    {
      label: "录入时间",
      prop: "entry_time",
    },
    {
      label: "状态",
      prop: "status",
      type: "select",
      options: { 0: "作废", 1: "待确认", 2: "已确认" },
    },
    {
      label: "备注",
      prop: "remark",
    },
    {
      label: "操作",
      type: "operate_btn",
      prop: "",
      width: "260px",
    },
  ],
  forms: [
    {
      label: "归属月份：",
      prop: "month_id",
      type: "select",
      placeholder: "- 请选择月份 -",
      span: 11,
      rule: [
        {
          required: true,
          message: "- 请选择月份 -",
          trigger: "blur",
        },
      ],
    },
    {
      label: "业务人员：",
      prop: "user_id",
      type: "select",
      placeholder: "- 请选择业务员 -",
      span: 11,
      rule: [
        {
          required: true,
          message: "- 请选择业务员 -",
          trigger: "blur",
        },
      ],
    },
    {
      label: "新客销售额",
      prop: "new_sales",
      type: "input",
      span: 11,
      placeholder: "请输入当月新客销售额目标($)",
      rule: [
        {
          required: true,
          message: "请输入当月新客销售额目标($)",
          trigger: "blur",
        },
        {
          pattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
          message: "请输入非负数",
        },
      ],
    },
    {
      label: "复购销售额",
      prop: "old_sales",
      type: "input",
      span: 11,
      placeholder: "请输入当月复购销售额目标($)",
      rule: [
        {
          required: true,
          message: "请输入当月复购销售额目标($)",
          trigger: "blur",
        },
        {
          pattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
          message: "请输入非负数",
        },
      ],
    },
    {
      label: "新开客户数",
      prop: "new_client",
      type: "input",
      span: 11,
      placeholder: "请输入当月新开客户数目标",
      rule: [
        {
          required: true,
          message: "请输入当月新开客户数目标",
          trigger: "blur",
        },
        {
          pattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
          message: "请输入非负数",
        },
      ],
    },
    {
      label: "复购客户数",
      prop: "old_client",
      type: "input",
      span: 11,
      placeholder: "请输入当月复购客户数目标",
      rule: [
        {
          required: true,
          message: "请输入当月复购客户数目标",
          trigger: "blur",
        },
        {
          pattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
          message: "请输入非负数",
        },
      ],
    },
    {
      label: "备注",
      prop: "remark",
      type: "input",
      span: 22,
      inputType: "textarea",
    },
  ],
};
