export default {
  selection: false,
  columns: [
    // 表格列的配置
    {
      label: "标识码",
      prop: "acc_code",
    },
    {
      label: "账户名",
      prop: "acc_name",
    },
    {
      label: "账户号",
      prop: "acc_no",
    },
    {
      label: "开户行",
      prop: "bank",
    },
    {
      label: "创建人",
      prop: "user_id",
    },
    {
      label: "账户类型",
      prop: "acc_type",
      type: "select",
      options: { 1: "公司账户", 2: "供应商账户", 3: "服务商账户" },
    },
    {
      label: "账户状态",
      prop: "status",
      mustFill: true,
      type: "select",
      options: { 1: "有效", 0: "关闭" },
    },
    {
      label: "备注",
      prop: "remark",
      mustFill: true,
    },
  ],
  actions: {
    // 表格操作列的配置
    fixedWidth: 110, // fixedWidth（可选） 设置操作列的固定宽度，不加该参数默认是按钮list长度*50px
    list: [
      {
        label: "编辑",
        event: "edit",
        displayRule: () => {
          return true;
        },
      },
      {
        label: "删除",
        event: "del",
        displayRule: () => {
          return true;
        },
      },
    ],
  },
  forms: [
    {
      label: "识别短码",
      prop: "acc_code",
      type: "input",
      rule: [
        {
          required: true,
          message: "请输入账户信息简称，如TT-全球",
          trigger: "blur",
        },
      ],
    },
    {
      label: "账户名",
      prop: "acc_name",
      type: "input",
      rule: [
        {
          required: true,
          message: "请输入银行账户名",
          trigger: "blur",
        },
      ],
    },
    {
      label: "账户号",
      prop: "acc_no",
      type: "input",
      rule: [
        {
          required: true,
          message: "请输入银行账户号",
          trigger: "blur",
        },
      ],
    },
    {
      label: "开户银行",
      prop: "bank",
      type: "input",
      rule: [
        {
          required: true,
          message: "请输入开户银行（包含分行）",
          trigger: "blur",
        },
      ],
    },
    {
      label: "账户类型",
      prop: "acc_type",
      type: "select",
      options: { 1: "公司账户", 2: "供应商账户", 3: "服务商账户" },
      rule: [{ required: true, message: "请选择账户类型", trigger: "change" }],
    },
    {
      label: "账户状态:",
      prop: "status",
      type: "switch",
      modelValue: "1",
      activeText: "是",
      inactiveText: "否",
      rule: [{ required: true }],
    },
    {
      label: "账户排序",
      prop: "sort",
      type: "input",
      rule: [
        {
          required: true,
          message: "排序（可以将常用的账户放前面）",
          trigger: "blur",
        },
      ],
    },
    {
      label: "备注",
      prop: "remark",
      type: "input",
      inputType: "textarea",
    },
  ],
};
