export default {
  selection: true,
  searchFields: [
    // 搜索组件的配置项
    {
      name: "订单号",
      value: "ord_no",
      type: "input",
      isFixed: true,
      span: 4,
    },
    {
      name: "订单备注",
      value: "ord_remark",
      type: "input",
      isFixed: true,
      span: 4,
    },
  ],
  columns: [
    // 表格列的配置
    {
      label: "签约日期",
      prop: "create_time",
    },
    {
      label: "订单号",
      prop: "ord_no",
    },
    {
      label: "客户",
      prop: "company_name",
    },
    {
      label: "收款状态",
      prop: "collection_status",
    },
    {
      label: "应收款总额",
      prop: "receivables",
    },
    {
      label: "已收款总额",
      prop: "received_amount",
    },
    {
      label: "待收款总额",
      prop: "pending_payment",
    },
    {
      label: "归属人",
      prop: "user_name_ch",
    },
    {
      label: "订单备注",
      prop: "ord_remark",
    },
    {
      label: "操作",
      type: "operate_btn",
      prop: "",
      width: "120px",
    },
  ],
};
