import { request } from "./request.js";

export function dayScheduleList(data = {}) {
  return request({
    url: "daySchedule/dayScheduleList",
    method: "post",
    data,
  });
}
/**我的计划列表 */
export function myDayScheduleList(data = {}) {
  return request({
    url: "daySchedule/myDayScheduleList",
    method: "post",
    data,
  });
}
/**某用户查询当天计划列表 */
export function getUserTheNewestDaySchedule(data = {}) {
  return request({
    url: "daySchedule/getUserTheNewestDaySchedule",
    method: "post",
    data,
  });
}
/**删除的计划列表 */
export function recycleScheduleList(data = {}) {
  return request({
    url: "daySchedule/recycleScheduleList",
    method: "post",
    data,
  });
}
//新增、更新计划
export function addDaySchedule(data = {}) {
  return request({
    url: "daySchedule/addDaySchedule",
    method: "post",
    data,
  });
}
//完成、删除某一条计划详情
export function updateScheduleTips(data = {}) {
  return request({
    url: "daySchedule/updateScheduleTips",
    method: "post",
    data,
  });
}
//获取某天所有计划
export function dayScheduleTips(data = {}) {
  return request({
    url: "daySchedule/dayScheduleTips",
    method: "post",
    data,
  });
}
//获取当天的日计划
export function dayTipsList(data = {}) {
  return request({
    url: "daySchedule/dayTipsList",
    method: "post",
    data,
  });
}
