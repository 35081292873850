<template>
  <div style="height: 100%">
    <div style="text-align: center; margin: 20px; font-size: 30px">
      采购单 {{ pur_no }} 入库
    </div>
    <el-row>
      <el-col :span="24">
        <isdTable
          show-summary
          :loading="tableLoading"
          :rows="totalData"
          :height="600"
          :columns="config.columns"
          :selection="config.selection"
          :actions="config.actions"
          @action-click="actionClick"
          style="font-size: 12px"
        >
        </isdTable>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <isdPagination
          @size-change="sizeChange"
          :current-page="currentPage"
          @current-change="pageChange"
          layout="total,sizes,prev,pager,next,jumper"
          :page-size="pageSize"
          :page-sizes="[20, 50, 100]"
          :total="pageTotal"
        ></isdPagination>
      </el-col>
    </el-row>
    <!-- 新增/编辑弹窗 -->
    <isdFormDialog
      @submit.prevent
      ref="formDialogIns"
      :config="config.forms"
      :loading="formDialog.loading"
      :label-width="formDialog.labelWidth"
      :row="formDialog.row"
      v-model="formDialog.show"
      :col="formDialog.col"
      :title="formDialog.title"
      :width="formDialog.width"
      @close-dialog="closeFormDialog"
    ></isdFormDialog>
  </div>
</template>
<script>
import { ref, reactive, onMounted } from "vue";
import { useRoute, useRouter, route } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import {
  getPurchaseDetailList,
  inspectPurchaseOrder,
  getIMEICodeByPurchaseDetails,
} from "@/network/purchase.js";
import isdFormDialog from "@/views/components/isdFormDialog/Index.vue";
import isdTable from "@/views/components/isdTable/index.vue";
import configData from "./config";
import { ListClass } from "@/composition/ListClass.js";
import isdPagination from "@/views/components/isdPage/index.vue";
export default {
  components: {
    isdFormDialog,
    isdTable,
    isdPagination,
  },
  setup() {
    const formDialogIns = ref(null);
    const config = reactive(configData);
    // 新建/修改弹窗对象
    const formDialog = reactive({
      labelWidth: "90px", // 弹窗label宽度
      loading: false, // 表单加载中显示
      row: {}, // 数据项对象
      show: false, // 显示弹窗
      col: 1, // 弹窗内列数
      title: "", // 弹窗标题
      width: "450px", // 弹窗宽度
    });
    const route = ref(useRoute());
    const pur_id = ref(null);
    const pur_no = ref(null);
    //选中的应付款ID或者修改更新时，传过来的参数（upt+发票id）
    const purchaseInfo = route.value.query.arrData;
    class TableList extends ListClass {
      beforeGetList(condition) {
        condition.pur_id = pur_id.value;
        condition.pur_no = pur_no.value;
        condition.purchaseInfo = purchaseInfo; //把勾选的订单id也传过去
        return condition;
      }
    }
    const {
      currentPage,
      pageSize,
      pageTotal,
      tableLoading,
      totalData,
      getList,
      searchList,
      pageChange,
      sizeChange,
      handleSelectedRow,
    } = new TableList({
      Api: {
        listApi: getPurchaseDetailList,
      },
    });
    const getPurchaseDetails = () => {
      getList();
    };
    // 编辑删除事件
    const actionClick = (event, row) => {
      switch (event) {
        case "inspect":
          inspectOrder(row);
          break;
      }
    };
    const inspectOrder = (row) => {
      formDialog.show = true;
      formDialog.title = "采购产品入库";
      formDialog.row = row;
      getIMEICodeByPurchaseDetails({
        pur_detials_id: row.id,
      }).then((res) => {
        formDialog.row.imei = res.join("\n");
      });
      formDialog.row.store_num = row.num;
    };
    const closeAction = () => {
      formDialog.show = false;
      formDialog.row = {};
      formDialog.loading = false;
    };
    // 新增弹窗关闭
    const closeFormDialog = (params) => {
      if (!params) {
        closeAction();
        return;
      }
      formDialog.loading = true;
      params.imei = params.imei.split("\n");
      // 编辑
      inspectPurchaseOrder({
        data: params,
      }).then((res) => {
        if (res.code == 42) {
          formDialog.loading = false;
          getList();
          ElMessage.success(res.msg);
          closeAction();
        } else {
          ElMessage.error(res.msg);
          formDialog.loading = false;
          getList();
          closeAction();
        }
      });
    };
    return {
      closeFormDialog,
      formDialog,
      config,
      actionClick,
      currentPage,
      pageSize,
      pageTotal,
      tableLoading,
      totalData,
      getList,
      searchList,
      pageChange,
      sizeChange,
      handleSelectedRow,
      formDialogIns,
      getPurchaseDetails,
      pur_id,
      pur_no,
    };
  },
};
</script>
