<template>
  <div class="container">
    <div style="text-align: center">
      <img src="@/assets/logo.png" alt="logo" />
    </div>
    <div class="main">
      <el-form :model="formState" label-width="120px">
        <el-form-item label="登陆账号">
          <el-input v-model="formState.user_email" />
        </el-form-item>
        <el-form-item label="账号密码">
          <el-input
            v-model="formState.user_psw"
            type="password"
            placeholder="请输入密码"
            show-password
          />
        </el-form-item>
        <div class="login-form-wrap">
          <el-form-item name="remember" no-style>
            <el-checkbox v-model="formState.remember">自动登录</el-checkbox>
          </el-form-item>
        </div>
        <el-form-item>
          <el-button type="primary" native-type="submit" @click="login"
            >登录</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import { ref, reactive } from "vue";
import { ElMessage } from "element-plus";
import { useRoute, useRouter } from "vue-router";
import { Login } from "@/network/user.js";
export default {
  components: {},
  setup() {
    const token = window.localStorage.getItem("token");
    const router = useRouter();
    const route = useRoute();
    if (token) {
      // ElMessage.success("您已经登陆了");
      // debugger;
      // router.push({
      //   path: "home",
      // });
      const formState = reactive({
        user_email: "",
        user_psw: "",
        remember: true,
      });
    }
    const formState = reactive({
      user_email: "",
      user_psw: "",
      remember: true,
    });
    const login = () => {
      Login(formState).then((res) => {
        if (res.data) {
          ElMessage.success("登录成功");
          window.localStorage.setItem("token", res.data.token);
          router.push({
            path: "home",
          });
        } else {
          ElMessage.warning(res.msg);
        }
      });
    };
    return {
      formState,
      login,
    };
  },
};
</script>
<style>
.container {
  position: relative;
  width: 100%;
  min-height: 100%;
  padding: 110px 0 144px;
  background-repeat: no-repeat;
  background-position: center 110px;
  background-size: 100%;
}
.main {
  width: 368px;
  min-width: 260px;
  margin: 50px auto;
}
</style>
