export default {
  selection: true,
  searchFields: [
    // 搜索组件的配置项
    {
      name: "出运单号",
      value: "ship_no",
      type: "input",
      isFixed: true,
      span: 4,
    },
    {
      name: "发货备注（业务）",
      value: "remark",
      type: "input",
      isFixed: true,
      span: 4,
    },
  ],
  columns: [
    // 表格列的配置
    {
      label: "计划日期",
      prop: "create_time",
    },
    {
      label: "客户名字",
      prop: "cst_name_ch",
    },
    {
      label: "相关订单",
      prop: "ord_no",
    },
    {
      label: "出运单号",
      prop: "ship_no",
    },
    {
      label: "计划出运数",
      prop: "shipping_num",
    },
    {
      label: "发票编码",
      prop: "inv_no",
    },
    {
      label: "承运货代",
      prop: "spr_name_ch",
    },
    {
      label: "发货渠道",
      prop: "shipping_name_ch",
    },
    {
      label: "预期发货",
      prop: "expected_time",
    },
    {
      label: "发货备注（业务）",
      prop: "remark",
    },
    {
      label: "操作",
      type: "operate_btn",
      prop: "",
      width: "180px",
    },
  ],
};
