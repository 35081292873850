import dayjs from 'dayjs';
const utils = {
    formatDate(date) {
        return this.formatDateTime(date, 'YYYY-MM-DD');
    },
    formatDateTime(date, format = 'YYYY-MM-DD HH:mm:ss') {
        return date ? dayjs(date).format(format) : date;
    },
    ArrMove(arr, index, tindex) { // 数组移动，arr目标数组，index当前下标，tindex目标下标
        arr.splice(tindex, 0, arr.splice(index, 1)[0]);
        return arr;
    },
    // 产生唯一字符串
    /* eslint-disable no-magic-numbers */
    uuid  () {
        const s = [];
        const hexDigits = '0123456789abcdef';
        for (let i = 0; i < 36; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
        s[8] = s[13] = s[18] = s[23] = '-';

        const uuid = s.join('');
        return uuid;
    }
};
export default utils;
