import { request } from "./request.js";

export function orderList(data = {}) {
  return request({
    url: "order/orderList",
    method: "post",
    data,
  });
}
export function myOrderList(data = {}) {
  return request({
    url: "order/myOrderList",
    method: "post",
    data,
  });
}
export function orderAdd(data = {}) {
  return request({
    url: "order/orderAdd",
    method: "post",
    data,
  });
}
export function orderDel(data = {}) {
  return request({
    url: "order/orderDel",
    method: "post",
    data,
  });
}
export function editOrder(data = {}) {
  return request({
    url: "order/editOrder",
    method: "post",
    data,
  });
}
export function getOrderInfo(data = {}) {
  return request({
    url: "order/getOrderInfo",
    method: "post",
    data,
  });
}
export function addOrder(data = {}) {
  return request({
    url: "order/addOrder",
    method: "post",
    data,
  });
}
export function getNationList(data = {}) {
  return request({
    url: "order/getNationList",
    method: "post",
    data,
  });
}
export function getShippingList(data = {}) {
  return request({
    url: "order/getShippingList",
    method: "post",
    data,
  });
}
export function getProductList(data = {}) {
  return request({
    url: "order/getProductList",
    method: "post",
    data,
  });
}
export function addOrderDetails(data = {}) {
  return request({
    url: "order/addOrderDetails",
    method: "post",
    data,
  });
}
export function getProductDep(data = {}) {
  return request({
    url: "order/getProductDep",
    method: "post",
    data,
  });
}
export function getOrderDetailList(data = {}) {
  return request({
    url: "order/getOrderDetailList",
    method: "post",
    data,
  });
}
export function delProductDetails(data = {}) {
  return request({
    url: "order/delProductDetails",
    method: "post",
    data,
  });
}
export function imeiSearch(data = {}) {
  return request({
    url: "order/imeiSearch",
    method: "post",
    data,
  });
}
export function imeiDel(data = {}) {
  return request({
    url: "order/imeiDel",
    method: "post",
    data,
  });
}
