<template>
  <div class="order-detail-contain">
    <el-tabs v-model="activeName">
      <el-tab-pane label="列表" name="first">
        <list></list>
      </el-tab-pane>
      <el-tab-pane label="分类" name="second">
        <groupList></groupList>
      </el-tab-pane>
      <el-tab-pane label="回收站" name="four">
        <recycleList></recycleList>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { ref } from "vue";
import groupList from "./groupList/index.vue";
import list from "./supplierList/index.vue";
import recycleList from "./recycleList/index.vue";
export default {
  name: "supplierList",
  components: {
    list,
    recycleList,
    groupList,
  },
  setup() {
    const activeName = ref("first");
    return {
      activeName,
    };
  },
};
</script>
<style scoped lang="scss">
.order-detail-contain {
  height: 100%;
  padding: 20px 0;
  .el-tabs {
    height: 100%;
    :deep .el-tabs__content {
      height: calc(100% - 100px);
    }
    .el-tab-pane {
      height: 100%;
    }
  }
}
</style>
