export default {
  selection: true,
  columns: [
    // 表格列的配置
    {
      label: "所属客户",
      prop: "cst_id_v",
    },
    {
      label: "付款人",
      prop: "account_name",
    },
    {
      label: "付款账户",
      prop: "account_no",
      mustFill: true,
    },
    {
      label: "付款银行",
      prop: "bank_name",
      mustFill: true,
    },
    {
      label: "状态",
      prop: "status",
      type: "select",
      options: { 1: "有效", 0: "关闭" },
    },
    {
      label: "备注",
      prop: "remark",
      mustFill: true,
    },
  ],
  actions: {
    // 表格操作列的配置
    fixedWidth: 110, // fixedWidth（可选） 设置操作列的固定宽度，不加该参数默认是按钮list长度*50px
    list: [
      {
        label: "编辑",
        event: "edit",
        displayRule: () => {
          return true;
        },
      },
      {
        label: "删除",
        event: "del",
        displayRule: () => {
          return true;
        },
      },
    ],
  },
  forms: [
    // 表单页面的配置
    {
      label: "付款人",
      prop: "account_name",
      type: "input",
      rule: [{ required: true, message: "付款人不能为空", trigger: "blur" }],
    },
    {
      label: "付款账户",
      prop: "account_no",
      type: "input",
      rule: [{ required: true, message: "付款账户不能为空", trigger: "blur" }],
    },
    {
      label: "银行名称",
      prop: "bank_name",
      type: "input",
      rule: [{ required: true, message: "银行名称不能为空", trigger: "blur" }],
    },
    {
      label: "状态",
      prop: "status",
      type: "switch",
      activeValue: 1,
      inactiveValue: 0,
      options: [
        { label: "开启", value: 1 },
        { label: "关闭", value: 0 },
      ],
      rule: [{ required: true, message: "状态不能为空", trigger: "blur" }],
    },
    {
      label: "备注",
      prop: "remark",
      type: "input",
      inputType: "textarea",
    },
  ],
};
