<template>
  <div class="flex-layout order-management">
    <isdListPaper v-loading="tableLoading">
      <template v-slot:header-button>
        <el-button
          type="primary"
          :disabled="!selectedRows.length"
          @click="createPurchase"
        >
          生成采购单
        </el-button>
      </template>
      <template v-slot:header-search>
        <isdSearchBox
          ref="searchBoxIns"
          :search-key="config.name"
          :fields="config.searchFields"
          @search-change="searchList"
          :loading="tableLoading"
          class="track-search"
        ></isdSearchBox>
      </template>
      <template v-slot:list="props">
        <isdTable
          :loading="tableLoading"
          :rows="totalData"
          :height="props.tableHeight"
          :columns="config.columns"
          :selection="config.selection"
          :actions="config.actions"
          @selection-change="handleSelectedRow"
          style="font-size: 12px"
        >
          <template v-slot:col-append="{ col, row }">
            <span v-if="col.type === 'operate_btn'">
              <el-button
                round
                size="small"
                @click="confirmOrder(row.ord_list_id)"
                ><el-icon style="vertical-align: middle"><Select /></el-icon
                ><span style="vertical-align: middle">
                  完成采购
                </span></el-button
              >
              <el-button
                round
                size="small"
                @click="showPurchaseDetails(row.ord_list_id)"
                ><el-icon style="vertical-align: middle"><View /></el-icon
                ><span style="vertical-align: middle">
                  采购详情
                </span></el-button
              >
              <el-button round size="small" @click="printOrder(row.ord_list_id)"
                ><el-icon style="vertical-align: middle"><Printer /></el-icon
                ><span style="vertical-align: middle">
                  订单详情
                </span></el-button
              >
            </span>
          </template>
        </isdTable>
      </template>
      <template v-slot:footer>
        <isdPagination
          @size-change="sizeChange"
          :current-page="currentPage"
          @current-change="pageChange"
          layout="total,sizes,prev,pager,next,jumper"
          :page-size="pageSize"
          :page-sizes="[20, 50, 100]"
          :total="pageTotal"
        ></isdPagination>
      </template>
    </isdListPaper>
  </div>
</template>
<script>
import { purchaseWaitList, confirmOrderPlan } from "@/network/purchase.js";
import { Select, Printer, View } from "@element-plus/icons-vue";
import { defineComponent, ref, reactive, onMounted } from "vue";
import { useRouter } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import { ListClass } from "@/composition/ListClass.js";
import configData from "./config";
import isdTable from "@/views/components/isdTable/index.vue";
import isdPagination from "@/views/components/isdPage/index.vue";
import isdListPaper from "@/views/components/isdListPaper/index.vue";
import isdSearchBox from "@/views/components/isdSearchBox/index.vue";
export default defineComponent({
  components: {
    Select,
    Printer,
    View,
    isdTable,
    isdPagination,
    isdSearchBox,
    isdListPaper,
  },
  setup() {
    const config = reactive(configData); // 列表配置
    const searchBoxIns = ref(null); // 搜索实例
    const columnConfigIns = ref(null); // 列配置实例
    const statusDialogIns = ref(null); // 状态弹框实例
    const detailPageIns = ref(null); // 详情编辑页ref
    const tableBox = ref(null);
    // 格式化查询条件
    const dataFormat = (condition) => {
      condition.symbolType = ">";
      condition.listType = 1;
      return condition;
    };
    class TableList extends ListClass {
      beforeGetList(condition) {
        condition = dataFormat(condition);
        return condition;
      }
    }
    const {
      currentPage,
      pageNo,
      pageSize,
      pageTotal,
      tableLoading,
      totalData,
      getList,
      searchList,
      pageChange,
      sizeChange,
      selectedRows,
      handleSelectedRow,
    } = new TableList({
      Api: {
        listApi: purchaseWaitList,
      },
    });
    const router = useRouter();
    const printOrder = (val) => {
      let routeUrl = router.resolve({
        name: "printOrder",
        query: {
          ordId: val,
        },
      });
      window.open(routeUrl.href, "_blank");
    };
    const showPurchaseDetails = (val) => {
      let routeUrl = router.resolve({
        name: "showPurchaseDetails",
        query: {
          ordId: val,
        },
      });
      window.open(routeUrl.href, "_blank");
    };
    const createPurchase = () => {
      // 生成采购单
      const arr = selectedRows.value.map((item) => {
        return item.ord_list_id; // 只返回订单id号
      });
      router.push({
        name: "createPurchase",
        query: {
          arrData: arr,
        },
      });
    };
    // 设置已完成采购计划
    const confirmOrder = (val) => {
      ElMessageBox.confirm("确定要将该订单设置为已完成采购下单吗?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          confirmOrderPlan({ ord_list_id: val, type: 1 }).then((res) => {
            if (res.code == 0) {
              ElMessage.success(res.msg);
              getList();
            } else {
              ElMessage.error(res.msg);
            }
          });
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "设置取消",
          });
        });
    };
    onMounted(() => {
      getList();
    });
    return {
      tableBox,
      config,
      pageNo,
      pageSize,
      pageTotal,
      tableLoading,
      totalData,
      searchList,
      pageChange,
      sizeChange,
      handleSelectedRow,
      searchBoxIns,
      columnConfigIns,
      detailPageIns,
      statusDialogIns,
      currentPage,
      selectedRows,
      createPurchase,
      confirmOrder,
      printOrder,
      showPurchaseDetails,
    };
  },
});
</script>

<style lang="scss">
.order-management {
  .el-table th > .cell {
    word-break: keep-all;
  }
}
</style>
