<template>
  <div class="order-detail-contain">
    <el-tabs v-model="activeName">
      <el-tab-pane label="我的" name="first">
        <myList></myList>
      </el-tab-pane>
      <el-tab-pane label="所有" name="second" v-if="operate_right">
        <allList></allList>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { ref, onMounted } from "vue";
import { getUserRights } from "@/network/schedule.js";
import myList from "./My/index.vue";
import allList from "./All/index.vue";
import recycleList from "./Recycle/index.vue";
export default {
  name: "dayScheduleList",
  components: {
    myList,
    allList,
    recycleList,
  },
  setup() {
    const activeName = ref("first");
    const operate_right = ref(false);
    const getList = () => {
      getUserRights().then((res) => {
        if (res.code == 0) {
          operate_right.value = true;
        }
      });
    };
    onMounted(() => {
      getList();
    });
    return {
      activeName,
      operate_right,
    };
  },
};
</script>
<style scoped lang="scss">
.order-detail-contain {
  height: calc(100vh - 60px);
  overflow: auto;
  .el-tabs {
    height: 100%;
    :deep .el-tabs__content {
      height: calc(100% - 55px);
    }
    .el-tab-pane {
      height: 100%;
    }
  }
}
</style>
