<template>
  <div style="height: 100%">
    <!-- 新增计划日期 -->
    <el-dialog
      v-model="dialogFormDate"
      :title="title"
      :append-to-body="true"
      z-index="100"
    >
      <el-form :model="formDate">
        <el-form-item label="计划日期">
          <el-date-picker
            class="time_sel"
            v-model="formDate.schedule_date"
            type="date"
            placeholder="选择日期"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormDate = false">取消</el-button>
          <el-button type="primary" @click="">{{ goTitle }}</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 新增计划项目 -->
    <el-dialog
      v-model="dialogFormTips"
      :title="title"
      :append-to-body="true"
      z-index="100"
    >
      <el-form :model="formTips">
        <el-form-item label="时间节点：">
          <el-time-select
            class="time_sel"
            v-model="formTips.time"
            start="08:30"
            step="00:30"
            end="24:00"
            placeholder="选择时间"
          />
        </el-form-item>
        <el-form-item style="margin-bottom: 0px" label="计划内容：">
          <el-input
            type="textarea"
            :autosize="true"
            :resize="none"
            v-model="formTips.content"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormTips = false">取消</el-button>
          <el-button type="primary" @click="addTips">{{ goTitle }}</el-button>
        </span>
      </template>
    </el-dialog>

    <el-row gutter="24">
      <el-col :span="6" v-for="(item, index) in dayList" :key="index">
        <el-card class="box-card">
          <template #header>
            <div class="card-header">
              <span class="card-date">{{ item.schedule_date }}</span>
              <el-button
                @click="addTipsGo(item.day_id)"
                class="button"
                text
                round
                ><el-icon> <plus /> </el-icon>新增日程项</el-button
              >
            </div>
          </template>
          <el-row>
            <el-form :model="item">
              <el-col :span="24">
                <el-form-item v-for="(item, index) in item.tips" :key="index">
                  <el-col :span="24" :model="item">
                    <el-form-item>
                      <span slot="label">
                        <i class="el-icon-edit"></i>
                        {{ item.time }}：
                      </span>
                      {{ item.content }}
                    </el-form-item>
                  </el-col>
                </el-form-item>
              </el-col>
            </el-form>
          </el-row>

          <div class="footer-button">
            <el-button
              type="primary"
              @click="handleShowDetails(item.day_id)"
              size="small"
              text
              ><el-icon> <View /> </el-icon>&nbsp;详情</el-button
            >
          </div>
        </el-card>
      </el-col>
      <el-drawer v-model="showDetails" :title="`计划详情`" size="50%">
        <!-- <template #header>
          <h4>23/05/06 计划详情</h4>
        </template> -->
        <template #default>
          <el-form>
            <el-row v-for="(item, index) in dayTipsList" :key="index">
              <el-col :span="18" :model="item">
                <el-form-item>
                  <span slot="label">
                    <i class="el-icon-edit"></i>
                    {{ item.time }}：
                  </span>
                  {{ item.content }}
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-button
                  @click="completeTips(row)"
                  size="small"
                  type="success"
                  round
                  ><el-icon style="vertical-align: middle"> <Check /> </el-icon
                  >完成</el-button
                >
                <el-button @click="addTipsGo()" size="small" type="info" round
                  ><el-icon style="vertical-align: middle"> <Edit /> </el-icon
                  >修改</el-button
                >
                <el-button @click="delTips()" size="small" type="danger" round
                  ><el-icon style="vertical-align: middle"> <Delete /> </el-icon
                  >删除</el-button
                >
              </el-col>
            </el-row>
          </el-form>
        </template>
        <template #footer>
          <div style="flex: auto">
            <el-button @click="cancelClick">cancel</el-button>
            <el-button type="primary" @click="confirmClick">confirm</el-button>
          </div>
        </template>
      </el-drawer>

      <!-- <el-col :span="6">
        <el-card class="box-card">
          <template #header>
            <div class="card-header">
              <span>Card name</span>
              <el-button class="button" text>Operation button</el-button>
            </div>
          </template>
          <div v-for="o in 4" :key="o" class="text item">
            {{ "List item " + o }}
          </div>
        </el-card>
      </el-col>

      <el-col :span="6">
        <el-card class="box-card">
          <template #header>
            <div class="card-header">
              <span>Card name</span>
              <el-button class="button" text>Operation button</el-button>
            </div>
          </template>
          <div v-for="o in 4" :key="o" class="text item">
            {{ "List item " + o }}
          </div>
        </el-card>
      </el-col>

      <el-col :span="6">
        <el-card class="box-card">
          <template #header>
            <div class="card-header">
              <span>Card name</span>
              <el-button class="button" text>Operation button</el-button>
            </div>
          </template>
          <div v-for="o in 4" :key="o" class="text item">
            {{ "List item " + o }}
          </div>
        </el-card>
      </el-col> -->
    </el-row>
  </div>
</template>

<script>
import { ref, reactive, toRefs, onMounted } from "vue";
import { dayScheduleList, dayScheduleTips } from "@/network/daySchedule.js";
import { Delete, Edit, Plus, View, Check } from "@element-plus/icons-vue";
import { ElMessage, ElMessageBox } from "element-plus";
export default {
  components: {
    Delete,
    Edit,
    Plus,
    View,
    Check,
  },
  setup() {
    const currentPage = ref(1);
    const pageSize = ref(10);
    const pageTotal = ref(0);
    const state = reactive({
      //dataSource: [],
      title: "新增",
      goTitle: "添加",
      dialogFormDate: false,
      dialogFormTips: false,
      showDetails: false,
      dayList: [],
      dayTipsList: [],
    });
    const formDate = reactive({
      id: "",
      schedule_date: "",
      summary: "",
    });
    const formTips = reactive({
      id: "",
      day_id: "",
      content: "",
      time: "",
    });
    const getList = () => {
      dayScheduleList({
        currentPage: currentPage.value,
        pageSize: pageSize.value,
        listType: 1,
      }).then((res) => {
        if (res.code == 0) {
          state.dayList = res.data;
          pageTotal.value = res.data.pageTotal;
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    const handleSizeChange = (val) => {
      pageSize.value = val;
      getList();
    };
    const handleCurrentChange = (val) => {
      currentPage.value = val;
      getList();
    };
    onMounted(() => {
      getList();
    });
    //------------以上为列表数据
    const handleShowDetails = (id) => {
      state.showDetails = true;
      //dayScheduleTips(id)
      //console.log(id, "id");

      dayScheduleTips({ id: id }).then((res) => {
        console.log(res, "8888");
        if (res.code == 0) {
          state.dayTipsList = res.data;
          console.log(state.dayTipsList, "state.dayTipsList");
        } else {
          ElMessage.error(res.msg);
        }
      });
    };

    return {
      ...toRefs(state),
      formDate,
      formTips,
      currentPage,
      pageSize,
      pageTotal,
      handleSizeChange,
      handleCurrentChange,
      handleShowDetails,
    };
  },
};
</script>
<style scoped lang="scss">
/* .el-select :deep .select-popper {
  z-index: 8888 !important;
  top: auto !important;
  left: auto !important;
} */
.actionBtn {
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;
}
.el-row {
  margin-bottom: 20px;
}
.el-row:last-child {
  margin-bottom: 0;
}
.el-col {
  border-radius: 4px;
}
.el-card {
  height: 300px;
  position: relative;
}
::v-deep .el-card__body {
  // min-height: 50px;
  height: calc(100% - 103px);
  overflow: auto;
  box-sizing: border-box;
  padding-bottom: 34px;
  .footer-button {
    position: absolute;
    bottom: 10px;
    right: 26px;
  }
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card-date {
  font-size: 18px;
}
.text {
  font-size: 14px;
}
.item {
  margin-bottom: 18px;
}

.footer-button {
  /* background-color: red; */
  text-align: right;
}
::v-deep .time_sel {
  .el-input__inner {
    padding-left: 32px !important;
  }
}
</style>
<style></style>
