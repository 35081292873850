<template>
  <div class="columnChart" :id="id"></div>
</template>
<script>
import { Column, Bar, Line, DualAxes, Pie } from "@antv/g2plot";
export default {
  props: {
    id: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
    configData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  methods: {
    chart() {
      let chartType = null;
      if (this.type == "bar") {
        chartType = Bar;
      } else if (this.type == "column") {
        chartType = Column;
      } else if (this.type == "line") {
        chartType = Line;
      } else if (this.type == "dualAxes") {
        chartType = DualAxes;
      } else if (this.type == "pie") {
        chartType = Pie;
      }
      return new chartType(this.id, { ...this.configData });
    },
    init() {
      const stackedColumnPlot = this.chart();
      stackedColumnPlot.render();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.init();
    });
  },
};
</script>
<style lang="scss">
.columnChart {
  width: 100%;
  height: 100%;
}
</style>
