export default {
  selection: true,
  searchFields: [
    // 搜索组件的配置项
    {
      name: "设置备注",
      value: "remark",
      type: "input",
      isFixed: true,
      span: 4,
    },
  ],
  columns: [
    // 表格列的配置
    {
      label: "归属月份",
      prop: "month",
      fixed: true,
    },
    {
      label: "业务员",
      prop: "user_name_ch",
    },
    {
      label: "业务等级",
      prop: "level_name_ch",
    },
    {
      label: "本月请假",
      prop: "leave_days",
    },
    {
      label: "养老金",
      prop: "pension",
    },
    {
      label: "医疗保险",
      prop: "medical",
    },
    {
      label: "工伤保险",
      prop: "injury",
    },
    {
      label: "失业保险",
      prop: "unemployment",
    },
    {
      label: "生育医疗",
      prop: "fertility",
    },
    {
      label: "公积金",
      prop: "housing_fund",
    },
    {
      label: "其他收入",
      prop: "other_salary",
    },
    {
      label: "工资税费",
      prop: "taxes",
    },
    {
      label: "创建时间",
      prop: "create_time",
    },
    {
      label: "状态",
      prop: "status",
      type: "select",
      options: { 0: "作废", 1: "待确认", 2: "已确认" },
    },
    {
      label: "备注",
      prop: "remark",
    },
    {
      label: "操作",
      type: "operate_btn",
      prop: "",
      width: "260px",
    },
  ],
  forms: [
    {
      label: "归属月份：",
      prop: "month_id",
      type: "select",
      placeholder: "- 请选择月份 -",
      span: 11,
      rule: [
        {
          required: true,
          message: "- 请选择月份 -",
          trigger: "blur",
        },
      ],
    },
    {
      label: "业务人员：",
      prop: "user_id",
      type: "select",
      placeholder: "- 请选择业务员 -",
      span: 11,
      rule: [
        {
          required: true,
          message: "- 请选择业务员 -",
          trigger: "blur",
        },
      ],
    },
    {
      label: "业务等级：",
      prop: "level_id",
      type: "select",
      placeholder: "- 请选择业务等级 -",
      span: 11,
      rule: [
        {
          required: true,
          message: "- 请选择业务等级 -",
          trigger: "blur",
        },
      ],
    },
    {
      label: "养老金额：",
      prop: "pension",
      type: "input",
      span: 11,
      placeholder: "请输入当月个人承担的养老金",
      rule: [
        {
          pattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
          message: "请输入非负数",
        },
      ],
    },
    {
      label: "医疗保险：",
      prop: "medical",
      type: "input",
      span: 11,
      placeholder: "请输入当月个人承担的医疗保险",
      rule: [
        {
          pattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
          message: "请输入非负数",
        },
      ],
    },
    {
      label: "工伤保险：",
      prop: "injury",
      type: "input",
      span: 11,
      placeholder: "请输入当月个人承担的工伤保险",
      rule: [
        {
          pattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
          message: "请输入非负数",
        },
      ],
    },
    {
      label: "失业保险：",
      prop: "unemployment",
      type: "input",
      span: 11,
      placeholder: "请输入当月个人承担的失业保险",
      rule: [
        {
          pattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
          message: "请输入非负数",
        },
      ],
    },
    {
      label: "生育医疗：",
      prop: "fertility",
      type: "input",
      span: 11,
      placeholder: "请输入当月个人承担的生育医疗",
      rule: [
        {
          pattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
          message: "请输入非负数",
        },
      ],
    },
    {
      label: "住房公积金",
      prop: "housing_fund",
      type: "input",
      span: 11,
      placeholder: "请输入当月个人承担的住房公积金",
      rule: [
        {
          pattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
          message: "请输入非负数",
        },
      ],
    },
    {
      label: "其他收入：",
      prop: "other_salary",
      type: "input",
      span: 11,
      placeholder: "请输入当月其他收入",
      rule: [
        {
          pattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
          message: "请输入非负数",
        },
      ],
    },
    {
      label: "工资税费：",
      prop: "taxes",
      type: "input",
      span: 11,
      placeholder: "请输入当月税费，默认0",
      rule: [
        {
          pattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
          message: "请输入非负数",
        },
      ],
    },
    {
      label: "备注",
      prop: "remark",
      type: "input",
      span: 22,
      inputType: "textarea",
    },
  ],
};
