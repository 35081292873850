<template>
  <div style="height: 100%; padding: 10px">
    <div style="text-align: center; font-size: 30px; margin-bottom: 30px">
      Commercial Invoice
    </div>
    <el-row :gutter="24" style="margin: 20px 0px; font-size: 14px">
      <el-col :span="12">Invoice NO.: {{ invInfo.inv_no }} </el-col>
      <el-col :span="12" style="text-align: right"
        >Date:{{ invInfo.create_time }}</el-col
      >
    </el-row>
    <div>
      <div class="div_titel">Issuer Info:</div>
      <el-row :gutter="24" style="margin: 20px 0px">
        <el-col :span="8">Issuer: {{ shipperInfo.shipper }} </el-col>
        <el-col :span="8">Phone:{{ shipperInfo.phone }} </el-col>
        <el-col :span="8">Post Code:{{ shipperInfo.post_code }} </el-col>
      </el-row>
      <el-row :gutter="24" style="margin: 20px 0px">
        <el-col :span="24"
          >Address: {{ shipperInfo.add }} {{ shipperInfo.district }}
          {{ shipperInfo.city }} {{ shipperInfo.country }}
        </el-col>
      </el-row>
      <div class="div_titel">Consignee Info:</div>
      <el-row :gutter="24" style="margin: 20px 0px">
        <el-col :span="8"
          >Consignee: {{ consigneeInfo.consignee_name }}
        </el-col>
        <el-col :span="8">Phone:{{ consigneeInfo.consignee_phone }} </el-col>
        <el-col :span="8"
          >Post Code:{{ consigneeInfo.consignee_post_code }}
        </el-col>
      </el-row>
      <el-row
        :gutter="24"
        style="margin: 20px 0px"
        v-if="consigneeInfo.consignee_vat"
      >
        <el-col :span="8" v-if="consigneeInfo.consignee_vat"
          >VAT NO.: {{ consigneeInfo.consignee_vat }}
        </el-col>
        <el-col :span="8" v-if="consigneeInfo.consignee_eori"
          >EORI NO.: {{ consigneeInfo.consignee_eori }}
        </el-col>
        <el-col :span="8" v-if="consigneeInfo.consignee_gov"
          >GOV NO.: {{ consigneeInfo.consignee_gov }}
        </el-col>
      </el-row>
      <el-row :gutter="24" style="margin: 20px 0px">
        <el-col :span="8" v-if="consigneeInfo.consignee_comp"
          >Comp Name: {{ consigneeInfo.consignee_comp }}
        </el-col>
        <el-col :span="16">Address: {{ consigneeInfo.consignee_add }} </el-col>
      </el-row>
    </div>
    <div class="content">
      <el-table
        :data="dataSource"
        style="width: 100%; font-size: 12px"
        height="400px"
      >
        <el-table-column prop="pdt_item" label="Item No." width="110" />
        <el-table-column
          prop="hs_code"
          label="HS Code"
          width="100"
          v-if="invInfo.is_show_hsCode"
        />
        <el-table-column prop="dsp" label="Product Description" width="200" />
        <el-table-column prop="declared_value" label="Unit Price" width="100">
          <template #default="scope">
            {{ invInfo.currency_id }} {{ scope.row.declared_value }}
          </template>
        </el-table-column>
        <el-table-column prop="num" label="Qty" width="100" />
        <el-table-column prop="amount" label="Amount">
          <template #default="scope">
            {{ invInfo.currency_id }} {{ scope.row.amount }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div>
      <table
        v-if="invInfo.shipping_cost"
        style="width: 100%; line-height: 45px; color: #606266; font-size: 12px"
      >
        <tr>
          <td width="200">&nbsp;</td>
          <td width="100" v-if="invInfo.is_show_hsCode">&nbsp;</td>
          <td width="300">Shipping Cost:</td>
          <td>{{ invInfo.currency_id }} {{ invInfo.shipping_cost }}</td>
        </tr>
        <tr
          style="background-color: #f5f7fa; font-size: 16px; font-weight: 400"
        >
          <td width="200">Total</td>
          <td width="100" v-if="invInfo.is_show_hsCode">&nbsp;</td>
          <td width="100" style="text-align: left">
            {{ invInfo.total_num }} PCs
          </td>
          <td>{{ invInfo.currency_id }} {{ invInfo.total_sum }}</td>
        </tr>
      </table>
    </div>
    <div>
      <el-row :gutter="20" style="margin-top: 20px">
        <el-col v-if="invInfo.remark"> Remark: {{ invInfo.remark }} </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { ref, reactive, toRefs, onMounted } from "vue";
import { ElMessage } from "element-plus";
import { printOrderInvoice } from "@/network/showDetails.js";
import { useRoute } from "vue-router";
export default {
  components: {},
  setup() {
    const route = ref(useRoute());
    const state = reactive({
      dataSource: [],
      shipperInfo: [],
      consigneeInfo: [],
      invInfo: [],
    });
    const printInfo = route.value.query.arrData;
    const getList = () => {
      printOrderInvoice({ data: printInfo }).then((res) => {
        if (res.code == 0) {
          state.shipperInfo = res.data.shipperInfo;
          state.consigneeInfo = res.data.consigneeInfo;
          state.dataSource = res.data.lists;
          state.invInfo = res.data.invInfo;
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    onMounted(() => {
      getList();
    });
    return {
      ...toRefs(state),
      // getSummaries,
    };
  },
};
</script>
<style scoped>
.div_titel {
  font-size: 14px;
  font-weight: 400;
  border-left: 5px solid darkorange;
  padding-left: 5px;
}
</style>
