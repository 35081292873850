<template>
  <div style="height: 100%">
    <div style="text-align: center; margin: 20px; font-size: 30px">
      收/付款账户维护
    </div>
    <el-row :gutter="20">
      <el-col :span="4" :offset="22" style="margin-bottom: 20px">
        <el-button type="primary" @click="addAccount"
          ><span>新增账户</span></el-button
        >
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <isdTable
          :loading="tableLoading"
          :rows="totalData"
          :height="600"
          :columns="config.columns"
          :selection="config.selection"
          :actions="config.actions"
          @action-click="actionClick"
          style="font-size: 12px"
        >
        </isdTable>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <isdPagination
          @size-change="sizeChange"
          :current-page="currentPage"
          @current-change="pageChange"
          layout="total,sizes,prev,pager,next,jumper"
          :page-size="pageSize"
          :page-sizes="[20, 50, 100]"
          :total="pageTotal"
        ></isdPagination>
      </el-col>
    </el-row>
    <!-- 新增/编辑弹窗 -->
    <isdFormDialog
      ref="formDialogIns"
      :config="config.forms"
      :loading="formDialog.loading"
      :label-width="formDialog.labelWidth"
      :row="formDialog.row"
      v-model="formDialog.show"
      :col="formDialog.col"
      :title="formDialog.title"
      :width="formDialog.width"
      @close-dialog="closeFormDialog"
    ></isdFormDialog>
  </div>
</template>
<script>
import { ref, reactive, onMounted } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import {
  getPaymentAccountList,
  addPaymentAccount,
  deletePaymentAccount,
} from "@/network/basic.js";
import isdFormDialog from "@/views/components/isdFormDialog/Index.vue";
import { useRoute, useRouter } from "vue-router";
import isdTable from "@/views/components/isdTable/index.vue";
import configData from "./config";
import { ListClass } from "@/composition/ListClass.js";
import isdPagination from "@/views/components/isdPage/index.vue";
export default {
  components: {
    isdFormDialog,
    isdTable,
    isdPagination,
  },
  setup() {
    const route = ref(useRoute());
    const acc_ownership = route.value.query.acc_ownership;
    const formDialogIns = ref(null);
    const config = reactive(configData);
    // 新建/修改弹窗对象
    const formDialog = reactive({
      labelWidth: "90px", // 弹窗label宽度
      loading: false, // 表单加载中显示
      row: {}, // 数据项对象
      show: false, // 显示弹窗
      col: 1, // 弹窗内列数
      title: "", // 弹窗标题
      width: "600px", // 弹窗宽度
    });
    class TableList extends ListClass {
      beforeGetList(condition) {
        condition.symbolType = "=";
        condition.acc_ownership = acc_ownership ? acc_ownership : 0; //账户归属，默认0则归属公司
        return condition;
      }
    }
    const {
      currentPage,
      pageSize,
      pageTotal,
      tableLoading,
      totalData,
      getList,
      searchList,
      pageChange,
      sizeChange,
      handleSelectedRow,
    } = new TableList({
      Api: {
        listApi: getPaymentAccountList,
      },
    });
    onMounted(() => {
      getList();
    });
    // 订单详情表格编辑删除事件
    const actionClick = (event, row) => {
      switch (event) {
        case "edit":
          editAccount(row);
          break;
        case "del":
          delAccount(row);
          break;
      }
    };
    const editAccount = (val) => {
      formDialog.show = true;
      formDialog.title = "录入账户信息";
      val.status = "1";
      formDialog.row = val;
    };
    const closeAction = () => {
      formDialog.show = false;
      formDialog.row = {};
      formDialog.loading = false;
    };
    const addAccount = () => {
      // 新增行
      formDialog.show = true;
      formDialog.title = "新增收付款账户";
      formDialog.row = {
        id: "",
        acc_type: "1",
        acc_ownership: acc_ownership ? acc_ownership : 0,
        sort: 0,
        status: "1",
      };
    };
    // 新增弹窗关闭
    const closeFormDialog = (params) => {
      if (!params) {
        closeAction();
        return;
      }
      formDialog.loading = true;
      // 编辑
      addPaymentAccount(params).then((res) => {
        if (res.code == 0) {
          formDialog.loading = false;
          getList();
          ElMessage.success(res.msg);
          closeAction();
        } else {
          ElMessage.error(res.msg);
          formDialog.loading = false;
          getList();
          closeAction();
        }
      });
    };
    // 删除账户
    const delAccount = (row) => {
      ElMessageBox.confirm("确定要删除该条数据吗?", "警告", {
        confirmButtonText: "删除",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deletePaymentAccount({ id: row.id, type: 0 }).then((res) => {
            if (res.code == 0) {
              ElMessage.success(res.msg);
              getList();
            } else {
              ElMessage.error(res.msg);
            }
          });
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "删除取消",
          });
        });
    };
    return {
      closeFormDialog,
      formDialog,
      config,
      currentPage,
      pageSize,
      pageTotal,
      tableLoading,
      totalData,
      getList,
      searchList,
      pageChange,
      sizeChange,
      handleSelectedRow,
      formDialogIns,
      actionClick,
      addAccount,
      editAccount,
      //btn_title,
    };
  },
};
</script>
