<template>
  <div style="height: 100%">
    <div class="actionBtn">
      <el-button type="primary" @click="menuGo(0)"
        ><el-icon> <plus /> </el-icon><span>新增</span></el-button
      >
    </div>
    <el-dialog v-model="dialogFormVisible" :title="title">
      <el-form :model="formState">
        <el-form-item
          label="导航归属："
          :label-width="formLabelWidth"
          prop="fmid"
          required
        >
          <el-select v-model="formState.fmid" :popper-append-to-body="false">
            <el-option
              v-for="item in fatherMenuList"
              :key="item.mid"
              :label="item.label"
              :value="item.mid"
            />
          </el-select>
        </el-form-item>

        <el-form-item
          label="导航名称："
          :label-width="formLabelWidth"
          prop="label"
          required
        >
          <el-input
            v-model="formState.label"
            placeholder="输入导航名称："
            clearable
          />
        </el-form-item>
        <el-form-item label="导航图标：" :label-width="formLabelWidth">
          <el-input v-model="formState.icon" clearable />
        </el-form-item>
        <el-form-item
          label="导航url："
          :label-width="formLabelWidth"
          prop="url"
        >
          <el-input
            v-model="formState.url"
            placeholder="输入导航链接"
            clearable
          />
        </el-form-item>
        <el-form-item
          label="导航排序："
          :label-width="formLabelWidth"
          prop="sort"
        >
          <el-input
            v-model="formState.sort"
            placeholder="输入导航排序"
            clearable
          />
        </el-form-item>

        <el-form-item label="状态：" :label-width="formLabelWidth">
          <el-switch
            v-model="formState.status"
            class="ml-2"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-text="开启"
            inactive-text="关闭"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取消</el-button>
          <el-button type="primary" @click="add">{{ goTitle }}</el-button>
        </span>
      </template>
    </el-dialog>

    <div class="content">
      <el-table :data="dataSource" style="width: 100%" row-key="mid" border>
        <el-table-column fixed prop="label" label="菜单名" width="150" />
        <el-table-column prop="icon" label="图标" width="200" />
        <el-table-column prop="url" label="跳转URL" width="200" />
        <el-table-column prop="sort" label="排序" width="120" />
        <el-table-column prop="status_s" label="状态" width="120">
          <template #default="scope">
            <span :style="{ color: scope.row.status == 1 ? 'green' : 'red' }">
              {{ scope.row.status == 1 ? "开启" : "关闭" }}</span
            >
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="200">
          <template #default="scope">
            <el-button type="primary" @click="menuGo(scope.row)">
              <el-icon> <edit /> </el-icon><span>编辑</span></el-button
            >
            <el-button type="danger" @click="del(scope.row.mid)"
              ><el-icon> <delete /> </el-icon><span>删除</span></el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { ref, reactive, toRefs, onMounted } from "vue";
import {
  menuList,
  menuAdd,
  menuDel,
  getFatherMenuList,
} from "@/network/admin.js";
import { Delete, Edit, Plus } from "@element-plus/icons-vue";
import { ElMessage, ElMessageBox } from "element-plus";

export default {
  components: {
    Delete,
    Edit,
    Plus,
  },
  setup() {
    const state = reactive({
      dataSource: [],
      fatherMenuList: [],
      title: "添加菜单",
      goTitle: "添加",
      dialogFormVisible: false,
      formLabelWidth: "140px",
    });
    const formState = reactive({
      mid: 0,
      fmid: 0,
      label: "",
      icon: "",
      url: "",
      sort: 0,
      status: true,
    });
    const getList = () => {
      menuList().then((res) => {
        if (res.code == 0) {
          state.dataSource = res.data;
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    const handleSizeChange = (val) => {
      pageSize.value = val;
      getList();
    };
    const handleCurrentChange = (val) => {
      currentPage.value = val;
      getList();
    };
    onMounted(() => {
      getList();
    });

    //------------以上为列表数据
    const menuGo = (row) => {
      state.dialogFormVisible = true;
      //返回一级菜单栏列表
      getFatherMenuList().then((res) => {
        if (res.code == 0) {
          state.fatherMenuList = res.data;
        } else {
          ElMessage.error(res.msg);
        }
      });
      if (row) {
        Object.assign(formState, row);
        state.goTitle = "更新";
        state.title = "修改菜单信息";
        formState.status = Boolean(row.status);
      } else {
        state.title = "添加菜单";
        formState.mid = 0;
        formState.fmid = 0;
        formState.label = "";
        formState.icon = "";
        formState.url = "";
        formState.status = true;
        formState.sort = "";
      }
    };

    const add = () => {
      //无论新增还是修改，用户名都是必填;
      if (!formState.label) {
        ElMessage.info("请输入菜单名");
        return false;
      }
      menuAdd(formState).then((res) => {
        if (res.code == 0) {
          ElMessage.success(res.msg);
          state.dialogFormVisible = false;
          getList();
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    //------------以上为编辑和修改数据
    const del = (mid) => {
      if (!mid) {
        ElMessage("所选数据为空，请重新选择！");
      } else {
        ElMessageBox.confirm("确定要删除该条数据吗?", "警告", {
          confirmButtonText: "删除",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            menuDel({ mid: mid }).then((res) => {
              if (res.code == 0) {
                ElMessage.success(res.msg);
                getList();
              } else {
                ElMessage.error(res.msg);
              }
            });
          })
          .catch(() => {
            ElMessage({
              type: "info",
              message: "删除取消",
            });
          });
      }
    };

    return {
      ...toRefs(state),
      formState,
      menuGo,
      add,
      del,
      handleSizeChange,
      handleCurrentChange,
    };
  },
};
</script>
<style scoped>
.el-select :deep .select-popper {
  z-index: 8888 !important;
  top: auto !important;
  left: auto !important;
}
.actionBtn {
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;
}
.content {
  height: calc(100% - 180px);
  position: relative;
}
.el-table {
  position: absolute;
}
.el-table__header {
  width: 100%;
}
.el-table__body {
  height: calc(100% - 40px);
}
.el-pagination {
  position: absolute;
  bottom: -56px;
  right: 20px;
}
</style>
