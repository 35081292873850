<template>
  <div style="height: 100%; padding: 10px">
    <div style="text-align: center; font-size: 30px; margin-bottom: 30px">
      收款单 {{ baseInfo.list_no }} 详情
    </div>
    <div>
      <div class="div_title">基本信息：</div>
      <el-row :gutter="24" style="margin: 20px 0px">
        <el-col :span="8">客户名字：{{ baseInfo.company_name }} </el-col>
        <el-col :span="8">付款账户：{{ baseInfo.payer_id }} </el-col>
        <el-col :span="8">付款方式： {{ baseInfo.method_id }} </el-col>
      </el-row>
      <el-row :gutter="24" style="margin: 20px 0px">
        <el-col :span="8">付款货币：{{ baseInfo.currency_id }} </el-col>
        <el-col :span="8">付款类型：{{ baseInfo.type }} </el-col>
        <el-col :span="8">制单业务： {{ baseInfo.user_id }} </el-col>
      </el-row>
      <el-row :gutter="24" style="margin-bottom: 20px">
        <el-col :span="8"
          >&nbsp;&nbsp;&nbsp;支付日期：{{ baseInfo.payment_date }}
        </el-col>
        <el-col :span="8">制单日期：{{ baseInfo.create_time }} </el-col>
        <el-col :span="8">最近更新：{{ baseInfo.update_time }} </el-col>
      </el-row>
    </div>
    <div class="div_title" style="margin-top: 20px">收款详情：</div>
    <div class="content">
      <el-table
        :data="dataSource"
        show-summary
        style="width: 100%; font-size: 12px"
        height="300px"
      >
        <el-table-column prop="ord_no" label="订单编码" width="200" />
        <el-table-column prop="amount" label="销售收款" width="100" />
        <el-table-column prop="bank_fee" label="外币费用" width="100" />
        <el-table-column prop="hand_fee" label="手续费用" width="100" />
        <el-table-column prop="total" label="小计" width="100" />
        <el-table-column prop="remark" label="备注" />
      </el-table>
    </div>
    <div>
      <el-row :gutter="20" style="margin-top: 20px">
        <el-col> 收款备注：{{ baseInfo.remark }} </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { ref, reactive, toRefs, onMounted } from "vue";
import { ElMessage } from "element-plus";
import { showCollectionInfo } from "@/network/showDetails.js";
import { useRoute } from "vue-router";
export default {
  components: {},
  setup() {
    const route = ref(useRoute());
    const state = reactive({
      dataSource: [],
      baseInfo: [],
      relationInfo: [],
      //orderNoS: "",
    });
    const printInfo = route.value.query.arrData;
    const getList = () => {
      showCollectionInfo({ list_id: printInfo, status: 11 }).then((res) => {
        if (res.code == 0) {
          //state.orderNoS = res.data.orderNoS;
          state.baseInfo = res.data.baseInfo;
          state.relationInfo = res.data.relationInfo;
          state.dataSource = res.data.lists;
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    onMounted(() => {
      getList();
    });
    return {
      ...toRefs(state),
    };
  },
};
</script>
<style scoped>
.div_title {
  font-size: 14px;
  font-weight: 400;
  border-left: 5px solid darkorange;
  padding-left: 5px;
}
</style>
