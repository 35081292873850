<template>
  <div style="height: 100%; padding: 10px">
    <div style="text-align: center; font-size: 30px; margin-bottom: 30px">
      订单 {{ orderInfo.ord_no }} 收支详情
    </div>

    <div>
      <div class="div_titel">基本信息:</div>
      <el-row :gutter="24" style="margin: 20px 0px">
        <el-col :span="8">跟进业务：{{ cstInfo.user_name_ch }}</el-col>
        <el-col :span="8">签约日期：{{ orderInfo.create_time }} </el-col>
        <el-col :span="8">订单状态：{{ orderInfo.ord_status }}</el-col>
      </el-row>
      <el-row :gutter="24" style="margin: 20px 0px">
        <el-col :span="8">客户名字：{{ cstInfo.company_name }}</el-col>
        <el-col :span="8">客户来源：{{ cstInfo.source_id }} </el-col>
        <el-col :span="8">国家/地区：{{ cstInfo.nation_id }}</el-col>
      </el-row>
      <div class="div_titel">订单信息:</div>
      <el-row :gutter="24" style="margin: 20px 0px">
        <el-col :span="8">手续费收入：{{ orderInfo.hand_fee_income }} </el-col>
        <el-col :span="8"
          >物流费收入：{{ orderInfo.logistic_fee_income }}</el-col
        >
        <el-col :span="8">保险费收入：{{ orderInfo.insurance_income }}</el-col>
      </el-row>
    </div>
    <div class="content">
      <el-table
        :data="dataSource"
        show-summary
        :summary-method="getSummaries"
        style="width: 100%; font-size: 12px"
        height="200px"
      >
        <el-table-column prop="pdt_model" label="产品编码" width="300" />
        <el-table-column prop="pdt_dep" label="产品描述" width="300" />
        <el-table-column prop="pdt_num" label="销售数量" width="100" />
        <el-table-column prop="pdt_sale_price" label="销售单价" width="100" />
        <el-table-column prop="sale_amount" label="销售小计" width="100" />
        <el-table-column prop="store_num" label="采购数量" width="100" />
        <el-table-column prop="sum_total" label="采购小计（￥）" />
      </el-table>
    </div>
    <el-row :gutter="24" style="margin: 20px 0px">
      <el-col :span="6"
        >产品销售收入：{{ total_info.total_order_details_income }}</el-col
      >
      <el-col :span="6"
        >物流总收入：{{ total_info.total_shipping_cost_income }}
      </el-col>
      <el-col :span="6"
        >财务总收入：{{ total_info.total_hand_fee_income }}</el-col
      >
      <el-col :span="6">订单总收入：{{ total_info.total_income }}</el-col>
    </el-row>

    <div class="div_titel" style="margin: 20px 0px">物流信息:</div>
    <div class="content">
      <el-table
        :data="shippingInfo"
        show-summary
        style="width: 100%; font-size: 12px"
        height="200px"
      >
        <el-table-column prop="ord_no" label="订单编码" width="300" />
        <el-table-column prop="ship_no" label="出运编码" width="200" />
        <el-table-column prop="shipping_cost" label="物流费" width="100" />
        <el-table-column prop="packing_fee" label="打包费" width="100" />
        <el-table-column prop="insurance_fee" label="保险费" width="100" />
        <el-table-column prop="remote_fee" label="超区费" width="100" />
        <el-table-column prop="other_fee" label="其他费" width="100" />
        <el-table-column prop="total" label="小计" />
      </el-table>
    </div>
    <div class="div_titel" style="margin: 20px 0px">财务信息:</div>
    <div class="content">
      <el-table
        :data="collectionInfo"
        show-summary
        style="width: 100%; font-size: 12px"
        height="200px"
      >
        <el-table-column prop="ord_no" label="订单编码" width="300" />
        <el-table-column prop="list_no" label="收款编码" width="200" />
        <el-table-column prop="amount" label="销售收款" width="100" />
        <el-table-column prop="bank_fee" label="外币费用" width="100" />
        <el-table-column prop="hand_fee" label="手续费用" width="100" />
        <el-table-column prop="currency_id" label="支付货币" width="100" />
        <el-table-column prop="rate_saler" label="汇率" width="100" />
        <el-table-column prop="total" label="小计" />
      </el-table>
    </div>
    <el-row :gutter="24" style="margin: 20px 0px">
      <el-col :span="6"
        >产品采购支出：{{ total_info.total_order_details_cost }}</el-col
      >
      <el-col :span="6"
        >物流总支出：{{ total_info.total_shipping_cost }}
      </el-col>
      <el-col :span="6"
        >财务总支出：{{ total_info.total_hand_fee_cost }}</el-col
      >
      <el-col :span="6">订单总支出：{{ total_info.total_cost }}</el-col>
    </el-row>
    <div class="div_titel" style="margin: 20px 0px">订单汇总:</div>
    <el-row :gutter="24" style="margin: 20px 0px">
      <el-col :span="6"
        >实际总收款：{{ total_info.total_collection }} （{{
          total_info.total_collection_rmb
        }}）</el-col
      >
      <el-col :span="6">订单总支出：{{ total_info.total_cost }}</el-col>
      <el-col :span="6">订单总毛利：{{ total_info.gross_profit }}</el-col>
      <el-col :span="6">订单毛利率：{{ total_info.gross_margin }}</el-col>
    </el-row>
    <div style="margin: 20px 0px">&nbsp;</div>
  </div>
</template>
<script>
import { ref, reactive, toRefs, onMounted } from "vue";
import { ElMessage } from "element-plus";
import { showOrderProfitDetails } from "@/network/showDetails.js";
import { useRoute } from "vue-router";
export default {
  components: {},
  setup() {
    const route = ref(useRoute());
    const state = reactive({
      dataSource: [],
      collectionInfo: [],
      shippingInfo: [],
      total_info: [],
      orderInfo: [],
      cstInfo: [],
    });
    const printInfo = route.value.query.ordId;
    const getList = () => {
      showOrderProfitDetails({ data: printInfo }).then((res) => {
        if (res.code == 0) {
          state.orderInfo = res.data.orderInfo;
          state.cstInfo = res.data.cstInfo;
          state.dataSource = res.data.lists;
          state.collectionInfo = res.data.collectionInfo;
          state.shippingInfo = res.data.shippingInfo;
          state.total_info = res.data.total_info;
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    const getSummaries = (param) => {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index == 0) {
          sums[0] = "合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (
          !values.every((value) => isNaN(value)) &&
          [2, 4, 5, 6].includes(index)
        ) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        } else {
          sums[index] = "";
        }
      });

      return sums;
    };
    onMounted(() => {
      getList();
    });
    return {
      ...toRefs(state),
      getSummaries,
    };
  },
};
</script>
<style scoped>
.div_titel {
  font-size: 14px;
  font-weight: 400;
  border-left: 5px solid darkorange;
  padding-left: 5px;
}
.el-table__body-wrapper {
  height: auto !important;
}
</style>
