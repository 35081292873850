export default {
  selection: true,
  searchFields: [
    // 搜索组件的配置项
    {
      name: "公司名字",
      value: "spr_comp_name",
      type: "input",
      isFixed: true,
      span: 4,
    },
    {
      name: "联系人",
      value: "contact_person",
      type: "input",
      isFixed: true,
      span: 4,
    },
    {
      name: "供应商主营描述",
      value: "spr_dep",
      type: "input",
      isFixed: true,
      span: 4,
    },
  ],
  columns: [
    // 表格列的配置
    {
      label: "公司名字",
      prop: "spr_comp_name",
    },
    {
      label: "分类",
      prop: "spr_group_name_ch",
    },
    {
      label: "联系人",
      prop: "contact_person",
    },
    {
      label: "联系电话",
      prop: "spr_phone",
    },
    {
      label: "公司对接人",
      prop: "user_name_ch",
    },
    {
      label: "创建时间",
      prop: "create_time",
    },
    {
      label: "状态",
      prop: "spr_status",
      mustFill: true,
      type: "select",
      options: { 1: "有效", 0: "关闭" },
    },
    {
      label: "供应商简介",
      prop: "spr_dep",
    },
    {
      label: "备注",
      prop: "spr_remark",
      mustFill: true,
    },
  ],
};
