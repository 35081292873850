<template>
  <div class="order-detail-contain">
    <el-tabs v-model="activeName">
      <el-tab-pane label="我的客户" name="first">
        <myCustomerList></myCustomerList>
      </el-tab-pane>
      <el-tab-pane label="我关注的" name="three">
        <focusCustomerList></focusCustomerList>
      </el-tab-pane>
      <el-tab-pane label="所有客户" name="second" v-if="operate_right">
        <allCustomerList></allCustomerList>
      </el-tab-pane>
      <!-- <el-tab-pane label="回收站" name="four">
        <recycleList></recycleList>
      </el-tab-pane> -->
    </el-tabs>
  </div>
</template>
<script>
import { getUserRights } from "@/network/customer.js";
import { ref, onMounted } from "vue";
import allCustomerList from "./allCustomer/index.vue";
import myCustomerList from "./myCustomer/index.vue";
import focusCustomerList from "./focusCustomer/index.vue";
// import recycleList from "./reCustomer/index.vue";
export default {
  name: "customerList",
  components: {
    allCustomerList,
    myCustomerList,
    focusCustomerList,
    // recycleList,
  },
  setup() {
    const activeName = ref("first");
    const operate_right = ref(false);
    const getList = () => {
      getUserRights().then((res) => {
        if (res.code == 0) {
          operate_right.value = true;
        }
      });
    };
    onMounted(() => {
      getList();
    });
    return {
      activeName,
      operate_right,
    };
  },
};
</script>
<style scoped lang="scss">
.order-detail-contain {
  height: 100%;
  padding: 20px 0;
  .el-tabs {
    height: 100%;
    :deep .el-tabs__content {
      height: calc(100% - 100px);
    }
    .el-tab-pane {
      height: 100%;
    }
  }
}
</style>
