<template>
  <div style="height: 100%; padding: 10px">
    <div style="text-align: center; font-size: 30px; margin-bottom: 30px">
      发货单 {{ baseInfo.ship_no }} 详情
    </div>
    <div>
      <div class="div_title">基本信息：</div>
      <el-row :gutter="24" style="margin: 20px 0px">
        <el-col :span="8">发票编码：{{ baseInfo.inv_list_id }} </el-col>
        <el-col :span="8">发货渠道：{{ baseInfo.shipping_id }} </el-col>
        <el-col :span="8">物流单号：{{ baseInfo.shipping_waybill_no }} </el-col>
      </el-row>
      <el-row :gutter="24" style="margin-bottom: 20px">
        <el-col :span="8"
          >&nbsp;&nbsp;&nbsp;计划日期：{{ baseInfo.create_time }}
        </el-col>
        <el-col :span="8">预期发货：{{ baseInfo.expected_time }} </el-col>
        <el-col :span="8">发货日期：{{ baseInfo.shipping_time }} </el-col>
      </el-row>
      <div class="div_title">包裹信息：</div>
      <el-row :gutter="24" style="margin: 20px 0px">
        <el-col :span="8">包裹实重：{{ baseInfo.pkg_weight }} KG</el-col>
        <el-col :span="5" :offset="1">长：{{ baseInfo.pkg_l }} cm</el-col>
        <el-col :span="5">宽：{{ baseInfo.pkg_w }} cm</el-col>
        <el-col :span="5">高：{{ baseInfo.pkg_h }} cm</el-col>
      </el-row>
    </div>
    <div class="content">
      <el-table
        :data="dataSource"
        show-summary
        style="width: 100%; font-size: 12px"
        height="300px"
      >
        <el-table-column prop="pdt_no" label="产品编码" width="300" />
        <el-table-column prop="pdt_color" label="产品颜色" width="100" />
        <el-table-column prop="num" label="下单数量" width="100" />
        <el-table-column prop="dsp" label="产品描述" />
      </el-table>
    </div>
    <div class="div_title" style="margin-top: 20px">费用分摊：</div>
    <div class="content">
      <el-table
        :data="relationInfo"
        show-summary
        style="width: 100%; font-size: 12px"
        height="200px"
      >
        <el-table-column prop="ord_no" label="订单编码" width="300" />
        <el-table-column prop="shipping_cost" label="物流费用" width="100" />
        <el-table-column prop="packing_fee" label="打包费用" width="100" />
        <el-table-column prop="insurance_fee" label="保险费用" />
        <el-table-column prop="remote_fee" label="超区费用" />
        <el-table-column prop="other_fee" label="其他费用" />
        <el-table-column prop="total" label="费用小计" />
        <el-table-column prop="remark" label="分摊备注" />
      </el-table>
    </div>
    <div>
      <el-row :gutter="20" style="margin-top: 20px">
        <el-col> 出运备注：{{ baseInfo.remark }} </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { ref, reactive, toRefs, onMounted } from "vue";
import { ElMessage } from "element-plus";
import { showDeliveredInfo } from "@/network/showDetails.js";
import { useRoute } from "vue-router";
export default {
  components: {},
  setup() {
    const route = ref(useRoute());
    const state = reactive({
      dataSource: [],
      baseInfo: [],
      relationInfo: [],
      //orderNoS: "",
    });
    const printInfo = route.value.query.arrData;
    const getList = () => {
      showDeliveredInfo({ data: printInfo }).then((res) => {
        if (res.code == 0) {
          //state.orderNoS = res.data.orderNoS;
          state.baseInfo = res.data.baseInfo;
          state.relationInfo = res.data.relationInfo;
          state.dataSource = res.data.lists;
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    onMounted(() => {
      getList();
    });
    return {
      ...toRefs(state),
    };
  },
};
</script>
<style scoped>
.div_title {
  font-size: 14px;
  font-weight: 400;
  border-left: 5px solid darkorange;
  padding-left: 5px;
}
</style>
