<template>
  <div style="height: 100%">
    <el-row :gutter="20">
      <el-col
        :span="4"
        :offset="22"
        style="margin-bottom: 20px; margin-top: 20px"
      >
        <el-button type="primary" @click="addConfig()"
          ><span>新增地址</span></el-button
        >
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <isdTable
          :loading="tableLoading"
          :rows="totalData"
          :height="600"
          :columns="config.columns"
          :selection="config.selection"
          :actions="config.actions"
          style="font-size: 12px"
        >
          <template v-slot:col-append="{ col, row }">
            <span v-if="col.type === 'action'">
              <el-button type="text" @click="editCompAdd(row)">编辑</el-button>
              <el-button
                v-if="row.status"
                type="text"
                @click="deleteCompAdd({ id: row.id, type: 0 })"
                >删除</el-button
              >
              <el-button
                v-else
                type="text"
                @click="deleteCompAdd({ id: row.id, type: 1 })"
                >恢复</el-button
              >
            </span>
          </template>
        </isdTable>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <isdPagination
          @size-change="sizeChange"
          :current-page="currentPage"
          @current-change="pageChange"
          layout="total,sizes,prev,pager,next,jumper"
          :page-size="pageSize"
          :page-sizes="[20, 50, 100]"
          :total="pageTotal"
        ></isdPagination>
      </el-col>
    </el-row>
    <!-- 新增/编辑弹窗 -->
    <isdFormDialog
      ref="formDialogIns"
      :config="config.forms"
      :loading="formDialog.loading"
      :label-width="formDialog.labelWidth"
      :row="formDialog.row"
      v-model="formDialog.show"
      :col="formDialog.col"
      :title="formDialog.title"
      :width="formDialog.width"
      @close-dialog="closeFormDialog"
    ></isdFormDialog>
  </div>
</template>
<script>
import { ref, reactive, onMounted } from "vue";
import { getCompAddList, addCompAdd, deleteAdd } from "@/network/basic.js";
import { ElMessage, ElMessageBox } from "element-plus";
import isdFormDialog from "@/views/components/isdFormDialog/Index.vue";
import isdTable from "@/views/components/isdTable/index.vue";
import configData from "./config";
import { ListClass } from "@/composition/ListClass.js";
import isdPagination from "@/views/components/isdPage/index.vue";
export default {
  components: {
    isdFormDialog,
    isdTable,
    isdPagination,
  },
  setup() {
    const formDialogIns = ref(null);
    const config = reactive(configData);
    // 新建/修改弹窗对象
    const formDialog = reactive({
      labelWidth: "90px", // 弹窗label宽度
      loading: false, // 表单加载中显示
      row: {}, // 数据项对象
      show: false, // 显示弹窗
      col: 2, // 弹窗内列数
      title: "新建", // 弹窗标题
      width: "70%", // 弹窗宽度
    });
    class TableList extends ListClass {
      beforeGetList(condition) {
        return condition;
      }
    }
    const {
      currentPage,
      pageSize,
      pageTotal,
      tableLoading,
      totalData,
      getList,
      searchList,
      pageChange,
      sizeChange,
      handleSelectedRow,
    } = new TableList({
      Api: {
        listApi: getCompAddList,
      },
    });
    onMounted(() => {
      getList();
    });
    const editCompAdd = (row) => {
      formDialog.show = true;
      formDialog.title = "编辑地址信息";
      row.status = "1";
      formDialog.row = row;
    };
    const closeAction = () => {
      formDialog.show = false;
      formDialog.row = {};
      formDialog.loading = false;
    };
    // 删除地址
    const deleteCompAdd = (row) => {
      ElMessageBox.confirm("确定要删除该条数据吗?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteAdd({ id: row.id, type: row.type }).then((res) => {
            if (res.code == 0) {
              ElMessage.success(res.msg);
              getList();
            } else {
              ElMessage.error(res.msg);
            }
          });
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "删除取消",
          });
        });
    };
    // 新增弹窗关闭
    const closeFormDialog = (params) => {
      if (!params) {
        closeAction();
        return;
      }
      addCompAdd(params).then((res) => {
        if (res.code == 0) {
          formDialog.loading = false;
          getList();
          ElMessage.success(res.msg);
          closeAction();
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    const addConfig = () => {
      // 新增行
      formDialog.show = true;
      formDialog.title = "新增地址信息";
      formDialog.row = {
        id: "",
        status: "1",
      };
    };
    return {
      addConfig,
      closeFormDialog,
      formDialog,
      config,
      currentPage,
      pageSize,
      pageTotal,
      tableLoading,
      totalData,
      getList,
      searchList,
      pageChange,
      sizeChange,
      handleSelectedRow,
      formDialogIns,
      editCompAdd,
      deleteCompAdd,
    };
  },
};
</script>
<style scoped>
.div_title {
  margin: 20px 0;
  padding-left: 10px;
  border-left: 10px solid grey;
  font-size: 20px;
}
</style>
