<template>
  <div style="height: 100%">
    <div style="text-align: center; margin: 20px; font-size: 30px">
      {{ title }}<v-if formState.order>：{{ formState.ord_no }}</v-if>
    </div>
    <el-form :model="formState" :rules="rules">
      <div class="div_title">客户基本信息：</div>
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="客户名字：">
            <el-input v-model="customerInfo.company_name" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="国家/地区：">
            <el-input v-model="customerInfo.nation_id_v" disabled />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="客户归属：">
            <el-input v-model="customerInfo.user_id_v" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="国家来源：">
            <el-input v-model="customerInfo.source_id_v" disabled />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="24">
          <el-form-item label="客户备注：">
            <el-input
              type="textarea"
              v-model="customerInfo.cst_remark"
              disabled
            />
          </el-form-item>
        </el-col>
      </el-row>
      <div class="div_title">订单基本信息：</div>
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="手续费收入：">
            <el-input
              v-model="formState.hand_fee_income"
              placeholder="收取客户的手续费收入"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="物流费收入：">
            <el-input
              v-model="formState.logistic_fee_income"
              placeholder="收取客户的物流费用"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="保险费收入：">
            <el-input
              v-model="formState.insurance_income"
              placeholder="收取客户的保险费收入"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="24">
          <el-form-item label="订单备注：">
            <el-input
              type="textarea"
              v-model="formState.ord_remark"
              placeholder="输入订单备注信息"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-row :gutter="20">
      <el-col :span="4" :offset="11"
        ><el-button size="large" type="primary" @click="add()"
          ><span>保存订单</span></el-button
        ></el-col
      >
    </el-row>
  </div>
</template>
<script>
import { ref, reactive, toRefs, onMounted } from "vue";
import { getOrderInfo, orderAdd } from "@/network/order.js";
import { useRoute } from "vue-router";
import { getCustomerInfo } from "@/network/customer.js";
import { ElMessage } from "element-plus";
export default {
  emits: ["getOderListId"],
  setup(props, context) {
    const orderListId = ref(null);
    const state = reactive({
      customerInfo: [],
      getCurrencyList: [],
      getNationList: [],
      getShippingList: [],
      getProductList: [],
      title: "新增订单",
      goTitle: "添加",
      cstId: "",
    });

    const formState = reactive({
      ord_list_id: "",
      ord_no: "",
      cst_id: "",
      hand_fee_income: "",
      logistic_fee_income: "",
      insurance_income: "",
      ord_approval_id: "",
      create_time: "",
      update_time: "",
      ord_status: "",
      ord_remark: "",
    });
    const route = ref(useRoute());
    const ordId = ref(route.value.query.ordId);
    const cstId = route.value.query.cstId;
    const initOrderData = async () => {
      if (cstId) {
        //新增订单
        state.cstId = cstId;
        formState.ord_list_id = 0;
        formState.ord_no = "";
        formState.cst_id = cstId;
        formState.hand_fee_income = "";
        formState.logistic_fee_income = "";
        formState.insurance_income = "";
        formState.ord_approval_id = 1;
        formState.create_time = "";
        formState.update_time = "";
        formState.ord_status = "";
        formState.ord_remark = "";
      } else {
        //修改订单
        await getOrderInfo({ ord_list_id: ordId.value }).then((res) => {
          if (res.code == 0) {
            Object.assign(formState, res.data);
            state.goTitle = "更新";
            state.title = "修改订单";
            state.cstId = formState.cst_id;
          } else {
            ElMessage.error(res.msg);
          }
        });
      }
    };
    onMounted(async () => {
      await initOrderData();
      await getCustomerInfo({ cst_id: state.cstId }).then((res) => {
        if (res.code == 0) {
          state.customerInfo = res.data;
        } else {
          ElMessage.error(res.msg);
        }
      });
    });

    const add = () => {
      orderAdd(formState).then((res) => {
        if (res.code == 0) {
          ElMessage.success(res.msg);
          orderListId.value = res.data;
          context.emit("getOrderListId", orderListId.value);
          Object.assign(formState, res.data);
        } else {
          ElMessage.error(res.msg);
        }
      });
    };

    return {
      ...toRefs(state),
      formState,
      add,
      orderListId,
    };
  },
};
</script>
<style scoped>
.div_title {
  margin: 20px 0;
  padding-left: 10px;
  border-left: 10px solid grey;
  font-size: 20px;
}
</style>
