<template>
  <div style="height: 100%">
    <div style="text-align: center; margin: 20px; font-size: 30px">
      采购单 {{ formState.pur_no }}
    </div>
    <el-form :model="formState" :rules="rules">
      <div class="div_title">供应商信息：</div>
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="供应商：" prop="spr_id">
            <el-select
              v-model="formState.spr_id"
              :popper-append-to-body="false"
              placeholder="-请选择供应商-"
              clearable
              filterable
            >
              <el-option
                v-for="item in supplierList"
                :key="item.spr_id"
                :label="item.spr_comp_name"
                :value="item.spr_id"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="div_title">采购单信息：</div>
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="采购类型：" prop="pur_type">
            <el-select
              v-model="formState.pur_type"
              :popper-append-to-body="false"
              placeholder="-请选择采购单类型-"
            >
              <el-option
                v-for="item in paymentTypeList"
                :key="item.payment_id"
                :label="item.payment_name_ch"
                :value="item.payment_id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="一单多采：">
            <el-switch
              v-model="formState.is_create_pur"
              :popper-append-to-body="false"
              inline-prompt
              active-text="是"
              inactive-text="否"
            >
            </el-switch>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="下单日期：" prop="order_time">
            <el-date-picker
              v-model="formState.order_time"
              type="date"
              placeholder="选择采购单下单日期"
              format="YYYY/MM/DD"
              value-format="YYYY-MM-DD"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="预期入库：" prop="expected_time">
            <el-date-picker
              v-model="formState.expected_time"
              type="date"
              placeholder="请设置采购订单预计入库日期"
              format="YYYY/MM/DD"
              value-format="YYYY-MM-DD"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="24">
          <el-form-item label="采购备注：">
            <el-input
              type="textarea"
              v-model="formState.remark"
              placeholder="采购该订单的备注信息"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="4" :offset="20">
          <el-button size="default" type="primary" @click="add()">{{
            btn_title
          }}</el-button>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import { ref, reactive, toRefs, onMounted } from "vue";
import {
  createPurchase,
  addPurchase,
  getSupplierList,
  getPaymentTypeList,
} from "@/network/purchase.js";
import { useRoute, useRouter, route } from "vue-router";
import { ElMessage } from "element-plus";
export default {
  emits: ["getPurchaseInfo"],
  setup(props, context) {
    const route = ref(useRoute());
    const state = reactive({
      dataSource: [],
      supplierList: [],
      paymentTypeList: [],
      btn_title: "下一步",
    });
    const purchaseInfo = route.value.query.arrData;
    if (purchaseInfo[0] == "udt") {
      state.btn_title = "更新";
    }
    const formState = reactive({
      id: "",
      pur_no: "",
      spr_id: "",
      pur_type: "",
      expected_time: "",
      order_time: "",
      warehouse_time: "",
      is_create_pur: true,
      remark: "",
    });
    const rules = reactive({
      spr_id: [
        {
          required: true,
          message: "请选择供应商/服务商",
          trigger: "blur",
        },
      ],
      pur_type: [
        {
          required: true,
          message: "请选择采购订单类型",
          trigger: "blur",
        },
      ],
      expected_time: [
        {
          required: true,
          message: "请设置预计到货日期",
          trigger: "blur",
        },
      ],
      order_time: [
        {
          required: true,
          message: "请选择采购单下单日期",
          trigger: "blur",
        },
      ],
    });
    const router = useRouter();
    const getList = () => {
      createPurchase({ data: purchaseInfo }).then((res) => {
        //仅用于加载生成页面
        if (res.code == 0) {
          Object.assign(formState, res.data.list);
        } else {
          ElMessage.error(res.msg);
          router.push({
            name: "purchase",
          });
        }
      });
      getSupplierList({ symbolType: "=", spr_type: 1 }).then((res) => {
        if (res.code == 0) {
          state.supplierList = res.data;
        } else {
          ElMessage.error(res.msg);
        }
      });
      getPaymentTypeList({ symbolType: "=", payment_type: 2 }).then((res) => {
        if (res.code == 0) {
          state.paymentTypeList = res.data;
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    onMounted(() => {
      getList();
    });
    const add = () => {
      //添加或更新
      addPurchase({ formState: formState, purchaseInfo: purchaseInfo }).then(
        (res) => {
          if (res.code == 0) {
            ElMessage.success(res.msg);
            formState.id = res.data.id;
            formState.pur_no = res.data.pur_no;
            context.emit("getPurchaseInfo", {
              pur_id: formState.id,
              pur_no: formState.pur_no,
            });
          } else {
            ElMessage.error(res.msg);
          }
        }
      );
    };
    return {
      ...toRefs(state),
      formState,
      add,
      rules,
    };
  },
};
</script>
<style scoped>
.div_title {
  margin: 20px 0;
  padding-left: 10px;
  border-left: 10px solid grey;
  font-size: 20px;
}
</style>
